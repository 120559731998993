import { Component, OnInit } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { AuditState } from 'src/app/pages/audit/auditState';

@Component({
  selector: 'app-customfield-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss']
})
export class CustomFieldDatetimepickerComponent extends BaseControlComponent implements OnInit {
  constructor(private auditState: AuditState) {
    super();
  }

  displayFormat: string;
	hideDropdownButton: boolean;
  readOnly: boolean = false;
  inputMask: string;

  ngOnInit(): void {
    super.ngOnInit();

    this.inputMask = (super.getExtendedProperty("InputMask", "") as string);
    this.readOnly = this.auditState.readonly;
		this.hideDropdownButton = super.getExtendedProperty("HideDropdownButton", false);
  }
}
