<app-popup #popup>
  <div class="layout-vertical" [formGroup]="form" id="ResultItem">
    <div class="layout-horizontal padding-left height-normal color-set-blue">
      <div class="layout-left">
        {{'dataSource.item.edit.title' | translate}}
      </div>
    </div>

    <div class="layout-center padding-normal">
      <div formArrayName="fieldTitles">
        <div *ngFor="let field of fieldTitles.controls; let i=index">
          <div [formGroupName]="i">
            <label class="field">{{field.get("key").value}}</label>

            <div [ngSwitch]="field.get('controlType').value" class="zone">
              <div *ngSwitchCase="customFieldControlTypes.TextBox">
                <dx-text-box class="text-box" formControlName="data" valueChangeEvent="keyup" [maxLength]="field.get('maxLength').value">
                </dx-text-box>
              </div>

              <div *ngSwitchCase="customFieldControlTypes.RichTextBox">
                <dx-html-editor formControlName="data" height="200px">
                    <dxo-toolbar>
                      <dxi-item name="undo"></dxi-item>
                      <dxi-item name="redo"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                      <dxi-item name="font" [acceptedValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="bold"></dxi-item>
                      <dxi-item name="italic"></dxi-item>
                      <dxi-item name="strike"></dxi-item>
                      <dxi-item name="underline"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="alignLeft"></dxi-item>
                      <dxi-item name="alignCenter"></dxi-item>
                      <dxi-item name="alignRight"></dxi-item>
                      <dxi-item name="alignJustify"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="orderedList"></dxi-item>
                      <dxi-item name="bulletList"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="color"></dxi-item>
                      <dxi-item name="background"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="link"></dxi-item>
                      <dxi-item name="image"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="clear"></dxi-item>
                      <dxi-item name="codeBlock"></dxi-item>
                      <dxi-item name="blockquote"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="insertTable"></dxi-item>
                      <dxi-item name="deleteTable"></dxi-item>
                      <dxi-item name="insertRowAbove"></dxi-item>
                      <dxi-item name="insertRowBelow"></dxi-item>
                      <dxi-item name="deleteRow"></dxi-item>
                      <dxi-item name="insertColumnLeft"></dxi-item>
                      <dxi-item name="insertColumnRight"></dxi-item>
                      <dxi-item name="deleteColumn"></dxi-item>
                    </dxo-toolbar>
                  </dx-html-editor>
              </div>

              <div *ngSwitchDefault class="alert alert-warning">
                Contrôle non-géré: {{field.get("key").value}}/{{field.get("controlType").value}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-right space">
          <button type="submit" [disabled]="!form.valid || !form.dirty" class="color-set-green compact"
            (click)="saveButtonClick()">
            <div><span>{{ 'general.save' | translate }}</span></div>
          </button>
          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ 'general.cancel' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>

  </div>

  <app-modal #modalPopup></app-modal>
</app-popup>