// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditAttachment extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditAttachment, string>;
  public static tableName: string = 'AuditAttachment';

  public static AUDIT_ID: string = 'auditId';  public static CREATED_BY_ID: string = 'createdById';  public static CREATED_DATE: string = 'createdDate';  public static DATA: string = 'data';  public static ID: string = 'id';  public static NAME: string = 'name';  public static NOTES: string = 'notes';  public static POSITION: string = 'position';  public static TIME_STAMP: string = 'timeStamp';  public static TYPE_ID: string = 'typeId';

  public auditId: string;  public createdById: string;  public createdDate: Date;  public data: string;  public id: string;  public name: string;  public notes: string;  public position: number;  public timeStamp: Date;  public typeId: string;

  public constructor(args?: Partial<AuditAttachment>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditAttachment.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditAttachment.tableName);
      this.table.mapToClass(AuditAttachment);
  }
}
