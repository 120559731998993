<app-popup #popup>
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left padding-left-normal">
          <button><i *ngIf="options.titleIcon" [ngClass]="options.titleIcon ? options.titleIcon : ''"></i></button>{{ options.title }}
        </div>
      </div>
    </div>
    <div id="message" class="layout-center content">
      {{ options.content }}
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal padding-horizontal-normal color-set-white">
        <div class="layout-right layout-space height-normal">
          <button class="color-set-blue compact" type="button" *ngFor="let button of options.buttons" (click)="close(button.key)"
            [id]="button.key">
            <div><span>{{button.description}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
