// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AlertEvent extends ChangeTrackingEntity {
  public static table: Dexie.Table<AlertEvent, string>;
  public static tableName: string = 'AlertEvent';

  public static ALERT_CONDITION_DETAIL_ID: string = 'alertConditionDetailId';  public static ALERT_EVENT_TYPE_ID: string = 'alertEventTypeId';  public static CAN_BE_CANCELLED: string = 'canBeCancelled';  public static DESCRIPTION: string = 'description';  public static EMAIL_ADDRESS: string = 'emailAddress';  public static GROUP_EVENTS: string = 'groupEvents';  public static ID: string = 'id';  public static IS_ACTIVE: string = 'isActive';  public static NOTE: string = 'note';  public static TIME_STAMP: string = 'timeStamp';

  public alertConditionDetailId: string;  public alertEventTypeId: number;  public canBeCancelled: boolean;  public description: string;  public emailAddress: string;  public groupEvents: boolean;  public id: string;  public isActive: boolean;  public note: string;  public timeStamp: Date;

  public constructor(args?: Partial<AlertEvent>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AlertEvent.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AlertEvent.tableName);
      this.table.mapToClass(AlertEvent);
  }
}
