import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';
import { ProgramSubscriptionViewModel } from "./programSubscriptionViewModel";

@Injectable({
  providedIn: 'root',
})
export class ProgramSubscriptionHttpClient{
  private configUrl = `${environment.apiBaseUrl}/ProgramSubscription`;

  constructor(private http: HttpClient) { }

  async getPrograms(): Promise<ProgramSubscriptionViewModel[]>{
    return await lastValueFrom(
      this.http.get<ProgramSubscriptionViewModel[]>(
        `${this.configUrl}/Get/`));
  }

  async addSubscription(programId: string): Promise<void> {
    await lastValueFrom(
        this.http.post(`${this.configUrl}/Add/${programId}`, {}));
  }

  async removeSubscription(programId: string): Promise<void> {
    await lastValueFrom(
        this.http.delete(`${this.configUrl}/Delete/${programId}`));
  }
}