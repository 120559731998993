import { Injectable } from "@angular/core";
import { CustomFieldControlType } from "../data/models/form/customFieldControlType";
import { DynamicSection } from "../data/models/form/dynamicSection";
import { Form } from "../data/models/form/form";
import { Section } from "../data/models/form/section";
import { SectionType } from "../data/models/form/sectionType";
import { ProgramVersionRepository } from "../data/repositories/programVersionRespository";
import { ProgramDataTemplate } from "../data/models/database/programDataTemplate.database";
import { AuditDataTable } from "../data/models/database/auditDataTable.database";
import { BaseRepository } from "../data/baseRepository";
import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";
import { Control } from "../data/models/form/control";
import { DynamicTabStructureItem } from "../data/models/database/dynamicTabStructureItem.database";
import { DynamicTabAuditTemplate } from "../data/models/database/dynamicTabAuditTemplate.database";
import { AuditService } from "src/app/pages/audit/auditService";
import { Audit } from "../data/models/database/audit.database";
import _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class FormInjector {
  constructor(
    private baseRepository: BaseRepository,
    private programVersionRepository: ProgramVersionRepository,
    private auditService: AuditService
  ) { };

  public async inject(auditId: string, values: Object[]) {
    let audit = await Audit.table.get(auditId);

    let programVersion = await this.programVersionRepository.getLatestProgramVersion(audit.programId);
    let formTemplateMetadata: Form = JSON.parse(programVersion.webModel);

    let auditDatatables = await AuditDataTable.table.where({ auditId }).toArray();

    let mappings = await this.getFormKeyMapping(audit.programId);

    let translatedValues = this.translateKeyToGuidName(values, mappings);

    await this.injectData(translatedValues, auditId, auditDatatables, formTemplateMetadata);

    let formSections = await this.getFullModel(auditId, formTemplateMetadata, auditDatatables);

    this.updateState(formSections);

    for (let auditDataTable of auditDatatables) {
      this.baseRepository.update(AuditDataTable.table, auditDataTable)
    }
  }

  private updateState(formSections: FormSection[]) {
    const STATE = "State";

    for (let formSection of formSections) {
      formSection.data[STATE] = SectionState.Valid;

      for (let formControl of formSection.dataControls) {

        if (formControl.section) {
          let alertSection = formControl.section;
          for (let alertControl of alertSection.dataControls) {
            if (alertControl.metadata.required && this.auditService.isControlEmpty(alertControl.metadata, alertSection.data[alertControl.metadata.dataColumnName])) {
              formControl.section.data[STATE] = SectionState.EmptyRequiredFields;
              formSection.data[STATE] = SectionState.EmptyRequiredFields;
            }
          }
        }

        if (formSection.data[STATE] === SectionState.EmptyRequiredFields) {
          continue;
        } else {
          if (formControl.metadata.required && this.auditService.isControlEmpty(formControl.metadata, formSection.data[formControl.metadata.dataColumnName])) {
            formSection.data[STATE] = SectionState.EmptyRequiredFields;
          }
        }
      }
    }
  }

  private async injectData(translatedSections: TranslatedSection[], auditId: string, auditDatatables: AuditDataTable[], formTemplate: Form) {
    for (let translatedValues of translatedSections) {

      if (translatedValues.isDynamic && translatedValues.map.length > 0) {

        let userToken = translatedValues.map[0].userKey.split(".");

        let sectionKey = userToken[0];
        let folderKey = userToken[1];

        let values = {};
        for (let translatedValue of translatedValues.map) {
          let columnKey = translatedValue.tableKey.split(".")[1];

          values[columnKey] = translatedValue.value;
        }

        let section = formTemplate.sections.find(x => x.name === sectionKey) as DynamicSection;
        let folder = section.folders.find(x => x.name === folderKey);
        let templateSection = section.templates.find(x => x.templateId === folder.templateId);
        let structureItem = await this.baseRepository.get(DynamicTabStructureItem.table, folder.id);


        let result = await DynamicTabAuditTemplate.table.toArray();

        let dynamicTabs = result.filter(x => x.auditId === auditId && x.dynamicTabStructureItem === structureItem.keyIdentifier);

        let maxPosition = (_.max(dynamicTabs.map(x => x.position)) || 0) + 1;
        let maxNumber = (_.max(dynamicTabs.map(x => Number(x.number))) || 0) + 1;

        let createDynamicTabInstanceResult = await this.auditService.addDynamicTabAuditTemplate(auditId, templateSection, structureItem.id, null, maxNumber.toString(), folder.description, maxPosition, values);

        auditDatatables.push(createDynamicTabInstanceResult.auditDataTable);
      } else {
        for (let keyMap of translatedValues.map) {
          let tableName = keyMap.tableKey.split('.')[0];
          let columnName = keyMap.tableKey.split('.')[1];

          let auditDataTable = auditDatatables
            .find(x => x.tableName === tableName
              && x.auditId === auditId);

          auditDataTable[columnName] = keyMap.value;
        }
      }
    }
  }

  private translateKeyToGuidName(sections: Object[], mappings: MappingSection[]) {
    let translatedSections: TranslatedSection[] = [];

    for (let section of sections) {
      let translatedSection = new TranslatedSection();

      for (let key of Object.keys(section)) {
        for (let mapping of mappings) {
          if (mapping.map[key]) {
            translatedSection.isDynamic = mapping.isDynamic;

            let keyMap = new KeyMapping();

            keyMap.userKey = key;
            keyMap.tableKey = mapping.map[key];
            keyMap.value = section[key];

            translatedSection.map.push(keyMap);
            break;
          }
        }
      }

      translatedSections.push(translatedSection);
    }

    return translatedSections;
  }

  private async getFormKeyMapping(formTemplateId): Promise<MappingSection[]> {
    let programVersion = await this.programVersionRepository.getLatestProgramVersion(formTemplateId);
    let formTemplate: Form = JSON.parse(programVersion.webModel);

    let mappingSections: MappingSection[] = [];

    for (let section of formTemplate.sections) {
      let mappingSection = new MappingSection();

      if (section.type === SectionType.CustomFields) {
        for (let control of Section.getAllControls(section)) {
          if (this.isDataControlType(control.type)) {
            mappingSection.map[section.name + "." + control.name] = control.dataTableName + "." + control.dataColumnName
          }
        }
      } else if (section.type === SectionType.DynamicTab) {
        let dynamicSection = section as DynamicSection;

        mappingSection.isDynamic = true;

        for (let folder of dynamicSection.folders) {
          if (!folder.templateId)
            continue;

          let dynamicTemplate = dynamicSection.templates.find(x => x.templateId === folder.templateId);

          if (!dynamicTemplate)
            continue;

          for (let control of Section.getAllControls(dynamicTemplate)) {
            if (this.isDataControlType(control.type)) {
              mappingSection.map[section.name + "." + folder.name + "." + control.name] = control.dataTableName + "." + control.dataColumnName
            }
          }
        }
      }

      mappingSections.push(mappingSection);
    }

    return mappingSections;
  }

  /**
   * getFullModel intent is to build or hydrate a model with both the metadata and the data.
   * This model is used to reprensent a form with all its sections including dynamic sections
   * which can't as easily be reprensented from the Form object (json form template). The same
   * applies to alert template, but currently ignored.
   * @param auditId 
   * @param formTemplateMetadata 
   * @param auditDatatables 
   * @returns FormSections which represent
   */
  private async getFullModel(auditId, formTemplateMetadata: Form, auditDatatables: AuditDataTable[]) {
    let formAlternate: FormSection[] = [];

    for (let sectionMetadata of formTemplateMetadata.sections) {
      if (sectionMetadata.type === SectionType.CustomFields) {
        let section = new FormSection();

        section.metadata = sectionMetadata;
        section.data = auditDatatables.find(x => x.tableName === sectionMetadata.dataTableName);

        section.dataControls = await this.getControls(sectionMetadata, section, auditId, formTemplateMetadata, auditDatatables);

        formAlternate.push(section);
      } else if (sectionMetadata.type === SectionType.DynamicTab) {
        let dynamicSectionMetadata = sectionMetadata as DynamicSection;

        for (let folder of dynamicSectionMetadata.folders) {
          if (!folder.templateId)
            continue;

          let dynamicTemplate = dynamicSectionMetadata.templates.find(x => x.templateId === folder.templateId);

          if (!dynamicTemplate)
            continue;

          let dynamicAuditDataTables = auditDatatables.filter(x => x.tableName === dynamicTemplate.dataTableName);

          for (let auditDataTable of dynamicAuditDataTables) {
            let section = new FormSection();

            section.dataControls = await this.getControls(dynamicTemplate, section, auditId, formTemplateMetadata, auditDatatables);
            section.metadata = dynamicTemplate;
            section.data = auditDataTable;

            formAlternate.push(section);
          }
        }
      }
    }

    return formAlternate;
  }

  /**
   * Get the controls which can be injected data into, because the data is tied to a section, it doesn't
   * have data directly at this time. Intention is to bind a section to a control when alert condition
   * is triggered for a full representation of the control, its alert section with its control and its data.
   * This section has been commented out has to be implemented / completed later.
   */
  private async getControls(sectionMetadata: Section, section: FormSection, auditId: any, formTemplateMetadata: Form, auditDatatables: AuditDataTable[]) {
    let controls: FormControl[] = [];

    for (let control of Section.getAllControls(sectionMetadata)) {
      if (this.isDataControlType(control.type)) {

        let formControl = new FormControl();

        formControl.metadata = control;

        // Kept for reference when a control alert condition would change the template.
        // However at this time, injecting data will not trigger alerts.
        // if (control.alertId) {
        //   for (let alertTemplate of control.alertTemplates) {
        //     let conditionDetail = await AlertConditionDetail.table.get(alertTemplate.conditionDetailId);
        //     let condition = await AlertCondition.table.get(conditionDetail.alertConditionId);

        //     let conditionEvaluator = new AlertConditionEvaluator(condition, conditionDetail);

        //     let value = section.data[control.dataColumnName];

        //     if (conditionEvaluator.match(control, value)) {
        //       let alertFormSection = new FormSection();


        //       let dynamicTabAuditTemplate = await DynamicTabAuditTemplate.table.where({ auditId, referenceTableId: section.data.id }).first();

        //       alertFormSection.metadata = formTemplateMetadata.alertTemplates.find(x => x.id === alertTemplate.templateId);
        //       alertFormSection.data = auditDatatables.find(x => x.tableName === alertFormSection.metadata.dataTableName && x.tableId === dynamicTabAuditTemplate.customTableId);
        //       alertFormSection.dataControls = await this.getControls(alertFormSection.metadata, alertFormSection, auditId, formTemplateMetadata, auditDatatables)

        //       formControl.section = alertFormSection;
        //     }
        //   }
        // }

        controls.push(formControl);
      }
    }

    return controls;
  }

  public async programDataTemplateToDatasourceKey(programDataId) {
    let programDataTemplate = await ProgramDataTemplate.table.get(programDataId);
    let dataSource: DataSource = JSON.parse(programDataTemplate.dataSource);

    let table: Table = dataSource.Tables.find(x => x.IsSearchable);

    return table.Name + "." + table.Columns.find(x => x.IsSearchable === true).Name;
  }

  public async getDataSourceValues(programDataId: string, searchedValue) {
    let programDataTemplate = await ProgramDataTemplate.table.get(programDataId);
    let dataSource: DataSource = JSON.parse(programDataTemplate.dataSource);

    let datasource = [];

    let tables: Table[] = dataSource.Tables.filter(x => !x.IsSearchable);

    for (const table of tables) {
      const columnName: string = table.Columns.find(x => x.IsSearchable).Name;

      for (const row of table.Rows) {
        let datasourceItem = {};

        for (const value of row.Values) {
          datasourceItem[value.ColumnName] = value.ValueAsBoolean
            || value.ValueAsInteger
            || value.ValueAsDouble
            || value.ValueAsGuid
            || value.ValueAsDateTime
            || value.ValueAsString
            || "";
        }

        if (datasourceItem[columnName] === searchedValue) {
          datasource.push(datasourceItem);
        }
      }
    }

    return datasource;
  }

  /**
   * @returns Return controls which actually contains data which can
   * be injected data into.
   */
  private isDataControlType(type: CustomFieldControlType): boolean {
    return type === CustomFieldControlType.CheckBox
      || type === CustomFieldControlType.ComboBox
      || type === CustomFieldControlType.DateTimePicker
      || type === CustomFieldControlType.GroupBox
      || type === CustomFieldControlType.TextBox
      || type === CustomFieldControlType.RichTextBox
      || type === CustomFieldControlType.MaskedEditTextBox
      || type === CustomFieldControlType.Signature
      || type === CustomFieldControlType.RadioButton;
  }
}

class DataSource {
  Tables: Table[]
}

class Table {
  Name: string
  Columns: Column[]
  Rows: Row[]
  IsSearchable: boolean
}

class Column {
  Description: string
  Name: string
  Tokens: string[]
  IsSearchable: boolean
  IsEmptyColumn: boolean
}

class Row {
  Values: Value[]
}

class Value {
  ColumnName: string
  Tokens: string[]
  ValueAsBoolean: boolean
  ValueAsInteger: number
  ValueAsDouble: number
  ValueAsGuid: string
  ValueAsDateTime: string
  ValueAsString: string
}

class FormSection {
  metadata: Section;
  dataControls: FormControl[] = [];
  data: AuditDataTable;
}

class FormControl {
  metadata: Control;
  section: FormSection;
}

class MappingSection {
  isDynamic: boolean;
  map: Object = {};
}

class KeyMapping {
  userKey: string;
  tableKey: string;
  value: any;
}

class TranslatedSection {
  isDynamic: boolean;
  map: KeyMapping[] = [];
}

