// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class UserAccount extends ChangeTrackingEntity {
  public static table: Dexie.Table<UserAccount, string>;
  public static tableName: string = 'UserAccount';

  public static AVATAR: string = 'avatar';  public static AVATAR_PATH: string = 'avatarPath';  public static CODE: string = 'code';  public static EMAIL: string = 'email';  public static EXTENSION: string = 'extension';  public static ID: string = 'id';  public static IMAGE: string = 'image';  public static IMAGE_PATH: string = 'imagePath';  public static INACTIVE: string = 'inactive';  public static IS_DELETED: string = 'isDeleted';  public static LIST_AUDITS_WITH_SUBSCRIPTION_ONLY: string = 'listAuditsWithSubscriptionOnly';  public static NAME: string = 'name';  public static OLD_ID: string = 'oldId';  public static PASSWORD: string = 'password';  public static PHONE: string = 'phone';  public static SIGNATURE: string = 'signature';  public static SUBSCRIPTION_PERIOD: string = 'subscriptionPeriod';  public static SUBSCRIPTION_PERIOD_AFTER_LAST_STEP: string = 'subscriptionPeriodAfterLastStep';  public static TIME_STAMP: string = 'timeStamp';  public static TITLE: string = 'title';  public static TYPE_ID: string = 'typeId';  public static WINDOWS_AUTHENTICATION: string = 'windowsAuthentication';  public static WINDOWS_USERNAME: string = 'windowsUsername';

  public avatar: string;  public avatarPath: string;  public code: string;  public email: string;  public extension: string;  public id: string;  public image: string;  public imagePath: string;  public inactive: boolean;  public isDeleted: boolean;  public listAuditsWithSubscriptionOnly: boolean;  public name: string;  public oldId: number;  public password: string;  public phone: string;  public signature: string;  public subscriptionPeriod: number;  public subscriptionPeriodAfterLastStep: number;  public timeStamp: Date;  public title: string;  public typeId: number;  public windowsAuthentication: boolean;  public windowsUsername: string;

  public constructor(args?: Partial<UserAccount>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return UserAccount.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(UserAccount.tableName);
      this.table.mapToClass(UserAccount);
  }
}
