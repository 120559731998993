import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppMiddleware } from './app.middleware';
import { AuthenticationServiceFactory } from './authentication/iAuthenticationService.factory';
import { IAuthenticationService } from './authentication/iAuthenticationService.interface';
import { UserAccount } from './core/data/models/database/userAccount.database';
import { LocalParameter } from './core/data/models/databaseLocal/localParameter.database';
import { SynchronizationService } from './core/data/synchronization/synchronizationService';
import { AppInfoService } from './core/services/app-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  private authenticationService: IAuthenticationService;

  constructor(
    public appInfo: AppInfoService,
    public serviceWorkerUpdate: SwUpdate,
    public synchronizationService: SynchronizationService,
    private translate: TranslateService,
    public authenticationServiceFactory: AuthenticationServiceFactory,
    private appMiddleware: AppMiddleware,
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
    this.translate.setDefaultLang(environment.language);
  }

  public async ngOnInit(): Promise<void> {
    await this.authenticationService.initialize();
    await this.appMiddleware.run();
    await this.setLanguage();
  }

  /**
   * Retrieve the language set by the user in his profil and set the translation service language with it.
   * It is necessary because on refresh, the translation service lose the setting applied by the user.
   */
  private async setLanguage() {
    let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);

    if (!userIdParameter) {
      return;
    }

    let userAccount = await UserAccount.table.get(userIdParameter.value);

    if (userAccount && userAccount["language"]) {
      this.translate.setDefaultLang(userAccount["language"]);
    }
  }

  ngOnDestroy(): void {
    this.authenticationService.destroy();
  }
}
