<div class="layout-horizontal color-set-white emptySection" (click)="showInstructionsPopup()">
  <div class="layout-left">
    <button type="button">
      <i class="fas fa-info-circle info-icon"></i>
    </button>
  </div>
  <div class="layout-center align-left">
    {{summary}}
  </div>
  <div class="layout-right">
    <button type="button" [id]="'InstructionButton-' + input.key">
      <i class="bi bi-chevron-right"></i>
    </button>
  </div>
</div>
