// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditAlertSummarySatelliteDataItem extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditAlertSummarySatelliteDataItem, string>;
  public static tableName: string = 'AuditAlertSummarySatelliteDataItem';

  public static AUDIT_ALERT_SUMMARY_ID: string = 'auditAlertSummaryId';  public static AUDIT_ID: string = 'auditId';  public static CONTROL_KEY: string = 'controlKey';  public static DATA_SOURCE_EXTERNAL_ID: string = 'dataSourceExternalId';  public static DATA_SOURCE_FOREIGN_KEY_ID: string = 'dataSourceForeignKeyId';  public static DATA_TYPE: string = 'dataType';  public static DESCRIPTION: string = 'description';  public static FORMATTED_VALUE: string = 'formattedValue';  public static ID: string = 'id';  public static TIME_STAMP: string = 'timeStamp';  public static VALUE_AS_BOOLEAN: string = 'valueAsBoolean';  public static VALUE_AS_DATE_TIME: string = 'valueAsDateTime';  public static VALUE_AS_DECIMAL: string = 'valueAsDecimal';  public static VALUE_AS_INTEGER: string = 'valueAsInteger';  public static VALUE_AS_STRING: string = 'valueAsString';

  public auditAlertSummaryId: string;  public auditId: string;  public controlKey: string;  public dataSourceExternalId: string;  public dataSourceForeignKeyId: string;  public dataType: number;  public description: string;  public formattedValue: string;  public id: string;  public timeStamp: Date;  public valueAsBoolean: boolean;  public valueAsDateTime: Date;  public valueAsDecimal: number;  public valueAsInteger: number;  public valueAsString: string;

  public constructor(args?: Partial<AuditAlertSummarySatelliteDataItem>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditAlertSummarySatelliteDataItem.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditAlertSummarySatelliteDataItem.tableName);
      this.table.mapToClass(AuditAlertSummarySatelliteDataItem);
  }
}
