// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class SharedParameter extends ChangeTrackingEntity {
  public static table: Dexie.Table<SharedParameter, string>;
  public static tableName: string = 'SharedParameter';

  public static ID: string = 'id';  public static NAME: string = 'name';  public static TIME_STAMP: string = 'timeStamp';  public static VALUE_AS_BOOLEAN: string = 'valueAsBoolean';  public static VALUE_AS_DATE: string = 'valueAsDate';  public static VALUE_AS_DATE_TIME: string = 'valueAsDateTime';  public static VALUE_AS_DECIMAL: string = 'valueAsDecimal';  public static VALUE_AS_INTEGER: string = 'valueAsInteger';  public static VALUE_AS_STRING: string = 'valueAsString';

  public id: string;  public name: string;  public timeStamp: Date;  public valueAsBoolean: boolean;  public valueAsDate: Date;  public valueAsDateTime: Date;  public valueAsDecimal: number;  public valueAsInteger: number;  public valueAsString: string;

  public constructor(args?: Partial<SharedParameter>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return SharedParameter.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(SharedParameter.tableName);
      this.table.mapToClass(SharedParameter);
  }
}
