import { Injectable } from "@angular/core";

@Injectable()
export class ComparatorService {
  /**
  * Returns a value indicating whether the two strings are equal case insensitive and diatritic agnostic (é == e).
  */
  static stringMatch(a, b) {
    if(!a) {
      a = "";
    }

    if(!b) {
      b = "";
    }

    let aUpperCaseWithoutDiacritics = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    let bUpperCaseWithoutDiacritics = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();;

    return aUpperCaseWithoutDiacritics.includes(bUpperCaseWithoutDiacritics);
  }

  /**
  * Returns a numeric value indicating the whether the two string are equal (0) or not (> 0 or < 0).
  */
  static localeCompare(a: string, b: string, sensitivity = 'base') {
    return a.localeCompare(b, undefined, { sensitivity: sensitivity } as Intl.CollatorOptions);
  }
}
