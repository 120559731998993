import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-audit-section-photo',
  templateUrl: './audit-section-photo.component.html',
  styleUrls: ['./audit-section-photo.component.scss']
})
export class AuditSectionPhotoComponent {
  @Input() public auditId: string;
}
