<div class="layout-vertical">
  <div class="layout-top">
    <div *ngIf="input && input.generateLabel" class="layout-horizontal">
      <div class="layout-center align-left padding-horizontal-normal">
        <label class="field">{{input.description}}</label>
      </div>
    </div>
  </div>
  <div class="layout-center">
    <app-list #list [dataSource]="formImageMetadataDataSource" [displayGrid]="true" [enableFilter]="true"
      [canEnableSelection]="true" [canToggleSelection]="true" [selectionEnabled]="false" [showCheckBoxes]="false"
      [(selectedItems)]="selectedAuditImages" [numberOfSelectableItem]="-1">
      <ng-template #listGroupTemplate let-item>
        {{item.key}}
      </ng-template>

      <ng-template #rightOptionalButton>
        <button *ngIf="!isLink" class="color-set-transparent" (click)="imageInput.click()" id=""
          [disabled]="!acceptedTypes">
          <i class="fa fa-file"></i>
        </button>
        <button *ngIf="!isLink" class="color-set-transparent" (click)="camera.open()" id="">
          <i class="fa fa-camera"></i>
        </button>
        <button *ngIf="enableImageLinking" class="color-set-transparent" (click)="displayImageLinkPopup()">
          <i class="fa fa-link"></i>
        </button>
        <button class="color-set-transparent" (click)="actionPopup.display()" id="">
          <i class="fa fa-ellipsis-h"></i>
        </button>
      </ng-template>

      <ng-template #listTemplate let-item>
        <div style="position:relative;" (click)="onImageClick($event, item)">
          <img src="{{ item.imageId | fetchImage | async | fetchImageUrl }}" class="list-image">

          <button *ngIf="!list.selectionEnabled" class="additionnalInformationButton"
            (click)="onEditDetailsClick($event, item)">
            <i class="fa-regular fa-pen-to-square"></i>
          </button>

          <button *ngIf="list.selectionEnabled && list.isSelectedItem(item)" class="selectedItemButton">
            <i class="fa fa-circle-check"></i>
          </button>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>

<!---------------- Update details popup (Start) ------------------>

<app-popup #updateDetailsPopup [visible]="false" [canClose]="true" [boxFixed]="true" [vertical]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padding-left-normal height-normal">
        <div class="layout-left">
          {{ 'formPhoto.actions.detailsTitle' | translate}}
        </div>
        <div class="layout-right">
          <button (click)="updateDetailsPopup.close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>

    <div class="layout-center padding-normal">
      <div class="padding-bottom" *ngIf="selectedAuditImages.length === 1">
        <div>
          {{ 'formPhoto.actions.description' | translate}}
        </div>
        <div>
          <dx-text-area [maxLength]="250" [height]="200" #descriptionControl
            [disabled]="selectedAuditImages.length > 1">
          </dx-text-area>
        </div>
      </div>
      <div class="height-normal">
        <div>
          <app-checkbox #printControl (valueChanged)="handlePrintValueChange($event)"></app-checkbox>
          {{ 'formPhoto.actions.print' | translate}}
        </div>
      </div>
      <div class="height-normal">
        <div>
          <app-checkbox #duplicateControl (valueChanged)="handleDuplicateValueChange($event)"></app-checkbox>
          {{ 'formPhoto.actions.duplicate' | translate}}
        </div>
      </div>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal  padding-horizontal-normal padding-vertical-compact">
        <div class="layout-right">
          <button class="compact color-set-green" (click)="saveDetails()">
            <div><span>{{ "general.save" | translate}}</span></div>
          </button>
          <button class="compact color-set-gray margin-left-normal" (click)="updateDetailsPopup.close()">
            <div><span>{{ "general.cancel" | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>

<!---------------- Update details popup (End) ------------------>

<app-popup #actionPopup [visible]="false" [canClose]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padding-left-normal height-normal">
        <div class="layout-left">
          {{ 'formPhoto.actions.title' | translate}}
        </div>
        <div class="layout-right">
          <button (click)="actionPopup.close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-center">
    <div class="layout-horizontal padding-right-normal height-normal" *ngIf="allowFilterByLinkImages">
      <div class="layout-center align-left">
        <button class="width-100 color-set-transparent" (click)="toggleLinkImagesVisibility()">
          <i class="fas fa-solid fa-paperclip"></i>
          <div> <span> {{ showLinkedImages ?
              translateService.instant("components.customFields.pictureBox.hideLinkedImages") :
              translateService.instant("components.customFields.pictureBox.showLinkedImages") }} </span> </div>
        </button>
      </div>
    </div>

    <div *ngIf="!isLink" class="layout-horizontal padding-right-normal height-normal">
      <div class="layout-center align-left">
        <button class="width-100 color-set-transparent" (click)="editDetails()"
          [disabled]="selectedAuditImages.length === 0">
          <i class="fas fa-align-justify"></i>
          <div> <span> {{ 'formPhoto.actions.editDetails' | translate}}</span> </div>
        </button>
      </div>
    </div>

    <div *ngIf="!isLink" class="layout-horizontal padding-right-normal height-normal">
      <div class="layout-center align-left">
        <button class="width-100 color-set-transparent" (click)="showDeletePopupConfirmation()"
          [disabled]="selectedAuditImages.length === 0">
          <i class="fas fa-trash"></i>
          <div> <span> {{ 'formPhoto.actions.delete' | translate}}</span> </div>
        </button>
      </div>
    </div>
  </div>
</app-popup>

<app-popup id="link-image-popup" #linkImagePopup *ngIf="!isLink" [canClose]="true" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-center align-left">
          <button>
            <i class="fas fa-link"></i>
            <div><span>{{ 'formPhoto.actions.linkImages' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <div class="layout-right">
            <button (click)="linkImagePopup.close()"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <app-picture-box #linkedImagePictureBox [auditId]="auditId" [enableImageLinking]="false" [isLink]="true"
        [selectionEnabled]="true" [canToggleSelection]="true" [canEnableSelection]="false" [allowFilterByLinkImages]="true">
      </app-picture-box>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal padding-vertical-compact">
        <div class="layout-right space">
          <button class="color-set-gray compact" (click)="linkImagePopup.close()">
            <div> <span> {{ 'general.cancel' | translate}}</span> </div>
          </button>
          <button class="color-set-blue compact" (click)="linkImages()"
            [disabled]="linkedImagePictureBoxComponent && linkedImagePictureBoxComponent.selectedAuditImages.length === 0">
            <div> <span> {{ 'formPhoto.actions.linkImages' | translate}}</span> </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>

<div *ngIf="!isLink">
  <img id="image-element" [src]="currentImageSrc" #imageElement>
  <div #markerAreaElement></div>
</div>


<app-popup id="delete-image-popup" #deleteImagePopup [canClose]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-center align-left">
          <button>
            <div><span>{{ 'formPhoto.deleteConfirmationTitle' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button (click)="deleteImagePopup.close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <div class="padding-normal">
        <span *ngIf="selectedAuditImages?.length === 1">
          {{ 'formPhoto.deleteConfirmationMessage' | translate }}
        </span>
        <span *ngIf="selectedAuditImages?.length > 1">
          {{ 'formPhoto.deleteMultipleConfirmationMessage' | translate: { count: selectedAuditImages?.length } }}
        </span>
      </div>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal padding-vertical-compact">
        <div class="layout-right space">
          <button class="color-set-invert-red compact" (click)="confirmDeleteImage()">
            <div> <span> {{ 'general.delete' | translate}}</span> </div>
          </button>
          <button class="color-set-gray compact" (click)="deleteImagePopup.close()">
            <div> <span> {{ 'general.cancel' | translate}}</span> </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>

<input multiple="multiple" (change)="onFileChanged($event)" #imageInput style="display: none" type="file"
  [accept]="acceptedTypes" (click)="resetImageInputValue()" #cameraInput>

<app-camera *ngIf="!isLink" #camera (photoChange)="onPictureTaken($event)">
</app-camera>

<app-modal></app-modal>

<app-progression-popup [visible]="savingFilesInProgress"
  [title]="'components.customFields.pictureBox.saveFiles.title' | translate" [message]="savingFilesProgressMessage"
  [progress]="savingFilesProgressValue"></app-progression-popup>
