<app-popup #databaseResetPopup>
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-center align-left">
          <button><i class="fas fa-trash"></i>
            <div><span> {{ 'synchronization.resetDatabasePopup.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="databaseResetPopup.close()"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center padding-normal">
      <div *ngIf="!synchronizationService.offline">
        {{ 'synchronization.resetDatabasePopup.message' | translate}}
      </div>
      <div *ngIf="synchronizationService.offline">
        {{ 'synchronization.resetDatabasePopup.messageOffline' | translate}}
      </div>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white padding-horizontal-normal padding-vertical-compact">
        <div class="layout-right space" *ngIf="!synchronizationService.offline">
          <button name="resetButton" class="compact color-set-invert-red" (click)="databaseContext.resetDatabase()">
            <div><span>{{ 'synchronization.resetDatabasePopup.confirmReset' | translate}} </span></div>
          </button>
          <button name="cancelButton" class="compact color-set-gray" (click)="databaseResetPopup.close()">
            <div><span>{{ 'synchronization.resetDatabasePopup.cancel' | translate}}</span></div>
          </button>
        </div>
        <div class="layout-right space" *ngIf="synchronizationService.offline">
          <button name="cancelButton" class="compact color-set-blue" (click)="databaseResetPopup.close()">
            <div><span>{{ 'general.ok' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
