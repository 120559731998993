// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditImage extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditImage, string>;
  public static tableName: string = 'AuditImage';

  public static ANNOTATION: string = 'annotation';  public static AUDIT_ID: string = 'auditId';  public static CUSTOM_FIELD_NAME: string = 'customFieldName';  public static CUSTOM_TABLE_ID: string = 'customTableId';  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IMAGE_ID: string = 'imageId';  public static IS_LINK: string = 'isLink';  public static ORIGINAL_IMAGE_ID: string = 'originalImageId';  public static POSITION: string = 'position';  public static TIME_STAMP: string = 'timeStamp';  public static TO_DUPLICATE: string = 'toDuplicate';  public static TO_PRINT: string = 'toPrint';

  public annotation: string;  public auditId: string;  public customFieldName: string;  public customTableId: string;  public description: string;  public id: string;  public imageId: string;  public isLink: boolean;  public originalImageId: string;  public position: number;  public timeStamp: Date;  public toDuplicate: boolean;  public toPrint: boolean;

  public constructor(args?: Partial<AuditImage>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditImage.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditImage.tableName);
      this.table.mapToClass(AuditImage);
  }
}
