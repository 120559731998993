import { Injectable } from "@angular/core";
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditState } from "src/app/pages/audit/auditState";
import { AlertTemplateField } from "../data/models/alertTemplateField";
import { Form } from "../data/models/form/form";
import { SectionStateService } from "./sectionStateService";

@Injectable({
  providedIn: 'root'
})
export class AlertTemplateService {
  constructor(
    private auditState: AuditState,
    private auditService: AuditService,
    private sectionStateService: SectionStateService) { }

  public async configure(currentAlertTemplate: AlertTemplateField, newTemplateAlert: AlertTemplateField) {
    if (currentAlertTemplate) {
      await this.auditService.removeDynamicTabAuditTemplate(currentAlertTemplate, this.auditState);
    }

    if (newTemplateAlert) {
      if (!newTemplateAlert.customTableId) {
        let alertTemplateSection = this.auditState.form.alertTemplates.find(x => x.id === newTemplateAlert.templateId);

        let result = await this.auditService.addDynamicTabAuditTemplate(
          this.auditState.audit.id,
          alertTemplateSection,
          newTemplateAlert.structureItemId,
          newTemplateAlert.referenceTableId)

          newTemplateAlert.customTableId = result.auditDataTable.tableId;
          newTemplateAlert.id = result.dynamicTabAuditTemplate.id
      }
    }

    await this.sectionStateService.load(this.auditState.audit.id, this.auditState.form, this.auditState.sectionStates);

    this.auditState.raiseRefreshSectionStates();
  }
}
