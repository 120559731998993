import { Injectable } from "@angular/core";
import { Program } from "../data/models/database/program.database";
import { Control } from "../data/models/form/control";
import { Form } from "../data/models/form/form";
import { Section } from "../data/models/form/section";
import { ProgramVersionRepository } from "../data/repositories/programVersionRespository";

@Injectable({
  providedIn: 'root'
})
export class FormTemplateService{
  constructor(private programVersionRepository: ProgramVersionRepository){
  }

  async loadControls(formTemplate: Program) : Promise<Control[]> {
    let controls: any[] = [];

    var programVersion = await this.programVersionRepository.getLatestProgramVersion(formTemplate.id);

    var form = JSON.parse(programVersion.webModel) as Form;

    for (const section of form.sections) {
      Section.mapExtendedProperties(section)

      for (const control of Section.getAllControls(section)) {
        // This prevent having to create a new object just to add this information.
        control["sectionDescription"] = section.description;

        controls.push(control);
      }
    }

    return controls;
  }
}
