import { Injectable } from '@angular/core';
import { PermissionType } from '../data/enums/permissionType';
import { PermissionList } from '../data/models/database/permissionList.database';
import { Permission } from '../data/models/database/permission.database';
import { UserAccountGroup } from '../data/models/database/userAccountGroup.database';
import { LocalParameter } from '../data/models/databaseLocal/localParameter.database';
import { UserAccount } from '../data/models/database/userAccount.database';
import { UserType } from '../data/enums/userType';

// This service create a link between the permission in Nspek web and the ones used in Nspek windows using
// the column UniqueKey that are hardcoded. Nspek web uses the additionnal table PermissionList and PermissionListGroup,
// each permissions being assigned to a PermissionList, but it is not assign in Nspek windows.
@Injectable({
  providedIn: 'root'
})

export class PermissionService {
  private permissions: Map<PermissionType, boolean> = new Map();
  private permissionList: PermissionList[];
  private permissionsMap: Map<string, string> = new Map();
  private permissionsMapReversed: Map<string, string> = new Map();

  public getPermissionIdFromUniqueKey(uniqueKey: string): string {
    return this.permissionsMap.get(uniqueKey);
  }

  public getUniqueKeyFromPermissionId(permissionId: string): string {
    return this.permissionsMapReversed.get(permissionId);
  }

  // Can't be done in constructor because of async
  public async createCorrespondanceMap() {
    await this.initializePermissionMap();
    
    // Permission keys are hardcoded in Nspek windows, the corresponding code for the web is needed
    this.addKeyToMaps("AddAudit", this.findPermission("permissions.audit.add"));
    this.addKeyToMaps("SaveAudit", this.findPermission("permissions.audit.edit"));
    this.addKeyToMaps("RemoveAudit", this.findPermission("permissions.audit.delete"));
    this.addKeyToMaps("SaveOthersAudit", this.findPermission("permissions.audit.editOthers"));

    this.addKeyToMaps("HandleSharedOptions", this.findPermission("permissions.option.parameter"));

    this.addKeyToMaps("HandleFormTemplates", this.findPermission("permissions.formTemplate.handle"));

    this.addKeyToMaps("HandleUserGroups", this.findPermission("permissions.userGroup.handle"));
  
    this.addKeyToMaps("HandleUsers", this.findPermission("permissions.user.handle"));
    
    this.addKeyToMaps("ViewConfigurationWizard", this.findPermission("permissions.general.configuration"));

    this.addKeyToMaps("ModifyDropDownLists", this.findPermission("permissions.general.editList"));

    this.addKeyToMaps("ManageAlertConditions", this.findPermission("permissions.warningInstruction.conditions"));

    this.addKeyToMaps("ManageInstructions", this.findPermission("permissions.warningInstruction.instructions"));

    this.addKeyToMaps("ManageImageSizes", this.findPermission("permissions.general.imageSize"));
    this.addKeyToMaps("ManageTaskTypes", this.findPermission("permissions.general.taskTypes"));
    this.addKeyToMaps("ManageDataSources", this.findPermission("permissions.general.dataSources"));
    this.addKeyToMaps("ManageMobiles", this.findPermission("permissions.general.mobiles"));
  }

  public async hasAccess(permission: PermissionType): Promise<boolean> {
    if (await this.currentUserIsAdmin())
      return true;

    await this.initializePermissionMap();

    // if the permission doesn't exist in the map
    // the user doesn't have it and false is return
    return this.permissions.get(permission) || false;
  }

  public async initializePermissionMap(){
    if (!this.permissions || this.permissions.size === 0)
      await this.createUserPermissionMap();
  }

  public async createUserPermissionMap(): Promise<void> {
    this.permissions = new Map();

    this.permissionList = await PermissionList.table.toArray();

    let currentUserGroupIds: string[] = await this.getCurrentUserGroupIds();

    // Fetch all permissions applicable to the currect user
    let userPermissions: Permission[] = (await Permission.table.toArray()).filter((permission) => currentUserGroupIds.includes(permission.groupId));

    // Add each of them to the permission map
    userPermissions.forEach((userPermission) => {
      let permissionCode: string = this.permissionList.find(permissionList => permissionList.id === userPermission.permissionListId)?.code;
      if (permissionCode)
        this.permissions.set(permissionCode as PermissionType, true);
    });
  }

  private async getCurrentUserGroupIds(): Promise<string[]> {
    // current user is not fetch by authenticatedUser because permission service
    // can be called before authenticatedUser is initialize
    let currentUserAccount = await this.getCurrentUserAccount();

    let userAccountGroup: UserAccountGroup[] = await UserAccountGroup.table.toArray();

    return userAccountGroup
      .filter(userAccountGroup => userAccountGroup.userId === currentUserAccount.id)
      .map(userAccountGroup => userAccountGroup.groupId);
  }

  private addKeyToMaps(key1: string, key2: string) {
    this.permissionsMap.set(key1, key2);
    this.permissionsMapReversed.set(key2, key1);
  }

  private findPermission(code: string): string {
    return this.permissionList.find(x => x.code == code).id
  }

  private async getCurrentUserAccount():Promise<UserAccount>
  {
    let userIdParameter: LocalParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);

    return await UserAccount.table.get(userIdParameter.value);
  }

  private async currentUserIsAdmin(): Promise<Boolean> {
    let currentUserAccount = await this.getCurrentUserAccount();

    return currentUserAccount.typeId == UserType.Administrator;
  }
}
