import { SearchOperator } from "./custom-field-filter/searchOperators/searchOperator";

export class CustomFieldFilterViewModel{
  public constructor(args?: Partial<CustomFieldFilterViewModel>) {
    Object.assign(this, args);
  }

  control: string;
  description: string;
  searchOperator: SearchOperator;
}
