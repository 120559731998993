import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import _, { filter } from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { Program } from 'src/app/core/data/models/database/program.database';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { UserService } from 'src/app/core/services/userService';
import { AuditListSearchOptions } from "src/app/pages/audit-list/auditListSearchOptions";
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgramVersionRepository } from 'src/app/core/data/repositories/programVersionRespository';
import { Form } from 'src/app/core/data/models/form/form';
import { CustomFieldFilterViewModel } from './customFieldFilterViewModel';
import { DropdownComponent } from 'src/app/components/controls/dropdown/dropdown.component';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';
import { FormFilterOptionService } from 'src/app/core/services/formFilterOptionService';
import { Router } from '@angular/router';
import { CustomFieldFilterComponent } from './custom-field-filter/custom-field-filter.component';
import { Control } from 'src/app/core/data/models/form/control';
import { Section } from 'src/app/core/data/models/form/section';
import { SearchOperator } from './custom-field-filter/searchOperators/searchOperator';
import Dexie from 'dexie';
import { StringSearchOperator } from './custom-field-filter/searchOperators/stringSearchOperator';
import { BooleanSearchOperator } from './custom-field-filter/searchOperators/booleanSearchOperator';
import { DateSearchOperator } from './custom-field-filter/searchOperators/dateSearchOperator';
import { DataSourceSearchOperator } from './custom-field-filter/searchOperators/dataSourceSearchOperator';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';
import moment from 'moment';
import { DataType } from 'src/app/core/data/models/form/dataType';
import { NumericSearchOperator } from './custom-field-filter/searchOperators/numericSearchOperator';
import { FormTemplateService } from 'src/app/core/services/formTemplateService';
import { FormatUtility } from 'src/app/core/formatUtility';

@Component({
  selector: 'app-audit-list-search',
  templateUrl: './audit-list-search.component.html',
  styleUrls: ['./audit-list-search.component.scss']
})
export class AuditListSearchComponent implements OnInit {
  @Input() public searchOptions: AuditListSearchOptions = new AuditListSearchOptions();
  @Input() public filterCount: number = 0;
  @Output() public filterCountChange = new EventEmitter<number>();

  @ViewChild("searchForm") public searchForm: PopupComponent;

  @ViewChild("customFieldFilterPopup") public customFieldFilterPopup: CustomFieldFilterComponent;

  @ViewChild("customFieldFilterFormTemplateDropdown") public customFieldFilterFormTemplateDropdown: DropdownComponent;

  public selectedProgramDescription: string;

  public formTemplates: Program[] = [];
  public assignedToUsers: UserAccount[] = [];
  public workflowStepNames: any[] = [];

  public visible: boolean = false;

  public formTemplateDataSource: Function;
  public assignedToDataSource: Function;
  public workflowStepDataSource: Function;

  public customFieldSearchOperators: CustomFieldFilterViewModel[] = [];

  // Represents a list of controls of the selected form template when only one selected.
  // Key the the id of the form template and content is a list of controls flatten.
  private formTemplateControls: Control[];

  constructor(
    private userAccountRepository: UserAccountRepository,
    private userService: UserService,
    private formFilterOptionService: FormFilterOptionService,
    private router: Router,
    private formTemplateService: FormTemplateService
  ) {
  }

  async formTemplateSelectedItemsChange(programs: Program[]){
    this.clearFormFieldFilters();

    if (programs.length === 1){
      this.formTemplateControls = await this.formTemplateService.loadControls(programs[0]);
    }
  }

  async addCustomFieldSearchOperator() {
    let searchOperator = new SearchOperator();

    if (await this.customFieldFilterPopup.show(searchOperator, this.formTemplates[0], this.formTemplateControls)){
      let viewModel = new CustomFieldFilterViewModel();

      this.mapCustomFieldSearchOperatorViewModel(searchOperator, viewModel);

      this.customFieldSearchOperators.push(viewModel);
    }
  }

  async deleteCustomFieldSearchOperator(index: number) {
    this.customFieldSearchOperators.splice(index, 1);
  }

  async editCustomFieldSearchOperator(index: number){
    let customFieldSearchOperator = this.customFieldSearchOperators[index];

    if (await this.customFieldFilterPopup.show(customFieldSearchOperator.searchOperator, this.formTemplates[0], this.formTemplateControls)){
      this.mapCustomFieldSearchOperatorViewModel(customFieldSearchOperator.searchOperator, customFieldSearchOperator)
    }
  }

  public async ngOnInit(): Promise<void> {
    this.formTemplateDataSource = async (listContext: ListComponent) => {
      let forms = await Program.table.toArray();

      let formWithDisplayName: any = this.changeFormDisplayName(forms);

      let availableForms = formWithDisplayName.filter(x => !x.isDeleted && x.isActive
        && x.displayName.toLowerCase().includes(listContext.filter.toLowerCase()));

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.templateIds && this.searchOptions.templateIds.length > 0) {
        this.formTemplates = availableForms.filter(form => this.searchOptions.templateIds.indexOf(form.id) > 0);
        this.setFilterCount();
      }

      return new ListDataSourceFunctionResult({
        itemCount: availableForms.length,
        items: availableForms
      });
    };

    this.assignedToDataSource = async (listContext: ListComponent) => {
      let users = await this.userAccountRepository.getUsers();

      let usersWithInitials = this.changeUserDisplayName(users);

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.assignedToIds && this.searchOptions.assignedToIds.length > 0) {
        this.assignedToUsers = usersWithInitials.filter(users => this.searchOptions.assignedToIds.indexOf(users.id) > 0);
        this.setFilterCount();
      }

      let filteredUser = usersWithInitials.filter(user => {
        return user.name.toLowerCase().includes(listContext.filter.toLowerCase());
      });

      return new ListDataSourceFunctionResult({
        itemCount: filteredUser.length,
        items: filteredUser
      });
    };

    this.workflowStepDataSource = async (listContext: ListComponent): Promise<ListDataSourceFunctionResult> => {
      let workflowSteps = await WFStep.table.toArray();

      let workflowStepsNames = workflowSteps.map((workflowStep) => {
        return workflowStep.name;
      });

      let distinctWorkflowStepsNames: any[] = workflowStepsNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      // This step in onty required because the list support only object as dataSource at this time.
      let distinctWorkflowStepsNamesObject = distinctWorkflowStepsNames.map((workflowStep) => {
        return { name: workflowStep };
      });

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.workflowStepNames && this.searchOptions.workflowStepNames.length > 0) {
        this.workflowStepNames = distinctWorkflowStepsNamesObject.filter(workflowStep => this.searchOptions.workflowStepNames.indexOf(workflowStep.name) > 0);
        this.setFilterCount();
      }

      let filteredWorkflowStepsNames = distinctWorkflowStepsNamesObject.filter((workflowStep) => {
        return workflowStep.name.toLowerCase().includes(listContext.filter.toLowerCase());
      });

      return new ListDataSourceFunctionResult({
        itemCount: filteredWorkflowStepsNames.length,
        items: filteredWorkflowStepsNames
      });
    };
  }

  public async open() {
    if (!this.searchOptions.templateIds) {
      this.searchOptions.templateIds = null;
    }

    if (this.searchOptions.templateIds) {
      let templates: Program[] = await Program.table.bulkGet(this.searchOptions.templateIds);
      templates = this.changeFormDisplayName(templates);

      this.formTemplates = templates;
    }

    if (this.searchOptions.assignedToIds) {
      let users: UserAccount[] = await UserAccount.table.bulkGet(this.searchOptions.assignedToIds);
      users = this.changeUserDisplayName(users);

      this.assignedToUsers = users;
    }

    if (this.searchOptions.workflowStepNames) {
      this.workflowStepNames = this.searchOptions.workflowStepNames.map(workflowstepName => {
        return { name: workflowstepName };
      });
    }

    if (this.searchOptions.customFieldSearchOperators){
      if (this.formTemplates.length === 1){
        if (!this.formTemplateControls){
          this.formTemplateControls = await this.formTemplateService.loadControls(this.formTemplates[0]);
        }

        for (const searchOperator of this.searchOptions.customFieldSearchOperators) {
          let viewModel = new CustomFieldFilterViewModel();

          // The control could not exist anymore if the form template has been modified.
          // Because search operators are serialized as string and don't synchronized, they can be
          // out of sync easily.
          if (await this.mapCustomFieldSearchOperatorViewModel(searchOperator, viewModel)){
            this.customFieldSearchOperators.push(viewModel);
          }
        }
      }
    }

    await this.searchForm.display();
  }

  private async mapCustomFieldSearchOperatorViewModel(searchOperator: SearchOperator, viewModel: CustomFieldFilterViewModel): Promise<boolean> {
    let searchOperatorTokens = searchOperator.key.split(".");

    let control = this.formTemplateControls.find(x => x.dataTableName === searchOperatorTokens[0] && x.dataColumnName === searchOperatorTokens[1]);

    if (!control)
      return false;

    viewModel.control = control.sectionDescription + " => " + control.description;

    switch (control.type) {
      case CustomFieldControlType.TextBox:
        if (control["dataType"] as DataType == DataType.String){
          let stringSearchOperator = (searchOperator as StringSearchOperator);

          viewModel.description = StringSearchOperator.getComparisonTypeDescription(stringSearchOperator.comparisonType) + ": " + stringSearchOperator.value;
        }
        else{
          let numericSearchOperator = (searchOperator as NumericSearchOperator);

          viewModel.description = NumericSearchOperator.getComparisonTypeDescription(numericSearchOperator.comparisonType) + ": " + numericSearchOperator.value;
        }
        break;
      case CustomFieldControlType.CheckBox:
        let booleanSearchOperator = (searchOperator as BooleanSearchOperator);

        viewModel.description = booleanSearchOperator.value ? "Oui" : "Non";
        break;
      case CustomFieldControlType.RadioButton:
      case CustomFieldControlType.ComboBox:
        let dataSourceSearchOperator = (searchOperator as DataSourceSearchOperator);

        if (dataSourceSearchOperator.value){
          viewModel.description = (await CustomFieldValueItem.table.get(dataSourceSearchOperator.value)).description;
        }

        break;
      case CustomFieldControlType.MaskedEditTextBox:
        viewModel.description = (searchOperator as StringSearchOperator).value;
        break;
      case CustomFieldControlType.DateTimePicker:
        let dateSearchOperator = (searchOperator as DateSearchOperator);

        let dateResult: string;
        let displayFormat = control["displayFormat"];

        if (dateSearchOperator.fromValue && dateSearchOperator.toValue)
          dateResult = "De " + this.getFormattedDate(dateSearchOperator.fromValue, displayFormat) + " À " + this.getFormattedDate(dateSearchOperator.toValue, displayFormat);
        else if (dateSearchOperator.fromValue)
          dateResult = "De " + this.getFormattedDate(dateSearchOperator.fromValue, displayFormat);
        else if (dateSearchOperator.toValue)
          dateResult = "À " + this.getFormattedDate(dateSearchOperator.toValue, displayFormat);

          viewModel.description = dateResult;
        break;
      default:
        break;
    }

    viewModel.searchOperator = searchOperator;

    return true;
  }

  private getFormattedDate(date: Date, outputFormat: string){
    let defaultDateFormat = "dd-MM-yyyy HH:mm";

    return moment(date).format(FormatUtility.convertInfragisticsToMomentDateFormat(outputFormat ?? defaultDateFormat))
  }

  private customFieldFilterSelectedIndex: number;

  public customFieldFilterSectionClick(index: number) {
    this.customFieldFilterSelectedIndex = index;
  }

  public removeCustomFieldFilter() {
    this.customFieldSearchOperators.splice(this.customFieldFilterSelectedIndex, 1);
  }

  public search(): void {
    this.searchOptions.templateIds = this.formTemplates.map((program) => program.id);
    this.searchOptions.assignedToIds = this.assignedToUsers.map((userAccount) => userAccount.id);
    this.searchOptions.workflowStepNames = this.workflowStepNames.map((workflowStepName) => workflowStepName.name);
    this.searchOptions.customFieldSearchOperators = this.customFieldSearchOperators.map(x => x.searchOperator);

    this.close();
  }

  public clear() {
    this.formTemplates = [];
    this.assignedToUsers = [];
    this.workflowStepNames = [];

    this.clearFormFieldFilters();

    this.searchOptions = new AuditListSearchOptions();

    this.close();
  }

  private clearFormFieldFilters(){
    // Must not reassign the array to a new memory position because Angular would not be
    // able to track changes and refresh the UI.
    this.customFieldSearchOperators.splice(0);
  }

  public async close() {
    this.setFilterCount();

    await this.formFilterOptionService.save(this.searchOptions);

    this.searchForm.close();
    this.searchForm.resolve(true);
  }

  changeFormDisplayName(forms: Program[]) {
    let formWithDisplayName: any = forms.map<any>((form: any) => {
      form.displayName = `${form.numberPrefix} - ${form.description}`;
      return form;
    });

    return formWithDisplayName;
  }

  changeUserDisplayName(users: UserAccount[]) {
    let usersWithInitials = users.map((user: any) => {
      user.initials = this.userService.getInitials(user.name);
      return user;
    });

    return usersWithInitials;
  }

  public setFilterCount() {
    let filterCount = 0;
    for (let key in this.searchOptions) {
      let value = this.searchOptions[key];
      if (value) {
        if (Array.isArray(value)) {
          filterCount += value.length;
        } else {
          filterCount++;
        }
      }
    }

    this.filterCount = filterCount;
    this.filterCountChange.emit(this.filterCount);
  }
}
