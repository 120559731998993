<app-popup #popup>
  <div class="layout-vertical">

    <div class="layout-horizontal padding-left padding-right height-normal color-set-blue">
      <div class="layout-left">
        {{ 'form.documentTypeSelection.title' | translate}}
      </div>
    </div>

    <div class="layout-center padding-normal">
      <dx-radio-group
        [items]="items"
        [(value)]="selectedType"
        valueExpr="type"
        displayExpr="description"
        ></dx-radio-group>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-right space">
          <button class="color-set-green compact"
            (click)="printButtonClick()" [disabled]="!selectedType">
            <div><span>{{ 'form.documentTypeSelection.print' | translate}}</span></div>
          </button>
          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ 'general.cancel' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>

  </div>
</app-popup>
