import { CustomFieldSearch } from "./customFieldSearch";
import { Document } from "./document";
import { DynamicSection } from "./dynamicSection";
import { Section } from "./section";
import { Step } from "./step";

export class Form {
  id: string;
  version: number;
  name: string;
  description: string;
  steps: Step[];
  sections: Section[];
  alertTemplates: Section[];
  customFieldsSearch: CustomFieldSearch[];
  documents: Document[];

  static findSection(form: Form, sectionId: string) {
    for (const section of form.sections) {
      if (section.id === sectionId)
        return section;
      else {
        if (!!section["templates"]) {
          for (const subSection of (section as DynamicSection).templates) {
            if (subSection.id === sectionId)
              return subSection;
          }
        }
      }
    }
  }

  static findSectionByTableName(form: Form, name: string) {
    for (const section of form.sections) {
      if (section.dataTableName === name)
        return section;
      else {
        if (!!section["templates"]) {
          for (const subSection of (section as DynamicSection).templates) {
            if (subSection.dataTableName === name)
              subSection["isTemplate"] = true;
            
              return subSection;
          }
        }
      }
    }
  }

  static getAllSections(form: Form) {
    let result = [];

    for (const section of form.sections) {
      result.push(section);
      
      if (!!section["templates"]) {
        for (const subSection of (section as DynamicSection).templates) {
          result.push(subSection);
        }
      }
    }

    return result;
  }
}
