import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { Injectable } from "@angular/core";
import { ValidationDictionary } from "../../validationDictionary";
import { Validation } from "../../validation";

@Injectable({
  providedIn: 'root',
})
export class InitializeOfflineModeCommand {
  constructor() { }

  private async canSkip(): Promise<boolean> {
    let offlineModeParameter = await LocalParameter.table.get(LocalParameter.FORCED_OFFLINE_MODE);

    return offlineModeParameter && offlineModeParameter.value;
  }

  public async run() : Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.FORCED_OFFLINE_MODE,
          value: false
        }));
      }
    } catch (error) {
      validationDictionary.add(new Validation({key: "InitializeOfflineModeCommand", message: error.message}));
    }

    return validationDictionary;
  }
}