// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DataSourceImportationResult extends ChangeTrackingEntity {
  public static table: Dexie.Table<DataSourceImportationResult, string>;
  public static tableName: string = 'DataSourceImportationResult';

  public static DATA: string = 'data';  public static DATA_SOURCE_IMPORTATION_ID: string = 'dataSourceImportationId';  public static ID: string = 'id';  public static ID_KEY: string = 'idKey';  public static TIME_STAMP: string = 'timeStamp';

  public data: string;  public dataSourceImportationId: string;  public id: string;  public idKey: string;  public timeStamp: Date;

  public constructor(args?: Partial<DataSourceImportationResult>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DataSourceImportationResult.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DataSourceImportationResult.tableName);
      this.table.mapToClass(DataSourceImportationResult);
  }
}
