<div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
  <div class="layout-left">
    {{ title }}
  </div>

  <div class="layout-right">
    <button class="color-set-transparent" (click)="removeSecurityGroup()" [disabled]="!selectedSecurityGroup">
      <i class="fas fa-solid fa-trash"></i>
    </button>

    <button class="color-set-transparent" (click)="addSecurityGroup()" [disabled]="disabled">
      <i class="fas fa-solid fa-plus"></i>
    </button>
  </div>
</div>

<div class="padding-normal">
  <div class="alert alert-danger" role="alert" *ngIf="!hasDataSourceItems">{{ 'entitySecurityGroup.warningNoGroups' | translate}}</div>

  <app-list #securityGroupList [dataSource]="securityGroupDataSource"
    [numberOfSelectableItem]="1" [hoverable]="true" [selectionEnabled]="true"
    (selectedItemsChange)="selectSecurityGroup($event)" [showCheckBoxes]="false">
    <ng-template #listTemplate let-item>
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-left">
          {{ item.text }}
        </div>
      </div>
    </ng-template>
  </app-list>
</div>

<app-entity-security-group #entitySecurityGroupPopup [source]="source"></app-entity-security-group>

<app-modal #modalPopup></app-modal>
