import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainMenuComponent } from './pages/mainMenu/mainMenu.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskTypeListComponent } from './pages/tasks/task-type-list/task-type-list.component';
import {
  DxAutocompleteModule,
  DxBoxModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxHtmlEditorModule,
  DxNumberBoxModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxColorBoxModule,
  DxDateBoxComponent,
  DxTreeListModule
} from 'devextreme-angular';
import { DxoFormItemModule } from 'devextreme-angular/ui/nested';
import { SafeHtmlPipe } from './core/pipe/safe-html.pipe';
import { AuditListComponent } from './pages/audit-list/audit-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuditListSearchComponent } from './pages/audit-list/audit-list-search/audit-list-search.component';
import { ProfilePictureComponent } from './pages/profile/profile-picture/profile-picture.component';
import { AuditSectionInformationComponent } from "./pages/audit/audit-section-information/audit-section-information.component";
import { AuditSectionsComponent } from './pages/audit/audit-sections/audit-sections.component';
import { LabelControl } from "src/app/core/directives/LabelControl"
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditState } from "src/app/pages/audit/auditState";
import { MarkAsteriskDirective } from "src/app/components/markAsteriskDirective";
import { AuditResolver } from "src/app/pages/audit/auditResolver";
import { AuditComponent } from './pages/audit/audit.component';
import { AuditNavigationComponent } from './pages/audit/audit-navigation/audit-navigation.component';
import { AuditSectionDetailComponent } from './pages/audit/audit-section-detail/audit-section-detail.component';
import { FormFieldControlService } from "src/app/core/services/formFieldControlService";
import { DynamicFormInputComponent } from './components/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { TextboxComponent } from './components/customFields/controls/textbox/textbox.component';
import { LabelComponent } from './components/customFields/controls/label/label.component';
import { FormService } from "src/app/pages/audit/formService";
import { CustomFieldCheckboxComponent } from "src/app/components/customFields/controls/checkbox/checkbox.component";
import { GroupboxComponent } from './components/customFields/controls/groupbox/groupbox.component';
import { CustomFieldDatetimepickerComponent } from './components/customFields/controls/datetimepicker/datetimepicker.component';
import { RadioButtonComponent } from './components/customFields/controls/radiobutton/radiobutton.component';
import { CustomFieldDropdownComponent } from './components/customFields/controls/dropdown/dropdown.component';
import { PanelComponent } from './components/customFields/controls/panel/panel.component';
import { SignatureComponent } from './components/customFields/controls/signature/signature.component';
import { CameraComponent } from './components/customFields/controls/camera/camera.component';
import { FormattedLabelComponent } from './components/customFields/controls/formattedlabel/formattedlabel.component';
import { BaseControlComponent } from './components/customFields/controls/basecontrol/basecontrol.component';
import { CustomFieldValueListsComponent } from './pages/custom-field-value-lists/custom-field-value-lists.component';
import { CustomFieldValueListResolver } from "src/app/pages/custom-field-value-lists/customFieldValueListsResolver";
import { CustomFieldValueListService } from 'src/app/components/custom-field-value-lists/customFieldValueListService';
import { CustomFieldValueListsListComponent } from './components/custom-field-value-lists/custom-field-value-lists-list/custom-field-value-lists-list.component';
import { CustomFieldValueListDetailsComponent } from './components/custom-field-value-lists/custom-field-value-list-details/custom-field-value-list-details.component';
import { RichtexteditorComponent } from './components/customFields/controls/richtexteditor/richtexteditor.component';
import { ConnectedGuardService } from './core/services/connectedGuardService';
import { BaseListDetailsState } from './components/custom-field-value-lists/baseListDetailsState';
import { NewAuditComponent } from './pages/audit-list/new-audit/new-audit.component';
import { AuditSectionDetailResolver } from './pages/audit/audit-section-detail/audit-section-detail-resolver';
import { DynamicFormService } from './core/services/dynamicFormService';
import { NavigatorGuard } from './core/navigatorGuard';
import { CustomFieldValueItemService } from './components/custom-field-value-lists/customFieldValueItemService';
import { WebcamModule } from 'ngx-webcam';
import { PictureBoxComponent } from './components/customFields/controls/picture-box/picture-box.component';
import { InstructionsButtonComponent } from './components/instructions/instructions-button/instructions-button.component';
import { InstructionsPanelComponent } from './components/instructions/instructions-panel/instructions-panel.component';
import { InstructionState } from './components/instructions/instructionState';
import { EntityAutoSaveGuard } from './core/sections/entityAutoSaveGuard';
import { SimplePopupComponent } from './components/popup/simplePopup/simplePopup.component';
import { AlertSummaryComponent } from './pages/audit/alert-summary/alert-summary.component';
import { AlertTemplateComponent } from './components/customFields/controls/alert-template/alert-template.component';
import { SectionStateService } from './core/services/sectionStateService';
import { SynchronizationErrorComponent } from './pages/synchronization-error/synchronization-error.component';
import { StorageService } from './core/data/storage/storageService';
import { FormTemplateFillRedirectComponent } from './pages/formtemplate-fill-redirect/formtemplate-fill-redirect.component';
import { NewDynamicTabInstanceComponent } from './pages/audit/new-dynamic-tab-instance/new-dynamic-tab-instance.component';
import { VersionService } from './core/services/versionService';
import { PagingComponent } from './components/paging/paging.component';
import { ListComponent } from './components/list/list.component';
import { AuditDocumentsComponent } from './pages/audit/audit-documents/audit-documents.component';
import { DataSourceImportationListComponent } from './pages/data-source/data-source-importation-list/data-source-importation-list.component';
import DataSourceImportationComponent from './pages/data-source/data-source-importation/data-source-importation.component';
import { SynchronizationGuard } from './core/data/synchronization/synchronizationGuard';
import { DropdownComponent } from './components/controls/dropdown/dropdown.component';
import { UserService } from './core/services/userService';
import { TaskMapComponent } from './pages/tasks/task-map/task-map.component';
import { TaskListComponent } from './pages/tasks/task-list/task-list.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AuditNotFoundComponent } from './pages/audit/audit-not-found/audit-not-found.component';
import TaskTypeEditComponent from './pages/tasks/task-type-edit/task-type-edit.component';
import { TaskFilterComponent } from './pages/tasks/task-filter/task-filter.component';
import { WorkflowStepPopupComponent } from './components/workflowstep/workflow-step-popup/workflow-step-popup.component';
import { WorkflowStepService } from './core/services/workflowStepService';
import { PendingChangesGuard } from './components/forms/pendingChangesGuard';
import { TouchClickDirective } from './components/touchClickDirective';
import { ButtonComponent } from './components/controls/button/button.component';
import { HealthComponent } from './pages/health/health.component';
import { SectionFieldRequiredDirective } from './components/sectionFieldRequiredDirective';
import { SystemInformationComponent } from './pages/system-information/system-information.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationAuthorizationRouting } from './authentication/authenticationAuthorization.routing';
import { ComponentModule } from './components/component.module';
import { AuthenticationAuthorizationModule } from './authentication/authentication.module';
import { UnauthorizedComponent } from './authentication/unauthorized/unauthorized.component';
import { ListOptionComponent } from './components/list/list-option/list-option.component';
import { DataSourceImportationResultComponent } from './pages/data-source/data-source-importation-result/data-source-importation-result.component';
import { DataSourceImportationResultItemComponent } from './pages/data-source/data-source-importation-result-item/data-source-importation-result-item.component';
import { DataSourceSelectorComponent } from './pages/data-source/data-source-selector/data-source-selector.component';
import { CustomFieldButtonComponent } from './components/customFields/controls/button/button.component';
import { DataSourcePropertyComponent } from './pages/data-source/data-source-property/data-source-property.component';
import { HeaderDetailComponent } from './components/headers/header-detail/header-detail.component';
import { HeaderListComponent } from './components/headers/header-list/header-list.component';
import { PermissionService } from './core/services/permissionService';
import { AuditDuplicateService } from './pages/audit/audit-duplicate/auditDuplicateService';
import { AuditDuplicateComponent } from './pages/audit/audit-duplicate/audit-duplicate.component';
import { EntitySecurityGroupComponent } from './pages/tasks/entity-security-group/entity-security-group.component';
import { EntitySecurityGroupListComponent } from './pages/tasks/entity-security-group-list/entity-security-group-list.component';
import { EntitySecurityGroupPermissionRepository } from './pages/tasks/entity-security-group/entitySecurityGroupPermissionRepository';
import { OptionListEditComponent } from './pages/tasks/option-list-edit/option-list-edit.component';
import { AppMiddleware } from './app.middleware';
import { AppResolver } from './app.resolver';
import { OfflineComponent } from './pages/offline/offline.component';
import { AuditDeleteComponent } from './pages/audit/audit-delete/audit-delete.component';
import { HeaderAuditComponent } from './components/headers/header-audit/header-audit.component';
import { AdditionalOptionsComponent } from './pages/audit/additional-options/additional-options.component';
import { DataSourceUrlParametrizationComponent } from './pages/data-source/data-source-url-parametrization/data-source-url-parametrization.component';
import { DataImportationComponent } from './pages/data-importation/data-importation.component';
import { ProgressionPopupComponent } from './components/progression-popup/progression-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentFormatTypeComponent } from './pages/audit/audit-documents/document-format-type/document-format-type.component';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthenticationServiceFactory } from './authentication/iAuthenticationService.factory';
import { SignaturePadAngularComponent } from './components/customFields/controls/signature/signature-pad.component';
import { SynchronizationDatabaseResetPopupComponent } from './components/synchronization/synchronization-database-reset-popup/synchronization-database-reset-popup.component';
import { FormFilterOptionService } from './core/services/formFilterOptionService';
import { CustomFieldFilterComponent } from './pages/audit-list/audit-list-search/custom-field-filter/custom-field-filter.component';
import { FormTemplateService } from './core/services/formTemplateService';
import { DateTimePickerComponent } from './components/controls/date-time-picker/date-time-picker.component';
import { ProgramSubscriptionComponent } from "./pages/program-subscription/program-subscription.component";
import { AuthenticationRedirectGuard } from './authentication/authenticationRedirectGuard';
import { NavigationService } from './core/navigationService';
import { ConnectionService } from './core/services/connectionService';
import { AuthenticationAzureAdService } from './authentication/authenticationAzureAd.service';
import { FetchImagePipe, FetchImageUrlPipe } from './components/customFields/controls/picture-box/fetchImagePipe';
import { AuditSectionPhotoComponent } from './pages/audit/audit-section-photo/audit-section-photo.component';
import { CheckboxComponent } from './components/controls/checkbox/checkbox.component';

let routes = [];

routes = AuthenticationAuthorizationRouting.register(routes);

routes = routes.concat([
  {
    path: 'synchronization-error',
    component: SynchronizationErrorComponent,
  },
  {
    path: '',
    component: MainMenuComponent,
    resolve: [AppResolver],
  },
  {
    path: 'home',
    component: MainMenuComponent,
    resolve: [AppResolver],
  },
  {
    path: 'system',
    component: SystemInformationComponent,
  },
  {
    path: 'administration',
    children: [
      {
        path: 'health',
        component: HealthComponent,
      },
      {
        path: 'data-source-importations',
        canActivate: [ConnectedGuardService],
        children: [
          {
            path: 'new',
            component: DataSourceImportationComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: ':id',
            component: DataSourceImportationComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: '**',
            component: DataSourceImportationListComponent
          }
        ],
      },
      {
        path: 'task-types',
        canActivate: [ConnectedGuardService],
        children: [
          {
            path: 'new',
            component: TaskTypeEditComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: ':id',
            component: TaskTypeEditComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: '**',
            component: TaskTypeListComponent
          }
        ],
      },
    ]
  },
  {
    path: '',
    resolve: [AppResolver],
    canActivateChild: [NavigatorGuard],
    canActivate: [NavigatorGuard],
    children: [
      {
        path: 'formtemplates/fill',
        component: FormTemplateFillRedirectComponent,
      },
      {
        path: 'formtemplates/:id',
        component: UnauthorizedComponent,
      },
      {
        path: 'formtemplates',
        component: UnauthorizedComponent,
      },
      {
        path: 'forms/:auditNumber/duplicate',
        component: AuditDuplicateComponent
      },
      {
        path: 'forms/:auditNumber/delete',
        component: AuditDeleteComponent
      },
      {
        path: 'forms',
        children: [
          {
            path: "notfound/:number",
            component: AuditNotFoundComponent
          },
          {
            path: ':auditNumber',
            component: AuditComponent,
            resolve: { AuditResolver },
            children: [
              {
                path: '',
                redirectTo: 'sections',
                pathMatch: 'full'
              },
              {
                // Permet d'avoir un url sans spécification d'onglet auquel cas ce sera l'onglet
                // d'informations par défaut.
                path: 'sections',
                component: AuditSectionsComponent,
                resolve: { AuditSectionDetailResolver },
              },
              {
                path: 'alerts/:alertDetailId',
                canDeactivate: [EntityAutoSaveGuard],
                resolve: { AuditSectionDetailResolver },
                component: AuditSectionDetailComponent,
              },
              {
                path: 'instances/:instanceDetailId',
                canDeactivate: [EntityAutoSaveGuard],
                resolve: { AuditSectionDetailResolver },
                component: AuditSectionDetailComponent,
              },
              {
                path: 'sections/:idDetail',
                component: AuditSectionDetailComponent,
                canDeactivate: [EntityAutoSaveGuard],
                resolve: { AuditSectionDetailResolver },
              }
            ]
          },
          {
            path: '**',
            component: AuditListComponent
          }
        ]
      },
      {
        path: 'tasks',
        canActivate: [ConnectedGuardService],
        children: [
          {
            path: 'map',
            component: TaskMapComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: 'filter',
            component: TaskFilterComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: '**',
            component: TaskListComponent
          }
        ]
      },
      {
        path: 'data-sources',
        canActivate: [ConnectedGuardService],
        resolve: { CustomFieldValueListResolver },
        component: CustomFieldValueListsComponent,
        children: [
          {
            path: ':id',
            component: CustomFieldValueListDetailsComponent,
          }
        ]
      },
      {
        path: 'data-source-importations-result-item',
        canActivate: [ConnectedGuardService],
        children: [
          {
            path: 'new',
            component: DataSourceImportationResultItemComponent,
            canDeactivate: [EntityAutoSaveGuard],
          },
          {
            path: ':id',
            component: DataSourceImportationResultItemComponent,
            canDeactivate: [EntityAutoSaveGuard],
          }
        ],
      },
      {
        path: 'data-sources-url-parametrization',
        canActivate: [ConnectedGuardService],
        resolve: { CustomFieldValueListResolver },
        component: DataSourceUrlParametrizationComponent
      },
      {
        path: 'profile/:userAccountId',
        component: ProfileComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'profile-picture',
        component: ProfilePictureComponent
      }
    ]
  },
  {
    path: "program-subscription-management",
    children: [
      {
        path: "**",
        resolve: [AppResolver],
        component: ProgramSubscriptionComponent
      }
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    redirectTo: 'home',
  }
]);

@NgModule({
  imports: [
    // Added onSameUrlNavigation to ensure synchronization refresh
    // the page once completed.
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled",
      onSameUrlNavigation: 'reload', enableTracing: false
    }),
    TranslateModule,
    CommonModule,
    DxAutocompleteModule,
    DxBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxHtmlEditorModule,
    DxNumberBoxModule,
    DxoFormItemModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxColorBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTreeListModule,
    DxScrollViewModule,
    DxValidationGroupModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxButtonModule,
    DxListModule,
    FormsModule,
    ReactiveFormsModule,
    DxPopupModule,
    WebcamModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    AuthenticationAuthorizationModule,
    ComponentModule,
    DragDropModule
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationRedirectGuard,
    SynchronizationGuard,
    ConnectedGuardService,
    AuditService,
    AuditState,
    AuditResolver,
    CustomFieldValueListService,
    CustomFieldValueItemService,
    CustomFieldValueListResolver,
    FormFieldControlService,
    FormService,
    DecimalPipe,
    BaseListDetailsState,
    AuditSectionDetailResolver,
    DynamicFormService,
    NavigatorGuard,
    InstructionState,
    EntityAutoSaveGuard,
    SectionStateService,
    StorageService,
    VersionService,
    UserService,
    WorkflowStepService,
    PendingChangesGuard,
    PermissionService,
    AppMiddleware,
    AppResolver,
    AuditDuplicateService,
    EntitySecurityGroupPermissionRepository,
    AuthenticationServiceFactory,
    AuthenticationAzureAdService,
    FormFilterOptionService,
    FormTemplateService,
    FetchImagePipe,
    FetchImageUrlPipe,
    NavigationService,
    NavigationService,
    ConnectionService
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    MainMenuComponent,
    ProfileComponent,
    ProfilePictureComponent,
    SafeHtmlPipe,
    FetchImagePipe,
    FetchImageUrlPipe,
    AuditListComponent,
    AuditListSearchComponent,
    AuditSectionInformationComponent,
    AuditSectionsComponent,
    AuditComponent,
    AuditNavigationComponent,
    AuditSectionDetailComponent,
    LabelControl,
    MarkAsteriskDirective,
    TouchClickDirective,
    SectionFieldRequiredDirective,
    DynamicFormInputComponent,
    DynamicFormComponent,
    TextboxComponent,
    LabelComponent,
    CustomFieldCheckboxComponent,
    CheckboxComponent,
    GroupboxComponent,
    CustomFieldDatetimepickerComponent,
    RadioButtonComponent,
    DropdownComponent,
    CustomFieldDropdownComponent,
    PanelComponent,
    SignatureComponent,
    CameraComponent,
    FormattedLabelComponent,
    BaseControlComponent,
    CustomFieldValueListsComponent,
    CustomFieldValueListsListComponent,
    CustomFieldValueListDetailsComponent,
    RichtexteditorComponent,
    NewAuditComponent,
    PictureBoxComponent,
    InstructionsButtonComponent,
    InstructionsPanelComponent,
    SimplePopupComponent,
    AlertSummaryComponent,
    AlertTemplateComponent,
    SynchronizationErrorComponent,
    FormTemplateFillRedirectComponent,
    NewDynamicTabInstanceComponent,
    PagingComponent,
    ListComponent,
    AuditDocumentsComponent,
    DataSourceImportationListComponent,
    DataSourceImportationComponent,
    TaskListComponent,
    TaskMapComponent,
    AuditNotFoundComponent,
    TaskTypeListComponent,
    TaskTypeEditComponent,
    TaskFilterComponent,
    WorkflowStepPopupComponent,
    ButtonComponent,
    HealthComponent,
    SystemInformationComponent,
    ListOptionComponent,
    DataSourceImportationResultComponent,
    DataSourceImportationResultItemComponent,
    DataSourceSelectorComponent,
    CustomFieldButtonComponent,
    DataSourcePropertyComponent,
    HeaderDetailComponent,
    HeaderListComponent,
    OfflineComponent,
    HeaderAuditComponent,
    AuditDuplicateComponent,
    AuditDeleteComponent,
    AdditionalOptionsComponent,
    DataSourceUrlParametrizationComponent,
    DataSourceUrlParametrizationComponent,
    EntitySecurityGroupComponent,
    EntitySecurityGroupListComponent,
    OptionListEditComponent,
    OfflineComponent,
    DataImportationComponent,
    ProgressionPopupComponent,
    DocumentFormatTypeComponent,
    SignaturePadAngularComponent,
    SynchronizationDatabaseResetPopupComponent,
    CustomFieldFilterComponent,
    DateTimePickerComponent,
    ProgramSubscriptionComponent,
    AuditSectionPhotoComponent,
    ProgramSubscriptionComponent
  ]
})
export class AppRoutingModule { }
