<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'programSubscriptionManagement.title' | translate}} ({{ itemCount }})
      </div>
    </div>
  </div>

  <div class="layout-center">
    <div *ngIf="!isOnline" class="layout-horizontal height-small color-set-invert-red padding-horizontal-normal">
      <div class="layout-center align-left">
        {{ 'programSubscriptionManagement.isOffline' | translate}}
      </div>
    </div>

    <app-list #list [dataSource]="programSubscriptionDataSource" [selectedItems]="selectedItems" 
        [canToggleSelection]="true" [numberOfSelectableItem]="numberOfSelectableItems()" 
        [enableFilter]="true" (activatedItem)="selectedItemsChange($event)" [showCheckBoxes]="false" [showInstructions]="false">
      <ng-template #listTemplate let-item>
        <div class="layout-vertical">
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  {{item.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>

<app-modal #modalPopup></app-modal>