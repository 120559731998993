import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { StorageState } from 'src/app/core/data/storage/storageState';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { SynchronizationStatus } from 'src/app/core/data/synchronization/synchronizationStatus';
import { environment } from 'src/environments/environment';
import { IAuthenticationService } from 'src/app/authentication/iAuthenticationService.interface';
import { AuthenticationServiceFactory } from 'src/app/authentication/iAuthenticationService.factory';
import { Router } from '@angular/router';
import { PopupUtility } from '../../popup/popup.utility';
import { TranslateService } from '@ngx-translate/core';
import { SimplePopupComponent } from '../../popup/simplePopup/simplePopup.component';
import { SynchronizationPopupState } from '../synchronizationPopupState';

@Component({
  selector: 'app-synchronization-context',
  templateUrl: './synchronization-context.component.html',
  styleUrls: ['./synchronization-context.component.scss']
})
export class SynchronizationContextComponent implements AfterViewInit, OnInit {
  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  public SynchronizationStatus = SynchronizationStatus;
  public viewInitComplete = false;

  // This will prevent to click on the synchronize button when the user is not authenticated.
  // There are some cases when creating a new database where the user is not still existing
  // but the synchronize button is displayed. And the synchronize button requires to have a valid
  // authenticated user to works properly.
  public isAuthenticated: boolean;

  private authenticationService: IAuthenticationService;

  constructor(
    public synchronizationService: SynchronizationService,
    public storageState: StorageState,
    public authenticationServiceFactory: AuthenticationServiceFactory,
    public router: Router,
    public synchronizationPopupState: SynchronizationPopupState,
    private translateService: TranslateService
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = true; // await this.authenticationService.isAuthenticated();
  }

  async ngAfterViewInit(): Promise<void> {
    // HACK: This is used to avoid displaying the hide animation
    // on page load. By delaying view init, it keeps animation at 0s
    // duration set in the SCSS.
    setTimeout(() => this.viewInitComplete = true, 500);
  }

  public version = environment.version;

  async synchronize() {
    if (await this.synchronizationService.getUpdates(true)) {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));
    }
  }

}
