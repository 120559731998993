export class Color {
    a: number;
    r: number;
    g: number;
    b: number;

    public constructor(args?: Partial<Color>) {
        Object.assign(this, args);
    }

    static toColor(value): Color {
        value >>>= 0;
        var b = value & 0xFF,
            g = (value & 0xFF00) >>> 8,
            r = (value & 0xFF0000) >>> 16,
            a = ((value & 0xFF000000) >>> 24) / 255;

        return new Color({ r: r, g: g, b: b, a: a });
    }

    toHex() {
        return "#" + this.getHexCode(this.r) + this.getHexCode(this.g) + this.getHexCode(this.b);
    }

    private getHexCode(colorCode: number) {
        var hex = colorCode.toString(16);

        return hex.length == 1 ? "0" + hex : hex;
    }

    static getNumericCode(colorCode: string) {
        var parseString = colorCode.replace("#","");
        var num = parseInt(parseString, 16);

        return num;
    }
}