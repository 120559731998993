// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Permission extends ChangeTrackingEntity {
  public static table: Dexie.Table<Permission, string>;
  public static tableName: string = 'Permission';

  public static ACCESS_VALUE: string = 'accessValue';  public static GROUP_ID: string = 'groupId';  public static ID: string = 'id';  public static PERMISSION_LIST_ID: string = 'permissionListId';  public static TIME_STAMP: string = 'timeStamp';  public static UNIQUE_KEY: string = 'uniqueKey';  public static USER_ID: string = 'userId';

  public accessValue: number;  public groupId: string;  public id: string;  public permissionListId: string;  public timeStamp: Date;  public uniqueKey: string;  public userId: string;

  public constructor(args?: Partial<Permission>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Permission.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Permission.tableName);
      this.table.mapToClass(Permission);
  }
}
