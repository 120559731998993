import { BaseRepository } from "src/app/core/data/baseRepository";
import { Injectable } from "@angular/core";
import { DatabaseContext } from "src/app/core/data/databaseContext";
import { Logger } from "src/app/core/log/logger";
import { CustomFieldValueItem } from "../../core/data/models/database/customFieldValueItem.database";
import { CustomFieldValueItemRepository } from "../../core/data/repositories/customFieldValueItemRepository";

@Injectable({
  providedIn: 'root',
})
export class CustomFieldValueItemService {
  constructor(
    public databaseContext: DatabaseContext,
    private customFieldValueItemRepository: CustomFieldValueItemRepository,
    protected logger: Logger,
    private baseRepository: BaseRepository) { }

  public async insert(customFieldItem: CustomFieldValueItem) {

    customFieldItem.position = await this.getPosition(customFieldItem.valueListId);

    return await this.baseRepository.insert(CustomFieldValueItem.table, customFieldItem);
  }

  async getPosition(valueListId: string): Promise<number> { 
    return await this.customFieldValueItemRepository.getItemsByListId(valueListId, "position").then(customFieldItems => {
      if (customFieldItems.length > 0) {
        return customFieldItems[customFieldItems.length - 1].position + 1;
      } else {
        return 1;
      }
    });
  }
}
