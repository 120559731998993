<app-popup #popup [(visible)]="visible" [canClose]="true" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padd">
        <div class="layout-left">
          <button><i class="fas fa-plus"></i>
            <div><span> {{ 'createForm.toolbar.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button (click)="close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <app-list #list [dataSource]="dataSource" [enableFilter]="true" [numberOfRequiredItems]="1"
        [numberOfSelectableItem]="1" [hoverable]="true" [selectionEnabled]="true"
        (selectedItemsChange)="selectProgram($event)">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal height-normal padding-horizontal-normal">
            <div class="layout-left">
              {{ item.name }}
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white padding-compact">
        <div class="layout-right">
          <button name="createButton" [class.color-set-green]="selectedProgram"
            [disabled]="!selectedProgram || createAuditPromise" (click)="create()">
            <i *ngIf="createAuditPromise" class="fas fa-circle-notch fa-spin"></i>
            <div><span>{{ 'createForm.create' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
<app-progression-popup
  [visible]="createAuditPromise"
  title="{{ 'createForm.progressTitle' | translate}}"
  message="{{ 'createForm.progressMessage' | translate}}"></app-progression-popup>