import { Injectable } from '@angular/core';
import { FormFieldImageExtendedValue } from '../data/models/formFieldImageExtendedValue';
import { AuditImage } from '../data/models/database/auditImage.database';
import { BaseRepository } from '../data/baseRepository';
import { Image as DatabaseImage } from '../data/models/database/image.database';
import imageCompression from 'browser-image-compression';
import { ParameterPhoto } from '../data/models/database/parameterPhoto.database';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  static META_DATA_PREFIX: string = "data:image/jpeg;base64,"

  constructor(private baseRepository: BaseRepository) {
  }

  // [browser-image-compression - npm](https://www.npmjs.com/package/browser-image-compression)
  // const options: Options = { 
  //   maxSizeMB: number,            // (default: Number.POSITIVE_INFINITY)
  //   maxWidthOrHeight: number,     // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
  //                                 // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
  //                                 // Please check the Caveat part for details.
  //   onProgress: Function,         // optional, a function takes one progress argument (percentage from 0 to 100) 
  //   useWebWorker: boolean,        // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  //   libURL: string,               // optional, the libURL of this library for importing script in Web Worker (default: https://cdn.jsdelivr.net/npm/browser-image-compression/dist/browser-image-compression.js)
  //   preserveExif: boolean,        // optional, use preserve Exif metadata for JPEG image e.g., Camera model, Focal length, etc (default: false)

  //   signal: AbortSignal,          // optional, to abort / cancel the compression

  //   // following options are for advanced users
  //   maxIteration: number,         // optional, max number of iteration to compress the image (default: 10)
  //   exifOrientation: number,      // optional, see https://stackoverflow.com/a/32490603/10395024
  //   fileType: string,             // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
  //   initialQuality: number,       // optional, initial quality value between 0 and 1 (default: 1)
  //   alwaysKeepResolution: boolean // optional, only reduce quality, always keep width and height (default: false)
  // }
  public async compress(file: File): Promise<File> {
    // There is always at most one record in this table so no need to filter it with concerns of performance.
    let parameterPhotos = await ParameterPhoto.table.toArray();
    let parameterPhoto = parameterPhotos[0];

    const defaultInitialQuality: number = 1;
    const defaultMaximumWidthOrSize: any = undefined;
    const defaultMaximumSize: number = Number.POSITIVE_INFINITY;

    let maximumSize: number;
    let maximumWidthOrHeight: any;
    let initialQuality: number;

    if (parameterPhoto) {
      maximumSize = !!parameterPhoto.maximumSize ? parameterPhoto.maximumSize : defaultMaximumSize;
      maximumWidthOrHeight = !!parameterPhoto.maximumWidthOrHeight ? parameterPhoto.maximumWidthOrHeight : defaultMaximumWidthOrSize;
      initialQuality = !!parameterPhoto.initialQuality ? parameterPhoto.initialQuality / 100 : 1
    }
    else {
      maximumSize = defaultMaximumSize;
      maximumWidthOrHeight = defaultMaximumWidthOrSize;
      initialQuality = defaultInitialQuality;
    }

    const options = {
      maxSizeMB: maximumSize,
      maxWidthOrHeight: maximumWidthOrHeight,
      useWebWorker: true,
      initialQuality: initialQuality
    }
    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  getBase64Size(base64String: string): number {
    // Remove data URL prefix if present
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '');

    // Get the length of the decoded base64 string
    const decodedData = atob(base64WithoutPrefix);

    // Return the size of the decoded data in bytes
    return decodedData.length;
  }

  static numberOfBytesInOneKb: number = 1024;

  static convertBytesToMB(sizeInBytes: number): number {
    return sizeInBytes / this.numberOfBytesInOneKb / this.numberOfBytesInOneKb;
  }

  static convertMBToBytes(sizeInMB: number): number {
    return sizeInMB * this.numberOfBytesInOneKb * this.numberOfBytesInOneKb
  }

  /**
  * Format bytes as human-readable text.
  * 
  * @param bytes Number of bytes.
  * @param decimales Number of decimal places to display.
  * 
  * @return Formatted string.
  */
  static getHumanFileSize(bytes, cultureSuffix, decimales = 1) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    let u = -1;
    const r = 10 ** decimales;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(decimales) + ' ' + units[u] + cultureSuffix;
  }

  public async convertFileToBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  public removeBase64Metadata(value: string) {
    return FormFieldImageExtendedValue.removeBase64Metadata(value);
  }

  async deleteAuditImageWithImageReference(auditImagesToDelete: AuditImage[]) {
    if (!auditImagesToDelete || auditImagesToDelete.length === 0) {
      return;
    }

    await this.baseRepository.delete(AuditImage.table, auditImagesToDelete);

    let auditId = auditImagesToDelete[0].auditId;
    let allAuditAuditImages = await AuditImage.table.where({ auditId: auditId }).toArray();

    let referencedImagesToDelete: string[] = [];

    referencedImagesToDelete.push.apply(referencedImagesToDelete, auditImagesToDelete.map((x) => { return x.originalImageId }));
    referencedImagesToDelete.push.apply(referencedImagesToDelete, auditImagesToDelete.map((x) => { return x.imageId }));

    let imageWithoutAnyReferences = [];

    for (let referencedImageId of referencedImagesToDelete.filter(imageId => imageId)) {
      let auditImage = allAuditAuditImages.find(auditImage => auditImage.originalImageId === referencedImageId
        || auditImage.imageId === referencedImageId)

      if (!auditImage) {
        imageWithoutAnyReferences.push(new DatabaseImage({ id: referencedImageId }));
      }
    }

    if (imageWithoutAnyReferences.length > 0) {
      await this.baseRepository.delete(DatabaseImage.table, imageWithoutAnyReferences);
    }
  }

  public async deleteImageWithoutReference(imageId: string, auditId: string) {
    let allAuditAuditImages = await AuditImage.table.where({ auditId: auditId }).toArray();

    for (let auditImage of allAuditAuditImages) {
      if (auditImage.imageId === imageId || auditImage.originalImageId === imageId) {
        // Do not delete image, it is still referenced by an other auditImage
        return;
      }
    }

    await this.baseRepository.delete(DatabaseImage.table, new DatabaseImage({ id: imageId }));
  }

  async updateAuditImage(auditImage: AuditImage) {
    await this.baseRepository.update(AuditImage.table, auditImage);
  }

  async updateImage(image: DatabaseImage) {
    await this.baseRepository.update(DatabaseImage.table, image);
  }

  async insertImage(image: DatabaseImage) {
    await this.baseRepository.insert(DatabaseImage.table, image);
  }
}
