declare var window: any;

export const environment = {
  production: window.config?.production,
  apiBaseUrl: window.config?.apiBaseUrl,
  logLevel: window.config?.logLevel,
  numberOfItemPerPage: window.config?.numberOfItemPerPage, // Should be moved to userPreference when available.
  databaseScenario: window.config?.databaseScenario,
  mobileIdHeaderKey: window.config?.mobileIdHeaderKey,
  userIdHeaderKey: window.config?.userIdHeaderKey,
  auditSubscriptionDays: window.config?.auditSubscriptionDays,
  canEditProfile: window.config?.canEditProfile,
  language: window.config?.language,
  version: window.config?.version,
  databaseVersion: window.config?.databaseVersion,
  defaultDebounceMilliseconds: window.config?.defaultDebounceMilliseconds,
  displayLoadingMilliseconds: window.config?.displayLoadingMilliseconds,
  requireSynchroronizationCheckInterval: window.config?.requireSynchroronizationCheckInterval,
  synchronizationDebounceMilliseconds: window.config?.synchronizationDebounceMilliseconds,
  synchronizationMaxDataValuesSize: window.config?.synchronizationMaxDataValuesSize,
  authentication: window.config?.authentication,
  enableDataSourceImportation: window.config?.enableDataSourceImportation,
  formMobilePrefix: window.config?.formMobilePrefix,
  formPrefixMaxDisplayLength: window.config?.formPrefixMaxDisplayLength,
  currentLocation: window.config?.currentLocation,
  enableFeatures: {
    dataSourceImportations: window.config?.enableFeatures.dataSourceImportations,
    tasks: window.config?.enableFeatures.tasks,
    securityGroups: window.config?.enableFeatures.securityGroups,
    instructions: window.config?.enableFeatures.instructions,
    alerts: window.config?.enableFeatures.alerts,
    syncErrorDatabaseReset: window.config?.enableFeatures.syncErrorDatabaseReset,
    mobiles: window.config?.enableFeatures.mobiles,
    imagesSize: window.config?.enableFeatures.imagesSize,
    programSubscriptionManagement: window.config?.enableFeatures.programSubscriptionManagement
  },
  UI: {
    threeStatesCheckboxesOrder: window.config?.UI.threeStatesCheckboxesOrder
  },
  disableNativeCamera: window.config?.disableNativeCamera,
  nativeCameraSupportedPlatforms: window.config?.nativeCameraSupportedPlatforms,
  cameraFlash: window.config?.cameraFlash,

  constants: {
    USERID_PARAMETER_NAME: "UserId"
  },
  compressionLevel: window.config?.compressionLevel,
  checkServerInterval: window.config?.checkServerInterval,
  disableAlerts: window.config?.disableAlerts,
  markerJsLicenseKey: window.config?.markerJsLicenseKey
};