import { Injectable } from "@angular/core";
import { DatabaseContext } from "./databaseContext";
import { ChangeTrackingFactory } from "./changeTracking/changeTrackingFactory";
import { SynchronizationService } from "./synchronization/synchronizationService";
import { StorageService } from "./storage/storageService";
import { Logger } from "../log/logger";

@Injectable({
  providedIn: "root",
})
export abstract class BaseRepositoryConstructor {
  constructor(
    public databaseContext: DatabaseContext,
    public logger: Logger,
    public changeTrackingFactory: ChangeTrackingFactory,
    public synchronizationService: SynchronizationService,
    public storageService: StorageService
  ) {
  }
}
