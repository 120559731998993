import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { current } from 'devextreme/ui/themes';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  images = [
    {
      path: "./assets/Portraits/Frame_1_200.png"
    },
    {
      path: "./assets/Portraits/Frame_2_200.png"
    },
    {
      path: "./assets/Portraits/Frame_3_200.png"
    },
    {
      path: "./assets/Portraits/Frame_4_200.png"
    },
    {
      path: "./assets/Portraits/Frame_5_200.png"
    },
    {
      path: "./assets/Portraits/Frame_11_200.png"
    },
    {
      path: "./assets/Portraits/Frame_12_200.png"
    },
    {
      path: "./assets/Portraits/Frame_13_200.png"
    },
    {
      path: "./assets/Portraits/Frame_14_200.png"
    },
    {
      path: "./assets/Portraits/Frame_15_200.png"
    },
    {
      path: "./assets/Portraits/Frame_16_200.png"
    },
    {
      path: "./assets/Portraits/Frame_17_200.png"
    },
    {
      path: "./assets/Portraits/Frame_18_200.png"
    },
    {
      path: "./assets/Portraits/Frame_19_200.png"
    },
    {
      path: "./assets/Portraits/Frame_20_200.png"
    },
    {
      path: "./assets/Portraits/Frame_21_200.png"
    },
    {
      path: "./assets/Portraits/Frame_22_200.png"
    },
    {
      path: "./assets/Portraits/Frame_23_200.png"
    },
    {
      path: "./assets/Portraits/Frame_24_200.png"
    },
    {
      path: "./assets/Portraits/Frame_25_200.png"
    },
  ]

  constructor(
    private baseRepository: BaseRepository,
    private router: Router,
    private authenticatedUser: AuthenticatedUser,
  ) { }

  ngOnInit(): void {
  }

  async changeProfilePicturePath(imagePath: string) {
    this.authenticatedUser.imagePath = imagePath;
    this.authenticatedUser.avatarPath = imagePath.replace("200", "40");

    await this.baseRepository.update(UserAccount.table, this.authenticatedUser as any);

    this.goToProfile();
  }

  goToProfile() {
    this.router.navigate(['profile'])
  }
}
