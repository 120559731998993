import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuditState } from "src/app/pages/audit/auditState";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { IEntityAutoSaveGuard } from "src/app/core/sections/IEntityAutoSaveGuard";
import { CanLeaveEntityAutoSaveResult } from "src/app/core/sections/canLeaveEntityAutoSaveResult";
import { DefaultSectionGuard } from "src/app/core/sections/defaultSectionGuard";
import { Subscription } from "rxjs";
import { DynamicFormCustomAction } from "src/app/components/dynamic-form/dynamicFormCustomAction";
import { Router } from "@angular/router";
import { HeaderState } from "src/app/components/headers/header/headerState";

@Component({
  selector: "app-audit-section-detail",
  templateUrl: "./audit-section-detail.component.html",
  styleUrls: ["./audit-section-detail.component.scss"],
})
export class AuditSectionDetailComponent implements IEntityAutoSaveGuard, OnInit, OnDestroy {
  @ViewChild("customFieldTab") customFieldTab: IEntityAutoSaveGuard;
  @ViewChild("informationTab") informationTab: IEntityAutoSaveGuard;

  public sectionTypes = SectionType;
  public customActions: DynamicFormCustomAction[];
  private backButtonClickSubscription: Subscription;

  constructor(
    public readonly auditState: AuditState,
		private readonly router: Router,
		private readonly headerState: HeaderState,
  ) { }

  ngOnInit(): void {
    this.headerState.isAlert = true;
    this.customActions = this.auditState.getCustomActions();
  }

  ngOnDestroy(): void {
    this.backButtonClickSubscription?.unsubscribe();
    this.headerState.isAlert = false;
  }

  public async canLeave(): Promise<CanLeaveEntityAutoSaveResult> {
    switch (this.auditState.section.type) {
      case SectionType.Undefined:
      case SectionType.CustomFields:
        return this.getSectionGuard().canLeave();
      default:
        return new CanLeaveEntityAutoSaveResult({success: true});
    }
  }

  public async saveChanges(): Promise<boolean> {
    switch (this.auditState.section.type) {
      case SectionType.Undefined:
      case SectionType.CustomFields:
        return this.getSectionGuard().saveChanges();
      default:
        return true;
    }
  }

  public navigateToAuditSections(): void {
    this.router.navigate([`/forms/${this.auditState.audit.number}`]);
  }

  private getSectionGuard(): IEntityAutoSaveGuard {
    let auditSectionGuard: IEntityAutoSaveGuard;

    if (this.customFieldTab)
      auditSectionGuard = (this.customFieldTab as IEntityAutoSaveGuard);
    else if (this.informationTab)
      auditSectionGuard = (this.informationTab as IEntityAutoSaveGuard);
    else {
      auditSectionGuard = new DefaultSectionGuard();
    }

    return auditSectionGuard;
  }

  async stepChanging(){
    await this.getSectionGuard().saveChanges();
  }
}
