import { Component, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { Logger } from 'src/app/core/log/logger';
import { DocumentTypeViewModel } from './documentTypeViewModel';
import { DocumentType } from './documentType';
import { AuditState } from '../../auditState';

@Component({
  selector: 'app-document-format-type',
  templateUrl: './document-format-type.component.html',
  styleUrls: ['./document-format-type.component.scss']
})
export class DocumentFormatTypeComponent {
  @ViewChild(PopupComponent) popup: PopupComponent;

  items: DocumentTypeViewModel[] = [];

  selectedType: DocumentType;

  private promiseResolver = (boolean) => {};

  constructor(
    private logger: Logger,
    private auditState: AuditState
    ) { }

  async display(documentId){
    this.selectedType = null;

    let document = this.auditState.form.documents.find(x => x.id == documentId);

    this.items = [];
    this.items.push(new DocumentTypeViewModel({description: "Pdf", type: DocumentType.pdf}));

    if (document.fileExtension == ".docx")
      this.items.push(new DocumentTypeViewModel({description: "Word", type: DocumentType.word}));
    else if (document.fileExtension == ".xlsx")
      this.items.push(new DocumentTypeViewModel({description: "Excel", type: DocumentType.excel}));
    else
      await this.logger.logWarning(`File extension ${document.fileExtension} is not supported.`);

    this.popup.display();

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  printButtonClick(){
    this.popup.close();
    this.promiseResolver(true);
  }

  cancelButtonClick(){
    this.popup.close();
    this.promiseResolver(false);
  }
}
