import { Injectable } from "@angular/core";
import { AuditListSearchOptions } from "src/app/pages/audit-list/auditListSearchOptions";
import { BaseRepository } from "../baseRepository";
import { UserAudit } from "../models/databaseLocal/userAudit.database";
import _ from 'lodash';
import moment from "moment";
import { WFStep } from "../models/database/wFStep.database";
import { ComparatorService } from "../../services/comparator.service";
import { ListDataSourceFunctionResult } from "src/app/components/list/listDatasourceFunctionResult";
import { BaseRepositoryConstructor } from "../baseRepositoryConstructor";
import { PermissionService } from "../../services/permissionService";
import { AuthenticatedUser } from "../../security/authenticatedUser";
import { PermissionType } from "../enums/permissionType";
import { AuditDataTable } from "../models/database/auditDataTable.database";
import { AuditState } from "src/app/pages/audit/auditState";
import { Form } from "../models/form/form";
import { CustomFieldControlType } from "../models/form/customFieldControlType";
import { CustomFieldValueItem } from "../models/database/customFieldValueItem.database";
import { Program } from "../models/database/program.database";
import { ProgramVersion } from "../models/database/programVersion.database";
import { ProgramVersionRepository } from "./programVersionRespository";
import { Section } from "../models/form/section";
import { TranslateService } from "@ngx-translate/core";
import { DataType } from "../models/form/dataType";
import { FormatUtility } from "../../formatUtility";
import { NumberFormatter } from "../../numberFormatter";
import { InputMaskFormatter } from "../../inputMaskFormatter";
import { StringSearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/stringSearchOperator";
import { NumericSearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/numericSearchOperator";
import { BooleanSearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/booleanSearchOperator";
import { DataSourceSearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/dataSourceSearchOperator";
import { DateSearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/dateSearchOperator";
import { StringComparisonType } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/stringComparisonType";
import { FormTemplateService } from "../../services/formTemplateService";
import { SearchOperator } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/searchOperator";
import { NumericComparisonType } from "src/app/pages/audit-list/audit-list-search/custom-field-filter/searchOperators/numericComparisonType";
import { Control } from "../models/form/control";
import { environment } from "src/environments/environment";
import { SynchronizationService } from "../synchronization/synchronizationService";

@Injectable({
  providedIn: 'root',
})
export class UserAuditRepository extends BaseRepository {
  private _forms: Form[] = [];

  constructor(
    public baseRepositoryConstructor: BaseRepositoryConstructor,
    private permissionService: PermissionService,
    private authenticatedUser: AuthenticatedUser,
    private programVersionRepository: ProgramVersionRepository,
    private translateService: TranslateService,
    private formTemplateService: FormTemplateService,
    private synchronizationService: SynchronizationService
  ) {
    super(baseRepositoryConstructor);
  }

  async getSortedUserAuditListWithSearchOptions(searchOptions: AuditListSearchOptions, adHocFilter: string): Promise<ListDataSourceFunctionResult> {
    let audits: UserAudit[] = await UserAudit.table.toArray();

    let filteredAudits = [];

    let canEditOthers = await this.permissionService.hasAccess(PermissionType.FormEditOthers);

    let formTemplate: Program;
    let formTemplateControls: Control[];

    for (let audit of audits) {
      let keep = true;

      if (keep && (this.synchronizationService.offline || searchOptions.synchronizedOnly)) {
        keep = audit.synchronized;
      }

      if (keep && searchOptions.templateIds && searchOptions.templateIds.length > 0) {
        keep = _.includes(searchOptions.templateIds, audit.programId);
      }

      if (keep && searchOptions.workflowStepNames && searchOptions.workflowStepNames.length > 0) {
        let workflowSteps = await WFStep.table.toArray();
        let filteredWorkflowStep = workflowSteps.filter(workflowStep => _.includes(searchOptions.workflowStepNames, workflowStep.name));
        let filteredWorkflowStepIds = filteredWorkflowStep.map(workflowStep => workflowStep.id);

        keep = _.includes(filteredWorkflowStepIds, audit.stepId);
      }

      if (keep && searchOptions.number && searchOptions.number.length > 0) {
        keep = ComparatorService.stringMatch(audit.number || '', searchOptions.number)
      }

      if (keep && searchOptions.externalNumber && searchOptions.externalNumber.length > 0) {
        keep = ComparatorService.stringMatch(audit.externalNumber || '', searchOptions.externalNumber)
      }

      if (keep && searchOptions.assignedToIds && searchOptions.assignedToIds.length > 0) {
        keep = _.includes(searchOptions.assignedToIds, audit.responsibleId);
      }

      if (keep && searchOptions.fromCreatedDate) {
        keep = moment(audit.createdDate).isSameOrAfter(moment(searchOptions.fromCreatedDate));
      }

      if (keep && searchOptions.fromCreatedDate) {
        keep = moment(audit.createdDate).isSameOrBefore(moment(searchOptions.toCreatedDate));
      }

      if (keep && searchOptions.fromUpdatedDate) {
        keep = moment(audit.updatedDate).isSameOrAfter(moment(searchOptions.fromUpdatedDate));
      }

      if (keep && searchOptions.toUpdatedDate) {
        keep = moment(audit.updatedDate).isSameOrBefore(moment(searchOptions.toUpdatedDate));
      }

      if (keep && adHocFilter) {
        // This is a first iteration, to test the feature in combinaison of the
        // filters above. In the future, this should be improved by looping
        // though object keys and filter base on property type to be used as a default
        // in many situation.
        keep = ComparatorService.stringMatch(audit.number || '', adHocFilter)
          || ComparatorService.stringMatch(audit.externalNumber || '', adHocFilter)
          || ComparatorService.stringMatch(moment(audit.createdDate).format('l LT'), adHocFilter)
          || ComparatorService.stringMatch(moment(audit.updatedDate).format('l LT'), adHocFilter)
          || ComparatorService.stringMatch(moment(audit.effectiveDate).format('l LT'), adHocFilter)
          || ComparatorService.stringMatch(audit.stepName || '', adHocFilter)
          || ComparatorService.stringMatch(audit.programDescription || '', adHocFilter)
          || ComparatorService.stringMatch(audit.createdByName || '', adHocFilter)
          || ComparatorService.stringMatch(audit.responsibleName || '', adHocFilter)
      }

      if (keep)
        keep = audit.createdById === this.authenticatedUser.id || canEditOthers;

      if (keep && searchOptions.customFieldSearchOperators.length > 0) {
        // Load only once the template and his controls. Because it is not possible to filter using custom field
        // with more than one form template, when the template is loaded, no need to load again for the other forms
        // because they will be of course associated with the same form template.
        if (!formTemplate) {
          formTemplate = await Program.table.get(audit.programId);

          formTemplateControls = await this.formTemplateService.loadControls(formTemplate);
        }

        let auditDataTables: any[] = await AuditDataTable.table.where("auditId").equals(audit.id).toArray();

        for (const searchOperator of searchOptions.customFieldSearchOperators) {
          let searchOperatorTokens = SearchOperator.getTokens(searchOperator);
          let control = formTemplateControls.find(x => x.dataTableName === searchOperatorTokens[0] && x.dataColumnName === searchOperatorTokens[1]);

          // Algorithms are easier to handle with keep by default equal to false and set to true
          // if the criterion match the search operator.
          keep = false;

          if (!control)
            break;

          let auditDataTable = auditDataTables.find(x => x.tableName == searchOperatorTokens[0]);

          if (!auditDataTable)
            break;

          let dataValue = auditDataTable[searchOperatorTokens[1]];

          switch (control.type) {
            case CustomFieldControlType.MaskedEditTextBox:
            case CustomFieldControlType.TextBox:
              if (control["dataType"] as DataType == DataType.String || control.type == CustomFieldControlType.MaskedEditTextBox) {
                let stringSearchOperator = (searchOperator as StringSearchOperator);
                let comparisonType = stringSearchOperator.comparisonType;

                let stringValue: string = dataValue ?? "";

                switch (comparisonType) {
                  case StringComparisonType.contains:
                    if (stringValue.includes(stringSearchOperator.value))
                      keep = true;

                    break;
                  case StringComparisonType.endWith:
                    if (stringValue.endsWith(stringSearchOperator.value))
                      keep = true;

                    break;
                  case StringComparisonType.exact:
                    if (stringValue == stringSearchOperator.value)
                      keep = true;

                    break;
                  case StringComparisonType.notContains:
                    if (!stringValue.includes(stringSearchOperator.value))
                      keep = true;

                    break;
                  case StringComparisonType.startWith:
                    if (stringValue.startsWith(stringSearchOperator.value))
                      keep = true;

                    break;
                  default:
                    if (stringValue.includes(stringSearchOperator.value))
                      keep = true;

                    break;
                }

              }
              else {
                let numericSearchOperator = (searchOperator as NumericSearchOperator);

                let comparisonType = numericSearchOperator.comparisonType;

                let numberValue: number = dataValue ?? 0;

                switch (comparisonType) {
                  case NumericComparisonType.equal:
                    if (numberValue == numericSearchOperator.value)
                      keep = true;

                    break;
                  case NumericComparisonType.notEqual:
                    if (numberValue != numericSearchOperator.value)
                      keep = true;

                    break;
                  case NumericComparisonType.greaterOrEqualThan:
                    if (numberValue >= numericSearchOperator.value)
                      keep = true;

                    break;
                  case NumericComparisonType.greaterThan:
                    if (numberValue > numericSearchOperator.value)
                      keep = true;

                    break;
                  case NumericComparisonType.lowerThan:
                    if (numberValue < numericSearchOperator.value)
                      keep = true;

                    break;
                  case NumericComparisonType.lowerOrEqualThan:
                    if (numberValue <= numericSearchOperator.value)
                      keep = true;

                    break;
                  default:
                    if (numberValue == numericSearchOperator.value)
                      keep = true;

                    break;
                }

              }
              break;
            case CustomFieldControlType.CheckBox:
              let booleanSearchOperator = (searchOperator as BooleanSearchOperator);

              let booleanValue = !!dataValue;

              if (booleanSearchOperator.value == booleanValue)
                keep = true;

              break;
            case CustomFieldControlType.RadioButton:
            case CustomFieldControlType.ComboBox:
              let dataSourceSearchOperator = (searchOperator as DataSourceSearchOperator);

              if (dataValue && dataSourceSearchOperator.value == dataValue.toString())
                keep = true;

              break;
            case CustomFieldControlType.DateTimePicker:
              let dateSearchOperator = (searchOperator as DateSearchOperator);

              if (dataValue) {
                let momentDateValue: moment.Moment;

                momentDateValue = moment(dataValue);

                if (dateSearchOperator.fromValue && dateSearchOperator.toValue) {
                  let momentFromValue = moment(dateSearchOperator.fromValue);
                  let momentToValue = moment(dateSearchOperator.toValue);

                  if (momentDateValue.isBetween(momentFromValue, momentToValue, "day", "[]"))
                    keep = true;
                }
                else if (dateSearchOperator.fromValue) {
                  let momentFromValue = moment(dateSearchOperator.fromValue);

                  if (momentDateValue.isSameOrAfter(momentFromValue, "day"))
                    keep = true;
                }
                else if (dateSearchOperator.toValue) {
                  let momentToValue = moment(dateSearchOperator.toValue);

                  if (momentDateValue.isSameOrBefore(momentToValue, "day"))
                    keep = true;
                }
              }

              break;
            default:
              break;
          }

          // Stop search immediatly when finding something that not match the criterion.
          if (!keep)
            break;
        }
      }

      if (keep) {
        filteredAudits.push(audit);
      }
    }

    let itemCount = filteredAudits.length;
    let sortedItems = _.orderBy(filteredAudits, "updatedDate", "desc");

    for (const userAudit of filteredAudits) {
      userAudit.formattedNumber = this.sanitarizeNumber(userAudit.number);
    }

    return new ListDataSourceFunctionResult({
      itemCount: itemCount,
      items: sortedItems
    });
  }

  /**
* Returns the number with the prefix truncated to maximum x characters.
*/
  public sanitarizeNumber(number: string): string {
    if (!number)
      return "";

    const prefixIdentifier: string = environment.formMobilePrefix;

    const maximumDisplayPrefixLength: number = environment.formPrefixMaxDisplayLength;

    let prefixIdentifierPosition = number.lastIndexOf(prefixIdentifier);

    if (prefixIdentifierPosition < 0)
      return number;
    else {
      // There is a dash before the prefix so remove it from the prefix length.
      if (prefixIdentifierPosition - 1 > maximumDisplayPrefixLength)
        return number.substring(0, maximumDisplayPrefixLength) + number.substring(prefixIdentifierPosition - 1);
      else
        return number;
    }
  }

  public async feedCustomFieldsSearch(audits: any[]) {
    // This will be the collection of string to display for each form. It's a key/value pair
    // with the name of the control and his value.
    const linksPropertyName: string = "links";

    for (const audit of audits) {
      let form = this._forms.find(x => x.id == audit.programId);

      // The form template is put in cache to use it more than once if other forms have the same
      // template.
      if (!form) {
        let programVersion = await this.programVersionRepository.getLatestProgramVersion(audit.programId);

        if (programVersion && programVersion.webModel) {
          form = JSON.parse(programVersion.webModel);

          this._forms.push(form);
        }
      }

      if (!form) {
        continue;
      }

      audit[linksPropertyName] = [];

      let auditDataTables = await AuditDataTable.table.where("auditId").equals(audit.id).toArray();

      for (const section of form.sections) {
        let controls = Section.getAllControls(section);

        for (const control of controls) {
          if (!control.allowSearch)
            continue;

          let auditDataTable = auditDataTables.find(x => x.auditId == audit.id && x.tableName == control.dataTableName);

          if (!auditDataTable)
            continue;

          let value = auditDataTable[control.dataColumnName];

          let key = section.description + "." + control.description;
          let displayedValue: string;

          if (value === undefined)
            value = this.translateService.instant("auditList.dataNotDefined");
          else {
            switch (control.type) {
              case CustomFieldControlType.RadioButton:
              case CustomFieldControlType.ComboBox:

                let customFieldValueItem = await CustomFieldValueItem.table.get(value);

                displayedValue = customFieldValueItem?.description || '';

                break;
              case CustomFieldControlType.DateTimePicker:
                var outputFormat = control.extendedProperties.find(x => x.key == "DisplayFormat");

                displayedValue = moment(value).format(FormatUtility.convertInfragisticsToMomentDateFormat(!!outputFormat ? outputFormat.value.toString() : ""));

                break;
              case CustomFieldControlType.TextBox:
                var dataType = control.extendedProperties.find(x => x.key == "DataType");

                displayedValue = value;

                if (dataType) {
                  if (dataType.value == DataType.Decimal) {
                    var outputFormat = control.extendedProperties.find(x => x.key == "DisplayFormat");

                    if (outputFormat) {
                      displayedValue = NumberFormatter.format(value, outputFormat.value)
                    }
                  }
                }

                break;
              case CustomFieldControlType.MaskedEditTextBox:
                var inputMask = control.extendedProperties.find(x => x.key == "InputMask");

                if (inputMask) {
                  displayedValue = InputMaskFormatter.format(inputMask.value, value);
                }

                break;
              case CustomFieldControlType.CheckBox:
                if (value === null) {
                  displayedValue = ""
                }
                else if (value) {
                  displayedValue = this.translateService.instant("popup.yes");
                }
                else {
                  displayedValue = this.translateService.instant("popup.no");
                }

                break;
              default:
                displayedValue = value;

                break;
            }
          }

          audit[linksPropertyName].push({ key: key, value: displayedValue });
        }
      }
    }
  }
}
