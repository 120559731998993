// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AlertConditionDetail extends ChangeTrackingEntity {
  public static table: Dexie.Table<AlertConditionDetail, string>;
  public static tableName: string = 'AlertConditionDetail';

  public static ALERT_CONDITION_ID: string = 'alertConditionId';  public static ALERT_VISIBILITY: string = 'alertVisibility';  public static CHECK_BOX_CHOICE_ID: string = 'checkBoxChoiceId';  public static COLOR: string = 'color';  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IS_DELETED: string = 'isDeleted';  public static KEY_IDENTIFIER: string = 'keyIdentifier';  public static MAXIMUM_VALUE: string = 'maximumValue';  public static MINIMUM_VALUE: string = 'minimumValue';  public static NAME: string = 'name';  public static PROGRAM_DROP_DOWN_LIST_ENTRY_ID: string = 'programDropDownListEntryId';  public static SEVERITY_LEVEL: string = 'severityLevel';  public static TIME_STAMP: string = 'timeStamp';

  public alertConditionId: string;  public alertVisibility: boolean;  public checkBoxChoiceId: number;  public color: number;  public description: string;  public id: string;  public isDeleted: boolean;  public keyIdentifier: string;  public maximumValue: number;  public minimumValue: number;  public name: string;  public programDropDownListEntryId: string;  public severityLevel: number;  public timeStamp: Date;

  public constructor(args?: Partial<AlertConditionDetail>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AlertConditionDetail.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AlertConditionDetail.tableName);
      this.table.mapToClass(AlertConditionDetail);
  }
}
