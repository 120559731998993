import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import imageCompression from 'browser-image-compression';
import { DxTextAreaComponent } from 'devextreme-angular';
import Dexie from 'dexie';
import _ from 'lodash';
import * as markerjs2 from 'markerjs2';
import moment from 'moment';
import { CheckboxComponent } from 'src/app/components/controls/checkbox/checkbox.component';
import { CameraComponent } from 'src/app/components/customFields/controls/camera/camera.component';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { AuditImage } from 'src/app/core/data/models/database/auditImage.database';
import { Image as BaseImage, Image as DatabaseImage } from 'src/app/core/data/models/database/image.database';
import { ParameterPhoto } from 'src/app/core/data/models/database/parameterPhoto.database';
import { FormFieldImageExtendedValue } from 'src/app/core/data/models/formFieldImageExtendedValue';
import { TableEntity } from 'src/app/core/data/tableEntity';
import { Logger } from 'src/app/core/log/logger';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { ImageService } from 'src/app/core/services/image.service';
import { AuditState } from 'src/app/pages/audit/auditState';
import { BaseControlComponent } from '../basecontrol/basecontrol.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-picture-box',
  templateUrl: './picture-box.component.html',
  styleUrls: ['./picture-box.component.scss']
})
export class PictureBoxComponent extends BaseControlComponent implements OnInit {
  @Input() public auditId: string;
  @Input() public enableImageLinking: boolean = true;
  @Input() public isLink: boolean;
  @Input() public allowFilterByLinkImages: boolean;

  @ViewChild("camera") camera: CameraComponent;
  @ViewChild('actionPopup') actionPopup: PopupComponent;
  @ViewChild('linkImagePopup') linkImagePopup: PopupComponent;
  @ViewChild('updateDetailsPopup') updateDetailsPopup: PopupComponent;
  @ViewChild('deleteImagePopup') deleteImagePopup: PopupComponent;
  @ViewChild("imageInput") imageInput: ElementRef;
  @ViewChild("descriptionControl") descriptionControl: DxTextAreaComponent;
  @ViewChild("printControl") printControl: CheckboxComponent;
  @ViewChild("duplicateControl") duplicateControl: CheckboxComponent;
  @ViewChild("list") list: ListComponent;

  @ViewChild("linkedImagePictureBox") linkedImagePictureBoxComponent: PictureBoxComponent;
  @ViewChild('imageElement', { read: ElementRef, static: false }) imageElement: ElementRef;
  @ViewChild('markerAreaElement', { read: ElementRef, static: false }) markerAreaElement: ElementRef;


  public moment = moment;
  public displayGrid = true;
  public selectedAuditImages: AuditImage[] = [];
  public acceptedTypes: string = null;
  public showLinkedImages: boolean = true;
  public savingFilesInProgress: boolean = false;
  public savingFilesProgressMessage: string = "";
  public savingFilesProgressValue: number = 0;
  public currentImageSrc: string;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  constructor(
    private baseRepository: BaseRepository,
    private auditState: AuditState,
    private imageService: ImageService,
    private logger: Logger,
    public translateService: TranslateService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    let parameters = await ParameterPhoto.table.toArray();

    if (parameters && parameters.length > 0 && parameters[0].acceptedFormats) {
      this.acceptedTypes = parameters[0].acceptedFormats.split(';').map(x => `image/${x}`).join(', ');
    } else {
      this.acceptedTypes = "image/*"
    }

    if (this.isLink) {
      this.list.selectionEnabled = true;
      this.list.canEnableSelection = false;
    }
  }

  public formImageMetadataDataSource = async (listContext: ListComponent) => {
    let auditImages;

    if (this.input.dataColumnName) {
      auditImages = await AuditImage.table.where({ auditId: this.auditId, customTableId: this.auditState.customTableId, customFieldName: this.input.dataColumnName }).toArray();
    }
    else {
      auditImages = await AuditImage.table.where('auditId').equals(this.auditId).toArray();
    }

    if (!this.showLinkedImages) {
      auditImages = auditImages.filter(x => !x.isLink);
    }

    await this.mapAuditImageViewModels(auditImages);

    if (listContext.filter) {
      auditImages = auditImages.filter((x) => {
        return ComparatorService.stringMatch(x.description, listContext.filter)
          || ComparatorService.stringMatch(x.customFieldDescription, listContext.filter)
      });
    }

    return new ListDataSourceFunctionResult({
      items: auditImages,
      itemCount: auditImages.length
    });
  }

  async selectImage(imageFile) {
    const file = imageFile.target.files[0];

    let imageUrl = await this.imageService.convertFileToBase64(file);

    this.onPictureTaken(imageUrl);
  }

  private printValueChanged: boolean;
  private duplicateValueChanged: boolean;

  handlePrintValueChange(e) {
    this.printValueChanged = true;
  }

  handleDuplicateValueChange(e) {
    this.duplicateValueChanged = true;
  }

  public onEditDetailsClick(event, auditImage) {
    if (!this.list.selectionEnabled) {
      this.selectedAuditImages = [auditImage]
    }

    // Because selectedAuditImages is tied to the HTML interface
    // this action need to be executed in the next javascript
    // event loop so that it can have time to render and handle
    // the correct state.
    setTimeout(() => this.editDetails())

    event.stopPropagation();
  }

  public editDetails() {
    this.actionPopup.close();

    this.printControl.suspendValueChange();
    this.duplicateControl.suspendValueChange();

    if (this.selectedAuditImages.length == 1)
      this.descriptionControl.value = this.selectedAuditImages[0].description;

    this.printControl.threeStates = this.selectedAuditImages.length > 1
    this.duplicateControl.threeStates = this.selectedAuditImages.length > 1

    if (_.every(this.selectedAuditImages, x => x.toPrint)) {
      this.printControl.value = true;
    }
    else if (_.every(this.selectedAuditImages, x => !x.toPrint)) {
      this.printControl.value = false;
    }
    else {
      this.printControl.value = undefined;
    }

    if (_.every(this.selectedAuditImages, x => x.toDuplicate)) {
      this.duplicateControl.value = true;
    }
    else if (_.every(this.selectedAuditImages, x => !x.toDuplicate)) {
      this.duplicateControl.value = false;
    }
    else {
      this.duplicateControl.value = undefined;
    }

    this.printControl.resumeValueChange();
    this.duplicateControl.resumeValueChange();

    this.updateDetailsPopup.display();
  }

  public async deleteSelectedImages() {
    this.actionPopup.close();

    await this.imageService.deleteAuditImageWithImageReference(this.selectedAuditImages);

    this.list.updateData();

    this.selectedAuditImages = [];
  }

  public toggleLinkImagesVisibility() {
    this.showLinkedImages = !this.showLinkedImages;
    this.selectedAuditImages = [];
    this.list.updateData();
  }

  async onFileChanged(event: any) {
    this.saveFile(event.target.files);
  }

  private getHumanFileSize(bytes) {
    return ImageService.getHumanFileSize(bytes, this.translateService.instant("components.customFields.pictureBox.validations.fileSizeSuffix"));
  }

  private async validateFileSizes(files: File[]) {
    let parameterPhoto: ParameterPhoto = (await ParameterPhoto.table.toArray())[0];
    let maximumSizeInMB = parameterPhoto?.systemWebMaximumSizeInMB ?? 25;

    for (let file of files) {
      if (ImageService.convertBytesToMB(file.size) > maximumSizeInMB) {
        let fileName = file.name;
        let fileSize = this.getHumanFileSize(file.size);
        let maximumFileSize = this.getHumanFileSize(ImageService.convertMBToBytes(maximumSizeInMB));

        PopupUtility.displayInformation(this.modalPopup, this.translateService, this.translateService.instant("components.customFields.pictureBox.validations.fileSizeValidationTitle"), this.translateService.instant("components.customFields.pictureBox.validations.fileSizeValidationDescription", { fileName: fileName, fileSize: fileSize, maximumFileSize: maximumFileSize }));

        return false;
      }
    }

    return true;
  }

  public async saveFile(files: File[]) {
    if (!await this.validateFileSizes(files))
      return;

    let databaseImages: DatabaseImage[] = [];
    let auditImages: AuditImage[] = [];
    let currentFileIndex = 0;

    this.savingFilesInProgress = true;

    for (let file of files) {
      currentFileIndex++;

      this.savingFilesProgressValue = Number(Number(currentFileIndex / files.length) * 100);

      this.savingFilesProgressMessage = this.translateService.instant("components.customFields.pictureBox.saveFiles.progressMessage", { currentFileIndex: currentFileIndex, totalFiles: files.length });

      await this.logger.logTrace(`Original file size ${this.getHumanFileSize(file.size)}`)

      let compressedImage = await this.imageService.compress(file);

      await this.logger.logTrace(`Compressed file size ${this.getHumanFileSize(compressedImage.size)}`);

      let base64 = await this.imageService.convertFileToBase64(compressedImage)

      let base64WithoutMetadata = this.imageService.removeBase64Metadata(base64);

      let image = new DatabaseImage({
        id: Dexie.Observable.createUUID(),
        auditId: this.auditId,
        value: base64WithoutMetadata
      })

      databaseImages.push(image);

      let auditImage: AuditImage = new AuditImage({
        id: Dexie.Observable.createUUID(),
        imageId: image.id,
        auditId: this.auditId,
        toPrint: true,
        toDuplicate: true
      });

      if (this.auditState.customTableId)
        auditImage.customTableId = this.auditState.customTableId;

      if (this.input.dataColumnName) {
        auditImage.customFieldName = this.input.dataColumnName;
      }

      auditImages.push(auditImage);
    }

    await this.baseRepository.batchInsert(new Array(new TableEntity(DatabaseImage.table, databaseImages), new TableEntity(AuditImage.table, auditImages)));

    this.savingFilesInProgress = false;

    this.list.updateData();
  }

  public async displayImageLinkPopup() {
    this.linkedImagePictureBoxComponent.selectedAuditImages = [];
    await this.linkedImagePictureBoxComponent.list.updateData();
    await this.linkImagePopup.display();
  }

  public async linkImages() {
    for (let auditImageToLink of this.linkedImagePictureBoxComponent.selectedAuditImages) {
      let auditImage: AuditImage = new AuditImage({ id: Dexie.Observable.createUUID() });

      auditImage.imageId = auditImageToLink.imageId;
      auditImage.auditId = this.auditId;

      if (this.input.dataColumnName) {
        auditImage.customFieldName = this.input.dataColumnName;
        auditImage.customTableId = this.auditState.customTableId;
        auditImage.isLink = true;
        auditImage.toPrint = auditImageToLink.toPrint;
        auditImage.toDuplicate = auditImageToLink.toDuplicate;
      }

      await this.baseRepository.insert(AuditImage.table, auditImage);
    }
    this.linkImagePopup.close();
    this.list.updateData();
  }

  public resetImageInputValue(): void {
    this.imageInput.nativeElement.value = null;
  }

  public showDeletePopupConfirmation() {
    this.actionPopup.close();
    this.deleteImagePopup.display();
  }

  public confirmDeleteImage(){
    this.deleteSelectedImages();
    this.deleteImagePopup.close();
  }

  public async saveDetails() {
    for (let auditImage of this.selectedAuditImages) {
      if (this.selectedAuditImages.length == 1)
        auditImage.description = this.descriptionControl.text;

      // When more than one image is selected with different values for the property, an undetermined checkbox style
      // is displayed. If this state remains at the moment of saving, no action should be take on those properties.
      if (this.printValueChanged && _.isBoolean(this.printControl.value))
        auditImage.toPrint = this.printControl.value;

      if (this.duplicateValueChanged && _.isBoolean(this.duplicateControl.value))
        auditImage.toDuplicate = this.duplicateControl.value;

      await this.imageService.updateAuditImage(auditImage);
    }

    this.updateDetailsPopup.close();
  }

  private async mapAuditImageViewModels(auditImages: any[]) {
    for (const auditImage of auditImages) {
      if (auditImage.customFieldName) {
        // Only have the custom field name; not the section name.
        // Have to find a way to find the fastest way and efficient considering this will be used a lot of time the control name accross all sections.
        // Could use a caching system that will be build at the first try to get a member and will be used until the end of life of the AuditState object.
        let result = this.auditState.getControlByName(auditImage.customFieldName);

        if (result)
          auditImage.customFieldDescription = result.section.description + " -> " + result.control.description;
      }
    }
  }

  async onPictureTaken(targetUrl: string) {
    let file = await imageCompression.getFilefromDataUrl(targetUrl, null, null);

    await this.saveFile([file]);
  }

  public onImageClick(event, auditImage) {
    if (this.list.selectionEnabled) {
      this.list.activateItem(auditImage);
    } else {
      this.showAnnotationTools(auditImage);
    }

    // Disable the list default activateItem since the list
    // click has custom implementation with this function
    event.stopPropagation();
  }

  public async showAnnotationTools(auditImage: AuditImage) {

    let image = await BaseImage.table.get(auditImage.originalImageId || auditImage.imageId);

    this.currentImageSrc = FormFieldImageExtendedValue.appendBase64Metadata(image.value);

    // Because 'image.src' changes dynamicly. The setTimeout
    // allow for the image time to change, by letting an angular event loop to complete
    // and update the UI before reading the imageElement.nativeElement src value.
    setTimeout(() => {
      this.imageElement.nativeElement.style.display = "block"
      this.imageElement.nativeElement.style.height = this.imageElement.nativeElement.naturalHeight;
      this.imageElement.nativeElement.style.width = this.imageElement.nativeElement.naturalWidth;

      let markerArea = new markerjs2.MarkerArea(this.imageElement.nativeElement);

      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

      markerArea.uiStyleSettings.undoButtonVisible = false;
      // markerArea.uiStyleSettings.redoButtonVisible = true;
      // markerArea.uiStyleSettings.clearButtonVisible = true;
      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;

      markerArea.renderAtNaturalSize = true;
      markerArea.settings.displayMode = 'popup';

      markerArea.addEventListener("render", async event => {
        await this.updateRenderedImage(auditImage, event.dataUrl, event.state);
        await this.list.updateData();

        // Because markerJs need the element to be displayed
        // to be able to render the image. The following action
        // need to be evaluated in the next javascript event loop
        // not to cause errors
        setTimeout(() => {
          this.imageElement.nativeElement.style.display = "none";
        })
      });

      markerArea.addEventListener('close', event => {
        // Because markerJs need the element to be displayed
        // to be able to render the image. The following action
        // need to be evaluated in the next javascript event loop
        // not to cause errors
        setTimeout(() => {
          this.imageElement.nativeElement.style.display = "none";
        })

      });

      markerjs2.Activator.addKey(environment.markerJsLicenseKey);
      markerArea.show();

      if (auditImage.annotation) {
        markerArea.restoreState(JSON.parse(auditImage.annotation));
      }
    });

  }

  private async updateRenderedImage(auditImage: AuditImage, imageUrl: string, annotationMarkerState: markerjs2.MarkerAreaState) {
    let imageWithoutMetaData = FormFieldImageExtendedValue.removeBase64Metadata(imageUrl);

    if (annotationMarkerState?.markers?.length > 0) {
      auditImage.annotation = JSON.stringify(annotationMarkerState);

      if (!auditImage.originalImageId) {
        let newImage: DatabaseImage = await this.baseRepository.insert(DatabaseImage.table, new DatabaseImage({
          auditId: auditImage.auditId,
          value: imageWithoutMetaData
        })) as DatabaseImage;

        auditImage.originalImageId = auditImage.imageId;
        auditImage.imageId = newImage.id;

        await this.baseRepository.update(AuditImage.table, auditImage);
      } else {
        await this.baseRepository.update(DatabaseImage.table, new DatabaseImage({
          id: auditImage.imageId,
          value: imageWithoutMetaData
        }));
      }
    } else {
      auditImage.annotation = null;

      if (auditImage.originalImageId) {
        let imageToDelete = auditImage.imageId;

        auditImage.imageId = auditImage.originalImageId;
        auditImage.originalImageId = null;

        await this.baseRepository.update(AuditImage.table, auditImage);
        await this.imageService.deleteImageWithoutReference(imageToDelete, auditImage.auditId);
      } else {
        await this.baseRepository.update(DatabaseImage.table, new DatabaseImage({
          id: auditImage.imageId,
          value: imageWithoutMetaData
        }));
      }
    }
  }
}
