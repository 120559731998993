import { AlertConditionDetail } from "src/app/core/data/models/database/alertConditionDetail.database";
import { UserAccount } from "src/app/core/data/models/database/userAccount.database";
import { formatDate } from '@angular/common';
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { Injectable } from "@angular/core";
import { AlertSummary } from "./alertSummary";
import { CustomFieldValueItem } from "src/app/core/data/models/database/customFieldValueItem.database";

@Injectable({ providedIn: "root" })
export class AlertSummaryMapper {
  constructor() { }

  async map(alertSummary: Array<AuditAlertSummary>): Promise<Array<AlertSummary>> {
    let result = new Array<AlertSummary>();

    let users = await UserAccount.table.where("id")
      .anyOf(alertSummary.map(x => x.userAccountId)).toArray();

    let alertConditions = await AlertConditionDetail.table
      .where("id").anyOf(alertSummary.map(x => x.alertConditionDetailId)).toArray();

    for (const alert of alertSummary) {
      let alertCondition = alertConditions.find(x => x.id === alert.alertConditionDetailId);

      if (!alertCondition.alertVisibility)
        continue;

      let alertSummary = new AlertSummary();

      let createdBy = users.find(x => x.id === alert.userAccountId);

      let controlDescriptions = alert.controlDescription.split(".");

      alertSummary.id = alert.id;
      alertSummary.auditId = alert.auditId;

      // Because we don't have access to customField currently in the web application
      // we need to check if the alert value match an id of a list item. If that is the
      // case we can display that value otherwise we keep the original value which would
      // be a number since alerts does not support string, boolean or date values directly.
      let listValue = await CustomFieldValueItem.table.get(alert.value);
      alertSummary.value = listValue?.description || alert.value;

      alertSummary.description = alertCondition.name;
      alertSummary.createdBy = createdBy.name;
      alertSummary.createdDate = formatDate(alert.createdDate, "dd-MMM-YYYY HH:mm:ss", "en-US");
      alertSummary.source = alert.source;
      alertSummary.controlKey = alert.controlKey;
      alertSummary.controlDescription = controlDescriptions[controlDescriptions.length - 1];
      alertSummary.category = controlDescriptions[0];
      alertSummary.statusClass = await this.getAlertStatusClass(alert.controlKey, alert.auditId);
      alertSummary.instruction = alertCondition.description;

      result.push(alertSummary);
    }

    return result;
  }

  // This method was an attempt the status of the alert sub section
  // to show if the question are answered correctly or not.
  // At this time we don't have access to the correctly values therfore
  // we default to unanswered "blue" state for now.
  private async getAlertStatusClass(controlKey, auditId) {
    return "defaultSection";

    // let controlKeys = controlKey.split(".");

    // let auditDataTable = await AuditDataTable.table
    //   .where("tableName").equals(controlKeys[0])
    //   .filter(x => x.auditId === auditId).first() as any;

    // // Currently
    // switch (auditDataTable.State) {
    //   // case SectionState.Valid:
    //   //   return "validSection";

    //   // case SectionState.EmptyRequiredFields:
    //   //   return "invalidSection";

    //   default:
    //     return "defaultSection";
    // }
  }
}
