import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormField } from "../data/models/formField";
import { StringUtility } from "../stringUtility";

export class CustomValidators{
  static createInputMaskValidator(): ValidatorFn {
   return (control: AbstractControl): ValidationErrors | null => {
     const value = control.value;

     let field = control["field"] as FormField<any>;

     if (!field)
       return null;

     let inputMask = field.extendedProperties.InputMask;

     if (StringUtility.validateMask(value ?? "", inputMask)){
       if (field.required){
         if (value){
           return null;
         }
         else{
           return { invalidMask: true };
         }
       }
       else{
         return null;
       }
     }
     else
       return { invalidMask: true };
   }
 }
}
