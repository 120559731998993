<app-popup #popup [(visible)]="visible" [canClose]="false" [boxFixed]="showMultipleChoices" [vertical]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal padding-left-normal color-set-blue">
        <div class="layout-left">
          {{ 'workflowStepPopup.changeStepTitle' | translate }}
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="close()"></i></button>
        </div>
      </div>
      <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage && !this.showMultipleChoices">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <div class="padding-left">
          {{ validationMessage }}
        </div>
      </div>
    </div>
    <div class="layout-center" *ngIf="loading">
      <div class="layout-horizontal padding-normal">
        <div class="layout-left">
          <button class="compact">
            <i class="fas fa-spin fa-sync"></i>
            <div><span>{{ 'workflowStepPopup.loadingMessage' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center padding-normal" *ngIf="!loading && workflowSteps.length === 0">
      {{ 'workflowStepPopup.noChangeStepMessage' | translate }}
    </div>
    <div class="layout-center padding-normal" *ngIf="!loading && workflowSteps.length > 0 && !showMultipleChoices && !validationMessage">
      {{ 'workflowStepPopup.changeStepConfirmationMessage' | translate }} '{{workflowSteps[0].name}}' ?
    </div>
    <div class="layout-center padding-normal" *ngIf="!loading && workflowSteps.length > 0 && showMultipleChoices && validationMessage">
      {{ validationMessage }}
    </div>
    <div class="layout-center" *ngIf="!loading && workflowSteps.length > 1 && this.showMultipleChoices">
      <app-list [(items)]="workflowSteps" [numberOfSelectableItem]="1" [selectionEnabled]="true" (selectedItemsChange)="selectWorkflowStep($event)">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal height-normal padding-horizontal-normal">
            <div class="layout-left">
              {{ item.name }}
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white padding-compact">
        <div class="layout-right">
          <button class="color-set-green compact"
          [disabled]="!(workflowSteps && (workflowSteps.length > 0 && selectedWorkflowStep && actionButtonEnabled))"
            (click)="confirmWorkflowStep()">
            <div><span>{{ 'workflowStepPopup.changeStepButton' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>

<app-modal #modalPopup></app-modal>