import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { WorkflowStepService } from 'src/app/core/services/workflowStepService';
import { AuditService } from 'src/app/pages/audit/auditService';
import { AuditState } from 'src/app/pages/audit/auditState';
import { PopupComponent } from '../../popup/popup.component';
import { Subscription } from 'rxjs';
import { PopupUtility } from '../../popup/popup.utility';
import { Router } from '@angular/router';
import { SimplePopupComponent } from '../../popup/simplePopup/simplePopup.component';
import { NavigationService } from 'src/app/core/navigationService';

@Component({
  selector: 'app-workflow-step-popup',
  templateUrl: './workflow-step-popup.component.html',
  styleUrls: ['./workflow-step-popup.component.scss']
})
export class WorkflowStepPopupComponent implements OnInit, OnDestroy {
  @Input() visible: boolean;
  @Input() userId: string;

  @ViewChild('popup') popup: PopupComponent;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  public loading: boolean = false;

  @Output() visibleChange: EventEmitter<any> = new EventEmitter();
  @Output() workflowStepSelected = new EventEmitter<WFStep>();

  public workflowSteps: WFStep[] = [];
  public selectedWorkflowStep: WFStep;

  public validationMessage: string;

  private visibleChangeSubsscription: Subscription;
  public actionButtonEnabled: boolean = false;

  public showMultipleChoices: boolean;

  constructor(
    private workflowStepService: WorkflowStepService,
    private auditService: AuditService,
    private auditState: AuditState,
    private translateService: TranslateService,
    private router: Router,
    private navigationService: NavigationService
  ) { }

  ngOnDestroy(): void {
    this.visibleChangeSubsscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    await this.updateData();

    this.visibleChangeSubsscription = this.popup.visibleChange.subscribe(x => {
      this.selectedWorkflowStep = null;
      this.validationMessage = "";
    });
  }

  public async updateData() {
    this.loading = true;
    this.workflowSteps = await this.workflowStepService.getNextWorkflowSteps(this.auditState.audit.stepId, this.userId);

    if (this.workflowSteps.length == 1)
      this.selectedWorkflowStep = this.workflowSteps[0];

    this.loading = false;

    this.showMultipleChoices = this.workflowSteps.length > 1;

    await this.setRequiredFieldsControlsState();
  }

  public async selectWorkflowStep(selectedWorkflowStep: WFStep) {
    this.selectedWorkflowStep = selectedWorkflowStep;

    await this.setRequiredFieldsControlsState();
  }

  private async setRequiredFieldsControlsState(){
    let targetStep: WFStep;

    if (this.workflowSteps && this.workflowSteps.length > 1) {
      targetStep = this.selectedWorkflowStep;
    } else if (this.workflowSteps && this.workflowSteps.length === 1) {
      targetStep = this.workflowSteps[0];
    }

    let canChangeStep: boolean = false;

    if (targetStep){
      if (targetStep.validateRequiredFields){
        canChangeStep = await this.auditService.validateRequiredFieldsByState(this.auditState.audit);
      }
      else{
        canChangeStep = true;
      }
    }

    if (canChangeStep){
      this.actionButtonEnabled = true;
      this.validationMessage = "";
    }
    else{
      this.actionButtonEnabled = false;

      if (targetStep)
        this.validationMessage = this.translateService.instant("workflowStepPopup.validations.requiredFields", { stepName: targetStep.name });
    }
  }

  public async confirmWorkflowStep() {
    let targetStep: WFStep;

    if (this.workflowSteps && this.workflowSteps.length > 1) {
      targetStep = this.selectedWorkflowStep;
    } else if (this.workflowSteps && this.workflowSteps.length === 1) {
      targetStep = this.workflowSteps[0];
    }

    this.workflowStepSelected.emit(targetStep);

    this.selectedWorkflowStep = null;
    this.visibleChange.emit(false);
    this.visible = false;
    this.validationMessage = "";
  }

  public display() {
    this.visible = true;
    this.updateData();
  }

  public close(){
    this.popup.close();

    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService, this.translateService.instant("audit.returnToMainMenuConfirmationTitle"), this.translateService.instant("audit.returnToMainMenuConfirmationMessage"), x => {
      this.navigationService.navigateToParentUrl();
    });
  }
}
