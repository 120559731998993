// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DynamicTabStructureItem extends ChangeTrackingEntity {
  public static table: Dexie.Table<DynamicTabStructureItem, string>;
  public static tableName: string = 'DynamicTabStructureItem';

  public static CUSTOM_TAB_CONTROL_ID: string = 'customTabControlId';  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IMAGE: string = 'image';  public static IS_DELETED: string = 'isDeleted';  public static KEY_IDENTIFIER: string = 'keyIdentifier';  public static MAX_ITEMS: string = 'maxItems';  public static NAME: string = 'name';  public static PARENT_ID: string = 'parentId';  public static POSITION: string = 'position';  public static TEMPLATE_ID: string = 'templateId';  public static TIME_STAMP: string = 'timeStamp';

  public customTabControlId: string;  public description: string;  public id: string;  public image: string;  public isDeleted: boolean;  public keyIdentifier: string;  public maxItems: number;  public name: string;  public parentId: string;  public position: number;  public templateId: string;  public timeStamp: Date;

  public constructor(args?: Partial<DynamicTabStructureItem>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DynamicTabStructureItem.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DynamicTabStructureItem.tableName);
      this.table.mapToClass(DynamicTabStructureItem);
  }
}
