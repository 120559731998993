<app-popup [visible]="true" [canClose]="false">
  <div class="layout-horizontal color-set-blue">
    <div class="layout-left padding-normal">
      {{"audit.delete.progressTitle" | translate}}
    </div>
  </div>

  <div class="content padding-normal">
    <div [ngSwitch]="deletingStep">
      <div *ngSwitchCase="DeleteStep.validating">{{"audit.validation" | translate}}</div>
      <div *ngSwitchCase="DeleteStep.deleting">{{"audit.delete.deleting" | translate}}</div>
    </div>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
      </div>
    </div>
  </div>
</app-popup>