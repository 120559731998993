import { AuditListSearchOptions } from "src/app/pages/audit-list/auditListSearchOptions";
import { BaseRepository } from "../data/baseRepository";
import { FormSearchOption } from "../data/models/databaseLocal/FormSearchOption.database";
import { Injectable } from "@angular/core";
import { EntityState } from "../data/changeTracking/entityState";

@Injectable({
  providedIn: 'root'
})
export class FormFilterOptionService{
  constructor(private baseRepository: BaseRepository) {
  }

  async save(searchOptions: AuditListSearchOptions) {
    let formSearchOptions = await (FormSearchOption.table).toArray();
    let formSearchOption: FormSearchOption;

    if (formSearchOptions.length === 0){
      formSearchOption = new FormSearchOption();
      formSearchOption.entityState = EntityState.New;
    }
    else{
      formSearchOption = formSearchOptions[0];

      formSearchOption.entityState = EntityState.Modified;
    }

    formSearchOption.externalNumber = searchOptions.externalNumber;
    formSearchOption.number = searchOptions.number;
    formSearchOption.fromCreatedDate = searchOptions.fromCreatedDate;
    formSearchOption.toCreatedDate = searchOptions.toCreatedDate;
    formSearchOption.fromUpdatedDate = searchOptions.fromUpdatedDate;
    formSearchOption.toUpdatedDate = searchOptions.toUpdatedDate;
    formSearchOption.assignedToIds = JSON.stringify(searchOptions.assignedToIds);
    formSearchOption.templateIds = JSON.stringify(searchOptions.templateIds);
    formSearchOption.workflowStepNames = JSON.stringify(searchOptions.workflowStepNames);
    formSearchOption.customFieldSearchOperators = JSON.stringify(searchOptions.customFieldSearchOperators);
    formSearchOption.synchronizedOnly = searchOptions.synchronizedOnly;

    await this.baseRepository.save(FormSearchOption.table, formSearchOption);
  }

  async get(): Promise<AuditListSearchOptions>{
    let formSearchOptions = await (FormSearchOption.table).toArray();

    if (formSearchOptions.length === 0)
      return new AuditListSearchOptions();
    else{
      var result = new AuditListSearchOptions();

      var formSearchOption = formSearchOptions[0];

      if (formSearchOption.assignedToIds)
        result.assignedToIds = JSON.parse(formSearchOption.assignedToIds);

      if (formSearchOption.templateIds)
        result.templateIds = JSON.parse(formSearchOption.templateIds);

      if (formSearchOption.workflowStepNames)
        result.workflowStepNames = JSON.parse(formSearchOption.workflowStepNames);

      if (formSearchOption.customFieldSearchOperators){
        result.customFieldSearchOperators = JSON.parse(formSearchOption.customFieldSearchOperators);
      }

      result.number = formSearchOption.number;
      result.externalNumber = formSearchOption.externalNumber;
      result.synchronizedOnly = formSearchOption.synchronizedOnly;
      result.fromCreatedDate = formSearchOption.fromCreatedDate;
      result.toCreatedDate = formSearchOption.toCreatedDate;
      result.fromUpdatedDate = formSearchOption.fromUpdatedDate;
      result.toUpdatedDate = formSearchOption.toUpdatedDate;

      return result;
    }
  }
}
