<form [formGroup]="form">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-lightblue padding-left" *ngIf="!auditState.reference">
        <div class="layout-left">
          <h1>{{auditState.section.description}}</h1>
        </div>
        <div class="layout-right">
          <button type="button" *ngFor="let action of customActions"
            (click)="executeCustomAction(action.name, action.parameters)">
            <i class="fas" [ngClass]="action.icon"></i>
          </button>

          <button class="color-set-transparent" type="button" (click)="onSaveChangesClick()" [disabled]="!form.dirty" id="SaveButton">
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

      <div class="layout-horizontal height-big color-set-blue" [ngStyle]="{'background-color' : auditState.section.alertPrimaryColor ? auditState.section.alertPrimaryColor : 'auto'}" *ngIf="auditState.reference">
        <div class="layout-center align-left">
          <button (click)="onBackToSectionClick()">
            <i class="fas fa-chevron-left"></i>
            <div><span [ngStyle]="{'mix-blend-mode' : auditState.section.alertPrimaryColor ? 'difference' : 'normal'}" > {{auditState.section.description}} </span></div>
          </button>
        </div>
        <div class="layout-right">
          <button class="color-set-transparent" type="button" (click)="onSaveChangesClick()" [disabled]="!form.dirty">
            <i class="fas fa-save"></i>
          </button>
          <!--
            Temporary disabled until we can implement a proper way to undo the form.
            <button type="button" (click)="onRevertChangesClick()" [disabled]="!form.dirty">
            <i class="fas fa-rotate-left"></i>
          </button> -->
        </div>
      </div>
    </div>

    <div class="layout-center">
      <div id="formFieldSection">
        <div *ngFor="let formField of formFields">
          <app-dynamic-form-input [input]="formField" [alertSecondaryColor]="auditState.section.alertSecondaryColor" [form]="form"></app-dynamic-form-input>
        </div>
      </div>
    </div>

    <app-popup [boxFixed]="true">
      <div class="layout-vertical">
        <div class="layout-top">
          <div class="layout-horizontal color-set-blue">
            <div class="layout-left">
              <button type="button"><i class="fas fa-info"></i>
                <div><span> {{ 'instructionPopup.title' | translate }}</span></div>
              </button>
            </div>
            <div class="layout-right">
              <button type="button" (click)="instructionPopup.close()"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <div class="layout-center content">
          <dx-scroll-view #dxScrollView id="dxScrollView" [scrollByContent]="true" [scrollByThumb]="true"
            showScrollbar="onHover" [bounceEnabled]="false" (onInitialized)="saveScrollViewInstance($event)">
            <app-instructions-panel [section]="auditState.section"></app-instructions-panel>
          </dx-scroll-view>
        </div>
      </div>
    </app-popup>
  </div>
</form>

<app-modal #deleteSectionModal></app-modal>

<app-new-dynamic-tab-instance #dynamicTabInstanceView></app-new-dynamic-tab-instance>
