import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { DocumentType } from 'src/app/pages/audit/audit-documents/document-format-type/documentType';
import { AlertService } from '../../utilities/alertService';

@Injectable({
  providedIn: 'root',
})
export class DocumentHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Document`;

  constructor(private http: HttpClient) { }

  sendEmail(auditId: string, documentId: string, sendToId: string, documentType: DocumentType) {
    this.http.post<void>(
      `${this.configUrl}/SendEmail`, null,
      { params: { auditId: auditId, documentId: documentId, sendToId: sendToId, documentType: Number(documentType) } })
      .pipe(catchError(x => of(`${x}`)))
      .subscribe(x => {
        if (x)
          AlertService.show(x);
      });
  }

  async download(auditId: string, documentId: string, documentType: DocumentType) {
    let response = await firstValueFrom(this.http.post(`${this.configUrl}/Download`, null,
      {
        responseType: 'blob',
        observe: 'response',
        params: {
          auditId: auditId,
          documentId: documentId,
          documentType: Number(documentType)
        }
      }));

    return { data: response.body, filename: response.headers.get('filename')  };
  }
}
