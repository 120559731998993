// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Audit extends ChangeTrackingEntity {
  public static table: Dexie.Table<Audit, string>;
  public static tableName: string = 'Audit';

  public static CREATED_BY_ID: string = 'createdById';  public static CREATED_DATE: string = 'createdDate';  public static EFFECTIVE_DATE: string = 'effectiveDate';  public static EXTERNAL_NUMBER: string = 'externalNumber';  public static ID: string = 'id';  public static IS_CREATED_FROM_ALERT_EVENT: string = 'isCreatedFromAlertEvent';  public static IS_DELETED: string = 'isDeleted';  public static LANGUAGE_ID: string = 'languageId';  public static NUMBER: string = 'number';  public static PROGRAM_ID: string = 'programId';  public static RESPONSIBLE_ID: string = 'responsibleId';  public static STEP_ID: string = 'stepId';  public static TIME_STAMP: string = 'timeStamp';  public static UPDATED_DATE: string = 'updatedDate';  public static VERSION_ID: string = 'versionId';

  public createdById: string;  public createdDate: Date;  public effectiveDate: Date;  public externalNumber: string;  public id: string;  public isCreatedFromAlertEvent: boolean;  public isDeleted: boolean;  public languageId: number;  public number: string;  public programId: string;  public responsibleId: string;  public stepId: string;  public timeStamp: Date;  public updatedDate: Date;  public versionId: string;

  public constructor(args?: Partial<Audit>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Audit.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Audit.tableName);
      this.table.mapToClass(Audit);
  }
}
