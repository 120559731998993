import { Component, OnInit, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { DangerPopupButton, SimplePopupButton } from 'src/app/components/popup/simplePopup/simplePopupButton';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { DataSourceImportationViewModel } from './dataSourceImportationViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-data-source-importation-list',
  templateUrl: './data-source-importation-list.component.html',
  styleUrls: ['./data-source-importation-list.component.scss']
})
export class DataSourceImportationListComponent implements OnInit {

  public dataSource: Function;
  public selectedDataSource;
  itemCount: number;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  @ViewChild('list') list: ListComponent;

  constructor(
    private readonly baseRepository: BaseRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.dataSource = async () => {
      let dataSources = (await DataSourceImportation.table.toArray()).filter(x => !x.isDeleted);

      let viewModel = _.map(dataSources, x => new DataSourceImportationViewModel({ id: x.id, name: x.name, selected: false }));

      this.itemCount = viewModel.length;

      return new ListDataSourceFunctionResult({
        items: _.sortBy(viewModel, x => x.name),
        itemCount: viewModel.length
      })
    };
  }

  selectedItemsChange(item) {
    this.selectedDataSource = item;
  }

  addClick() {
    this.router.navigate([`/administration/data-source-importations/new`]);
  }

  navigateToDatasource(item: DataSourceImportationViewModel) {
    this.router.navigate([`/administration/data-source-importations/${item.id}`]);
  }

  async deleteClick() {
    var hasLinkedTasks = await (TaskType.table.where("dataSourceImportationId").equals(this.selectedDataSource.id).filter(x => !x.isDeleted).count());

    if (hasLinkedTasks) {
      this.modalPopup.display(new SimplePopupOptions({
        titleIcon: 'fas fa-exclamation-triangle',
        title: this.translateService.instant("dataSourceImportation.list.validateDeleteTitle"),
        content: this.translateService.instant("dataSourceImportation.list.validateDeleteMessage"),
        onModalResult: async (result) => {
        }
      }))

      return;
    }

    this.modalPopup.display(new SimplePopupOptions({
      titleIcon: 'fas fa-exclamation-triangle',
      title: this.translateService.instant("dataSourceImportation.list.confirmSuppressionTitle"),
      content: this.translateService.instant("dataSourceImportation.list.confirmSuppressionMessage"),
      buttons: [
        new DangerPopupButton({ key: "delete", description: this.translateService.instant("dataSourceImportation.list.delete"), class: "danger" }),
        new SimplePopupButton({ description: this.translateService.instant("dataSourceImportation.list.cancel") })
      ],
      onModalResult: async (result) => {
        if (result === "delete") {
          var dataSource = await this.baseRepository.get<DataSourceImportation>(DataSourceImportation.table, this.selectedDataSource.id);

          dataSource.isDeleted = true;

          await this.baseRepository.update(DataSourceImportation.table, dataSource)

          this.selectedDataSource.length = 0;

          this.list.updateData();
        }
      }
    }));
  }
}
