<div *ngIf="!useDefaultSelect || numberOfSelectableItem < 0" class="layout-horizontal dropdown" (click)="showDropdown()">
  <div class="layout-left layout-no-overflow">
    <div class="layout-horizontal layout" style="justify-content: start;">
      <ng-container *ngIf="selectedItems && !Array.isArray(selectedItems)" >
        <ng-container *ngTemplateOutlet="dropdownSelectedTemplate; context: { $implicit: selectedItems }">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="Array.isArray(selectedItems)">
        <div class="layout-left tag" *ngFor="let item of selectedItems">
          <ng-container *ngTemplateOutlet="dropdownSelectedTemplate; context: { $implicit: item }">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="layout-right unset">
    <button type="button" *ngIf="!readOnly">
      <i class="fa fa-angle-down"></i>
    </button>
  </div>
</div>

<div *ngIf="useDefaultSelect && numberOfSelectableItem > 0">
  <select class="dropdown w-100 height-normal" id="classicDropdown" (change)="onSelectedItemsChange($event.target.value)">
    <option class="d-none" disabled [selected]="selectedItems === undefined" value></option>
    <option *ngFor="let item of itemList" [selected]="selectedItems && selectedItems[0].id == item.id" [value]="item.id">{{item.text}}</option>
  </select>
</div>

<app-popup #popup [canClose]="true" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-left">
          <button type="button"><i class="fas fa-search"></i>
            <div><span>{{ title }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button type="button"><i class="fas fa-times" (click)="popup.close()"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <app-list #list [dataSource]="dataSource" [selectionEnabled]="true" [(selectedItems)]="selectedItems"
        [enableFilter]="true" [numberOfSelectableItem]="numberOfSelectableItem"
        [numberOfRequiredItems]="numberOfRequiredItems" [hoverable]="true"
        (selectedItemsChange)="onSelectedItemsChange($event)">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal height-normal">
            <div class="layout-left">
              <ng-container *ngTemplateOutlet="dropdownListTemplate; context: { $implicit: item }"></ng-container>
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom" *ngIf="numberOfSelectableItem !== 1">
      <div class="layout-horizontal padding-compact">
        <div class="layout-right">
          <button type="button" class="color-set-green" (click)="popup.close()">
            <div>
              <span>{{ 'controls.dropdown.confirm' | translate }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
