<nav class="layout-horizontal color-set-black">
  <div class="layout-left">
    <button class="small font-smallest">
      <i class="fas fa-signal"></i>
      <div>
        <span *ngIf="!synchronizationService.offline">{{ 'navigator.onLine' | translate}}</span>
        <span *ngIf="synchronizationService.offline">{{'synchronizationService.offline' | translate}}</span>
      </div>
    </button>

    <button class="small font-smallest" *ngIf="!synchronizationService.offline">
      <i class="fas fa-check" *ngIf="!synchronizationService.inProgress"></i>
      <div *ngIf="!synchronizationService.synchronizationInProgressPromise">
        <span>{{'synchronizationService.status.ready'| translate}}</span>
      </div>

      <i class="fas fa-sync fa-spin" *ngIf="synchronizationService.synchronizationInProgressPromise"></i>
      <div *ngIf="synchronizationService.synchronizationInProgressPromise">
        <span>{{'synchronizationService.status.inProgress'| translate}}</span>
      </div>
    </button>
  </div>
  <div class="layout-right">
    <button class="small font-smallest">
      <div>
        <span>{{ 'synchronizationService.version' | translate }}{{version}}</span>
      </div>
    </button>
    <button class="small font-smallest" *ngIf="storageState.available"
      title="{{ 'navigator.storage.notificationNotAllowedWarning' | translate}}">
      <i class="fas fa-exclamation-triangle" *ngIf="!storageState.isPersisted"></i>
      <div>
        <span>{{ 'synchronizationService.remainingStorage' | translate }}{{storageState.availableStorage}}</span>
      </div>
    </button>
  </div>
</nav>

<button id="synchronizationButton" class="hide color-set-orange"
  [style.visibility]="synchronizationService.isAccessibleByLocation ? 'visible' : 'hidden'"
  [class.skipAnimation]="!viewInitComplete"
  [class.show]="synchronizationService.requireSynchronization && !synchronizationService.offline && !synchronizationService.synchronizationInProgressPromise"
  [class.pulse]="synchronizationService.requireSynchronization && !synchronizationService.offline && !synchronizationService.synchronizationInProgressPromise"
  [class.hide]="!synchronizationService.requireSynchronization || synchronizationService.offline || synchronizationService.synchronizationInProgressPromise"
  (click)="synchronize()" [disabled]="synchronizationService.offline && !isAuthenticated">
  <div> <span> {{'synchronizationService.synchronize' | translate}}</span> </div>
</button>

<button id="synchronizationButton" class="hide color-set-orange"
  [style.visibility]="synchronizationService.isAccessibleByLocation ? 'visible' : 'hidden'"
  [class.skipAnimation]="!viewInitComplete"
  [class.show]="synchronizationService.isForcedOffline"
  [class.pulse]="synchronizationService.isForcedOffline"
  [class.hide]="!synchronizationService.isForcedOffline"
  (click)="synchronizationService.toggleForcedOffline()" [disabled]="!synchronizationService.isForcedOffline">
  <div> <span> {{'systemInformation.parameters.onlineMode' | translate}}</span> </div>
</button>

<div class="popup" [class.show]="synchronizationPopupState.display" [class.overrideDefaultPopupZIndex]="true"
  [class.hide]="!synchronizationPopupState.display">
  <div class="mainOverlay">
    <div class="box">
      <div class="layout-vertical">
        <div class="layout-top">
          <div class="layout-horizontal color-set-blue">
            <div class="layout-left">
              <button *ngIf="!synchronizationService.hasError">
                <i class="fas fa-sync fa-spin"></i>
              </button>
              <button *ngIf="synchronizationService.hasError">
                <i class="fas fa-exclamation-triangle"></i>
              </button>
            </div>
            <div class="layout-center align-left padding-right">
              <span *ngIf="!synchronizationService.hasError">
                {{ 'synchronizationService.popup.inProgressTitle' | translate }}
              </span>
              <span *ngIf="synchronizationService.hasError">
                {{ 'synchronizationService.popup.warningTitle' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="layout-center">
          <div class="content" *ngIf="synchronizationService.hasError && synchronizationService.couldNotReachServer">
            {{'synchronizationService.popup.couldNotReachServer' | translate}}
          </div>
          <div class="content" *ngIf="synchronizationService.hasError && synchronizationService.isNotAuthenticated">
            {{'synchronizationService.popup.isNotAuthenticated' | translate}}
          </div>
          <div class="content" *ngIf="synchronizationService.hasError && synchronizationService.mobileIdNotConfigured">
            {{'synchronizationService.popup.mobileIdNotConfigured' | translate}}
          </div>
          <div class="content" *ngIf="synchronizationService.hasError && synchronizationService.hasOtherError">
            {{'synchronizationService.popup.hasOtherError' | translate}}
            {{ synchronizationService.hasOtherErrorMessage }}
          </div>

          <div class="content"
            *ngIf="!synchronizationService.hasError && synchronizationService.synchronizationInProgressInAnotherTab">
            {{'synchronizationService.popup.synchronizationInProgressInAnotherTab' | translate}}
          </div>
          <div class="content"
            *ngIf="!synchronizationService.hasError && !synchronizationService.synchronizationInProgressInAnotherTab">
            <div *ngIf="!synchronizationService.synchronizationInProgressInAnotherTab" class="margin-bottom-normal">
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                  role="progressbar" aria-valuenow="synchronizationPopupState.completionPercentage" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'width': synchronizationPopupState.completionPercentage+'%'}"></div>
              </div>
            </div>
            <div>
              <div>
                <p *ngIf="synchronizationService.synchronizationInProgressPromise">
                  ({{synchronizationPopupState.currentRange}} /
                  {{synchronizationPopupState.numberOfRange}})
                  <span
                    *ngIf="synchronizationPopupState.isReceiving">{{'synchronizationService.popup.reception' | translate}}</span>
                  <span
                    *ngIf="!synchronizationPopupState.isReceiving">{{'synchronizationService.popup.send' | translate}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-bottom" *ngIf="synchronizationService.popupConfirmationPromise">
          <div class="layout-horizontal height-normal padding-horizontal-normal">
            <div class="layout-left">
              <button [routerLink]="'system'" name="resetButton" class="compact color-set-gray"
                (click)="synchronizationService.hidePopup()">
                <div><span>{{ 'synchronizationService.popup.moreInfo' | translate}} </span></div>
              </button>
            </div>
            <div class="layout-right space">
              <button class="color-set-blue compact" type="button" (click)="synchronizationService.hidePopup()">
                <div><span>{{ 'general.ok' | translate}}</span></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</div>
