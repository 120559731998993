export class DataSourceCache{
  private dataSourceCache: { [key: string] : any; } = {};

  public match(key: string){
    return this.dataSourceCache[key];
  }

  public has(key: string){
    return !!this.match(key);
  }

  public delete(key: string){
    delete this.dataSourceCache[key];
  }

  public add(key: string, value: any){
    this.dataSourceCache[key] = value;
  }

  public clear(){
    this.dataSourceCache = {};
  }
}
