import { Injectable } from "@angular/core";
import { Validation } from "../../validation";
import { ValidationDictionary } from "../../validationDictionary";
import { SynchronizationService } from "./synchronizationService";
import { SynchronizationHttpClient } from "./synchronizationHttpClient";
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { UserHttpClient } from "../../security/userHttpClient";

@Injectable({
  providedIn: 'root',
})
export class FeedMobileCommand {
  constructor(
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private userHttpClient: UserHttpClient
  ) { }

  public async canSkip(): Promise<boolean> {
    if (this.synchronizationService.offline) {
      return true;
    }

    let synchronization = await this.synchronizationHttpClient.getLastSynchronization();

    let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);
    let userId = await this.userHttpClient.getUserId();
    userId = userId.toUpperCase();

    let hasCompletedAtLeastFirstSynchronization = synchronization && synchronization.completed;
    let userIdDidNotChanged = userIdParameter && userIdParameter.value === userId;

    return hasCompletedAtLeastFirstSynchronization && userIdDidNotChanged;
  }

  public async run(): Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        await this.synchronizationService.getUpdates(true);
      }
    } catch (error) {
      validationDictionary.add(new Validation({ key: "FeedMobileCommand", message: error.message }));
    }

    return validationDictionary;
  }
}