// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Mobile extends ChangeTrackingEntity {
  public static table: Dexie.Table<Mobile, string>;
  public static tableName: string = 'Mobile';

  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IS_DELETED: string = 'isDeleted';  public static IS_SERVER: string = 'isServer';  public static NAME: string = 'name';  public static NUMBER: string = 'number';  public static SYNCHRONIZATION_GROUP_ID: string = 'synchronizationGroupId';  public static TIME_STAMP: string = 'timeStamp';  public static USER_ACCOUNT_ID: string = 'userAccountId';  public static WHEN_DEFINITELY_DELETED: string = 'whenDefinitelyDeleted';

  public description: string;  public id: string;  public isDeleted: boolean;  public isServer: boolean;  public name: string;  public number: number;  public synchronizationGroupId: number;  public timeStamp: Date;  public userAccountId: string;  public whenDefinitelyDeleted: Date;

  public constructor(args?: Partial<Mobile>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Mobile.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Mobile.tableName);
      this.table.mapToClass(Mobile);
  }
}
