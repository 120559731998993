<div class="layout-vertical" [formGroup]="form">
  <div class="layout-center">
    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'taskType.edit.information' | translate}}
      </div>
    </div>

    <div class="padding-normal">
      <label for="name" class="field" [formGroup]="form" fieldControlName="name" appMarkAsterisk
        description="{{ 'taskType.edit.name' | translate}}"></label>

      <dx-text-box id="name" class="text-box" formControlName="name" valueChangeEvent="keyup" maxLength="250" ></dx-text-box>
    </div>

    <div class="padding-normal" *ngIf="false">
      <label for="typeId" class="field" [formGroup]="form" fieldControlName="typeId" appMarkAsterisk
        description="{{ 'taskType.edit.type' | translate}}"></label>

      <dx-select-box id="typeId" formControlName="typeId" [items]="types" valueExpr="id" displayExpr="description">
      </dx-select-box>
    </div>

    <div class="padding-normal">
      <label for="dataSourceImportationId" class="field" [formGroup]="form" fieldControlName="dataSourceImportationId"
        appMarkAsterisk description="{{ 'taskType.edit.dataSource' | translate}}"></label>

      <dx-select-box id="dataSourceImportationId" formControlName="dataSourceImportationId" [items]="importations"
        valueExpr="id" displayExpr="name">
      </dx-select-box>
    </div>

    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        Maps
      </div>
    </div>

    <div class="padding-normal">
      <label for="isActivated" class="field">{{ 'taskType.edit.activate' | translate}}</label>

      <div>
        <dx-check-box id="isActivated" formControlName="isActivated" valueExpr="id" displayExpr="text">
        </dx-check-box>
      </div>
    </div>

    <div class="padding-normal">
      <label for="longitudeField" class="field" [formGroup]="form" fieldControlName="longitudeField" appMarkAsterisk
        description="{{ 'taskType.edit.longitude' | translate}}"></label>

      <dx-select-box id="longitudeField" formControlName="longitudeField" [items]="lineFields" valueExpr="key"
        displayExpr="key">
      </dx-select-box>
    </div>

    <div class="padding-normal">
      <label for="latitudeField" class="field" [formGroup]="form" fieldControlName="latitudeField" appMarkAsterisk
        description="{{ 'taskType.edit.latitude' | translate}}"></label>

      <dx-select-box id="latitudeField" formControlName="latitudeField" [items]="lineFields" valueExpr="key"
        displayExpr="key">
      </dx-select-box>
    </div>

    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'taskType.edit.globalFilter' | translate}}
      </div>
    </div>

    <div class="padding-normal">
      <label for="globalJavascriptFilter" class="field">{{ 'taskType.edit.filter' | translate}}</label>

      <dx-text-box id="globalJavascriptFilter" class="text-box" formControlName="globalJavascriptFilter"
        valueChangeEvent="keyup" maxLength="2500">
      </dx-text-box>
    </div>

    <app-entity-security-group-list
      #entitySecurityGroupList
      [entityId]="model?.id"
      source="TaskType"
      title="{{ 'entitySecurityGroup.title' | translate}}"
      [disabled]="!id"></app-entity-security-group-list>

    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'taskType.edit.optionListsTitle' | translate}}
      </div>

      <div class="layout-right">
        <button class="color-set-transparent" (click)="removeOptionList()" [disabled]="!selectedOptionList">
          <i class="fas fa-solid fa-trash"></i>
        </button>

        <button class="color-set-transparent" (click)="addOptionList()" [disabled]="!id">
          <i class="fas fa-solid fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="padding-normal">
      <app-list #optionList [dataSource]="optionListDataSource"
        [numberOfSelectableItem]="1" [hoverable]="true" [selectionEnabled]="true"
        (selectedItemsChange)="selectOptionList($event)" [showCheckBoxes]="false">
        <ng-template #listTemplate let-item>
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  {{item.text}}
                </div>
              </div>

              <div class="layout-right">
                <button class="color-set-transparent" (click)="optionListItemClick($event, item)">
                  <i class="fas fa-solid fa-pen"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </app-list>

      <app-option-list optionListEdit></app-option-list>
    </div>
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal height-normal padding-horizontal-normal">
      <div class="layout-right layout-space">
        <button type="submit" [disabled]="!form.valid || !form.dirty" class="color-set-green compact"
          (click)="saveButtonClick()">
          <div><span>{{ 'general.save' | translate}}</span></div>
        </button>
        <button [disabled]="!form.dirty" class="color-set-gray compact" (click)="cancelButtonClick()">
          <div><span>{{ 'general.cancel' | translate}}</span></div>
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal #modalPopup></app-modal>
