import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DatabaseContext } from 'src/app/core/data/databaseContext';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { UserType } from 'src/app/core/data/models/database/userType.database';
import { UserType as UserTypeEnum } from 'src/app/core/data/enums/userType';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { DataSourceItem } from 'src/app/core/data/viewModels/dataSourceItem';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/services/userService';


@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  userAccount: UserAccount;
  canEditProfile: boolean;
  isAdministrator: boolean;
  userTypes;

  phonePattern: any = /[01-9]/;
  phoneRules: any = {
    X: /[01-9]/
  }

  public resetDatabasePopupVisible = false;
  public language = environment.language;
  public fontSize = 100;

  constructor(
    public route: ActivatedRoute,
    public databaseContext: DatabaseContext,
    private userAccountRepository: UserAccountRepository,
    private router: Router,
    private authenticatedUser: AuthenticatedUser,
    private baseRepository: BaseRepository,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    let userAccountId = this.route.snapshot.paramMap.get('userAccountId');

    if (!userAccountId) {
      userAccountId = this.authenticatedUser.id;
    }

    await this.loadData(userAccountId);
  }

  private async loadData(userId: string) {
    this.userAccount = await UserAccount.table.get(userId);

    if (this.userAccount["language"]) {
      this.language = this.userAccount["language"];
    }
    else {
      this.language = environment.language;
    }

    let userTypes: UserType[] = await UserType.table
      .where("id").notEqual(UserAccountRepository.SYNCHRONIZER_ID)
      .toArray();

    this.userTypes = userTypes.map((userType: UserType) => {
      return new DataSourceItem<number>(userType.id, userType.description);
    });

    this.isAdministrator = this.userAccount.typeId === UserTypeEnum.Administrator;
    this.canEditProfile = (environment.canEditProfile && this.userAccount.id == this.authenticatedUser.id) || this.isAdministrator;
  }

  goToProfilePicture() {
    this.router.navigate(['profile-picture']);
  }

  async nameValueChanged(e: Event) {
    if (e["value"] != this.userAccount.name && e["isValid"] == true) {
      this.userAccount.name = e["value"];
      this.authenticatedUser.initials = this.userService.getInitials(this.userAccount.name);
      await this.updateEmployee();
    }
  }

  async emailValueChanged(e: Event) {
    if (e["value"] != this.userAccount.email && e["isValid"] == true) {
      this.userAccount.email = e["value"];
      await this.updateEmployee();
    }
  }

  async phoneValueChanged(e: Event) {
    if (e["value"] != this.userAccount.phone && e["isValid"] == true) {
      if (e["value"].trim().length === 14) {
        this.userAccount.phone = e["value"];
        await this.updateEmployee();
      }
    }
  }

  async extensionValueChanged(e: Event) {
    if (e["value"] != this.userAccount.extension) {
      this.userAccount.extension = e["value"];
      await this.updateEmployee();
    }
  }

  async roleValueChanged(e: Event) {
    if (e["value"] != this.userAccount.title) {
      this.userAccount.title = e["value"];
      await this.updateEmployee();
    }
  }

  async typeValueChanged(e: Event) {
    if (e["value"] != this.userAccount.typeId) {
      this.userAccount.typeId = e["value"];
      await this.updateEmployee();
    }
  }

  async updateEmployee() {
    await this.baseRepository.update(UserAccount.table, this.userAccount);
  }

  changeLanguage(language: string) {
    this.userAccount["language"] = language;
    this.baseRepository.update(UserAccount.table, this.userAccount);
    this.translate.use(language);
  }

  changeFontSize(fontSize) {
    console.log(document.getElementsByTagName("html")[0]);
    document.getElementsByTagName("html")[0]["style"]["font-size"] = fontSize + "%";
  }
}
