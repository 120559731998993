import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Dexie from 'dexie';
import { Subscription } from 'rxjs';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { HeaderStateLeftButtonType } from 'src/app/components/headers/header/headerStateLeftButtonType';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { TaskFilter } from 'src/app/core/data/models/databaseLocal/taskFilter.database';
import { FieldTitle } from 'src/app/core/services/dataSourceImportations/fieldTitle';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss']
})
export class TaskFilterComponent implements OnInit, OnDestroy {
	@ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

	form: UntypedFormGroup;
	taskTypeName: string;
	taskFilter: TaskFilter;

	private backButtonSubscription: Subscription;

  constructor(
		private formBuilder: UntypedFormBuilder, 
		private route: ActivatedRoute,
		private router: Router,
		private baseRepository: BaseRepository,
		private headerState: HeaderState) {

		this.form = new UntypedFormGroup({
			distanceFromFirstTask: new UntypedFormControl("distanceFromFirstTask"),
			fields: formBuilder.array([]),
		});
	 }

	 ngOnDestroy(): void {
		this.backButtonSubscription.unsubscribe();

		this.headerState.setDefaultButtonState();

    this.headerState.title = "";
	}

  async ngOnInit(): Promise<void> {
		await this.load();

		this.backButtonSubscription = this.headerState.backButtonClick.subscribe(() => {
			this.navigateToList();
		});

		this.headerState.leftButtonType = HeaderStateLeftButtonType.backButton;
  }

	private navigateToList() {
		this.router.navigate([`/tasks`]);
	}

	get fields(): UntypedFormArray {
		return this.form.controls.fields as UntypedFormArray;
	}

	async load() {
		this.taskFilter = await TaskFilter.table.toCollection().first();

		if (this.taskFilter){
			let taskType: TaskType;

			taskType = await TaskType.table.get(this.taskFilter.taskTypeId);
	
			this.taskTypeName = taskType.name;
	
			let dataSourceImportation: DataSourceImportation;
	
			dataSourceImportation = await DataSourceImportation.table.get(taskType.dataSourceImportationId);

			if (dataSourceImportation.fieldTitles) {
				let filterFields: FieldTitle[] = [];

				if (this.taskFilter.filterFields){
					filterFields = JSON.parse(this.taskFilter.filterFields);
				}

				let fieldTitles: FieldTitle[] = JSON.parse(dataSourceImportation.fieldTitles);
	
				fieldTitles.forEach(item => {
					var filterField = filterFields.find(x => x.key === item.key);

					let value = "";

					if (filterField){
						value = filterField.value;
					}

					const alias = item.value || item.key;

					this.fields.push(this.formBuilder.group({ key: item.key, value: value, alias: alias }));
				});
			}

			this.form.controls.distanceFromFirstTask.setValue(this.taskFilter.distanceFromFirstTask);
		}
	}

	private async validate(): Promise<string[]>{
		let validationDictionary = [];

		if (!this.form.valid) {
			Object.keys(this.form.errors).map(error => {
				validationDictionary.push(this.form.errors[error])
			})
		}

		return validationDictionary;
	}

	async searchClick(){
		let validationDictionary = [];

		var result = await this.validate();

		if (result.length > 0){
			await this.modalPopup.display(new SimplePopupOptions({
				titleIcon: 'fas fa-exclamation-triangle',
				title: "Filter saving",
				content: validationDictionary[0]
			}));
		}

		this.taskFilter.distanceFromFirstTask = this.form.controls.distanceFromFirstTask.value;

		if (this.fields.length > 0) {
			let filterFields: FieldTitle[] = [];

			for (const fieldTitle of this.fields.controls) {
				filterFields.push({ key: fieldTitle.get("key").value, value: fieldTitle.get("value").value });
			}

			this.taskFilter.filterFields = JSON.stringify(filterFields);
		}
		else
			this.taskFilter.filterFields = "";

		await this.baseRepository.update(TaskFilter.table, this.taskFilter);

		this.router.navigate(["/tasks"]);
	}

	public clearForm(): void {
		for (let i = 0; i < this.fields.controls.length; i++) {
			this.form.get(`fields.${i}.value`).setValue("");
		}
		this.form.controls.distanceFromFirstTask.setValue(null);
	}
}
