<div class="layout-horizontal fill color-set-gray" *ngIf="display">
  <div class="layout-center">
    <div>
      <button type="button" (click)="goToFirstPage()" id="GoToFirstPageButton"><i class="bi bi-chevron-double-left"></i></button>
      <button type="button" (click)="goToPreviousPage()" id="GoToPreviousPageButton"><i class="bi bi-chevron-left"></i></button>
      <input class="basic page" (ngModelChange)="goToPage(page)" [(ngModel)]="page" [min]="firstPage" [max]="pageCount"
        type="number" id="GoToPageTextBox"/>
      <button type="button" (click)="goToNextPage()" id="GoToNextPageButton"><i class="bi bi-chevron-right"></i></button>
      <button type="button" (click)="goToLastPage()" id="GoToLastPageButton"><i class="bi bi-chevron-double-right"></i></button>
    </div>
  </div>
</div>
