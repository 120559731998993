<div class="layout-vertical">
  <div class="layout-center">
    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.systemInformationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.versionTitle' | translate }}</label>
      <div class="font-smallest font-gray">{{ 'systemInformation.versionDescription' | translate }}</div>
      <div>{{environment.version}}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.storageLabel' | translate }}</label>
      <div class="font-smallest font-gray">{{ 'systemInformation.storageDescription' | translate }} </div>
      <div>{{storageState.availableStorage}}</div>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.deviceInformationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.browserNameLabel' | translate }}</label>
      <div class="font-smallest font-gray">{{ 'systemInformation.browserNameDescription' | translate }}</div>
      <div>{{ platform.name }}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.browserVersionLabel' | translate }}</label>
      <div class="font-smallest font-gray">{{ 'systemInformation.browserVersionDescription' | translate }}</div>
      <div>{{ platform.version }}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.operatingSystemLabel' | translate }}</label>
      <div class="font-smallest font-gray">{{ 'systemInformation.operatingSystemDescription' | translate }}</div>
      <div>{{ platform.os }}</div>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.synchronizationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <button id="synchronizationButton" class="pill color-set-orange" (click)="synchronize()"
        [disabled]="synchronizationService.offline || !isDatabaseAvailable">
        <div>
          <span> {{'synchronizationService.synchronize' | translate}}</span>
        </div>
      </button>
    </div>

    <div class="padding-normal">
      <button id="synchronizationButton" class="pill color-set-orange" (click)="synchronizationService.toggleForcedOffline()"
        [disabled]="(synchronizationService.offline || !isDatabaseAvailable) && !synchronizationService.isForcedOffline">
        <div>
          <span *ngIf="!synchronizationService.isForcedOffline">{{ "systemInformation.parameters.offlineMode" | translate}}</span>
          <span *ngIf="synchronizationService.isForcedOffline">{{ "systemInformation.parameters.onlineMode" | translate}}</span>
        </div>
      </button>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.databaseSection' | translate }}
      </div>
    </div>

    <div>
      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'profilePage.administration.title' | translate}}
        </div>
      </div>
      <div class="zone-content">
        <div>
          <label for="user-type-text-box" class="label">{{ 'profilePage.administration.databaseAdministration' |
            translate}}</label>
        </div>
        <div class="layout-horizontal colors-set-white">
          <div class="layout-left">
            <button name="resetButton" class="color-set-invert-red" [disabled]="synchronizationService.offline || !isDatabaseAvailable"
              (click)="databaseResetPopup.databaseResetPopup.display()">
              <div><span>{{ 'profilePage.resetDatabasePopup.reset' | translate}}</span></div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-synchronization-database-reset-popup #databaseResetPopup></app-synchronization-database-reset-popup>
