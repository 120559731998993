import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderState } from "src/app/components/headers/header/headerState";
import { AuditState } from './auditState';
import { HeaderStateLeftButtonType } from 'src/app/components/headers/header/headerStateLeftButtonType';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit, OnDestroy {
  public sectionsVisible: boolean = false;

  private backButtonSubscription: Subscription;
  private titleSubscription: Subscription;

  constructor(
    private auditState: AuditState,
    private headerState: HeaderState,
    private synchronizationService: SynchronizationService) {
  }

  ngOnInit(): void {
    this.synchronizationService.isAccessibleByLocation = false;

    this.headerState.leftButtonType = HeaderStateLeftButtonType.audit;
    this.headerState.useBackButton = true;

    this.headerState.title = this.auditState.program.description;

    this.headerState.formSectionUrl = [`/forms/${this.auditState.auditNumber}/sections`];

    this.titleSubscription = this.headerState.titleClick.subscribe(() => {
      this.sectionsVisible = !this.sectionsVisible;
    });
  }

  ngOnDestroy(): void {
    this.headerState.setDefaultButtonState();

    this.headerState.title = "";

    this.titleSubscription.unsubscribe();

    this.synchronizationService.isAccessibleByLocation = true;
  }
}
