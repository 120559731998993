// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DynamicTabAuditTemplate extends ChangeTrackingEntity {
  public static table: Dexie.Table<DynamicTabAuditTemplate, string>;
  public static tableName: string = 'DynamicTabAuditTemplate';

  public static AUDIT_ID: string = 'auditId';  public static CUSTOM_TABLE_ID: string = 'customTableId';  public static DESCRIPTION: string = 'description';  public static DYNAMIC_TAB_STRUCTURE_ITEM: string = 'dynamicTabStructureItem';  public static ID: string = 'id';  public static NUMBER: string = 'number';  public static POSITION: string = 'position';  public static REFERENCE_TABLE_ID: string = 'referenceTableId';  public static TIME_STAMP: string = 'timeStamp';

  public auditId: string;  public customTableId: string;  public description: string;  public dynamicTabStructureItem: string;  public id: string;  public number: string;  public position: number;  public referenceTableId: string;  public timeStamp: Date;

  public constructor(args?: Partial<DynamicTabAuditTemplate>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DynamicTabAuditTemplate.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DynamicTabAuditTemplate.tableName);
      this.table.mapToClass(DynamicTabAuditTemplate);
  }
}
