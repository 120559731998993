import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormField } from "src/app/core/data/models/formField";
import { UntypedFormGroup } from "@angular/forms";
import { BaseControlComponent } from '../basecontrol/basecontrol.component';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-formattedlabel',
  templateUrl: './formattedlabel.component.html',
  styleUrls: ['./formattedlabel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormattedLabelComponent extends BaseControlComponent implements OnInit {

  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

  value: string;
  constructor(private sanitizer: DomSanitizer) {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.getExtendedProperty("Value", "") as string;
  }
}
