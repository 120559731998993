import { SearchOperator } from "./searchOperator";
import { StringComparisonType } from "./stringComparisonType";

export class StringSearchOperator extends SearchOperator{
  comparisonType: StringComparisonType
  value: string;

  public static getComparisonTypeDescription(comparisonType: StringComparisonType){
    switch (comparisonType) {
      case StringComparisonType.contains:
        return "Contient";
      case StringComparisonType.endWith:
        return "Se termine par";
      case StringComparisonType.exact:
        return "Est exactement";
      case StringComparisonType.notContains:
        return "Ne contient pas";
      case StringComparisonType.startWith:
        return "Commence par";
      default:
        return "Contient";
    }
  }
}
