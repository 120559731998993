import { ArrayUtility } from "src/app/core/arrayUtility";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { FormField } from "src/app/core/data/models/formField";
import { Injectable } from "@angular/core";
import { AuditAlertSummary } from "../data/models/database/auditAlertSummary.database";
import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";
import { AuditSectionState } from "../data/viewModels/auditSectionState";
import { AuditService } from "src/app/pages/audit/auditService";
import { Audit } from "../data/models/database/audit.database";
import { CustomFieldControlType } from "../data/models/form/customFieldControlType";
import { PictureBoxImage } from "../data/models/form/pictureBoxImage";
import Dexie from "dexie";
import { AuditImage } from "../data/models/database/auditImage.database";
import { Image } from "../data/models/database/image.database";
import { OtherSectionDataItem } from "src/app/components/dynamic-form/otherSectionDataItem";
import { AuditState } from "src/app/pages/audit/auditState";
import { DynamicTabAuditTemplate } from "../data/models/database/dynamicTabAuditTemplate.database";
import { Form } from "../data/models/form/form";
import { Section } from "../data/models/form/section";

@Injectable()
export class DynamicFormService {
  constructor(private baseRepository: BaseRepository, private auditService: AuditService) { }

  public async saveAlerts(alertSummary: AuditAlertSummary[]): Promise<boolean> {
    for (const alert of alertSummary) {
      // In the past, the treated alert was systematically set to false in order to "let the service extract additionnal information".
      // But there was a problem for alerts that sends email; if you were in another page and proceeded to save, even after the alert was marked 'treated' by
      // the server, it would continously spam an email for every new save.
      //
      // The Treated property is not received when synchronizing except when the client resets its indexed database.
      // The client must assume that the alert is treated by the server after each save.
      if(alert.treated)
        continue;
      
      try{
        alert.treated = true;

        alert.markAsUpdated();
        await this.baseRepository.save<AuditAlertSummary>(AuditAlertSummary.table, alert);
      }
      catch(e) {
        alert.treated = false;
        throw e;
      }
    }

    return true;
  }

  public async saveFields(auditId: string, otherSectionDataItems: OtherSectionDataItem[]) {
    if (otherSectionDataItems.length == 0)
      return;

    let auditDataTables = await AuditDataTable.table.where("auditId").equals(auditId).toArray();

    for (const otherSectionDataItem of otherSectionDataItems) {
      let auditDataTable = auditDataTables.find(x => x.tableName == otherSectionDataItem.dataTableName);

      if (auditDataTable) {
        auditDataTable[otherSectionDataItem.dataColumnName] = otherSectionDataItem.value;

        await this.baseRepository.update(AuditDataTable.table, auditDataTable);
      }
    }
  }

  public async save(formGroup: FormGroup, auditState: AuditState): Promise<boolean> {
    let auditId = auditState.audit.id;
    let formFields = auditState.formFields;
    let customTableId = auditState.customTableId;

    if (formFields.length > 0) {

      let auditDataTable = await AuditDataTable.table.where("tableId").equals(customTableId).first();

      let flattedFormFields = FormField.flatFormFields(formFields);

      flattedFormFields = flattedFormFields.filter(x => x.isInputControl())

      let groupedFormFields = ArrayUtility.groupBy(
        flattedFormFields,
        (x) => x.dataTableName
      );

      for (const groupedFormField of groupedFormFields) {
        if (!auditDataTable) {
          throw new Error("Audit Data Table should always exists at this moment.");
        }

        for (const formField of groupedFormField[1]) {
          let formControl = formGroup.controls[formField.key];

          let value: any = formControl.value;

          if (formControl.dirty) {
            if (formField.valueFormatter)
              value = formField.valueFormatter.format(value);

            if (formField.controlType === CustomFieldControlType.PictureBox)
              await this.setPictureBoxColumn(auditDataTable, formField.dataColumnName, value);
            else
              auditDataTable[formField.dataColumnName] = value === undefined ? null : value;

            if (formField.extendedValues) {
              for (const extendedValue of formField.extendedValues) {
                auditDataTable[extendedValue.dataColumnName] = extendedValue.getValue();
              }
            }
          }
        }

        await this.baseRepository.update(AuditDataTable.table, auditDataTable);

        // There is no alert triggering in alert template so no need to validate them when validating required fields.
        let validateAlertTemplates = !auditState.section["isAlertInstance"];

        let isRequiredFieldsFullyFilled = await this.auditService.validateSectionRequiredFields(auditState.audit, auditState.form, auditState.section, validateAlertTemplates);

        auditDataTable[AuditSectionState.StateField] = formGroup.valid && isRequiredFieldsFullyFilled ? SectionState.Valid : SectionState.EmptyRequiredFields;

        await this.baseRepository.update(AuditDataTable.table, auditDataTable);

        await this.propagateSectionStateToParent(auditState, auditDataTable);

        let audit = await this.baseRepository.get(Audit.table, auditId);

        await this.auditService.updateAuditHeader(audit);
      }
    }

    return true;
  }

  private async setPictureBoxColumn(auditDataTable: AuditDataTable, customFieldName: string, pictureBoxImage: PictureBoxImage) {
    // When the image is null, it means it was deleted and the rows of AuditImage and Image need to be deleted
    if (pictureBoxImage.image === null) {
      auditDataTable[customFieldName] = null;
      if (pictureBoxImage.auditImage)
        this.deleteAuditImage(pictureBoxImage.auditImage);
    }
    else {
      auditDataTable[customFieldName] = await this.savePictureBoxImage(pictureBoxImage, auditDataTable.auditId, customFieldName);
    }
  }

  /**
   * Delete the AuditImage and the Image rows associated with it
   */
  private async deleteAuditImage(auditImage: AuditImage) {
    this.baseRepository.deleteById(AuditImage.table, auditImage.id);
    this.baseRepository.deleteById(Image.table, auditImage.imageId);
    if (auditImage.originalImageId)
      this.baseRepository.deleteById(Image.table, auditImage.originalImageId);
  }

  /**
   * Images from picture box need additionnals steps to save them. The field in DataValues contains an array of ids
   * pointing to rows of AuditImage. They contain the id of the image, the annotation and the id of the original image
   * if annotation were added.
   * @returns id of the new AuditImage
   */
  private async savePictureBoxImage(pictureBoxImage: PictureBoxImage, auditId?: string, customFieldName?: string): Promise<string[]> {
    // The new auditImage while pictureBoxImage.auditImage contains the previous data about the AuditImage
    let auditImage: AuditImage = new AuditImage(pictureBoxImage.auditImage);
    let image: Image = await this.baseRepository.insert(Image.table, new Image({ value: pictureBoxImage.image, auditId: auditId })) as Image

    // There were no image before and one is added
    if (!pictureBoxImage.auditImage) {
      if (!auditId)
        throw new Error("The auditId is not specified for the image to save in the database.");

      auditImage = new AuditImage({ id: Dexie.Observable.createUUID() });

      auditImage.imageId = image.id;
      auditImage.auditId = auditId;
      auditImage.customFieldName = customFieldName;

      await this.baseRepository.insert(AuditImage.table, new AuditImage(auditImage)) as AuditImage
    }

    // Annotation were added and there were none before or an image was changed and annotated
    if (pictureBoxImage.annotation && (pictureBoxImage.newOriginalImage || auditImage.annotation === null)) {
      let originalImage: Image = await this.baseRepository.insert(Image.table, new Image({ value: pictureBoxImage.originalImage, auditId: auditId },)) as Image
      auditImage.originalImageId = originalImage.id;
    }

    auditImage.imageId = image.id;
    auditImage.auditId = auditId;
    auditImage.annotation = pictureBoxImage.annotation;
    auditImage.customFieldName = customFieldName;

    if (!pictureBoxImage.annotation)
      auditImage.originalImageId = null

    await this.baseRepository.update(AuditImage.table, auditImage)

    // Deletion of the old image needs to be done after the update of AuditImage because of the link between AuditImage and Image
    if (pictureBoxImage.auditImage)
      await this.baseRepository.deleteById(Image.table, pictureBoxImage.auditImage.imageId);

    // Annotation were removed or a new image was uploaded with new annotation and the old originalImage need to be deleted
    if (pictureBoxImage.auditImage?.originalImageId &&
      (!pictureBoxImage.annotation ||
        pictureBoxImage.annotation && pictureBoxImage.newOriginalImage))
      await this.baseRepository.deleteById(Image.table, pictureBoxImage.auditImage.originalImageId);

    return [auditImage.id];
  }

  /**
  * When the current section is an alert template, after saving it, must do a validation of the state
  * of the parent section because parent state depends its children.
  */
  private async propagateSectionStateToParent(auditState: AuditState, auditDataTable: AuditDataTable) {
    const parentAuditDataTable = await this.getParentAuditDataTable(auditDataTable.tableId);

    if (parentAuditDataTable) {
      const section = Form.findSectionByTableName(auditState.form, parentAuditDataTable.tableName);

      const isRequiredFieldsFullFilled = await this.auditService.validateSectionRequiredFields(auditState.audit, auditState.form, section, true);

      parentAuditDataTable[AuditSectionState.StateField] = isRequiredFieldsFullFilled ? SectionState.Valid : SectionState.EmptyRequiredFields;

      await this.baseRepository.update(AuditDataTable.table, parentAuditDataTable);
    }
  }

  private async getParentAuditDataTable(auditDataTableId: string): Promise<AuditDataTable> {
    let dynamicTabAuditTemplate = await DynamicTabAuditTemplate.table.where("customTableId").equals(auditDataTableId).first();

    if (!dynamicTabAuditTemplate)
      return null;

    if (!dynamicTabAuditTemplate.referenceTableId)
      return null;

    const parentAuditDataTable = AuditDataTable.table.get(dynamicTabAuditTemplate.referenceTableId);

    return parentAuditDataTable;
  }
}
