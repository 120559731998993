import { Injectable } from "@angular/core";
import { DatabaseContext } from "./databaseContext";
import { ChangeTrackingRange } from "./models/databaseLocal/changeTrackingRange.database";
import { LocalParameter } from "./models/databaseLocal/localParameter.database";
import { TableCache } from "./models/databaseLocal/tableCache.database";
import { TaskFilter } from "./models/databaseLocal/taskFilter.database";
import { UserAudit } from "./models/databaseLocal/userAudit.database";
import { FormSearchOption } from "./models/databaseLocal/FormSearchOption.database";
import { ChangeTracking } from "./models/databaseLocal/changeTracking.database";
import { FormTemplateNextNumber } from "./models/databaseLocal/formTemplateNextNumber.database";

@Injectable({
  providedIn: "root"
})
export class DatabaseTableLocal {
  public tableInformations: any = [
    { tableName: UserAudit.tableName, isAuditRelated: false, primaryKey: '$$id, programId, number, updatedDate, externalNumber' },
    { tableName: ChangeTrackingRange.tableName, isAuditRelated: false, primaryKey: 'id++' },
    { tableName: TaskFilter.tableName, isAuditRelated: false, primaryKey: 'id++' },
    { tableName: TableCache.tableName, isAuditRelated: false, primaryKey: 'id++' },
    { tableName: LocalParameter.tableName, isAuditRelated: false, primaryKey: 'name' },
    { tableName: FormSearchOption.tableName, isAuditRelated: false, primaryKey: 'id++' },
    { tableName: ChangeTracking.tableName, isAuditRelated: false, primaryKey: '++id, tableName' },
    { tableName: FormTemplateNextNumber.tableName, isAuditRelated: false, primaryKey: 'programId' },

  ]

  public registerAllTables(databaseContext: DatabaseContext) {
    UserAudit.selfRegister(databaseContext);
    ChangeTracking.selfRegister(databaseContext);
    ChangeTrackingRange.selfRegister(databaseContext);
    TaskFilter.selfRegister(databaseContext);
    TableCache.selfRegister(databaseContext);
    LocalParameter.selfRegister(databaseContext);
    FormSearchOption.selfRegister(databaseContext);
    FormTemplateNextNumber.selfRegister(databaseContext);
  }
}
