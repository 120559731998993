import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Dexie from 'dexie';
import _ from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { TaskFilter } from 'src/app/core/data/models/databaseLocal/taskFilter.database';
import { TaskListItemViewModel } from './taskListItemViewModel';
import { TaskListService } from './taskListService';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  constructor(
    private baseRepository: BaseRepository,
    private router: Router,
    private taskListService: TaskListService) { }

  @ViewChild("list") list: ListComponent;

  taskTypes: any[];

  taskFilter: TaskFilter;

  tasks: TaskListItemViewModel[] = [];
  selectedTasks: TaskListItemViewModel[] = [];

  // Represents a combination of the selected TaskType Id and OptionList Id.
  selectedItemId: string;

  loading: boolean;
  hideMap: boolean = true;
  itemCount: number = 0;
  selectedItemsCount: number = 0;
  public filterCount: number = 0;

  hasUserChanges: boolean = false;

  validationMessage: string;

  async ngOnInit(): Promise<void> {
    this.taskTypes = await this.taskListService.getTaskTypeOptions();
    this.taskFilter = await TaskFilter.table.toCollection().first()

    if (this.taskFilter){
      if (this.taskFilter.filterFields){
        let filterArray = JSON.parse(this.taskFilter.filterFields);

        for (let filter of filterArray) {
          if (filter["value"])
            this.filterCount++;
        }
      }

      if (this.taskFilter.distanceFromFirstTask)
        this.filterCount++;
    }

    // There will be one and only one item in this not synchronizable mobile only table
    // which will allow to save user preference about task selected for the next time
    // the user will enter in this page.
    if (!this.taskFilter) {
      this.taskFilter = new TaskFilter({ id: Dexie.Observable.createUUID() });

      this.baseRepository.insert(TaskFilter.table, this.taskFilter);
    }

    let selectedItem = this.taskTypes.find(x => x.key === this.taskFilter.getTaskTypeOptionListId());

    if (!selectedItem) {
      this.list.updateData();
    }
    else {
      this.selectedItemId = this.taskFilter.getTaskTypeOptionListId();
    }
  }

  async onValueChanged(e) {
    this.validationMessage = "";

    await this.taskListService.updateTaskFilterState(this.taskFilter, this.selectedItemId);

    let selectedTaskType = await TaskType.table.get(this.taskFilter.taskTypeId);

    this.hideMap = !selectedTaskType.isActivated;

    let displayOptions = await this.taskListService.getDisplayOptions(this.taskFilter);

    let taskResult = await this.taskListService.tryGetTasks(this.taskFilter, displayOptions);

    if (taskResult.success){
      this.tasks = taskResult.value;

      this.list.listOptions = displayOptions;

      this.itemCount = this.tasks.length;

      this.selectedTasks = await this.taskListService.getSelectedTasks(this.taskFilter.optionListId, this.tasks);

      this.selectedItemsCount = this.selectedTasks.length;

      this.list.updateData();
    }
    else{
      this.validationMessage = taskResult.validationDictionary.getFirstValidationMessage();
    }
  }

  async mapClick() {
    if (this.hasUserChanges)
      await this.taskListService.saveTasks(this.selectedTasks, this.taskFilter.optionListId, this.taskFilter.taskTypeId);

    this.router.navigate(['/tasks/map']);
  }

  async searchClick() {
    await this.baseRepository.update(TaskFilter.table, this.taskFilter);

    this.router.navigate(["/tasks/filter"]);
  }

  selectedItemsChange(items) {
    this.hasUserChanges = true;

    if (items)
      this.selectedItemsCount = items.length;
  }
}
