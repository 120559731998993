import { SectionType } from "src/app/core/data/models/form/sectionType";
import { DynamicTabFolderViewModel } from "./dynamicTabFolderViewModel";
import { SectionState } from "./sectionState";

export class SectionViewModel {
  id: string;
  description: string;
  type: SectionType;
  isActive: boolean = false;
  state: SectionState = SectionState.Valid;
  instancesVisible: boolean = false;
  dataTableName: string;
  folders: DynamicTabFolderViewModel[] = [];
  
  isDynamicTab(): boolean {
    return this.type === SectionType.DynamicTab;
  }

  public constructor(args?: Partial<SectionViewModel>) {
    Object.assign(this, args);
  }

  getFolders()
  {
    return this.folders.filter(x => x.templateId);
  }
}
