<app-popup [visible]="visible" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-left">
          <button>
            <i class="fas fa-info"></i>
            <div><span>{{ 'unauthorized.title' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center padding-normal">
      <span *ngIf="!synchronizationService.offline">{{ 'unauthorized.message' | translate }}</span>
      <span *ngIf="synchronizationService.offline">{{ 'unauthorized.messageOffline' | translate }}</span>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white height-normal padding-horizontal-normal">
        <div class="layout-right">
          <button class="compact color-set-gray" (click)="logout()" [disabled]="synchronizationService.offline">
            <div><span>{{ 'authentication.loginComponent.connectWithAnotherUser' | translate}}</span></div>
          </button>
          <button class="compact color-set-blue" (click)="retry()">
            <div><span>{{ 'components.customFields.imagePicker.retry' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
