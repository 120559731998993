import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { AuditDeleteService } from './auditDeleteService';
import { DeleteStep } from './deleteStep';

@Component({
  selector: 'app-audit-delete',
  templateUrl: './audit-delete.component.html',
  styleUrls: ['./audit-delete.component.scss']
})
export class AuditDeleteComponent implements OnInit {

  public deletingStep: DeleteStep = DeleteStep.validating;
  get DeleteStep() { return DeleteStep }

  constructor(
    private headerState: HeaderState,
    private auditDeleteService: AuditDeleteService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.headerState.title = "";
    this.headerState.useBackButton = false;
    // url format : /forms/:auditNumber/duplicate
    let auditNumber = this.router.url.split("/")[2];
    await this.validateAuditNumber(auditNumber);
    await this.deleteAudit(auditNumber);
  }

  public navigateToHome(): void {
    this.router.navigate([`/home`]);
  }

  private async validateAuditNumber(auditNumber: string): Promise<void> {
    if (await this.auditDeleteService.validateAuditNumber(auditNumber)) {
      this.deletingStep = DeleteStep.deleting;
    } else {
      this.router.navigate([`/forms/notfound/${auditNumber}`]);
    }
  }

  private async deleteAudit(auditNumber: string): Promise<void> {
    await this.auditDeleteService.deleteAudit(auditNumber);
    this.router.navigate([`/forms`]);
  }
}
