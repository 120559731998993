import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { AuditMessage } from 'src/app/core/data/models/database/auditMessage.database';
import { Control } from 'src/app/core/data/models/form/control';
import { Instruction } from 'src/app/core/data/models/form/instruction';
import { Section } from 'src/app/core/data/models/form/section';
import { InstructionState } from '../instructionState';

@Component({
  selector: 'app-instructions-panel',
  templateUrl: './instructions-panel.component.html',
  styleUrls: ['./instructions-panel.component.scss']
})
export class InstructionsPanelComponent implements OnInit, OnChanges {

  @Input() section: Section;

  instructions: Instruction[];

  constructor(private baseRepository: BaseRepository, public instructionState: InstructionState) { }

  async ngOnInit(): Promise<void> {
    this.instructions = await this.getSectionInstructions(this.section);
  }

  async ngOnChanges(): Promise<void> {
    this.instructions = await this.getSectionInstructions(this.section);
  }

  async getSectionInstructions(section: Section): Promise<Instruction[]> {
    let result: Instruction[] = [];

    for (let control of section.controls) {
      result = result.concat(await this.getControlInstructions(control))
    }

    return result;
  }

  async getControlInstructions(control: Control): Promise<Instruction[]> {
    let result = Array<Instruction>();
    if (control.instructionId) {
      let newInstruction = new Instruction;

      newInstruction.auditMessage = await this.baseRepository.get(AuditMessage.table, control.instructionId);
      newInstruction.controlName = control.name;
      newInstruction.controlDescription = control.description;

      result.push(newInstruction);
    }

    if (control.children?.length) {
      for (let child of control.children) {
        result = result.concat(await this.getControlInstructions(child));
      }
    }

    return result;
  }
}
