import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import 'dexie-observable';
import { DatabaseTableAll } from './databaseTableAll';
import { environment } from 'src/environments/environment';
import { Logger } from '../log/logger';

const originalCreateUUID = Dexie.Observable.createUUID;
Dexie.Observable.createUUID = () => {
  return originalCreateUUID().toUpperCase();
};


@Injectable({
  providedIn: 'root',
})
export class DatabaseContext extends Dexie {
  public constructor(
    private databaseTableAll: DatabaseTableAll,
    private logger: Logger
  ) {
    super("nSpekDatabase");

    let tables = {};

    // Create IndexedDb schema for all tables that have been auto generated
    // within databaseTable + additionnal custom tables above. All table are
    // expected to have an Id which will be a guid which is a string in javascript.
    for (let tableInformation of this.databaseTableAll.getTableInformations()) {
      tables[tableInformation.tableName] = tableInformation.primaryKey;
    }

    // The database version and schema will always be up to date with the current loaded
    // application and it is not possible update the application without updating the
    // database on startup.
    this.version(environment.databaseVersion).stores(tables);

    this.databaseTableAll.registerAllTables(this);

    this.on("populate", () => {
      // Delete all data of tables that have been deleted on the server.
      // While it would be best to drop the table, it can only be done by
      // setting the version stores to null. It can only be done if we 
      // manually handle each version or keep a list of table for comparison.
      // Since it can't be within IndexedDb itself, I don't think the localStorage
      // to be safe enough for this. For this reason, by simply deleting the data,
      // we have a simple solutions with very minimal impact. The tables will clean
      // themsleves with time when the whole database is dropped... we currently do
      // not have a process to do so, and I can't yet see if it is actually required
      // therefor I have not implemented anything yet.
      // https://github.com/dfahlander/Dexie.js/issues/275
      for (let table of this.tables.map(x => x.name)) {
        if (this.databaseTableAll.getTableNames().indexOf(table) < 0) {
          this.table(table).clear();
        }
      }
    });
  }

  async resetDatabase() {
    await this.delete();
    location.reload();
  }

  async tryOpenWithResetOnUpgradeError() {
    try {
      await this.open();
    } catch (e) {
      if (e instanceof Dexie.UpgradeError) {
        this.delete();
        this.open();
        await this.logger.logWarning("Database was reset due to invalid migration configuration.");
        await this.logger.logWarning(e.message);
      }
    } finally {
      await this.logger.logInformation("Using IndexedDB database version: " + this.verno)
    }
  }
}