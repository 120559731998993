import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { firstValueFrom } from "rxjs";
import { LocalParameter } from "../data/models/databaseLocal/localParameter.database";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private configUrl = `${environment.apiBaseUrl}/ping`;

  public constructor(
    public readonly httpClient: HttpClient
  ) { }

  public async isOnline(): Promise<boolean> {
    let isForcedOffline: boolean = (await LocalParameter.table.get(LocalParameter.FORCED_OFFLINE_MODE))?.value ?? false;
    if(isForcedOffline) {
      return false;
    }

    let canPing = false;

    try {
      await firstValueFrom(this.httpClient.get(this.configUrl))
      canPing = true;
    } catch (e) {

    }

    return navigator.onLine && canPing;
  }
}
