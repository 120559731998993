import { IAuthenticationService } from "./iAuthenticationService.interface";
import { AuthenticationServiceFactory } from "./iAuthenticationService.factory";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { SynchronizationService } from "../core/data/synchronization/synchronizationService";

@Injectable()
export class AuthenticationRedirectGuard{
  public authenticationService: IAuthenticationService;

  constructor(
    public authenticationServiceFactory: AuthenticationServiceFactory,
    public synchronizationService: SynchronizationService
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.authenticationService.isAuthenticated();
  }
}