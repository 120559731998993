import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class SynchronizationPopupState {
    public display:boolean;
    public currentRange:number = 0;
    public numberOfRange:number = 0;
    public completionPercentage:number = 0;
    public isReceiving = false;
    
    public initializeState(numberOfRange, isReceiving, currentRange = 0){
      this.numberOfRange = numberOfRange;
      this.isReceiving = isReceiving;
      this.updateState(currentRange);
    }
    
    public updateState(currentRange){
      this.currentRange = currentRange;
      this.setCompletionPercentage();
    }
    
    private setCompletionPercentage(){
      this.completionPercentage = (this.currentRange / this.numberOfRange) * 100;
    }
  }
  
  