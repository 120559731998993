import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Dexie from 'dexie';
import dexieObservable from 'dexie-observable';
import _ from 'lodash';
import { Subscription, throwIfEmpty } from 'rxjs';
import { BaseFormComponent } from 'src/app/components/forms/BaseForm.component';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { HeaderStateLeftButtonType } from 'src/app/components/headers/header/headerStateLeftButtonType';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { DataSourceImportationException } from 'src/app/core/data/models/database/dataSourceImportationException.database';
import { DataSourceImportationType } from 'src/app/core/data/models/database/dataSourceImportationType.database';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';
import { DataSourceImportationHttpClient } from 'src/app/core/services/dataSourceImportations/dataSourceImportationHttpClient';
import { FieldTitle } from 'src/app/core/services/dataSourceImportations/fieldTitle';
import { DataSourcePropertyComponent } from '../data-source-property/data-source-property.component';
import { DatasourceImportationType } from './datasourceImportationType';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { DataSourcePropertyService } from '../data-source-property/dataSourcePropertyService';

@Component({
  selector: 'app-data-source-importation',
  templateUrl: './data-source-importation.component.html',
  styleUrls: ['./data-source-importation.component.scss']
})
export default class DataSourceImportationComponent extends BaseFormComponent<DataSourceImportation> implements OnInit, OnDestroy {
  private backButtonSubscription: Subscription;

  types: any;

  controlTypes: any;

  public exceptions: DataSourceImportationException[] = [];

  public fieldTitleDataSource: Function;

  public selectedProperty;

  public isApi: boolean;

  public fieldTitles: any[] = [];

  @ViewChild('fieldTitleList') fieldTitleList: ListComponent;

  @ViewChild("result") result: ListComponent;

  @ViewChild(DataSourcePropertyComponent) dataSourcePropertyPopup: DataSourcePropertyComponent;

  public hasDataSourceResult: boolean = false;

  constructor(
    private route: ActivatedRoute,
    baseRepository: BaseRepository,
    private formBuilder: UntypedFormBuilder,
    private datasourceImportationHttpClient: DataSourceImportationHttpClient,
    private headerState: HeaderState,
    router: Router,
    translateService: TranslateService) {

    super(translateService, router, baseRepository);

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl("id"),
      name: new UntypedFormControl("name", [Validators.required, Validators.maxLength(250)]),
      idKey: new UntypedFormControl("idKey", [Validators.required, Validators.maxLength(250)]),
      typeId: new UntypedFormControl("typeId", [Validators.required]),
      isActive: new UntypedFormControl("isActive"),
      cron: new UntypedFormControl("cron", [Validators.required, Validators.maxLength(100)]),
      apiUrl: new UntypedFormControl("apiUrl", [Validators.required, Validators.maxLength(4000)]),
      apiAuthenticationToken: new UntypedFormControl("apiAuthenticationToken", [Validators.maxLength(1000)]),
      apiJsonPath: new UntypedFormControl("apiJsonPath"),
      fieldTitles: new UntypedFormControl("fieldTitles"),
      fieldTitlesKey: new UntypedFormControl("fieldTitlesKey", [Validators.maxLength(100)])
    });

    this.entityUrl = "administration/data-source-importations";
    this.table = DataSourceImportation.table;

    this.form.get('typeId').valueChanges.subscribe(val => {
      this.isApi = this.form.get('typeId').value === DatasourceImportationType.Api;

      this.setRequiredFields();
    });

    this.fieldTitleDataSource = async (): Promise<ListDataSourceFunctionResult> => {
      this.fieldTitles = [];

      if (this.dataSourceImportation && this.form.controls.fieldTitles.value) {
        let fieldTitles = JSON.parse(this.form.controls.fieldTitles.value);

        fieldTitles.forEach(item => {
          this.fieldTitles.push({ key: item.key, value: item.value, controlType: item.controlType, id: item.id || Dexie.Observable.createUUID(), visible: item.visible });
        });
      }

      this.fields = await _.map(this.fieldTitles.filter(x => x.controlType != CustomFieldControlType.RichTextBox), x => x.key);

      return new ListDataSourceFunctionResult({
        items: this.fieldTitles,
        itemCount: this.fieldTitles.length
      })
    };
  }

  private setRequiredFields() {
    this.setValidator(this.form.get("cron"), this.isApi);
    this.setValidator(this.form.get("apiUrl"), this.isApi);
  }

  private setValidator(control: AbstractControl, required: boolean) {
    if (required) {
      control.setValidators(Validators.required);
    }
    else {
      control.clearValidators();
    }

    control.updateValueAndValidity();
  }

  get dataSourceImportation(): DataSourceImportation {
    return <DataSourceImportation>this.model;
  }

  fields: string[] = [];

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');

    await this.load();

    this.backButtonSubscription = this.headerState.backButtonClick.subscribe(() => {
      super.navigateToList();
    });

    this.form.controls["name"].valueChanges.subscribe(x => {
      this.updateHeaderTitle(x);
    })

    this.headerState.leftButtonType = HeaderStateLeftButtonType.backButton;

    this.headerState.changeLeftButton("administration/data-source-importations")
  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();

    this.headerState.setDefaultButtonState();

    this.headerState.title = "";
  }

  async load() {
    this.types = await DataSourceImportationType.table.toArray();

    this.model = await this.createModel();

    this.formSynchronizer.setValuesToControls(this.model)

    this.updateHeaderTitle(this.dataSourceImportation.name);

    if (this.id)
      this.exceptions = await DataSourceImportationException.table.where("dataSourceImportationId").equals(this.dataSourceImportation.id).toArray()
    else
      this.exceptions = [];

    this.setRequiredFields();

    this.fieldTitleList.updateData();
    
    this.setControlsState();
  }

  private setControlsState() {
    super.setControlState(this.form.controls.fieldTitlesKey, !this.hasDataSourceResult);
  }

  public async validate(model: DataSourceImportation, validationDictionary: string[]): Promise<string[]> {
    if (model.fieldTitles) {
      if (!model.fieldTitlesKey) {
        validationDictionary.push(this.translateService.instant("dataSourceImportation.edit.validations.fieldTitleKeyRequired"));
      }
      else {
        let fieldTitles: FieldTitle[] = JSON.parse(model.fieldTitles);

        if (!_.find(fieldTitles, x => x.key === model.fieldTitlesKey)) {
          validationDictionary.push(this.translateService.instant("dataSourceImportation.edit.validations.fieldTitleKeyMustExist"))
        }
      }
    }

    var dataSourceImportations = await (await this.baseRepository.getAll(DataSourceImportation.table)).filter(x => !x.isDeleted);

    if (_.find(dataSourceImportations, x => x.id !== model.id && x.name == model.name))
      validationDictionary.push(this.translateService.instant("dataSourceImportation.edit.validations.nameAlreadyExist"))

    if (_.find(dataSourceImportations, x => x.id !== model.id && x.idKey == model.idKey))
      validationDictionary.push(this.translateService.instant("dataSourceImportation.edit.validations.keyAlreadyExist"))

    return validationDictionary;
  }

  updateHeaderTitle(value: string) {
    this.headerState.title = `<div><b>${value}</b></div><div>${this.translateService.instant("dataSourceImportation.list.title")}</div>`;
  }

  async retreiveFieldTitlesDataClick() {
    this.confirmUpdateFieldTitles();
  }

  confirmUpdateFieldTitles() {
    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService,
      this.translateService.instant("dataSourceImportation.edit.updateFieldTitlesTitle"),
      this.translateService.instant("dataSourceImportation.edit.updateFieldTitlesMessage"),
      async () => {
        this.retreiveFieldTitlesData();
      });
  }

  public retreivingFieldTitlesData: boolean = false;

  async retreiveFieldTitlesData() {
    this.retreivingFieldTitlesData = true;

    await this.datasourceImportationHttpClient.retreiveFieldTitles(<string>this.id, this.form.controls.apiUrl.value, this.form.controls.apiAuthenticationToken.value ?? "", this.form.controls.apiJsonPath.value ?? "").then(apiTokenResult => {
      let fieldTitles: any[] = [];

      if (apiTokenResult.Success) {
        let continueProcess: boolean = true;

        for (const fieldTitle of apiTokenResult.Items) {
          if (!DataSourcePropertyService.isReservedKeyword(fieldTitle.key)){
            PopupUtility.displayInformation(this.modalPopup, this.translateService,
              this.translateService.instant("dataSourceImportation.edit.retreivingFieldsExceptionTitle"),
              this.translateService.instant("dataSourceImportation.edit.validations.reservedKeyword", { keyword: fieldTitle.key }));

            continueProcess = false;
            break;
          }

          const key = fieldTitle.key[0].toLowerCase() + fieldTitle.key.substr(1, fieldTitle.key.length);

          fieldTitles.push({ key: key, value: fieldTitle.value });
        }

        if (continueProcess){
          this.form.controls.fieldTitlesKey.setValue("");

          this.form.controls.fieldTitles.setValue(JSON.stringify(fieldTitles));

          this.fieldTitleList.updateData();

          this.form.markAsDirty();
        }
      }
      else {
        PopupUtility.displayInformation(this.modalPopup, this.translateService,
          this.translateService.instant("dataSourceImportation.edit.retreivingFieldsExceptionTitle"),
          this.translateService.instant("dataSourceImportation.edit.retreivingFieldsExceptionMessage", { value1: apiTokenResult.ErrorMessage }));
      }      

      this.retreivingFieldTitlesData = false;
    })
  }

  afterSave(){
    this.result.updateData();
  }

  async saveButtonClick() {
    super.saveAndRedirect();
  }

  private async createModel() {
    let model: DataSourceImportation;

    if (!this.id) {
      model = new DataSourceImportation({ isActive: false, cron: "0 0/5 * ? * * *", name: "", isDeleted: false, entityState: EntityState.New });
    }
    else {
      model = await this.baseRepository.get(DataSourceImportation.table, this.id);

      if (model)
        model.entityState = EntityState.Modified;
    }

    return model;
  }

  async cancelButtonClick() {
    super.cancel();
  }

  async propertyItemClick(item) { 
    let isKey: boolean = false;

    if (item.key == this.form.controls.fieldTitlesKey.value)
      isKey = true;
    
    if (item.controlType == null)
      item.controlType = CustomFieldControlType.TextBox;

    if (await this.dataSourcePropertyPopup.display(item, this.fieldTitles, false, this.dataSourceImportation)) {
      let fieldTitle = this.fieldTitles.find(x => x.key === item.key);

      fieldTitle.key = item.key;
      fieldTitle.value = item.value;
      fieldTitle.controlType = item.controlType;
      fieldTitle.visible = item.visible || false;

      if (isKey){
        this.form.controls.fieldTitlesKey.setValue(item.key);
      }

      this.saveProperties();
    }
  }

  async selectedFieldTitlesItemsChange(item) {
    this.selectedProperty = item;
  }

  deleteFieldTitleClick() {
    if (this.selectedProperty.key === this.form.controls.fieldTitlesKey.value) {
      PopupUtility.displayInformation(this.modalPopup, this.translateService,
        this.translateService.instant("dataSourceProperty.validations.suppressionTitle"),
        this.translateService.instant("dataSourceProperty.validations.cannotDeleteLinkedKey"));

      return;
    }

    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService,
      this.translateService.instant("dataSourceProperty.validations.suppressionTitle"),
      this.translateService.instant("dataSourceProperty.validations.confirmSuppression"),
      async () => {
        _.remove(this.fieldTitles, x => x.key === this.selectedProperty.key);

        this.saveProperties();
      });
  }

  async addFieldTitleClick() {
    let newItem = { key: "", value: "", controlType: CustomFieldControlType.TextBox, id: Dexie.Observable.createUUID(), visible: false };

    if (await this.dataSourcePropertyPopup.display(newItem, this.fieldTitles, true, this.dataSourceImportation)) {
      this.fieldTitles.push(newItem)

      this.saveProperties();
    }
  }

  saveProperties() {
    this.form.controls.fieldTitles.setValue(JSON.stringify(this.fieldTitles));

    this.fieldTitleList.updateData();

    this.form.markAsDirty();
  }

  dataSourceChanged(itemCount: number) {
    this.hasDataSourceResult = itemCount > 0;

    this.setControlsState();
  }
}
