// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditDataTable extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditDataTable, string>;
  public static tableName: string = 'AuditDataTable';

  public static AUDIT_ID: string = 'auditId';  public static DATA_VALUES: string = 'dataValues';  public static DATA_VALUES_SIZE: string = 'dataValuesSize';  public static ID: string = 'id';  public static TABLE_ID: string = 'tableId';  public static TABLE_NAME: string = 'tableName';  public static TIME_STAMP: string = 'timeStamp';

  public auditId: string;  public dataValues: string;  public dataValuesSize: number;  public id: string;  public tableId: string;  public tableName: string;  public timeStamp: Date;

  public constructor(args?: Partial<AuditDataTable>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditDataTable.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditDataTable.tableName);
      this.table.mapToClass(AuditDataTable);
  }
}
