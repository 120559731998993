// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.
import { Injectable } from "@angular/core";
import { DatabaseContext } from "./databaseContext";
import { DatabaseTableInformation } from "./databaseTableInformation";
import { AlertCondition } from "./models/database/alertCondition.database";
import { AlertConditionDetail } from "./models/database/alertConditionDetail.database";
import { AlertControlType } from "./models/database/alertControlType.database";
import { AlertEvent } from "./models/database/alertEvent.database";
import { AlertEventType } from "./models/database/alertEventType.database";
import { ApplicationLog } from "./models/database/applicationLog.database";
import { Audit } from "./models/database/audit.database";
import { AuditAdditionalInfo } from "./models/database/auditAdditionalInfo.database";
import { AuditAlertSummary } from "./models/database/auditAlertSummary.database";
import { AuditAlertSummarySatelliteDataItem } from "./models/database/auditAlertSummarySatelliteDataItem.database";
import { AuditAttachment } from "./models/database/auditAttachment.database";
import { AuditDataTable } from "./models/database/auditDataTable.database";
import { AuditImage } from "./models/database/auditImage.database";
import { AuditMessage } from "./models/database/auditMessage.database";
import { AuditRemark } from "./models/database/auditRemark.database";
import { AuditSubscription } from "./models/database/auditSubscription.database";
import { AutomaticDocumentExportationTelemetry } from "./models/database/automaticDocumentExportationTelemetry.database";
import { CheckBoxChoice } from "./models/database/checkBoxChoice.database";
import { CustomFieldValueItem } from "./models/database/customFieldValueItem.database";
import { CustomFieldValueList } from "./models/database/customFieldValueList.database";
import { DataSourceImportation } from "./models/database/dataSourceImportation.database";
import { DataSourceImportationException } from "./models/database/dataSourceImportationException.database";
import { DataSourceImportationResult } from "./models/database/dataSourceImportationResult.database";
import { DataSourceImportationType } from "./models/database/dataSourceImportationType.database";
import { DynamicTabAuditTemplate } from "./models/database/dynamicTabAuditTemplate.database";
import { DynamicTabStructureItem } from "./models/database/dynamicTabStructureItem.database";
import { DynamicTabTemplate } from "./models/database/dynamicTabTemplate.database";
import { EntitySecurityGroup } from "./models/database/entitySecurityGroup.database";
import { Image } from "./models/database/image.database";
import { ImageSize } from "./models/database/imageSize.database";
import { Mobile } from "./models/database/mobile.database";
import { MobileEntity } from "./models/database/mobileEntity.database";
import { OptionList } from "./models/database/optionList.database";
import { Parameter } from "./models/database/parameter.database";
import { ParameterPhoto } from "./models/database/parameterPhoto.database";
import { Permission } from "./models/database/permission.database";
import { PermissionList } from "./models/database/permissionList.database";
import { PermissionListGroup } from "./models/database/permissionListGroup.database";
import { Program } from "./models/database/program.database";
import { ProgramDataTemplate } from "./models/database/programDataTemplate.database";
import { ProgramUserGroup } from "./models/database/programUserGroup.database";
import { ProgramVersion } from "./models/database/programVersion.database";
import { SharedParameter } from "./models/database/sharedParameter.database";
import { Synchronization } from "./models/database/synchronization.database";
import { SynchronizationRange } from "./models/database/synchronizationRange.database";
import { SynchronizationRangeItem } from "./models/database/synchronizationRangeItem.database";
import { Task } from "./models/database/task.database";
import { TaskImportationType } from "./models/database/taskImportationType.database";
import { TaskType } from "./models/database/taskType.database";
import { UserAccount } from "./models/database/userAccount.database";
import { UserAccountGroup } from "./models/database/userAccountGroup.database";
import { UserGroup } from "./models/database/userGroup.database";
import { UserType } from "./models/database/userType.database";
import { WFStep } from "./models/database/wFStep.database";
import { WFStepAttribution } from "./models/database/wFStepAttribution.database";
import { WFStepExecutor } from "./models/database/wFStepExecutor.database";


@Injectable({
    providedIn: "root"
})
export class DatabaseTableServer {
    public tableInformations: DatabaseTableInformation[] = [
      { tableName: AlertCondition.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertConditionDetail.tableName, isAuditRelated: false, primaryKey: '$$id, alertConditionId' },
      { tableName: AlertControlType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: AlertEvent.tableName, isAuditRelated: false, primaryKey: '$$id, alertConditionDetailId' },
      { tableName: AlertEventType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: ApplicationLog.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Audit.tableName, isAuditRelated: true, primaryKey: '$$id, number, externalNumber, programId' },
      { tableName: AuditAdditionalInfo.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAlertSummary.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAlertSummarySatelliteDataItem.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAttachment.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditDataTable.tableName, isAuditRelated: true, primaryKey: '$$id, auditId, tableName, tableId, [tableName+auditId]' },
      { tableName: AuditImage.tableName, isAuditRelated: true, primaryKey: '$$id, auditId, [auditId+customTableId+customFieldName]' },
      { tableName: AuditMessage.tableName, isAuditRelated: false, primaryKey: '$$id, summary' },
      { tableName: AuditRemark.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditSubscription.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AutomaticDocumentExportationTelemetry.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: CheckBoxChoice.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: CustomFieldValueItem.tableName, isAuditRelated: false, primaryKey: '$$id, valueListId, description' },
      { tableName: CustomFieldValueList.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DataSourceImportation.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DataSourceImportationException.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId' },
      { tableName: DataSourceImportationResult.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId' },
      { tableName: DataSourceImportationType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: DynamicTabAuditTemplate.tableName, isAuditRelated: true, primaryKey: '$$id, auditId, customTableId, referenceTableId, [customTableId+auditId], [referenceTableId+auditId]' },
      { tableName: DynamicTabStructureItem.tableName, isAuditRelated: false, primaryKey: '$$id, keyIdentifier' },
      { tableName: DynamicTabTemplate.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: EntitySecurityGroup.tableName, isAuditRelated: false, primaryKey: '$$id, entityId, source' },
      { tableName: Image.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: ImageSize.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Mobile.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: MobileEntity.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: OptionList.tableName, isAuditRelated: false, primaryKey: '$$id, entityId, source' },
      { tableName: Parameter.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: ParameterPhoto.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Permission.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: PermissionList.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: PermissionListGroup.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Program.tableName, isAuditRelated: false, primaryKey: '$$id, numberPrefix' },
      { tableName: ProgramDataTemplate.tableName, isAuditRelated: false, primaryKey: '$$id, programId' },
      { tableName: ProgramUserGroup.tableName, isAuditRelated: false, primaryKey: '$$id, userGroupId' },
      { tableName: ProgramVersion.tableName, isAuditRelated: false, primaryKey: '$$id, programId' },
      { tableName: SharedParameter.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: Synchronization.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: SynchronizationRange.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: SynchronizationRangeItem.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Task.tableName, isAuditRelated: false, primaryKey: '$$id, optionListId' },
      { tableName: TaskImportationType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: TaskType.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId, name' },
      { tableName: UserAccount.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: UserAccountGroup.tableName, isAuditRelated: false, primaryKey: '$$id, userId' },
      { tableName: UserGroup.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: UserType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WFStep.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WFStepAttribution.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WFStepExecutor.tableName, isAuditRelated: false, primaryKey: '$$id, wFStepId' },
      
    ]

    public registerAllTables(databaseContext: DatabaseContext) {
        AlertCondition.selfRegister(databaseContext);
      AlertConditionDetail.selfRegister(databaseContext);
      AlertControlType.selfRegister(databaseContext);
      AlertEvent.selfRegister(databaseContext);
      AlertEventType.selfRegister(databaseContext);
      ApplicationLog.selfRegister(databaseContext);
      Audit.selfRegister(databaseContext);
      AuditAdditionalInfo.selfRegister(databaseContext);
      AuditAlertSummary.selfRegister(databaseContext);
      AuditAlertSummarySatelliteDataItem.selfRegister(databaseContext);
      AuditAttachment.selfRegister(databaseContext);
      AuditDataTable.selfRegister(databaseContext);
      AuditImage.selfRegister(databaseContext);
      AuditMessage.selfRegister(databaseContext);
      AuditRemark.selfRegister(databaseContext);
      AuditSubscription.selfRegister(databaseContext);
      AutomaticDocumentExportationTelemetry.selfRegister(databaseContext);
      CheckBoxChoice.selfRegister(databaseContext);
      CustomFieldValueItem.selfRegister(databaseContext);
      CustomFieldValueList.selfRegister(databaseContext);
      DataSourceImportation.selfRegister(databaseContext);
      DataSourceImportationException.selfRegister(databaseContext);
      DataSourceImportationResult.selfRegister(databaseContext);
      DataSourceImportationType.selfRegister(databaseContext);
      DynamicTabAuditTemplate.selfRegister(databaseContext);
      DynamicTabStructureItem.selfRegister(databaseContext);
      DynamicTabTemplate.selfRegister(databaseContext);
      EntitySecurityGroup.selfRegister(databaseContext);
      Image.selfRegister(databaseContext);
      ImageSize.selfRegister(databaseContext);
      Mobile.selfRegister(databaseContext);
      MobileEntity.selfRegister(databaseContext);
      OptionList.selfRegister(databaseContext);
      Parameter.selfRegister(databaseContext);
      ParameterPhoto.selfRegister(databaseContext);
      Permission.selfRegister(databaseContext);
      PermissionList.selfRegister(databaseContext);
      PermissionListGroup.selfRegister(databaseContext);
      Program.selfRegister(databaseContext);
      ProgramDataTemplate.selfRegister(databaseContext);
      ProgramUserGroup.selfRegister(databaseContext);
      ProgramVersion.selfRegister(databaseContext);
      SharedParameter.selfRegister(databaseContext);
      Synchronization.selfRegister(databaseContext);
      SynchronizationRange.selfRegister(databaseContext);
      SynchronizationRangeItem.selfRegister(databaseContext);
      Task.selfRegister(databaseContext);
      TaskImportationType.selfRegister(databaseContext);
      TaskType.selfRegister(databaseContext);
      UserAccount.selfRegister(databaseContext);
      UserAccountGroup.selfRegister(databaseContext);
      UserGroup.selfRegister(databaseContext);
      UserType.selfRegister(databaseContext);
      WFStep.selfRegister(databaseContext);
      WFStepAttribution.selfRegister(databaseContext);
      WFStepExecutor.selfRegister(databaseContext);
      
    }
}
