export class StringUtility {
  /**
  * String.replace only replace the first occurrence found.
  * This will replace all occurrences.
  */
  static replaceAll(replaceString: string, searchValue: string, replaceWith: string): string {
    return replaceString.replace(new RegExp(searchValue, "g"), replaceWith);
  }

  /**
  * Returns a string with each {x} tags are replaced with the corresponding indexed value.
  * This is the same as string.format in C#.
  */
  static formatString(value: string, ...args: string[]): string {
    return value.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  }

  static turnToCamelCase(value: string) {
    if (value)
      return value.substr(0, 1).toLowerCase() + value.substr(1);
    else
      return "";
  }

  /**
  * Validate a mask.
  */
  static validateMask(input: string, mask: string): boolean {
    if (!mask)
      return true;

    if (input === undefined || input === null)
      input = "";

    let sanitizedMask: string = "";

    for (let i = 0; i < mask.length; i++) {
      const char = mask[i];

      switch (char) {
        case '0':
        case '9':
        case 'L':
        case 'l':
        case 'C':
        case 'c':
        case 'A':
        case 'a':
          sanitizedMask += char;

          break;

        default:

          break;
      }
    }

    mask = sanitizedMask;

    // Force the input to be the same lenght than the mask because it is much easier after
    // to validate each character in input with the related one at the same position in the
    // mask.
    if (input.length < mask.length){
      let paddingCharacters = mask.length - input.length;

      input += " ".repeat(paddingCharacters);
    }

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const maskChar = mask[i];

      switch (maskChar) {
        case '0':
          if (!/\d/.test(char)) {
            return false;
          }
          break;

        case '9':
          if (!/\d|\s/.test(char)) {
            return false;
          }
          break;

        case '#':
          if (!/\d|\s|[+-]/.test(char)) {
            return false;
          }
          break;

        case 'L':
          if (!/^[A-Za-z]+$/.test(char))
            return false;

          break;

        case 'l':
          if (char !== ' ' && !/^[A-Za-z]+$/.test(char))
            return false;

          break;
        case 'C':
          if (/\s/.test(char)) {
            return false;
          }
          break;

        case 'c':
          break;

        case 'A':
          if (!/\w/.test(char)) {
            return false;
          }
          break;

        case 'a':
          if (!/\w|\s/.test(char)) {
            return false;
          }
          break;
      }
    }

    return true;
  }
}
