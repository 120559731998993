// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditMessage extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditMessage, string>;
  public static tableName: string = 'AuditMessage';

  public static CUSTOM_TAB_CONTROL_ID: string = 'customTabControlId';  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IS_DELETED: string = 'isDeleted';  public static KEY_IDENTIFIER: string = 'keyIdentifier';  public static PROGRAM_ID: string = 'programId';  public static SUMMARY: string = 'summary';  public static TIME_STAMP: string = 'timeStamp';  public static TYPE: string = 'type';

  public customTabControlId: string;  public description: string;  public id: string;  public isDeleted: boolean;  public keyIdentifier: string;  public programId: string;  public summary: string;  public timeStamp: Date;  public type: number;

  public constructor(args?: Partial<AuditMessage>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditMessage.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditMessage.tableName);
      this.table.mapToClass(AuditMessage);
  }
}
