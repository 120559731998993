import { Injectable } from '@angular/core';
import { AuditRepository } from 'src/app/core/data/repositories/auditRepository';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AuditService } from '../auditService';

@Injectable({
  providedIn: 'root',
})
export class AuditDeleteService {

  constructor(
    private auditService: AuditService,
    private auditRepository: AuditRepository,
    private synchronizationService: SynchronizationService,
  ) {  }

  public async validateAuditNumber(auditNumber: string): Promise<boolean> {
    return await this.auditService.validateAuditNumberExist(auditNumber);
  }

  public async deleteAudit(auditNumber: string): Promise<void> {
    await this.auditRepository.deleteByNumber(auditNumber);
    await this.synchronizationService.getUpdates(false)
  }
}
