// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class WFStepExecutor extends ChangeTrackingEntity {
  public static table: Dexie.Table<WFStepExecutor, string>;
  public static tableName: string = 'WFStepExecutor';

  public static CAN_ATTRIBUTE_STEP: string = 'canAttributeStep';  public static CAN_DELETE: string = 'canDelete';  public static CAN_MODIFY: string = 'canModify';  public static ID: string = 'id';  public static TIME_STAMP: string = 'timeStamp';  public static USER_GROUP_ID: string = 'userGroupId';  public static W_F_STEP_ID: string = 'wFStepId';

  public canAttributeStep: boolean;  public canDelete: boolean;  public canModify: boolean;  public id: string;  public timeStamp: Date;  public userGroupId: string;  public wFStepId: string;

  public constructor(args?: Partial<WFStepExecutor>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return WFStepExecutor.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(WFStepExecutor.tableName);
      this.table.mapToClass(WFStepExecutor);
  }
}
