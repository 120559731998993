import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from 'src/app/components/forms/BaseForm.component';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { ChangeTrackingEntity } from 'src/app/core/data/changeTracking/changeTrackingEntity';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';

@Component({
  selector: 'app-data-source-importation-result-item',
  templateUrl: './data-source-importation-result-item.component.html',
  styleUrls: ['./data-source-importation-result-item.component.scss']
})
export class DataSourceImportationResultItemComponent extends BaseFormComponent<DataSourceImportationResult> {
  constructor(
    baseRepository: BaseRepository,
    private formBuilder: UntypedFormBuilder,
    router: Router,
    translateService: TranslateService) {

    super(translateService, router, baseRepository);

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl("id"),
      idKey: new UntypedFormControl("idKey"),
      fieldTitles: formBuilder.array([])
    });

    this.table = DataSourceImportationResult.table;
  }

  @ViewChild(PopupComponent) popup: PopupComponent;

  @Input() dataSourceImportationId = "";
  @Input() visible = false;
  @Input() canClose = true;
  @Input() boxFixed = false;
  @Input() minWidth = "";

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter;

  customFieldControlTypes = CustomFieldControlType;

  private item: DataSourceImportationResult;

  private promiseResolver = (boolean) => {};

  private dataSourceImportation: DataSourceImportation;

  public async display(id: string) {
    this.id = id;

    this.createModel();

    this.popup.width = this.minWidth;

    this.popup.display();

    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  public load() {
    
  }

  private async createModel() {
    if (this.id) {
      this.item = await DataSourceImportationResult.table.get(this.id.toString());
    }
    else {
      this.item = new DataSourceImportationResult({ dataSourceImportationId: this.dataSourceImportationId, entityState: EntityState.New });
    }

    this.model = this.item;

    this.dataSourceImportation = await DataSourceImportation.table.get(this.dataSourceImportationId);

    let fieldTitles = JSON.parse(this.dataSourceImportation.fieldTitles);

    this.fieldTitles.clear();

    fieldTitles.forEach(x => {
      let controlType = x.controlType;

      if (!controlType)
        controlType = CustomFieldControlType.TextBox;

      let maxLength = x.key === this.dataSourceImportation.fieldTitlesKey ? 250: 8000;

      let fieldItem = this.formBuilder.group({
        id: x.id,
        key: x.key,
        value: x.value,
        controlType: controlType,
        data: this.item[x.key],
        maxLength: maxLength
      });

      this.fieldTitles.push(fieldItem);
    });

    this.form.controls.id.setValue(this.item.id);
    this.form.controls.idKey.setValue(this.item.idKey);
  }

  public synchronizeModel() {
    if (this.fieldTitles.length > 0) {
      for (const fieldTitle of this.fieldTitles.controls) {
        this.item[fieldTitle.get("key").value] = fieldTitle.get("data").value;

        if (fieldTitle.get("key").value == this.dataSourceImportation.fieldTitlesKey){
          this.item.idKey = fieldTitle.get("data").value;
        }
      }
    }

    if (this.id) {
      this.model.entityState = EntityState.Modified;
    }
  }

  public async validate(model: ChangeTrackingEntity, validationDictionary: string[]): Promise<string[]>{
    const idKey = this.item.idKey;

    if (!idKey){
      validationDictionary.push(this.translateService.instant("dataSource.item.edit.validations.keyRequired"));

      return validationDictionary;
    }

    if (idKey.length > 250){
      validationDictionary.push(this.translateService.instant("dataSource.item.edit.validations.keyMaximumLength", 
      { 
        key: this.dataSourceImportation.fieldTitlesKey, 
        actualSize: idKey.length 
      }));

      return validationDictionary;
    }
  
    let duplicate = await DataSourceImportationResult.table.where("dataSourceImportationId").equals(this.dataSourceImportationId).filter(x => x.idKey === idKey && x.id !== this.id).toArray();

    if (duplicate.length > 0){
      validationDictionary.push(this.translateService.instant("dataSource.item.edit.validations.duplicateKey"));

      return validationDictionary;
    }

    return validationDictionary;
  }

  public async saveButtonClick() {
    if (await super.save()){
      this.popup.visible = false;

      this.promiseResolver(true);
    }
  }

  public cancelButtonClick() {
    this.popup.visible = false;

    this.promiseResolver(false);
  }

  public close(result?: string) {
    this.popup.close();
    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);
  }

  get fieldTitles(): UntypedFormArray {
    return this.form.controls.fieldTitles as UntypedFormArray;
  }
}
