<app-popup #popup [(visible)]="visible" [canClose]="false" id="optionListPopup">

  <div class="layout-vertical" id="optionListContainer">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padd">
        <div class="layout-left">
          <button><i class="fas fa-plus"></i>
            <div><span>{{ 'optionList.title' | translate}}</span></div>
          </button>
        </div>

        <div class="layout-right">
          <button (click)="close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>

    <div class="layout-center" [formGroup]="form">
      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'optionList.section.information' | translate}}
        </div>
      </div>

      <div class="padding-normal">
        {{ 'optionList.section.information.name' | translate}}
      
        <dx-text-box class="text-box" formControlName="name" valueChangeEvent="keyup" maxLength="250" ></dx-text-box>
      </div>

      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'optionList.section.filter' | translate}}
        </div>
      </div>
      
      <div class="padding-normal">
        {{ 'optionList.section.filter.mode' | translate}}
      
        <dx-select-box formControlName="filterMode" [items]="filterModes" valueExpr="key" displayExpr="text" appMarkAsterisk></dx-select-box>
      </div>

      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'optionList.section.filter.fields' | translate}}
        </div>
      </div>

      <div class="padding-normal">
        <div *ngIf="isJavascriptFilter">
          {{ 'optionList.section.javascriptFilter' | translate}}
        
          <dx-text-box class="text-box" formControlName="filterScript" valueChangeEvent="keyup"></dx-text-box>
        </div>
      
        <div *ngIf="!isJavascriptFilter">
          <div *ngFor="let field of fields; index as i">
              <label>{{field.text}}</label>
  
              <dx-text-box class="text-box" valueChangeEvent="keyup" [formControlName]="'__' + field.key + '__'"></dx-text-box>
          </div>
        </div>
      </div>

      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'optionList.section.display' | translate}}
        </div>
      </div>
      
      <div class="padding-normal">
        {{ 'optionList.section.display.groupBy' | translate}}
      
        <dx-tag-box [items]="fields" valueExpr="key" displayExpr="text"
          [(value)]="groupByFields" (onValueChanged)="groupByValueChange($event)"
          [showSelectionControls]="true"></dx-tag-box>

          <div class="padding-normal" *ngIf="displayGroupByHtmlTemplateControl">
            {{ 'optionList.section.display.groupByHtmlTemplate' | translate}}
          
            <dx-text-area [spellcheck]="false" class="text-box" formControlName="groupByHtmlTemplate" valueChangeEvent="keyup" [height]="200" ></dx-text-area>
          </div>
      </div>
      
      <div class="padding-normal">
        {{ 'optionList.section.display.sortBy' | translate}}
      
        <dx-tag-box [items]="fields" valueExpr="key" displayExpr="key"
          [(value)]="sortByFields" (onValueChanged)="sortByValueChange($event)"
          [showSelectionControls]="true"></dx-tag-box>
      </div>

      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'optionList.section.template' | translate}}
        </div>
      </div>
  
      <div class="padding-normal">
        {{ 'optionList.section.template.mode' | translate}}
      
        <dx-select-box formControlName="templateType" [items]="templateTypes" valueExpr="key" displayExpr="text" appMarkAsterisk></dx-select-box>
      </div>
      
      <div class="padding-normal" *ngIf="isHtmlTemplate">
        {{ 'optionList.section.template.templateHtml' | translate}}
      
        <dx-text-area [spellcheck]="false" class="text-box" formControlName="htmlTemplate" valueChangeEvent="keyup" [height]="200" ></dx-text-area>
      </div>
      
      <div *ngIf="!isHtmlTemplate">
        <div class="padding-normal">
          {{ 'optionList.section.template.line1' | translate}} <span class="required-asterisk" *ngIf="lineTemplate1Fields.length === 0">*</span>
        
          <dx-tag-box [items]="fields" valueExpr="key" displayExpr="text" [(value)]="lineTemplate1Fields" id="lineTemplate1"
            (onValueChanged)="lineTemplateFieldValueChange($event)" [showSelectionControls]="true"></dx-tag-box>
        </div>
        
        <div class="padding-normal">
          {{ 'optionList.section.template.line2' | translate}}
        
          <dx-tag-box [items]="fields" valueExpr="key" displayExpr="text" [(value)]="lineTemplate2Fields" id="lineTemplate2"
            (onValueChanged)="lineTemplateFieldValueChange($event)" [showSelectionControls]="true"></dx-tag-box>
        </div>
        
        <div class="padding-normal">
          {{ 'optionList.section.template.line3' | translate}}
        
          <dx-tag-box [items]="fields" valueExpr="key" displayExpr="text" [(value)]="lineTemplate3Fields" id="lineTemplate3"
            (onValueChanged)="lineTemplateFieldValueChange($event)" [showSelectionControls]="true"></dx-tag-box>
        </div>
      </div>

      <app-entity-security-group-list #visibleByList [entityId]="this.model?.id" source="OptionListVisibleBy" [requiresToBeAdministrator]="false" title="{{ 'optionList.section.template.visibleBy' | translate}}"></app-entity-security-group-list>

      <app-entity-security-group-list #allowedEditorsList [entityId]="this.model?.id" source="OptionListAllowedEditors" title="{{ 'optionList.section.template.allowedEditors' | translate}}"></app-entity-security-group-list>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-right layout-space">
          <button type="submit" [disabled]="!form.valid || !form.dirty" class="color-set-green compact"
            (click)="saveButtonClick()">
            <div><span>{{ 'general.save' | translate}}</span></div>
          </button>
          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ cancelButtonText }}</span></div>
          </button>
        </div>
      </div>
    </div>

  </div>

  <app-modal #modalPopup></app-modal>

</app-popup>