// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DynamicTabTemplate extends ChangeTrackingEntity {
  public static table: Dexie.Table<DynamicTabTemplate, string>;
  public static tableName: string = 'DynamicTabTemplate';

  public static CUSTOM_FIELD_TAB_CONTROL_ID: string = 'customFieldTabControlId';  public static CUSTOM_TAB_CONTROL_ID: string = 'customTabControlId';  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static IS_DELETED: string = 'isDeleted';  public static KEY_IDENTIFIER: string = 'keyIdentifier';  public static NAME: string = 'name';  public static TIME_STAMP: string = 'timeStamp';

  public customFieldTabControlId: string;  public customTabControlId: string;  public description: string;  public id: string;  public isDeleted: boolean;  public keyIdentifier: string;  public name: string;  public timeStamp: Date;

  public constructor(args?: Partial<DynamicTabTemplate>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DynamicTabTemplate.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DynamicTabTemplate.tableName);
      this.table.mapToClass(DynamicTabTemplate);
  }
}
