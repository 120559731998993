import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Program } from "src/app/core/data/models/database/program.database";
import { AuditService } from "src/app/pages/audit/auditService";
import { Router } from '@angular/router';
import { CreateAuditResult } from '../../audit/createAuditResult';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import _ from 'lodash';
import { UserAccountGroup } from 'src/app/core/data/models/database/userAccountGroup.database';
import { ProgramUserGroup } from 'src/app/core/data/models/database/programUserGroup.database';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { ProgramHttpClient } from 'src/app/core/data/synchronization/programHttpClient';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AlertService } from 'src/app/core/utilities/alertService';

@Component({
  selector: 'app-new-audit',
  templateUrl: './new-audit.component.html',
  styleUrls: ['./new-audit.component.scss']
})
export class NewAuditComponent implements OnInit {
  @Input() public visible: boolean;

  @ViewChild('list') list: ListComponent;

  public selectedProgram = null;
  public createAuditPromise: Promise<CreateAuditResult> = null;

  public dataSource: Function;

  constructor(
    private programHttpClient: ProgramHttpClient,
    private auditService: AuditService,
    private router: Router,
    private authenticatedUser: AuthenticatedUser,
    private synchronizationService: SynchronizationService
  ) { }

  show() {
    this.list.selectedItems = null;
    this.selectedProgram = null;
    this.list.updateData();
    this.visible = true;
  }

  ngOnInit(): void {
    this.dataSource = async (context) => {
      let programs;

      if (!this.synchronizationService.offline) {
        programs = await this.programHttpClient.getAll();
      } else {
        programs = await Program.table.toArray();
      }

      let filteredPrograms = programs.filter(x => x.isActive && x.hasVersions
        && !x.isDeleted
        && ComparatorService.stringMatch(x.name, context.filter));

      let userAccountGroups = await (UserAccountGroup.table.where("userId").equals(this.authenticatedUser.id)).toArray();

      let programUserGroups = await (ProgramUserGroup.table.where("userGroupId").anyOf(userAccountGroups.map(x => x.groupId))).toArray();

      filteredPrograms = filteredPrograms.filter(x => programUserGroups.find(y => y.programId === x.id));

      let sortedPrograms = filteredPrograms.sort((a, b) => ComparatorService.localeCompare(a.name, b.name));

      return new ListDataSourceFunctionResult({
        itemCount: sortedPrograms.length,
        items: sortedPrograms
      });
    }
  }

  selectProgram(program) {
    this.selectedProgram = program;
  }

  async create(): Promise<void> {
    if (!this.selectedProgram || this.createAuditPromise) {
      return;
    }

    this.createAuditPromise = this.auditService.createAudit(this.selectedProgram.id);

    let result = await this.createAuditPromise;

    this.createAuditPromise = null;

    if (result && result.success) {
      await this.router.navigateByUrl(`/forms/${result.audit.number}`);
    } else {
      // TODO AC: Convert to popup
      AlertService.show(result.message);
    }
  }

  close() {
    this.selectedProgram = null;
    this.visible = false;
  }
}
