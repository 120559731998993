<app-popup #popup [(visible)]="visible" [canClose]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i [ngClass]="titleIcon"></i>
            <div><span>{{ title | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="close()"></i></button>
        </div>
      </div>
    </div>
    <div *ngIf="templateIsEmpty" class="layout-center content">
      {{ 'newDynamicTabInstance.templateIsEmptyMessage' | translate }}
    </div>
    <div *ngIf="!templateIsEmpty" class="layout-center content" [formGroup]="form">
      <label>{{ 'newDynamicTabInstance.position' | translate }}</label>
      <input type="text" formControlName="position" maxlength="10" />
      <div class="validation" *ngIf="form.controls['position'].errors?.['required']">
        {{ 'newDynamicTabInstance.validations.positionRequired' | translate }}
      </div>
      <div class="validation" *ngIf="form.controls['position'].errors?.['alreadyExisting']">
        {{ 'newDynamicTabInstance.validations.alreadyExistingInstance' | translate }}
      </div>
      <label>{{ 'newDynamicTabInstance.name' | translate }}</label>
      <input type="text" formControlName="name" maxlength="250" />
      <div class="validation" *ngIf="form.controls['name'].errors?.['required']">
        {{ 'newDynamicTabInstance.validations.nameRequired' | translate }}
      </div>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal padding-normal">
        <div class="layout-right">
          <button *ngIf="!templateIsEmpty" name="createButton" class="color-set-green" (click)="save()"
            [class.disabled]="!form.valid || task">
            <i *ngIf="task" class="fas fa-circle-notch fa-spin"></i>
            <div><span>{{ saveButtonText | translate }}</span></div>
          </button>
          <button *ngIf="templateIsEmpty" name="okButton" class="color-set-blue" (click)="close()">
            <div><span>{{ 'general.ok' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
