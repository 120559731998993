import { Injectable } from "@angular/core";
import { AuthenticationWindowsHttpClient } from "./authenticationWindowsHttpClient";
import { AuthenticationAuthorizationRouting } from "./authenticationAuthorization.routing";
import { Router } from "@angular/router";
import { IAuthenticationService } from "./iAuthenticationService.interface";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationWindowsService implements IAuthenticationService {
  public canLogOff = false;

  constructor(
    private router: Router,
    private windowsAuthenticationHttpClient: AuthenticationWindowsHttpClient,
  ) { }

  public async isAuthenticated(): Promise<boolean> {
    try {
      return await this.windowsAuthenticationHttpClient.checkAuthentication();
    } catch (e) {
      return false;
    }
  }

  public async initialize(): Promise<void> {
    return;
  }

  public async login(): Promise<boolean> {
    try {
      let success = await this.windowsAuthenticationHttpClient.checkAuthentication();

      if (success) {
        await this.router.navigate(["/home"]);
      } else {
        await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
      }

      return success;

    } catch (e) {
      return false
    }
  }

  public async logout(): Promise<void> {
    return;
  }

  public destroy(): Promise<void> {
    return;
  }
}
