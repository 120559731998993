import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxCheckBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @ViewChild("control") control: DxCheckBoxComponent;

  @Output() valueChanged = new EventEmitter<any>();

  private _threeStates: boolean = true;

  private _suspendValueChange: boolean = false;

  get threeStates(): boolean {
    return this._threeStates;
  }
  set threeStates(value: boolean) {
    this._threeStates = value;
  }

  get value(): boolean {
    return this.control.value;
  }
  set value(value: boolean) {
    this.control.value = value;
  }

  suspendValueChange() {
    this._suspendValueChange = true;
  }

  resumeValueChange() {
    this._suspendValueChange = false;
  }

  handleValueChange(e) {
    if (this._suspendValueChange)
      return;

    if (!this.threeStates) {
      this.valueChanged.emit(e.value);

      return;
    }

    if (e.component.skipOnValueChanged) {
      e.component.skipOnValueChanged = false;

      this.valueChanged.emit(e.value);

      return;
    }

    if (e.value == true && e.previousValue !== undefined) {
      e.component.skipOnValueChanged = true;
      e.component.option("value", undefined);

      this.valueChanged.emit(e.value);

      return;
    }

    this.valueChanged.emit(e.value);
  }
}
