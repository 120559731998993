import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Program } from '../models/database/program.database';

@Injectable({
  providedIn: 'root',
})
export class ProgramHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Program`;

  constructor(private http: HttpClient) { }

  async getAll(): Promise<Program[]> {
    return await firstValueFrom(this.http.get<Program[]>(this.configUrl));
  }
}