<div class="layout-vertical">
  <div class="layout-top">
    <app-header-audit (leftButtonClick)="navigateToAuditList()" [auditNumber]="auditState.auditNumber"
      [title]="auditState.auditFormattedNumber">
    </app-header-audit>
  </div>

  <div class="layout-center">
    <div *ngFor="let section of sections" [id]="'Section-' + section.id"
      [class.dynamic]="section.type === sectionTypes.DynamicTab">
      <div class="section layout-horizontal height-normal color-set-white"
        [class.active]="section.id === auditState.section.id || section.id === auditState.reference"
        [class.invalid]="section.state !== sectionStates.Valid" (click)="onSectionClick(section)">
        <div class="layout-left" *ngIf="section.type !== sectionTypes.DynamicTab">
          <button>
            <i class="fas"></i>
          </button>
        </div>
        <div class="layout-left" *ngIf="section.type === sectionTypes.DynamicTab">
          <button>
            <i *ngIf="!section.instancesVisible" class="fas fa-chevron-right"></i>
            <i *ngIf="section.instancesVisible" class="fas fa-chevron-down"></i>
          </button>
        </div>
        <div class="layout-center align-left padding-right-normal font-bold">
          <span [class.textOnly]="section.type !== sectionTypes.DynamicTab">{{section.description}}</span>
        </div>
      </div>

      <ng-container *ngIf="section.instancesVisible">
        <div *ngFor="let folder of section.getFolders()" class="indent">
          <div class="layout-horizontal height-normal color-set-white section"
            [class.invalid]="folder.state !== sectionStates.Valid">
            <div class="layout-left" (click)="onDynamicFolderClick(folder)">
              <button>
                <i *ngIf="!folder.instancesVisible && folder.templateId" class="fas fa-chevron-right"></i>
                <i *ngIf="folder.instancesVisible && folder.templateId" class="fas fa-chevron-down"></i>
                <div><span>{{folder.description}}</span></div>
              </button>
            </div>
          </div>

          <div *ngIf="folder.instancesVisible" class="indent">
            <div class="layout-horizontal color-set-white height-normal padding-right-normal section font-small"
              (click)="onDynamicInstanceClick(instance)" *ngFor="let instance of folder.instances"
              [class.invalid]="instance.state !== sectionStates.Valid">

              <div class="layout-left">
                <button>
                  <i class="fas"></i>
                </button>
              </div>
              <div class="layout-center align-left">
                {{ instance.number + ' - ' + instance.description }}
              </div>
            </div>

            <div class="layout-horizontal color-set-white height-normal padding-right-normal section font-small"
              (click)="onAddDynamicTabInstance(folder)">
              <div class="layout-left">
                <button>
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="layout-center align-left">
                {{ 'audit-sections.addSection' | translate }}
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-new-dynamic-tab-instance #dynamicTabInstanceView></app-new-dynamic-tab-instance>
