import { Input, OnDestroy } from '@angular/core';
import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';

@Directive({
  selector: '[appMarkAsterisk]'
})
export class MarkAsteriskDirective implements OnInit, OnDestroy {

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private translateService: TranslateService) { }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  private  valueChangesSubscription: Subscription;

  @Input()
  public translate: string;

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  fieldControlName: string;

  @Input()
  description: string;

  private control: AbstractControl;

  async ngOnInit() : Promise<void> {
    if (this.fieldControlName){
      this.control =this.formGroup.controls[this.fieldControlName];

      this.elementRef.nativeElement.innerHTML = await this.getText();
  
      setTimeout(() => this.evaluateInternal(), 100);
  
      this.valueChangesSubscription = this.control.valueChanges.subscribe(val => {
        this.evaluateInternal();
      });
    }
  }

  private async getText() : Promise<string> {
    if (this.translate){
      return firstValueFrom(this.translateService.get(this.translate));
    }
    else{
      return new Promise<string>((resolve, reject) => { resolve(this.description)});
    }
  }

  public async evaluate(control: AbstractControl){
    const isRequired = control.hasValidator(Validators.required) || control.hasValidator(Validators.requiredTrue);

    let requiredMarker: string = "";

    if (isRequired && control.invalid) {
      requiredMarker = '<span class="required-asterisk">*</span>';
    }

    this.elementRef.nativeElement.innerHTML = await this.getText() + requiredMarker;
  }

  private async evaluateInternal(){
    this.evaluate(this.control);
  }
}
