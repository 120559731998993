import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export class FormSynchronizer{
	public constructor(args?: Partial<FormSynchronizer>) {
		Object.assign(this, args);
	}

	form: UntypedFormGroup;

	setValuesToControls(model: object){
		Object.keys(this.form.controls).forEach(key => {
			var control = this.form.controls[key]

			if (!(control instanceof UntypedFormArray))
				control.setValue(model[key]);
		});
	}

	setValuesToModel(model: object, excludedColumns?: string[]){
		Object.keys(this.form.controls).forEach(key => {
			var control = this.form.controls[key]

			if (!(control instanceof UntypedFormArray) && (!excludedColumns || !excludedColumns.includes(key)))
				model[key] = control.value;
		});
	}
}