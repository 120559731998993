import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { DocumentHttpClient } from 'src/app/core/services/documents/documentHttpClient';
import { AuditState } from '../auditState';
import { DocumentFormatTypeComponent } from './document-format-type/document-format-type.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { Document } from 'src/app/core/data/models/form/document';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-audit-documents',
  templateUrl: './audit-documents.component.html',
  styleUrls: ['./audit-documents.component.scss']
})
export class AuditDocumentsComponent implements OnInit {
  navigator: Navigator;

  @ViewChild(DocumentFormatTypeComponent) documentFormatTypePopup: DocumentFormatTypeComponent;
  @ViewChild(SimplePopupComponent) popUpConfirmation: SimplePopupComponent;

  constructor(
    public synchronizationService: SynchronizationService,
    private readonly auditState: AuditState,
    private readonly documentHttpClient: DocumentHttpClient,
    private readonly authenticatedUser: AuthenticatedUser,
    private readonly translateService: TranslateService
  ) { }

  documents: Document[];

  ngOnInit(): void {
    this.documents = this.getDocuments();
  }

  private getDocuments(): Document[] {
    return this.auditState.form.documents;
  }

  async sendEmail(documentId: string) {
    if (await this.documentFormatTypePopup.display(documentId)) {
      this.documentHttpClient.sendEmail(this.auditState.audit.id, documentId, this.authenticatedUser.id, this.documentFormatTypePopup.selectedType);

      PopupUtility.displayInformation(
        this.popUpConfirmation,
        this.translateService,
        this.translateService.instant("audit.sendDocumentByEmailConfirmationTitle"),
        this.translateService.instant("audit.sendDocumentByEmailConfirmation")
      );

    }
  }

  async download(documentId: string) {
    if (await this.documentFormatTypePopup.display(documentId)) {
      let file = await this.documentHttpClient.download(this.auditState.audit.id, documentId, this.documentFormatTypePopup.selectedType);

      // Filename comming from HTTP header which only support
      // ASCII characters, accents are not supported. For this reason
      // we need to decode the filename which was encoded in base64 on the server
      let filename = new TextDecoder().decode(this.base64ToArrayBuffer(file.filename));
      saveAs(file.data, filename);
    }
  }

  identify(index, item) {
    return item.id;
  }

  private base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
