import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { DangerPopupButton, SimplePopupButton } from 'src/app/components/popup/simplePopup/simplePopupButton';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportationResultItemComponent } from '../data-source-importation-result-item/data-source-importation-result-item.component';
import { DataSourceImportationResultViewModel } from './dataSourceImportationResultViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { ComparatorService } from 'src/app/core/services/comparator.service';

@Component({
  selector: 'app-data-source-importation-result',
  templateUrl: './data-source-importation-result.component.html',
  styleUrls: ['./data-source-importation-result.component.scss']
})
export class DataSourceImportationResultComponent implements OnInit {
  @Input() public id: string;

  public dataSource: Function;
  public selectedDataSource;
  itemCount: number = 0;

  @Input() public allowEdit: boolean;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  @ViewChild(DataSourceImportationResultItemComponent) resultItemPopup: DataSourceImportationResultItemComponent;

  @ViewChild('list') list: ListComponent;

  @Output()
  dataSourceChanged = new EventEmitter<number>();

  constructor(
    private readonly baseRepository: BaseRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService) { }

  updateData(){
    this.list.updateData();
  }

  matchDataSourceItem(item: DataSourceImportationResult, searchTerm) {
    const excludedProperties: string[] = ["dataSourceImportationId", "id"];

    for (const key in item) {
      if (item.hasOwnProperty(key) && !excludedProperties.find(x => x === key)) {
        const value = item[key];

        if (typeof value === 'string') {
          if (ComparatorService.stringMatch(value, searchTerm))
            return true;
        } else if (typeof value === 'number') {
          if (value === searchTerm)
            return true;
        }
      }
    }

    return false;
  }

  async ngOnInit(): Promise<void> {
    this.dataSource = async (args): Promise<ListDataSourceFunctionResult> => {
      let dataSources = [];
      let fieldTitles;
      let visibleProperties;

      if (this.id) {
        dataSources = await DataSourceImportationResult.table.where("dataSourceImportationId").equals(this.id).toArray();

        this.dataSourceChanged.emit(dataSources.length);

        if (args.filter)
          dataSources = dataSources.filter(x => this.matchDataSourceItem(x, args.filter));

        let dataSourceImportation = await DataSourceImportation.table.get(this.id);

        if (dataSourceImportation) {
          fieldTitles = JSON.parse(dataSourceImportation.fieldTitles);

          visibleProperties = _.map(fieldTitles.filter(x => x.visible), x => {
            return {key: x.key, description: x.value || x.key};
          });
        }
      }

      let viewModel = _.map(dataSources, x => {
        var description = "";

        for (const visibleProperty of visibleProperties) {
          if (description)
            description += "<br>";

          description += visibleProperty.description + ": " + (x[visibleProperty.key] || "");
        }

        return new DataSourceImportationResultViewModel({ id: x.id, description: description, selected: false });
      });

      this.itemCount = viewModel.length;

      return new ListDataSourceFunctionResult({
        items: viewModel,
        itemCount: viewModel.length
      })
    };
  }

  async addClick() {
    if (this.allowEdit) {
      await this.resultItemPopup.display(null);

      this.list.updateData();
    }
  }

  navigateToDatasource(item: DataSourceImportationResultViewModel) {
    if (this.allowEdit)
      this.resultItemPopup.display(item.id);
  }

  selectedItemsChange(dataSource) {
    this.selectedDataSource = dataSource;
  }

  async resultItemClick(item: DataSourceImportationResultViewModel) {
    if (this.allowEdit) {
      await this.resultItemPopup.display(item.id);

      this.list.updateData();
    }
  }

  async deleteClick() {
    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService, "Suppression", "Êtes-vous certain que vous voulez supprimer cet enregistrement?",
      async () => {
        var dataSource = await this.baseRepository.get<DataSourceImportationResult>(DataSourceImportationResult.table, this.selectedDataSource.id);

        await this.baseRepository.delete(DataSourceImportationResult.table, dataSource)

        this.selectedDataSource.length = 0;

        this.list.updateData();
      });
  }
}
