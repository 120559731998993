import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { AuditState } from 'src/app/pages/audit/auditState';
import { ButtonDataSourceSelectedEventArgs } from 'src/app/pages/audit/buttonDataSourceSelectedEventArgs';
import { DataImportationComponent } from 'src/app/pages/data-importation/data-importation.component';
import { DataSourceSelectorComponent } from 'src/app/pages/data-source/data-source-selector/data-source-selector.component';
import { BaseControlComponent } from '../basecontrol/basecontrol.component';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  selector: 'app-custom-field-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class CustomFieldButtonComponent extends BaseControlComponent implements OnInit {

  constructor(
    private readonly auditState: AuditState,
    private translateService: TranslateService,
    private synchronizationService: SynchronizationService
  ) {
    super();
  }

  @ViewChild(DataSourceSelectorComponent) dataSourceSelector: DataSourceSelectorComponent;
  @ViewChild(DataImportationComponent) dataTemplateSelector: DataImportationComponent;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  public text: string;

  public dataSourceImportationId: string;

  public controlType: ButtonControlType;

  controlTypes = ButtonControlType;

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.text = this.input.description;

    this.dataSourceImportationId = super.getExtendedProperty("DataSourceImportationId") as string;

    this.controlType = super.getExtendedProperty("ButtonType");
  }

  async click() {
    switch (this.controlType) {
      case ButtonControlType.Documentation:
        if (await this.dataSourceSelector.display()) {
          this.auditState.raiseButtonDataSourceSelected(new ButtonDataSourceSelectedEventArgs({ name: this.input.dataColumnName, dataSourceResultItemId: this.dataSourceSelector.selectedItem.id }));
        }

        break;
      case ButtonControlType.ProgramDataTemplate:

        try {
          if (await this.dataTemplateSelector.display(this.auditState.program.id, this.auditState.audit.id)) {
            PopupUtility.displayInformation(
              this.modalPopup, this.translateService,
              this.translateService.instant("button.dataImportationTitle"), this.translateService.instant("button.dataImportationMessage"),
              "fa-info",
              () => {
                document.location.reload();
              });
          }
        } catch (exception) {
          PopupUtility.displayInformation(
            this.modalPopup, this.translateService,
            this.translateService.instant("button.dataImportationTitle"), this.translateService.instant("button.dataImportationMessageFailed") + exception,
            "fa-info")
        }

        break;
      default:
        break;
    }
  }

}

enum ButtonControlType {
  Documentation = 0,
  ProgramDataTemplate = 1,
  Photos = 2
}