import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { environment } from "src/environments/environment";
import { AuthenticatedUser } from "../../security/authenticatedUser";
import { UserService } from "../../services/userService";
import { ValidationDictionary } from "../../validationDictionary";
import { UserAccount } from "../models/database/userAccount.database";
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { FeedMobileCommand } from "./feedMobileCommand";
import { InitializeMobileCommand } from "./initializeMobileCommand";
import { SynchronizationService } from "./synchronizationService";
import { InitializeOfflineModeCommand } from "./initializeOfflineModeCommand";

export const CHANGE_TRACKING_NUMBER_PARAMETER_NAME = "ChangeTrackingNumber";

@Injectable({
  providedIn: 'root',
})
export class SynchronizationMiddleware {
  constructor(
    private synchronizationService: SynchronizationService,
    private initializeMobileCommand: InitializeMobileCommand,
    private initializeOfflineModeCommand: InitializeOfflineModeCommand,
    private feedMobileCommand: FeedMobileCommand,
    private authenticatedUser: AuthenticatedUser,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  public async run(): Promise<ValidationDictionary> {
    let validationDictionary: ValidationDictionary;

    await this.synchronizationService.updateOfflineStatus();

    validationDictionary = await this.initializeOfflineModeCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;

    validationDictionary = await this.initializeMobileCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;

    validationDictionary = await this.feedMobileCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;

    let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);
    let userAccount = await UserAccount.table.get(userIdParameter.value);

    for (let key in userAccount) {
      this.authenticatedUser[key] = userAccount[key];
    }

    this.authenticatedUser.initials = this.userService.getInitials(userAccount.name);

    if (this.authenticatedUser["language"]) {
      moment.locale(this.authenticatedUser["language"]);
    }
    else {
      this.setLanguage(environment.language);
    }

    return validationDictionary;
  }

  private setLanguage(language) {
    this.translate.setDefaultLang(language);
    moment.locale(language);
  }
}