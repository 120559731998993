import { environment } from "src/environments/environment";
import { AuthenticationType } from "./authenticationType";
import { AuthenticationWindowsService } from "./authenticationWindows.service";
import { AuthenticationAzureAdService } from "./authenticationAzureAd.service";
import { IAuthenticationService } from "./iAuthenticationService.interface";
import { Injectable } from "@angular/core";
import { Logger } from "../core/log/logger";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationServiceFactory {
  constructor(
    private authenticationWindowsService: AuthenticationWindowsService,
    private authenticationAzureAdService: AuthenticationAzureAdService,
    private logger: Logger
  ) { }

  get(): IAuthenticationService {
    if (environment.authentication.authenticationType === AuthenticationType.Windows) {
      return this.authenticationWindowsService;
    } else if (environment.authentication.authenticationType === AuthenticationType.AzureAd) {
      return this.authenticationAzureAdService;
    } else if (environment.authentication.authenticationType === AuthenticationType.None) {
      // Windows authentication service is re-used because it is simple enough to behave
      // correctly for the application without authentication. It will call the /Login
      // page which will return correctly if the server ignore authentication correctly.
      return this.authenticationWindowsService;
    } else {
      throw `Configuration file config.json is not configured properly for property 'authentication.authenticationType'. Please choose between ${AuthenticationType.Windows} or ${AuthenticationType.AzureAd} `
    }
  }
}