import { Component, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerComponent)
  }]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor  {

  // ControlValueAccessor
  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {

  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }

  private _inputMask: string;

  @Input()
  get inputMask(){
    return this._inputMask;
  }
  set inputMask(value: string){
    this._inputMask = value;

    this.updateType();
  }

  @Input() readOnly: boolean = false;
  @Input() showDropDownButton: boolean = true;
  @Input() id: string;
  @Input() formControlName: string;

  private _form: FormGroup;

  public get form(): FormGroup{
    return this._form;
  }
  @Input()
  public set form(value: FormGroup){
    this._form = value;
  }

  type: string = "date";
  displayFormat: string;

  private getType(inputMask: string){
    switch (inputMask) {
      case "{date}":
      case "dd-MM-yyyy":
        return "date";
      case "{date} {time}":
      case "dd-MM-yyyy HH:mm":
        return "datetime";
      case "{time}":
      case "HH:mm":
        return "time";
      default:
        return "date";
    }
  }

  ngOnInit(): void {
    this.updateType();
  }

  private updateType(){
    this.type = this.getType(this.inputMask);
  }
}
