<div class="layout-horizontal color-set-white" *ngIf="input.alertControlCondition" (click)="navigateToAlertForm()"
  [class]="{'validSection' : state === sectionStates.Valid, 'invalidSection' : state !== sectionStates.Valid}" [id]="'AlertButton-' + input.key">
  <div class="layout-left">
    <button type="button">
      <i class="fa fa-exclamation-triangle" [style.color]="input.alertControlCondition.color"></i>
    </button>
  </div>
  <div class="layout-center align-left">
    <div class="layout-vertical">
      <div class="layout-top font-bold">{{input.alertControlCondition.name}}</div>
      <div class="layout-top">{{input.alertControlCondition.description}}</div>
      <div class="layout-top font-smallest font-red" *ngIf="input.alertTemplate && state !== sectionStates.Valid">
        {{'alerts.alertTemplateButtonMessage' | translate}}
      </div>
    </div>
  </div>
  <div class="layout-right" *ngIf="input.alertTemplate">
    <button type="button" [class.pulse]="state !== sectionStates.Valid"><i class="bi bi-chevron-right"
        [class.font-red]="state !== sectionStates.Valid"></i></button>
  </div>
</div>
