import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditState } from 'src/app/pages/audit/auditState';
import { SectionState } from 'src/app/pages/audit/audit-sections/sectionState';
import { AuditSectionState } from 'src/app/core/data/viewModels/auditSectionState';
import { FormField } from 'src/app/core/data/models/formField';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-template',
  templateUrl: './alert-template.component.html',
  styleUrls: ['./alert-template.component.scss']
})
export class AlertTemplateComponent implements OnInit {
  @Input() input: FormField<any>;

  public state: SectionState = SectionState.Valid;
  public sectionStates = SectionState;

  constructor(
    private router: Router,
    private auditState: AuditState
  ) { }

  async ngOnInit(): Promise<void> {
    await this.updateState();
  }

  public async updateState(){
    if (this.auditState.sectionStates) {
      if (this.input.alertTemplate){
        let sectionState = this.auditState.sectionStates.find(x =>
          x.customTableId === this.input.alertTemplate.customTableId);

        this.state = AuditSectionState.getState(sectionState?.state);
      }
      else{
        this.state = SectionState.Valid;
      }
    }
  }

  public async navigateToAlertForm(): Promise<void> {
    if (!this.input.alertTemplate) {
      return;
    }

    this.auditState.parentInstanceId = this.auditState.instanceId;

    await this.router.navigate([`/forms/${this.auditState.auditNumber}/alerts/${this.input.alertTemplate.id}`]);
  }
}
