export class InputMaskFormatter{
  public static format(inputMask: string, value: string) : string {
  let result: string = "";

    let position: number = 0;

    for (const inputMaskCharacter of inputMask) {
      switch (inputMaskCharacter) {
        case '0':
        case '9':
        case 'L':
        case 'l':
        case 'C':
        case 'c':
        case 'A':
        case 'a':
          result += value.substring(position, position + 1);

          position += 1;

          break;

        default:
          result += inputMask.substring(position, position + 1);

          break;
      }
    }

    return result;
  }
}
