import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from '../../popup/popup.component';
import { DatabaseContext } from 'src/app/core/data/databaseContext';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  selector: 'app-synchronization-database-reset-popup',
  templateUrl: './synchronization-database-reset-popup.component.html',
  styleUrls: ['./synchronization-database-reset-popup.component.scss']
})
export class SynchronizationDatabaseResetPopupComponent {

  @ViewChild("databaseResetPopup") databaseResetPopup: PopupComponent;

  constructor(
    public synchronizationService: SynchronizationService,
    public databaseContext: DatabaseContext,
  ) { }
}
