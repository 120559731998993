import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class UserAudit extends ChangeTrackingEntity {
  public static table: Dexie.Table<UserAudit, string>;
  public static tableName: string = 'UserAudit';
  public static serverToMobile: boolean = false

  public id: string;
  public number: string;
  public externalNumber: string;
  public createdDate: string;
  public updatedDate: string;
  public effectiveDate: string;
  public stepId: string;
  public stepName: string;
  public programId: string;
  public programDescription: string;
  public createdById: string;
  public createdByName: string;
  public responsibleId: string;
  public responsibleName: string;
  public synchronized:boolean;

  public constructor(args?: Partial<UserAudit>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() { 
    return UserAudit.tableName;
  }

  public getDatabasePrimaryKeyType()  { 
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(UserAudit.tableName);
    this.table.mapToClass(UserAudit);
  }
}
