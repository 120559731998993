export class SearchOperator{
  public constructor(args?: Partial<SearchOperator>) {
    Object.assign(this, args);
  }

  key: string;

  static getTokens(searchOperator: SearchOperator): string[] {
    return searchOperator.key.split(".");
  }
}
