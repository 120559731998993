// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditAlertSummary extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditAlertSummary, string>;
  public static tableName: string = 'AuditAlertSummary';

  public static ALERT_CONDITION_DETAIL_ID: string = 'alertConditionDetailId';  public static AUDIT_ID: string = 'auditId';  public static CONTROL_DESCRIPTION: string = 'controlDescription';  public static CONTROL_KEY: string = 'controlKey';  public static CREATED_DATE: string = 'createdDate';  public static ID: string = 'id';  public static SOURCE: string = 'source';  public static TIME_STAMP: string = 'timeStamp';  public static TREATED: string = 'treated';  public static USER_ACCOUNT_ID: string = 'userAccountId';  public static VALUE: string = 'value';

  public alertConditionDetailId: string;  public auditId: string;  public controlDescription: string;  public controlKey: string;  public createdDate: Date;  public id: string;  public source: string;  public timeStamp: Date;  public treated: boolean;  public userAccountId: string;  public value: string;

  public constructor(args?: Partial<AuditAlertSummary>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditAlertSummary.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditAlertSummary.tableName);
      this.table.mapToClass(AuditAlertSummary);
  }
}
