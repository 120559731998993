<app-popup #popup>
  <div class="layout-vertical">

    <div class="layout-horizontal padding-left height-normal color-set-lightblue">
      <div class="layout-left">
        <h1>{{ 'dataImportationSelector.title' | translate}}</h1>
      </div>
    </div>

    <div class="layout-center">

      <div class="layout-horizontal templateSections" id="templateSectionContainer">
        <div class="layout-center align-top templateSections">

          <div class="zone field-zone">
            <label class="field">{{ 'dataImportationSelector.template' | translate}}</label>
          </div>

          <app-list #dataTemplateList id="dataTemplateList"
          [dataSource]="templateDataSource"
          (selectedItemsChange)="programDataTemplateClick($event)"
            [numberOfRequiredItems]="1"
            [selectionEnabled]="true"
            [showCheckBoxes]="false"
            [showInstructions]="false"
            [enableFilter]="false"
            [paging]="false"
            [loadOnDemand]="true">
            <ng-template #listTemplate let-item>
              <div class="layout-vertical">
                <div class="layout-center">
                  <div class="layout-horizontal defaultPadding">
                    <div class="layout-left">
                      <div>
                        {{item.name}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-list>

        </div>


        <div class="layout-center align-top templateSections">
          <div class="zone field-zone">
            <label class="field">{{ 'dataImportationSelector.search' | translate}}</label>
          </div>

          <app-list #dataSearchList id="dataSearchList"
            [(items)]="searchDataSource"
            (selectedItemsChange)="dataSearchClick($event)"
            [numberOfRequiredItems]="1"
            [selectionEnabled]="true"
            [showCheckBoxes]="false"
            [showInstructions]="false"
            [enableFilter]="false"
            [loadOnDemand]="true">
            <ng-template #listTemplate let-item>
              <div class="layout-vertical">
                <div class="layout-center">
                  <div class="layout-horizontal defaultPadding">
                    <div class="layout-left">
                      <div [innerHTML]="item.getInfo()">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-list>
        </div>
      </div>

    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">

        <div class="layout-right space">
          <button type="submit" class="color-set-green compact"
            (click)="okButtonClick()" [disabled]="!canImportDataSource">
            <div><span>{{ 'dataImportationSelector.apply' | translate}}</span></div>
          </button>

          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ 'dataImportationSelector.cancel' | translate}}</span></div>
          </button>
        </div>

      </div>
    </div>
  </div>
</app-popup>

<app-progression-popup
  [visible]="importationInProgress"
  title="{{ 'button.dataImportationTitle' | translate}}"
  message="{{ 'dataImportationSelector.progression.message' | translate}}"></app-progression-popup>
