import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationServiceFactory } from '../iAuthenticationService.factory';
import { IAuthenticationService } from '../iAuthenticationService.interface';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements AfterViewInit {
  public authenticationService: IAuthenticationService;

  constructor(
    public authenticationServiceFactory: AuthenticationServiceFactory
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.authenticationService.logout();
    }, environment.authentication.logoutDelay || 3000);
  }
}
