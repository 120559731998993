import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntitySecurityGroup } from 'src/app/core/data/models/database/entitySecurityGroup.database';
import { UserGroup } from 'src/app/core/data/models/database/userGroup.database';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { EntitySecurityGroupComponent } from '../entity-security-group/entity-security-group.component';
import { EntitySecurityGroupPermissionRepository } from '../entity-security-group/entitySecurityGroupPermissionRepository';

@Component({
  selector: 'app-entity-security-group-list',
  templateUrl: './entity-security-group-list.component.html',
  styleUrls: ['./entity-security-group-list.component.scss']
})
export class EntitySecurityGroupListComponent {
  private _entityId: string;

  @Input() source: string;
  @Input() title: string;
  @Input() requiresToBeAdministrator: boolean = true;

  @Input() disabled: boolean;

  @Input() set entityId(value: string) {
    this._entityId = value;

    if (value)
      this.securityGroupList.updateData();
  }
  get entityId(): string {
    return this._entityId;
  }

  @ViewChild(EntitySecurityGroupComponent) entitySecurityGroupPopup: EntitySecurityGroupComponent;
  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;
  @ViewChild(ListComponent) securityGroupList: ListComponent;

  public securityGroupDataSource: Function;
  public selectedSecurityGroup: any;

  public hasDataSourceItems: Boolean = false;

  constructor(
    private baseRepository: BaseRepository,
    private translateService: TranslateService,
    private entitySecurityGroupPermissionRepository: EntitySecurityGroupPermissionRepository,
    private authenticatedUser: AuthenticatedUser) {

    this.securityGroupDataSource = async (context) => {
      let userGroupsToDisplay = [];

      if (this.entityId) {
        let userGroups = await UserGroup.table.toArray()

        let securityGroups = await EntitySecurityGroup.table.where("entityId").equals(this.entityId).toArray();

        securityGroups = securityGroups.filter(x => x.source === this.source);

        for (const securityGroup of securityGroups) {
          let userGroup = userGroups.find(x => x.id == securityGroup.userGroupId);

          userGroupsToDisplay.push({ id: securityGroup.id, text: userGroup.name });
        }

        userGroupsToDisplay = userGroupsToDisplay.sort(x => x.text);
      }

      this.hasDataSourceItems = userGroupsToDisplay.length > 0;

      return new ListDataSourceFunctionResult({
        itemCount: userGroupsToDisplay.length,
        items: userGroupsToDisplay
      });
    }
  }

  public refresh(){
    this.securityGroupList.updateData();
  }

  private async addValidateCallBack(): Promise<boolean>{
    let securityGroups = await EntitySecurityGroup.table.where("entityId").equals(this.entityId).toArray();

    securityGroups = securityGroups.filter(x => x.source === this.source);

    let continueProcess: boolean = false;

    if (securityGroups.length == 0){
      if (this.requiresToBeAdministrator && !(await this.entitySecurityGroupPermissionRepository.inInGroup(this.authenticatedUser.id, this.entitySecurityGroupPopup.selectedItem.id))){
        PopupUtility.displayInformation(this.modalPopup, this.translateService,
          this.translateService.instant("entitySecurityGroup.actions.addGroup.title"),
          this.translateService.instant("entitySecurityGroup.actions.addGroup.description"));
      }
      else {
        continueProcess = true;
      }
    }
    else {
      continueProcess = true;
    }

    return continueProcess;
  }

  public async addSecurityGroup() {
    if (await this.entitySecurityGroupPopup.show(this.entityId.toString(), () => { return this.addValidateCallBack(); })) {
      let newGroup = new EntitySecurityGroup();

      newGroup.entityId = this.entityId.toString();
      newGroup.userGroupId = this.entitySecurityGroupPopup.selectedItem.id;
      newGroup.source = this.source;

      await this.baseRepository.insert(EntitySecurityGroup.table, newGroup);

      this.entitySecurityGroupPopup.selectedItem = null;

      this.securityGroupList.updateData();
    }
  }

  public selectSecurityGroup(item) {
    this.selectedSecurityGroup = item;
  }

  public async removeSecurityGroup(){
    if (this.requiresToBeAdministrator && !await(this.entitySecurityGroupPermissionRepository.hasAccess(this.entityId, this.authenticatedUser.id, this.source, this.selectedSecurityGroup.id))){
      PopupUtility.displayInformation(
        this.modalPopup, this.translateService,
        this.translateService.instant("entitySecurityGroup.validations.cannotDeleteGroup.title"),
        this.translateService.instant("entitySecurityGroup.validations.cannotDeleteGroup.description"));
    }
    else {
      PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService,
        this.translateService.instant("entitySecurityGroup.actions.deleteGroup.title"),
        this.translateService.instant("entitySecurityGroup.actions.deleteGroup.description"),
        async () => {
          await this.baseRepository.deleteById(EntitySecurityGroup.table, this.selectedSecurityGroup.id);

          this.securityGroupList.updateData();

          this.selectedSecurityGroup = null;
        });
    }
  }
}
