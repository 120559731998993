import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { AdditionalOptionButton } from './additionalOptionButton';
import { PermissionService } from 'src/app/core/services/permissionService';
import { PermissionType } from 'src/app/core/data/enums/permissionType';

@Component({
  selector: 'app-additional-options',
  templateUrl: './additional-options.component.html',
  styleUrls: ['./additional-options.component.scss']
})
export class AdditionalOptionsComponent implements OnInit {

  @Input() auditNumber: string;
  @ViewChild('additionalOptions') additionalOptionsPopup: PopupComponent;
  @ViewChild('deleteConfirmation') deleteConfirmationPopup: PopupComponent;

  public buttons: Array<AdditionalOptionButton>;

  private duplicateActionIndex: number = 0;
  private deleteIndex: number = 1;

  constructor(
    public router: Router,
    private permissionService: PermissionService
    ) { }

  public async ngOnInit(): Promise<void> {
    this.buttons =
    [
      {
        text: "audit.duplicate",
        textClass: "",
        icon:"fa fa-clone",
        action: this.navigateToAuditDuplicate.bind(this),
        disabled: false
      },
      {
        text: "audit.delete",
        textClass: "color-set-red",
        icon:"fa-solid fa-trash color-set-red",
        action:this.openDeleteConfirmationPopup.bind(this),
        disabled: false
      },
    ];

    this.setButtonDisabledStatus();
  }

  public openAdditionalOptionsPopup(): void {
    this.additionalOptionsPopup.display();
  }

  public closeAdditionalOptionsPopup(): void {
    this.additionalOptionsPopup.close();
  }

  public openDeleteConfirmationPopup(): void {
    this.deleteConfirmationPopup.display();
  }

  public closeDeleteConfirmationPopup(): void {
    this.deleteConfirmationPopup.close();
  }

  public navigateToAuditDuplicate(): void {
    this.router.navigate([`/forms/${this.auditNumber}/duplicate`]);
  }

  public navigateToAuditDelete(): void {
    this.router.navigate([`/forms/${this.auditNumber}/delete`]);
  }

  /**
  * Indicate whether there is at least one option available based on current user permissions.
  */
  public hasAccessToAtLeastOneOption: boolean;

  private async setButtonDisabledStatus(): Promise<void> {
    let isDuplicateOptionDisabled = !await this.permissionService.hasAccess(PermissionType.FormAdd);

    this.buttons[this.duplicateActionIndex].disabled = isDuplicateOptionDisabled;

    let isDeleteDisabled = !await this.permissionService.hasAccess(PermissionType.FormDelete);

    if (isDeleteDisabled) {
      this.buttons[this.deleteIndex].disabled = isDeleteDisabled
      this.buttons[this.deleteIndex].textClass = "";
      this.buttons[this.deleteIndex].icon = "fa-solid fa-trash";
    }

    this.hasAccessToAtLeastOneOption = !!this.buttons.find(x => !x.disabled);
  }
}
