import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class BaseListDetailsState {
  public path = '';

  constructor(private router: Router) { }

  dataSource: Array<any>;
  currentItem: any;

  isNewItem: Boolean;

  init(dataSource: Array<any>) {
    this.dataSource = dataSource;

    if (this.dataSource.length > 0) {
      this.currentItem = this.dataSource[0];
    }
  }

  attachRoute(route: ActivatedRoute): Promise<void> {
    return new Promise(resolve => {
      route.params.subscribe((x) => {
        if (x.id === 'new') {
          this.currentItem = new Object;
          this.isNewItem = true;
        } else if (!Number.isNaN(x.id) && this.dataSource.find((item) => item.id == x.id) != null) {
          this.setCurrentItem(x.id);
          this.isNewItem = false;
        } else {
          this.isNewItem = false;
          this.router.navigate([`${this.path}`]);
        }

        resolve();
      });
    })
  }

  setCurrentPath(path: string) {
    this.path = path;
  }

  setCurrentItem(id: string) {
    this.currentItem = this.dataSource.find((x) => x.id == id);
  }

  onListItemClick(e: Event) {
    let item = e['itemData'];

    this.setCurrentItem(item.id);
    if (this.currentItem != null) {
      this.router.navigate([`${this.path}/${item.id}`]);
    }
  }

  goToNew() {
    this.router.navigate([`${this.path}/new`]);
  }

  // Redirige l'utilisateur vers un autre item si la liste n'est pas vide après la suppression.
  // Sinon redirige vers /new.
  redirectAfterDeletion() {
    if (this.dataSource != null) {
      let newCustomFieldId;
      let index = this.dataSource.indexOf(this.currentItem);

      if (this.dataSource.length > 1) {
        if (index > 0) {
          newCustomFieldId = this.dataSource[index - 1].id;
        } else {
          newCustomFieldId = this.dataSource[index + 1].id;
        }

        this.dataSource.splice(index, 1);
        this.setCurrentItem(newCustomFieldId);
        this.router.navigate([`${this.path}/${newCustomFieldId}`]);
      } else {
        this.dataSource.splice(index, 1);
        this.router.navigate([`${this.path}/new`]);
      }
    } else {
      this.router.navigate([`${this.path}/new`]);
    }
  }
}
