import { NumericComparisonType } from "./numericComparisonType";
import { SearchOperator } from "./searchOperator";

export class NumericSearchOperator extends SearchOperator{
  comparisonType: NumericComparisonType
  value: number;

  public static getComparisonTypeDescription(comparisonType: NumericComparisonType){
    switch (comparisonType) {
      case NumericComparisonType.equal:
        return "Égal à";
      case NumericComparisonType.greaterOrEqualThan:
        return "Plus grand ou égal à";
      case NumericComparisonType.greaterThan:
        return "Plus grand que";
      case NumericComparisonType.lowerOrEqualThan:
        return "Plus petit ou égal à";
      case NumericComparisonType.lowerThan:
        return "Plus petit que";
      case NumericComparisonType.notEqual:
        return "Pas égal à";
      default:
        return "Égal à";
    }
  }
}
