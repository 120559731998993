import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SynchronizationRangeItem } from '../models/database/synchronizationRangeItem.database';
import { ChangeTracking } from '../models/databaseLocal/changeTracking.database';
import { Synchronization } from '../models/database/synchronization.database';
import { firstValueFrom } from 'rxjs';
import { TableColumnNameReference } from './tableColumnNameReference';
import { SynchronizationRange } from '../models/database/synchronizationRange.database';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Synchronization`;

  constructor(private http: HttpClient) { }

  async pingSynchronizationServer(): Promise<boolean> {
    return await firstValueFrom(this.http.get<boolean>(`${this.configUrl}/PingSynchronizationServer`));
  }

  async getLastestChangeTrackingToApplyForMobile(): Promise<number> {
    return await firstValueFrom(this.http.get<number>(`${this.configUrl}/GetLastestChangeTrackingToApplyForMobile`));
  }

  async getLastSynchronization() {
    return await firstValueFrom(this.http.get<Synchronization>(`${this.configUrl}/GetLastSynchronization`));
  }

  async createSynchronization() {
    return await firstValueFrom(this.http.get<Synchronization>(`${this.configUrl}/CreateSynchronization`));
  }

  async prepareSynchronizationData(): Promise<SynchronizationRange[]> {
    return await firstValueFrom(this.http.post<SynchronizationRange[]>(`${this.configUrl}/PrepareSynchronizationData`, null));
  }

  async getSynchronizationRangeData(id: number) {
    return await firstValueFrom(this.http.get<SynchronizationRangeItem[]>(`${this.configUrl}/GetSynchronizationRangeData/${id}`));
  }

  async getDateTableColumnNameReference() {
    return await firstValueFrom(this.http.get<TableColumnNameReference[]>(`${this.configUrl}/GetDateTableColumnNameReference`));
  }

  async completeSynchronizationRange(id: number) {
    return await firstValueFrom(this.http.get<boolean>(`${this.configUrl}/CompleteSynchronizationRange/${id}`));
  }

  async completeSynchronization(synchonization: Synchronization) {
    return await firstValueFrom(this.http.post<boolean>(`${this.configUrl}/CompleteSynchronization`, synchonization));
  }

  async applyChangeTracking(changeTrackings: ChangeTracking[]) {
    return await firstValueFrom(this.http.post<number>(`${this.configUrl}/ApplyChangeTracking`, changeTrackings));
  }

  async subscribeToAudit(auditNumber: string) {
    return await firstValueFrom(this.http.post(`${this.configUrl}/SubscribeToAudits`, [auditNumber]));
  }

  async subscribeToProgram(programId: string, endDate: Date) {
    return await firstValueFrom(this.http.post(`${this.configUrl}/SubscribeToPrograms`, { programIds: [programId], endDate: endDate }));
  }
}