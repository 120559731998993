import { Injectable } from "@angular/core";
import { SynchronizationMiddleware } from "./core/data/synchronization/synchronizationMiddleware";
import { ValidationDictionary } from "./core/validationDictionary";
import { StoratePersistanceMiddleware } from "./storatePersistanceMiddleware";

@Injectable({
  providedIn: 'root'
})
export class AppMiddleware {
  // Even if not used, by being required by the app module, it creates the instance of the object
  // therefore initializing the application with required information
  constructor(
    private storatePersistanceMiddleware: StoratePersistanceMiddleware,
    private synchronizationMiddleware: SynchronizationMiddleware,
  ) { }

  public async run(): Promise<ValidationDictionary> {
    let validationDictionary = await this.synchronizationMiddleware.run();

    if (validationDictionary.isValid()) {
      await this.storatePersistanceMiddleware.run();
    }

    return validationDictionary;
  }
}