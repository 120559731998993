import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { IAuthenticationService } from "./iAuthenticationService.interface";
import { AuthenticationServiceFactory } from "./iAuthenticationService.factory";

@Injectable()
export class AuthenticationGuard {
  private authenticationService: IAuthenticationService;
  constructor(
    public authenticationServiceFactory: AuthenticationServiceFactory,
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authenticationService.isAuthenticated();
  }
}
