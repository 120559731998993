import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { Program } from 'src/app/core/data/models/database/program.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { ProgramSubscriptionHttpClient } from './programSubscriptionHttpClient';
import { ProgramSubscriptionViewModel } from './programSubscriptionViewModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-program-subscription',
  templateUrl: './program-subscription.component.html',
  styleUrls: ['./program-subscription.component.scss']
})
export class ProgramSubscriptionComponent implements OnInit, OnDestroy {

  public programSubscriptionDataSource: Function
  public subscribedProgram: ProgramSubscriptionViewModel[] = [];
  public selectedItems: ProgramSubscriptionViewModel[] = [];
  public isOnline: boolean = true;
  public itemCount: number;

  private serverConnectionSubscription: Subscription;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  @ViewChild('list') list: ListComponent;

  constructor(
    private readonly translateService: TranslateService,
    private readonly programSubscriptionHttpClient: ProgramSubscriptionHttpClient,
    private readonly synchronizationService: SynchronizationService
  ) { 

    // If a refresh of the page is done, there are good chances that synchronization service will be offline while rendering this component
    // so this event handler will make sure to refresh the page immediately after the state will change.
    this.serverConnectionSubscription = this.synchronizationService.serverConnectionSubject.subscribe(async (isOnline: boolean) => {
      this.isOnline = isOnline;

      this.list.updateData();
    });
  }

  ngOnDestroy(): void {
    this.serverConnectionSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.programSubscriptionDataSource = async (context) => {

      let programSubscriptions: ProgramSubscriptionViewModel[];

      if (this.synchronizationService.online) {
        this.isOnline = true;

        programSubscriptions = (await this.programSubscriptionHttpClient.getPrograms());
      } else {
        this.isOnline = false;

        let programs = (await Program.table.toArray());

        programSubscriptions = _.map(programs, program => new ProgramSubscriptionViewModel({
          id: program.id,
          name: program.name,
          isSubscribed: null
        }));

      }

      let filteredProgramSubscription = programSubscriptions.filter(
        program => ComparatorService.stringMatch(program.name, context?.filter ?? ""))

      this.selectedItems = filteredProgramSubscription.filter(program => program.isSubscribed == true);

      this.itemCount = filteredProgramSubscription.length;

      return new ListDataSourceFunctionResult({
        items: _.sortBy(filteredProgramSubscription, program => program.name),
        itemCount: filteredProgramSubscription.length
      })
    };
  }

  /**
   * This is to determine if items are selectable depending if the client is
   * online or not. When an item is selected, it sends to the api server that the
   * user wishes to subscribe to the selected program.
   * In case that the user is offline, instead of catching an error, the user won't
   * be able to select an item, therefore won't be able to make a request when in 
   * offline environment.
   * @returns infinite (-1) of selectable items if the client is online.
   */
  numberOfSelectableItems(): number {
    const infinite = -1;
    if(this.isOnline)
      return infinite;
    else
      return 0;
  }

  async selectedItemsChange(item) {
    if (!this.isOnline)
      return;

    await this.synchronizationService.getUpdates(true);

    try {
      item.isSubscribed = !item.isSubscribed;

      if (item.isSubscribed){
        await this.programSubscriptionHttpClient.addSubscription(item.id)
      }
      else {
        await this.programSubscriptionHttpClient.removeSubscription(item.id)
      }

      this.list.updateData();
    } catch (e) {
      PopupUtility.displayInformation(
        this.modalPopup,
        this.translateService,
        this.translateService.instant("programSubscriptionManagement.error.title"),
        this.translateService.instant("programSubscriptionManagement.error.description"));
    }
  }
}
