// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Synchronization extends ChangeTrackingEntity {
  public static table: Dexie.Table<Synchronization, number>;
  public static tableName: string = 'Synchronization';

  public static COMPLETED: string = 'completed';  public static DELETED: string = 'deleted';  public static END_TIME: string = 'endTime';  public static FAILED: string = 'failed';  public static FROM_CHANGE_TRACKING_ID: string = 'fromChangeTrackingId';  public static ID: string = 'id';  public static INSERTED: string = 'inserted';  public static IS_NEW_MOBILE: string = 'isNewMobile';  public static MOBILE_ID: string = 'mobileId';  public static START_TIME: string = 'startTime';  public static TIME_STAMP: string = 'timeStamp';  public static TO_CHANGE_TRACKING_ID: string = 'toChangeTrackingId';  public static UPDATED: string = 'updated';

  public completed: boolean;  public deleted: number;  public endTime: Date;  public failed: boolean;  public fromChangeTrackingId: number;  public id: number;  public inserted: number;  public isNewMobile: boolean;  public mobileId: string;  public startTime: Date;  public timeStamp: Date;  public toChangeTrackingId: number;  public updated: number;

  public constructor(args?: Partial<Synchronization>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Synchronization.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Synchronization.tableName);
      this.table.mapToClass(Synchronization);
  }
}
