import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationServiceFactory } from '../iAuthenticationService.factory';
import { IAuthenticationService } from '../iAuthenticationService.interface';
import { Location } from '@angular/common';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent {
  public authenticationService: IAuthenticationService;

  constructor(
    public authenticationServiceFactory: AuthenticationServiceFactory,
    public synchronizationService: SynchronizationService,
    private location: Location
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  public visible: boolean = true;

  public async retry() {
    // This will force to refresh the page with something visual for the user to allow
    // him to clearly see the mobile is trying to reauthorize.
    this.location.replaceState("/");
    window.location.reload();
  }

  async logout() {
    await this.authenticationService.logout();
    await this.authenticationService.login();
  }
}
