// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class OptionList extends ChangeTrackingEntity {
  public static table: Dexie.Table<OptionList, string>;
  public static tableName: string = 'OptionList';

  public static ENTITY_ID: string = 'entityId';  public static FILTER_MODE: string = 'filterMode';  public static FILTER_SCRIPT: string = 'filterScript';  public static GROUP_BY: string = 'groupBy';  public static GROUP_BY_HTML_TEMPLATE: string = 'groupByHtmlTemplate';  public static HTML_TEMPLATE: string = 'htmlTemplate';  public static ID: string = 'id';  public static LINE_TEMPLATE1: string = 'lineTemplate1';  public static LINE_TEMPLATE2: string = 'lineTemplate2';  public static LINE_TEMPLATE3: string = 'lineTemplate3';  public static NAME: string = 'name';  public static SORT_BY: string = 'sortBy';  public static SOURCE: string = 'source';  public static TEMPLATE_TYPE: string = 'templateType';  public static TEXT_FILTER: string = 'textFilter';  public static TIME_STAMP: string = 'timeStamp';

  public entityId: string;  public filterMode: number;  public filterScript: string;  public groupBy: string;  public groupByHtmlTemplate: string;  public htmlTemplate: string;  public id: string;  public lineTemplate1: string;  public lineTemplate2: string;  public lineTemplate3: string;  public name: string;  public sortBy: string;  public source: string;  public templateType: number;  public textFilter: string;  public timeStamp: Date;

  public constructor(args?: Partial<OptionList>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return OptionList.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(OptionList.tableName);
      this.table.mapToClass(OptionList);
  }
}
