<div *ngIf="supportNativeCamera">
  <input style="display: none" type="file" capture="user" accept="image/*" (change)="confirmPhoto($event)" #cameraInput>
</div>

<div *ngIf="!supportNativeCamera && visible">
  <div class="camera color-set-black" *ngIf="!imageUrl" >
    <div class="layout-vertical">
      <div class="layout-top">
        <div class="layout-horizontal padding-normal">
          <div class="layout-left" *ngIf="flashlightEnabled">
            <button type="button"  class="big round color-set-gray" (click)="toggleFlashlight()" [id]="'ToggleFlashlightButton'">
              <i class="fa-solid fa-bolt-lightning"></i>
            </button>
          </div>
          <div class="layout-left" *ngIf="flashlightEnabled">
            <button type="button" class="big round color-set-gray" *ngIf="flashEnabled" (click)="toggleFlash()" [id]="'ToggleFlashButton'">
              <i class="fa-solid fa-bolt-lightning"></i>
            </button>

            <button type="button" class="big round color-set-gray" *ngIf="!flashEnabled" (click)="toggleFlash()" [id]="'ToggleFlashButton'">
              <i class="fa-solid fa-slash"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="layout-center align-center">
        <video #video autoplay [srcObject]="camera"></video>
      </div>

      <div class="layout-bottom">
        <div class="layout-horizontal padding-normal">
          <div class="layout-left" *ngIf="deviceCount > 1">
            <button class="big round color-set-gray" (click)="switchCamera()" [id]="'SwitchCameraButton'">
              <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="layout-center">
            <button class="big round color-set-orange" (click)="capturePhoto()" [id]="'CapturePhotoButton'" [disabled]="!camera">
              <i class="fas fa-camera"></i>
            </button>
          </div>
          <div class="layout-right">
            <button type="button" class="big round color-set-gray" (click)="close()" [id]="'CloseButton'">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="image color-set-black" *ngIf="imageUrl">
    <div class="layout-vertical">
      <div class="layout-center align-center">
        <img #image [src]="imageUrl" />
      </div>

      <div class="layout-bottom">
        <div class="layout-horizontal padding-normal">
          <div class="layout-center">
            <button type="button" class="big round color-set-green" (click)="confirmPhoto()">
              <i class="fas fa-check"></i>
            </button>
          </div>
          <div class="layout-left">
            <button type="button" class="big round color-set-invert-red" (click)="clearPhoto()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-popup #popup>
  <div class="layout-horizontal color-set-blue">
    <div class="layout-center align-left">
      <button type="button" ><i class="fas fa-exclamation-triangle"></i>
        <div><span> {{ 'components.customFields.imagePicker.cameraDeviceNotAvailablePopup.title' | translate }}</span></div>
      </button>
    </div>
    <div class="layout-right">
      <button type="button" ><i class="fas fa-times" (click)="popup.close()"></i></button>
    </div>
  </div>
  <div class="content">
    <div>
      {{ 'components.customFields.imagePicker.cameraDeviceNotAvailablePopup.message' | translate}}
    </div>
    <div class="layout-horizontal color-set-white padding-vertical-compact">
      <div class="layout-right space">
        <button type="button" name="OkButton" class="compact color-set-blue" (click)="popup.close()">
          <div><span>{{ 'components.customFields.imagePicker.cameraDeviceNotAvailablePopup.ok' | translate}}</span></div>
        </button>
      </div>
    </div>
  </div>
</app-popup>
