import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { ChangeTracking } from "../models/databaseLocal/changeTracking.database";
import { ChangeTrackingEntity } from "./changeTrackingEntity";
import { DatabasePrimaryKeyType } from "./databasePrimaryKeyType";

@Injectable({
  providedIn: "root"
})
export class ChangeTrackingFactory {
  create(
    tableName: string,
    changeOperation: number,
    entity: ChangeTrackingEntity | any) {

    let isGuid = entity.getDatabasePrimaryKeyType() === DatabasePrimaryKeyType.guid;

    // This property should never go to the server because it is only used to detect whether the object must be
    // added, deleted or updated in IndexedDB. On server-side, this is done in a totally different way.
    delete entity["entityState"];

    // It look like there is a possibility to have this property in PascalCase but the use case
    // is not known at this point.
    if (entity["EntityState"] !== undefined)
    {
      debugger;

      delete entity["EntityState"];
    }

    let dataValues = JSON.stringify(entity);

    if (tableName == "DataSourceImportationResult")
      dataValues = JSON.stringify(this.convertDataSourceImportationResult(entity, dataValues));

    return new ChangeTracking({
      tableName: tableName,
      tableId: isGuid ? entity.id as string : null,
      tableIdInt: !isGuid ? entity.id as number : null,
      changeOperation: changeOperation,
      changeTrackingMarker: Dexie.Observable.createUUID(),
      timeStamp: new Date(),
      dataValues: dataValues,
      dataValuesSize: new TextEncoder().encode(dataValues).length
    });
  }

  convertDataSourceImportationResult(entity, dataValues): any {
      // This table has a column Data on the server database which is a JSON with all custom values.
      // This column is scattered in properties in IndexedDB.
      // The properties must be aggregate back to a column data in the change tracking to be in match with
      // the server. The scattering process is done in the class SynchronizationService.
      let result = {};

      let data = {};

      for (const property in entity) {
        switch (property) {
          case "id":
          case "idKey":
          case "timeStamp":
          case "dataSourceImportationId":
            result[property] = entity[property];
            break;

          default:
            data[property] = entity[property];

            break;
        }
      }

      result["data"] = JSON.stringify(data);

      return result;
  }
}
