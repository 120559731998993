// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class ParameterPhoto extends ChangeTrackingEntity {
  public static table: Dexie.Table<ParameterPhoto, string>;
  public static tableName: string = 'ParameterPhoto';

  public static ACCEPTED_FORMATS: string = 'acceptedFormats';  public static ID: string = 'id';  public static INITIAL_QUALITY: string = 'initialQuality';  public static MAXIMUM_SIZE: string = 'maximumSize';  public static MAXIMUM_WIDTH_OR_HEIGHT: string = 'maximumWidthOrHeight';  public static SYSTEM_WEB_MAXIMUM_SIZE_IN_M_B: string = 'systemWebMaximumSizeInMB';  public static SYSTEM_WINDOWS_MAXIMUM_SIZE_IN_M_B: string = 'systemWindowsMaximumSizeInMB';  public static TIME_STAMP: string = 'timeStamp';

  public acceptedFormats: string;  public id: string;  public initialQuality: number;  public maximumSize: number;  public maximumWidthOrHeight: number;  public systemWebMaximumSizeInMB: number;  public systemWindowsMaximumSizeInMB: number;  public timeStamp: Date;

  public constructor(args?: Partial<ParameterPhoto>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return ParameterPhoto.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(ParameterPhoto.tableName);
      this.table.mapToClass(ParameterPhoto);
  }
}
