import { UserAccountRepository } from "src/app/core/data/repositories/userAccountRepository";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { UserAccountMapper } from "src/app/pages/audit/userAccountMapper";
import { Injectable } from "@angular/core";
import { AuditRepository } from "src/app/core/data/repositories/auditRepository";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { CreateAuditResult } from "src/app/pages/audit/createAuditResult";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Program } from "src/app/core/data/models/database/program.database";
import { SharedParameter } from "src/app/core/data/models/database/sharedParameter.database";
import { Form } from "src/app/core/data/models/form/form";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { FormService } from "./formService";
import { ArrayUtility } from "src/app/core/arrayUtility";
import { Control } from "src/app/core/data/models/form/control";
import { ProgramVersionRepository } from "src/app/core/data/repositories/programVersionRespository";
import { environment } from "src/environments/environment";
import { Section } from "src/app/core/data/models/form/section";
import { UntypedFormGroup } from "@angular/forms";
import { UserAudit } from "src/app/core/data/models/databaseLocal/userAudit.database";
import { SectionState } from "./audit-sections/sectionState";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";
import Dexie from "dexie";
import { DynamicTabStructureItem } from "src/app/core/data/models/database/dynamicTabStructureItem.database";
import { AuditSectionState } from "src/app/core/data/viewModels/auditSectionState";
import { UserAccount } from "src/app/core/data/models/database/userAccount.database";
import { WFStep } from "src/app/core/data/models/database/wFStep.database";
import { Mobile } from "src/app/core/data/models/database/mobile.database";
import { AuditSubscription } from "src/app/core/data/models/database/auditSubscription.database";
import { MobileEntity } from "src/app/core/data/models/database/mobileEntity.database";
import { DynamicTabFolderViewModel } from "./audit-sections/dynamicTabFolderViewModel";
import { CreateDynamicTabInstanceResult } from "./createDynamicTabInstanceResult";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { DynamicInstance } from "src/app/core/data/models/form/dynamicInstance";
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { AuditAdditionalInfo } from "src/app/core/data/models/database/auditAdditionalInfo.database";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { StringUtility } from "src/app/core/stringUtility";
import { SynchronizationHttpClient } from "src/app/core/data/synchronization/synchronizationHttpClient";
import { SynchronizationService } from "src/app/core/data/synchronization/synchronizationService";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { CustomFieldControlType } from "src/app/core/data/models/form/customFieldControlType";
import { AlertTemplateField } from "src/app/core/data/models/alertTemplateField";
import { AuditState } from "./auditState";
import { FormTemplateNextNumber } from "src/app/core/data/models/databaseLocal/formTemplateNextNumber.database";
import { AlertService } from "src/app/core/utilities/alertService";
import { LocalParameter } from "src/app/core/data/models/databaseLocal/localParameter.database";
import moment from "moment";

@Injectable()
export class AuditService {
  constructor(
    private userAccountRepository: UserAccountRepository,
    private auditRepository: AuditRepository,
    private authenticatedUser: AuthenticatedUser,
    private programVersionRepository: ProgramVersionRepository,
    private baseRepository: BaseRepository,
    private formService: FormService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private synchronizationService: SynchronizationService,
    private translate: TranslateService
  ) { }

  public async getUsers(): Promise<DataSourceItem<string>[]> {
    const users = await this.userAccountRepository.getUsers();

    return new UserAccountMapper().mapToDataSourceItems(users);
  }

  public async getAuditByNumber(number: string): Promise<Audit> {
    return await this.auditRepository.getByNumber(number);
  }

  public async saveAudit(audit: Audit): Promise<unknown> {
    await this.updateAuditHeader(audit);
    await this.auditRepository.save(Audit.table, audit);

    return;
  }

  public async createAudit(programId: string, externalNumber: string = null, additionalDataInfos: any[] = null): Promise<CreateAuditResult> {
    let programVersion = await this.programVersionRepository.getLatestProgramVersion(programId);

    const result = new CreateAuditResult();

    if (!programVersion) {
      if (!this.synchronizationService.offline) {
        await this.synchronizationHttpClient.subscribeToProgram(programId, new Date());
        await this.synchronizationService.getUpdates(false);
        programVersion = await this.programVersionRepository.getLatestProgramVersion(programId);
      } else {
        result.success = false;
        result.message = this.translate.instant("auditList.openItemWithoutBeingOnline");
        return result;
      }
    }

    const audit = new Audit();

    const program = await Program.table.get(programId);

    let form = JSON.parse(programVersion.webModel) as Form;

    let defaultStepId = await this.getDefaultStepId(form);

    audit.createdById = this.authenticatedUser.id;
    audit.number = await this.getNextNumber(program);
    audit.externalNumber = externalNumber;
    audit.programId = programId;
    audit.stepId = defaultStepId;
    audit.responsibleId = this.authenticatedUser.id;
    audit.createdDate = new Date(Date.now());
    audit.effectiveDate = new Date(Date.now());
    audit.updatedDate = new Date(Date.now());

    try {
      let savedAudit = await this.baseRepository.insert<Audit>(Audit.table, audit) as Audit;

      await this.addSubscription(savedAudit.id);
      await this.addMobileEntity(savedAudit.id);
      await this.setDefaultValues(savedAudit, form);

      if (additionalDataInfos)
        await this.addAdditionalInfos(savedAudit.id, additionalDataInfos);

      result.audit = audit;
      result.success = true;

      await this.createAuditHeader(audit);
    } catch (e) {
      result.success = false;
      result.message = e.message;
    }

    return result;
  }

  async addAdditionalInfos(auditId: string, additionalDataInfos: any[]) {
    for (const additionalDataInfo of additionalDataInfos) {
      await this.baseRepository.insert(AuditAdditionalInfo.table,
        new AuditAdditionalInfo(
          {
            propertyName: additionalDataInfo.name.substring(0, 50),
            value: additionalDataInfo.value.substring(0, 250),
            auditId: auditId
          }));
    }
  }

  public async setDefaultValues(audit: Audit, form: Form) {
    let auditDataTables = new Array<AuditDataTable>();

    for (const section of form.sections) {
      if (!section.controls) continue;

      let flattenControls: Array<Control> = Section.getflatControls(section.controls);

      let groupedControls: Map<string, Control[]> = ArrayUtility.groupBy(
        flattenControls,
        (x) => x.dataTableName
      );

      for (const groupedControl of groupedControls) {
        let auditDataTable = new AuditDataTable();

        let key = groupedControl[0];
        let controls = groupedControl[1];

        auditDataTable.tableName = key;
        auditDataTable.auditId = audit.id;
        auditDataTable.tableId = Dexie.Observable.createUUID();
        auditDataTable.id = auditDataTable.tableId;

        this.setSectionDefaultValues(controls, auditDataTable);

        auditDataTables.push(auditDataTable);
      }
    }

    await this.baseRepository.insert<AuditDataTable>(
      AuditDataTable.table,
      auditDataTables
    );
  }

  /**
  * Set a default value for each configured control this way.
  */
  setSectionDefaultValues(controls: Control[], auditDataTable: AuditDataTable) {
    let state: SectionState = SectionState.Valid;

    for (const control of controls) {
      let defaultValue: any = Control.getExtendedProperty(control, "DefaultValue");

      if (defaultValue !== null && defaultValue !== undefined)
        auditDataTable[control.dataColumnName] = defaultValue;

      const value = auditDataTable[control.dataColumnName];

      if (control.required) {
        if (this.isControlEmpty(control, auditDataTable[control.dataColumnName])) {
          state = SectionState.EmptyRequiredFields;
        }
      }

      if (state != SectionState.EmptyRequiredFields) {
        let inputMask = control.extendedProperties.find(x => x.key == "InputMask");

        if (inputMask) {
          if (!StringUtility.validateMask(value ?? "", inputMask.value)) {
            // Could be a more specific state value but for the moment, integrating a new state
            // would requires a shutgun surgery.
            state = SectionState.EmptyRequiredFields;
          }
        }
      }
    }

    auditDataTable["State"] = state;
  }

  // This method is only intended to be used when creating a new audit.
  private async addSubscription(auditId: string) {
    let subscriptionStartDate = new Date();
    let subscriptionEndDate = new Date();

    subscriptionEndDate.setDate(subscriptionStartDate.getDate() + environment.auditSubscriptionDays);

    let auditSubcriptions = await this.baseRepository.getAll(AuditSubscription.table)

    let localExistingSubscription = auditSubcriptions.filter((auditSubcription) => {
      return auditSubcription.auditId === auditId && auditSubcription.userAccountId === this.authenticatedUser.id;
    });

    if (localExistingSubscription.length > 0) {
      await this.baseRepository.update(AuditSubscription.table, new AuditSubscription({
        id: localExistingSubscription[0].id,
        auditId: auditId,
        userAccountId: this.authenticatedUser.id,
        startDate: subscriptionStartDate,
        endDate: subscriptionEndDate
      }));
    } else {
      await this.baseRepository.insert(AuditSubscription.table, new AuditSubscription({
        auditId: auditId,
        userAccountId: this.authenticatedUser.id,
        startDate: subscriptionStartDate,
        endDate: subscriptionEndDate
      }));
    }
  }

  public async addMobileEntity(auditId: string) {
    await this.baseRepository.insert(MobileEntity.table, new MobileEntity({
      mobileId: this.synchronizationService.mobileId,
      entityId: auditId,
      tableName: Audit.tableName,
      timeStamp: new Date()
    }));
  }

  private async getDefaultStepId(form: Form) {
    return form.steps.sort(x => x.number)[0].id;
  }

  public async updateAuditHeader(audit: Audit) {
    audit.updatedDate = new Date(Date.now());
    await this.baseRepository.update(Audit.table, audit);

    let userAudit = await UserAudit.table.get(audit.id);

    await this.mapAuditHeaderFields(userAudit, audit);

    // The baseRepository should not be used in this case because this table
    // is not in the ChangeTracking process. Put will insert or update the record
    // in the IndexedDB database without propagating the data to the server through
    // the synchronization process.
    // This does not need to be tracked through changeTracking
    // since its a table only meant to represent all existing audits
    // and is refreshed every synchronization from the server. Updating
    // the information is required to be able to interact correctly
    // with the data, mostly to search form, until it is refresh again
    //  next synchronzation.
    await UserAudit.table.put(userAudit);
  }

  public async createAuditHeader(audit: Audit) {
    const userAudit = new UserAudit();

    await this.mapAuditHeaderFields(userAudit, audit);

    userAudit.id = audit.id;
    userAudit.number = audit.number;
    userAudit.createdDate = moment().utc().toString();
    userAudit.synchronized = true;

    await this.baseRepository.insert(UserAudit.table, userAudit);
  }

  private async mapAuditHeaderFields(userAudit: UserAudit, audit: Audit) {
    let program: Program = await this.baseRepository.get(Program.table, audit.programId);
    let responsible: UserAccount = await this.baseRepository.get(UserAccount.table, audit.responsibleId);
    let step: WFStep = await this.baseRepository.get(WFStep.table, audit.stepId);

    userAudit.updatedDate = moment().utc().toString();
    userAudit.externalNumber = audit.externalNumber;
    userAudit.responsibleId = audit.responsibleId;
    userAudit.responsibleName = responsible.name;
    userAudit.programId = program.id;
    userAudit.programDescription = program.description;
    userAudit.stepId = audit.stepId;
    userAudit.stepName = step.name;
  }

  private async getMobilePrefix(): Promise<string> {
    let parameter: SharedParameter = await SharedParameter.table.where("name").equals("MobileAuditNumberPrefix").first();

    let mobilePrefixNumber = parameter ? parameter.valueAsString : "M";

    let mobile: Mobile = await this.baseRepository.get(Mobile.table, this.synchronizationService.mobileId);

    return mobilePrefixNumber + mobile.number;
  }

  public async getNextNumber(program: Program): Promise<string> {
    let result: string = "";

    const nextNumber = await this.reserveNext(program.id);

    if (program.numberPrefix) result = program.numberPrefix + "-";

    result += (await this.getMobilePrefix()) + "-";

    if (program.numberPadding)
      result += nextNumber.toString().padStart(program.numberPadding, "0");
    else result += nextNumber;

    return result;
  }

  private async reserveNext(programId: string): Promise<number> {
    let formTemplateNextNumber = await FormTemplateNextNumber.table.where("programId").equals(programId).first();

    if (formTemplateNextNumber == null) {
      formTemplateNextNumber = new FormTemplateNextNumber();
      formTemplateNextNumber.programId = programId;

      let mobilePrefix: string = await this.getMobilePrefix();
      
      let audits: Audit[] = await Audit.table.where("programId").equals(programId).toArray();

      if (audits)
      {
        let maxNumber: number = 0;

        for (const audit of audits) {
          if (audit.number.indexOf("-" + mobilePrefix + "-") < 0)
            continue;

          let auditNumber = Number(_.last(audit.number.split("-")));

          if (!Number.isNaN(auditNumber)){
            if (auditNumber > maxNumber)
              maxNumber = auditNumber;
          }
        }

        // Require to decipher the audit to find the auto-incremented part because this value is stored nowhere else.
        formTemplateNextNumber.next = maxNumber + 1
      }
      else{
        formTemplateNextNumber.next = 1;
      }

      await FormTemplateNextNumber.table.put(formTemplateNextNumber);
    }

    const result = formTemplateNextNumber.next;

    formTemplateNextNumber.next += 1;

    await FormTemplateNextNumber.table.put(formTemplateNextNumber);

    return result;
  }

  public getInvalidControls(form: UntypedFormGroup) {
    const invalid = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }

    }
    return invalid;
  }

  async validateSectionRequiredFields(audit: Audit, form: Form, section: Section, validateAlertTemplates?: boolean): Promise<boolean> {
    let auditDataTables = null;

    // customTableId is present as a property when in an alert template. This allow to validate only this instance and not others from others controls.
    // Filter only the related alert instance when specified. Otherwise, a section is valid when all dynamic tab and alert instances are valid.
    if (section["customTableId"]) {
      auditDataTables = await AuditDataTable.table.where("tableId").equals(section["customTableId"]).toArray();;
    } else {
      auditDataTables = await AuditDataTable.table.where(['tableName+auditId']).equals([section.controls[0].dataTableName, audit.id]).toArray();
    }

    for (const auditDataTable of auditDataTables) {
      if (!await this.validateControlRequiredFields(form, section.controls, auditDataTable, validateAlertTemplates))
        return false;
    }

    return true;
  }

  isControlEmpty(control: Control, value: any) {
    if (control.type == CustomFieldControlType.CheckBox) {
      let threeStatesProperty = control.extendedProperties.find(x => x.key == "ThreeState");

      if (threeStatesProperty?.value) {
        if (value === undefined || value === null) {
          return true;
        }
      }
      else {
        if (!value) {
          return true;
        }
      }
    }
    else {
      if (!value)
        return true;
    }
  }

  async validateControlRequiredFields(form: Form, controls: Control[], auditDataTable: AuditDataTable, validateAlertTemplates: boolean) {
    let dynamicTabAuditTemplates: DynamicTabAuditTemplate[];

    for (const control of controls) {
      if (control.required) {
        if (this.isControlEmpty(control, auditDataTable[control.dataColumnName])) {
          return false;
        }
      }

      if (validateAlertTemplates && control.alertTemplates.length > 0) {
        // Try to look through each alert to find the one with a linked to the control value.
        // There could be more efficient way to retrieve the alert data values but for the moment, this is the way to go.
        for (const alertTemplateControl of control.alertTemplates) {
          // Each DynamicTabAuditTemplate rows related to the alert template system use the column ReferenceTableId to create a link with 
          // the parent AuditDataTable. So for a specified main section, all alert template AuditDataTable rows could be retreive in one shoot.
          if (!dynamicTabAuditTemplates)
            dynamicTabAuditTemplates = await DynamicTabAuditTemplate.table.where("referenceTableId").equals(auditDataTable.id).toArray();

          let structureItem = await DynamicTabStructureItem.table.get(alertTemplateControl.structureItemId);

          // Where using the alert template system, each custom field is linked to the dynamic tab structure item with the custom field name.
          // There could at most one item per control per section.
          let currentInstance = dynamicTabAuditTemplates.find(x => x.dynamicTabStructureItem == structureItem.keyIdentifier)

          if (currentInstance) {
            let alertTemplate = form.alertTemplates.find(x => x.id == structureItem.templateId);

            let alertAuditDataTemplate = await AuditDataTable.table.get(currentInstance.customTableId);

            if (alertAuditDataTemplate) {
              if (!await this.validateControlRequiredFields(form, alertTemplate.controls, alertAuditDataTemplate, false))
                return false;
            }

            // Considering a control could be linked to one and just one alert template, the moment a condition has been found, 
            // there is no point of looking for other templates.
            break;
          }
        }
      }

      if (control.children) {
        if (!await this.validateControlRequiredFields(form, control.children, auditDataTable, validateAlertTemplates)) {
          return false;
        }
      }
    }

    return true;
  }

  async validateRequiredFieldsByState(audit: Audit) {
    let auditDataTables = await (
      await this.baseRepository.queryAll<AuditDataTable>(
        AuditDataTable.table
      )
    ).where({ auditId: audit.id }).toArray()

    for (const auditDataTable of auditDataTables) {
      if (auditDataTable["State"] != undefined && auditDataTable["State"] == SectionState.EmptyRequiredFields)
        return false;
    }

    return true;
  }

  async validateRequiredFields(audit: Audit, form: Form): Promise<boolean> {
    for (const section of form.sections) {
      if (section.type == SectionType.CustomFields) {
        if (section.controls && section.controls.length > 0) {
          if (! await this.validateSectionRequiredFields(audit, form, section))
            return false;
        }
      }
      else if (section.type == SectionType.DynamicTab) {
        let dynamicSection: DynamicSection = section as DynamicSection;

        for (const template of dynamicSection.templates) {
          if (template?.controls && template.controls.length > 0) {
            if (! await this.validateSectionRequiredFields(audit, form, template))
              return false;
          }
        }
      }
    }

    for (const alertTemplate of form.alertTemplates) {
      if (alertTemplate.controls && alertTemplate.controls.length > 0) {
        if (! await this.validateSectionRequiredFields(audit, form, alertTemplate))
          return false;
      }
    }

    return true;
  }

  /**
   * Remove the existing audit data table related information and dynamic tab audit template instance.
   * This will delete all existing DynamicTabAuditTemplate and AuditDataTable linked to one of the alert condition templated of the control.
   */
  async removeDynamicTabAuditTemplate(alertTemplateField: AlertTemplateField, auditState: AuditState) {
    let control = Section.getControlFromStructureItemKey(auditState.section, alertTemplateField.structureItemId);

    for (const alertTemplate of control.alertTemplates) {
      let dynamicTabStructureItem = await DynamicTabStructureItem.table.get(alertTemplate.structureItemId);

      let alertTemplates = await DynamicTabAuditTemplate.table.where({ auditId: auditState.audit.id, dynamicTabStructureItem: dynamicTabStructureItem.keyIdentifier }).toArray();

      for (const alertTemplateInstance of alertTemplates) {
        this.baseRepository.deleteById(AuditDataTable.table, alertTemplateInstance.customTableId);

        await this.baseRepository.delete(DynamicTabAuditTemplate.table, alertTemplateInstance);
      }
    }
  }

  async deleteDynamicTabAuditTemplate(auditId: string, instanceId: string) {
    let instance = await this.baseRepository.get(DynamicTabAuditTemplate.table, instanceId);

    let auditDataTable = await AuditDataTable.table.where("tableId").equals(instance.customTableId).first();

    await this.baseRepository.delete(AuditDataTable.table, auditDataTable);
    await this.baseRepository.delete(DynamicTabAuditTemplate.table, instance);

    let auditAlerts = await AuditAlertSummary.table.where("auditId").equals(auditId).toArray();

    for (const auditAlert of auditAlerts) {
      let tokens = auditAlert.controlKey.split(".");

      if (tokens.length === 3) {
        if (tokens[2] === auditDataTable.tableId)
          this.baseRepository.delete(AuditAlertSummary.table, auditAlert);
      }
    }
  }

  /**
  * Create a new instance of dynamic tab template with an instance of the related custom field table.
  */
  async addDynamicTabAuditTemplate(auditId: string, section: Section, structureItemId: string, referenceTableId: string, number?: string, description?: string, position?: number, values?: Object): Promise<CreateDynamicTabInstanceResult> {
    let structureItem = await this.baseRepository.get(DynamicTabStructureItem.table, structureItemId);
    let auditDataTable = new AuditDataTable();

    auditDataTable.tableName = section.dataTableName;
    auditDataTable.tableId = Dexie.Observable.createUUID();
    auditDataTable.id = auditDataTable.tableId;
    auditDataTable.auditId = auditId;

    this.setSectionDefaultValues(Section.getflatControls(section.controls), auditDataTable);

    if (values) {
      for (let columnKey in values) {
        auditDataTable[columnKey] = values[columnKey];
      }
    }

    await this.baseRepository.insert(
      AuditDataTable.table,
      auditDataTable
    );

    let instance = new DynamicTabAuditTemplate();

    instance.auditId = auditId;
    instance.dynamicTabStructureItem = structureItem.keyIdentifier;
    instance.customTableId = auditDataTable.tableId;
    instance.number = number;
    instance.description = description;
    instance.position = position || 0;

    // When the dynamic tab instance is to hold data from alert template.
    if (referenceTableId) {
      instance.referenceTableId = referenceTableId;
    }

    await this.baseRepository.insert(
      DynamicTabAuditTemplate.table,
      instance);

    return new CreateDynamicTabInstanceResult({ auditDataTable: auditDataTable, dynamicTabAuditTemplate: instance });
  }

  public static createAuditSectionState(auditDataTable: AuditDataTable) {
    let auditSectionState = new AuditSectionState();

    auditSectionState.dataTableName = auditDataTable.tableName;
    auditSectionState.state = auditDataTable["State"];
    auditSectionState.customTableId = auditDataTable.tableId;

    return auditSectionState;
  }

  public async createDynamicTabInstance(folder: DynamicTabFolderViewModel, auditId: string, form: Form, number: string, description: string, position: number): Promise<CreateDynamicTabInstanceResult> {
    let parentSection = form.sections.find(x => x.id === folder.sectionId) as DynamicSection;

    let templateSection = parentSection.templates.find(x => x.templateId === folder.templateId);

    let newDynamicTab = await this.addDynamicTabAuditTemplate(auditId, templateSection, folder.id, null, number, description, position);

    let audit = await Audit.table.get(auditId);

    audit.updatedDate = new Date();

    await this.baseRepository.update(Audit.table, audit);

    return newDynamicTab;
  }

  public async updateDynamicTabInstance(id: string, number: string, description: string) {
    let dynamicTabAuditTemplate = await this.baseRepository.get(DynamicTabAuditTemplate.table, id);

    dynamicTabAuditTemplate.number = number;
    dynamicTabAuditTemplate.description = description;

    let audit = await Audit.table.get(dynamicTabAuditTemplate.auditId);
    audit.updatedDate = new Date();

    await this.baseRepository.update(DynamicTabAuditTemplate.table, dynamicTabAuditTemplate);
    await this.baseRepository.update(Audit.table, audit);
  }

  public getNextDynamicTabInstanceNumber(folder: DynamicTabFolderViewModel): number {
    let maxNumber = 0;

    for (const instance of folder.instances) {
      let instanceNumber = Number(instance.number);

      if (!Number.isNaN(instanceNumber)) {
        if (instanceNumber > maxNumber)
          maxNumber = instanceNumber;
      }
    }

    return maxNumber + 1;
  }

  public async loadDynamicTabInstances(auditId: string, form: Form) {
    let dynamicTabInstances = await DynamicTabAuditTemplate.table.where("auditId").equals(auditId).toArray();

    for (const section of form.sections) {
      if ((section as DynamicSection).folders) {
        let dynamicSection = section as DynamicSection;

        for (const folder of dynamicSection.folders) {
          let folderInstances = dynamicTabInstances.filter(x => x.dynamicTabStructureItem == folder.idKey);

          folder.instances = [];

          for (const instance of folderInstances.sort(x => x.position)) {
            folder.instances.push(new DynamicInstance({
              id: instance.id,
              number: instance.number,
              description: instance.description,
              customTableId: instance.customTableId,
              dataTableName: folder.tableName,
              position: instance.position
            }));
          }
        }
      }
    }
  }

  public async validateAuditNumberExist(auditNumber: string): Promise<boolean> {
    return !!await Audit.table.where('number').equals(auditNumber);
  }
}
