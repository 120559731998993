import { DocumentType } from "./documentType";

export class DocumentTypeViewModel{
  public constructor(args?: Partial<DocumentTypeViewModel>) {
    Object.assign(this, args);
  }

  description: string;
  type: DocumentType;
}
