import { Pipe, PipeTransform } from '@angular/core';
import { Image } from 'src/app/core/data/models/database/image.database';
import { ImageService } from 'src/app/core/services/image.service';

@Pipe({
  name: 'fetchImage'
})
export class FetchImagePipe implements PipeTransform {
  transform(imageId: string): any {
    let image = Image.table.get(imageId);
    return image;
  }
}

@Pipe({
  name: 'fetchImageUrl'
})
export class FetchImageUrlPipe implements PipeTransform {
  transform(image: Image): any {
    if (image) {
      return ImageService.META_DATA_PREFIX + image.value;
    }

    return null;
  }
}
