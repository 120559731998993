// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however 
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class LocalParameter extends ChangeTrackingEntity {
  public static table: Dexie.Table<LocalParameter, string>;
  public static tableName: string = 'LocalParameter';

  public name: string;
  public value: any;

  public constructor(args?: Partial<LocalParameter>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return LocalParameter.tableName;
  }

  public getDatabasePrimaryKeyType() {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(LocalParameter.tableName);
    this.table.mapToClass(LocalParameter);
  }

  public static MOBILE_ID_PARAMETER_NAME = "MobileId";
  public static USER_ID_PARAMETER_NAME = "UserId";
  public static FORCED_OFFLINE_MODE = "ForcedOfflineMode";
}

