import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { Program } from 'src/app/core/data/models/database/program.database';
import { Control } from 'src/app/core/data/models/form/control';
import { ProgramVersionRepository } from 'src/app/core/data/repositories/programVersionRespository';
import { SearchOperator } from './searchOperators/searchOperator';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';
import { Subscription } from 'rxjs';
import { StringComparisonType } from './searchOperators/stringComparisonType';
import { StringSearchOperator } from './searchOperators/stringSearchOperator';
import { DataType } from 'src/app/core/data/models/form/dataType';
import { NumericSearchOperator } from './searchOperators/numericSearchOperator';
import { BooleanSearchOperator } from './searchOperators/booleanSearchOperator';
import { DateSearchOperator } from './searchOperators/dateSearchOperator';
import { DataSourceSearchOperator } from './searchOperators/dataSourceSearchOperator';
import { NumericComparisonType } from './searchOperators/numericComparisonType';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';

@Component({
  selector: 'app-custom-field-filter',
  templateUrl: './custom-field-filter.component.html',
  styleUrls: ['./custom-field-filter.component.scss']
})
export class CustomFieldFilterComponent implements OnDestroy, OnInit {

  @ViewChild("customFieldForm") public popup: PopupComponent;

  controlDataSource: any;

  stringComparisonTypeDataSource: any;

  numericComparisonTypeDataSource: any;

  customFieldDataSource: any;

  form: FormGroup;

  searchOperator: SearchOperator;

  control: Control;

  inputMask: string;

  // This is a trick to expose the enum to the template.
  customFieldControlTypes = CustomFieldControlType;

  dataTypes = DataType;

  private controls: Control[];

  private controlValueChangesSubscription: Subscription;

  constructor(private programVersionRepository: ProgramVersionRepository) {
    this.form = new FormGroup({
      control: new FormControl('',[]),
      stringValue: new FormControl('',[]),
      stringComparisonType: new FormControl('',[]),
      numericValue: new FormControl('',[]),
      numericComparisonType: new FormControl('',[]),
      booleanValue: new FormControl('',[]),
      dateFromValue: new FormControl('',[]),
      dateToValue: new FormControl('',[]),
      dataSourceValue: new FormControl('',[]),
    })

    this.controlValueChangesSubscription = this.form.controls["control"].valueChanges.subscribe(x => {
      this.setControl();
    });
  }

  private async setControl(){
    var currentControl = this.controls.find(y => y.dataTableName + "." + y.dataColumnName == this.form.controls["control"].value);

    this.control = currentControl;

    if (this.control){
      this.refreshValues();

      this.inputMask = this.control["inputMask"];

      if (this.control.type == CustomFieldControlType.RadioButton || this.control.type == CustomFieldControlType.ComboBox){
        await this.loadCustomFieldDataSource();
      }
    }
  }

  private async loadCustomFieldDataSource(){
    let dataSourceId = this.control["dataSourceId"];

    let dataSource = (await CustomFieldValueItem.table.where("valueListId").equals(dataSourceId).toArray()).filter(x => !x.isDeleted);

    this.customFieldDataSource = dataSource.map(x => {
      return { key: x.id, description: x.description };
    });
  }

  ngOnInit(): void {
      this.stringComparisonTypeDataSource = [
        { key: StringComparisonType.exact, description: "Exacte" },
        { key: StringComparisonType.contains, description: "Contient" },
        { key: StringComparisonType.startWith, description: "Commence par" },
        { key: StringComparisonType.endWith, description: "Se termine par" },
        { key: StringComparisonType.notContains, description: "Ne contient pas" },
      ];

      this.numericComparisonTypeDataSource = [
        { key: NumericComparisonType.equal, description: "Exacte" },
        { key: NumericComparisonType.notEqual, description: "Différent" },
        { key: NumericComparisonType.greaterThan, description: "Plus grand que" },
        { key: NumericComparisonType.greaterOrEqualThan, description: "Plus grand ou égal" },
        { key: NumericComparisonType.lowerThan, description: "Plus petit" },
        { key: NumericComparisonType.lowerOrEqualThan, description: "Plus petit ou égal" },
      ];
  }

  ngOnDestroy(): void {
    this.controlValueChangesSubscription?.unsubscribe();
  }

  private refreshValues(){
    this.form.controls["stringComparisonType"].setValue("");
    this.form.controls["stringValue"].setValue("");
    this.form.controls["numericValue"].setValue("");
    this.form.controls["numericComparisonType"].setValue("");
    this.form.controls["dataSourceValue"].setValue("");
    this.form.controls["booleanValue"].setValue("");

    // Important to set the default value to null because when the drop down is hour, there is a quirk
    // from DevExpress dx-date-box where the drop down will always display No data to display instead of the hours.
    this.form.controls["dateFromValue"].setValue(null);
    this.form.controls["dateToValue"].setValue(null);
  }

  public async show(searchOperator: SearchOperator, formTemplate: Program, controls: Control[]): Promise<boolean> {
    this.searchOperator = searchOperator;

    this.initialize(formTemplate, controls);

    this.form.controls["control"].setValue(searchOperator.key);

    this.refreshValues();

    if (this.control){
      if (this.control.type == CustomFieldControlType.TextBox || this.control.type == CustomFieldControlType.MaskedEditTextBox){
        let dataType = this.control["dataType"] as DataType;

        if (dataType == DataType.Decimal){
          let numericSearchOperator = searchOperator as NumericSearchOperator;

          this.form.controls["numericComparisonType"].setValue(numericSearchOperator.comparisonType);
          this.form.controls["numericValue"].setValue(numericSearchOperator.value);
        }
        else{
          let stringSearchOperator = searchOperator as StringSearchOperator;

          this.form.controls["stringComparisonType"].setValue(stringSearchOperator.comparisonType);
          this.form.controls["stringValue"].setValue(stringSearchOperator.value);
        }
      }
      else if (this.control.type == CustomFieldControlType.CheckBox){
        let booleanSearchOperator = this.searchOperator as BooleanSearchOperator;

        this.form.controls["booleanValue"].setValue(booleanSearchOperator.value);
      }
      else if (this.control.type == CustomFieldControlType.DateTimePicker){
        let dateSearchOperator = this.searchOperator as DateSearchOperator;

        this.form.controls["dateFromValue"].setValue(dateSearchOperator.fromValue);
        this.form.controls["dateToValue"].setValue(dateSearchOperator.toValue);
      }
      else if (this.control.type == CustomFieldControlType.ComboBox || this.control.type == CustomFieldControlType.RadioButton){
        let dataSourceOperator = this.searchOperator as DataSourceSearchOperator;

        this.form.controls["dataSourceValue"].setValue(dataSourceOperator.value);
      }
    }

    return this.popup.display();
  }

  private async initialize(formTemplate: Program, controls: Control[]){
    this.controls = controls;

    this.controlDataSource = controls.map(x => {
      return { key: x.dataTableName + "." + x.dataColumnName, description: x.sectionDescription + " => " + x.description };
    });
  }

  public close(){
    this.popup.resolve(false);
    this.popup.close();
  }

  public apply(){
    this.searchOperator.key = this.form.controls["control"].value;

    if (this.control.type == CustomFieldControlType.TextBox || this.control.type == CustomFieldControlType.MaskedEditTextBox){
      let dataType = this.control["dataType"] as DataType;

      if (dataType == DataType.Decimal){
        let numericSearchOperator = this.searchOperator as NumericSearchOperator;

        numericSearchOperator.comparisonType = this.form.controls["numericComparisonType"].value;
        numericSearchOperator.value = this.form.controls["numericValue"].value;
      }
      else{
        let stringSearchOperator = this.searchOperator as StringSearchOperator;

        stringSearchOperator.comparisonType = this.form.controls["stringComparisonType"].value;
        stringSearchOperator.value = this.form.controls["stringValue"].value;
      }
    }
    else if (this.control.type == CustomFieldControlType.CheckBox){
      let booleanSearchOperator = this.searchOperator as BooleanSearchOperator;

      booleanSearchOperator.value = this.form.controls["booleanValue"].value;
    }
    else if (this.control.type == CustomFieldControlType.DateTimePicker){
      let dateSearchOperator = this.searchOperator as DateSearchOperator;

      dateSearchOperator.fromValue = this.form.controls["dateFromValue"].value;
      dateSearchOperator.toValue = this.form.controls["dateToValue"].value;
    }
    else if (this.control.type == CustomFieldControlType.ComboBox || this.control.type == CustomFieldControlType.RadioButton){
      let dataSourceOperator = this.searchOperator as DataSourceSearchOperator;

      dataSourceOperator.value = this.form.controls["dataSourceValue"].value;
    }

    this.popup.resolve(true);
    this.popup.close();
  }

  public cancel(){
    this.popup.resolve(false);
    this.popup.close();
  }
}
