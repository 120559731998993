import { Injectable } from "@angular/core";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Audit } from "../models/database/audit.database";
import { BaseRepositoryConstructor } from "../baseRepositoryConstructor";
import { UserAudit } from "../models/databaseLocal/userAudit.database";

@Injectable({
  providedIn: 'root',
})
export class AuditRepository extends BaseRepository {
  constructor(
    public baseRepositoryConstructor: BaseRepositoryConstructor,
  ) {
    super(baseRepositoryConstructor);
  }

  public async getByNumber(number: string): Promise<Audit> {
    let audits = await Audit.table.where("number").equals(number).toArray();

    return audits.filter(x => {
      return !x.isDeleted;
    })[0];
  }

  public async deleteByNumber(number: string): Promise<void> {
    let audit = await this.getByNumber(number);
    audit.isDeleted = true;
    await this.update(Audit.table, audit)
  }
}
