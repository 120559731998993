<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <app-date-time-picker
    [formControlName]="input.key"
		[showDropDownButton]="!hideDropdownButton"
    [readOnly]="readOnly"
    [inputMask]="inputMask"
    [id]="input.key"
    [form]="form"
  ></app-date-time-picker>
</div>
