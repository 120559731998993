import { UntypedFormGroup } from '@angular/forms';
import { debounceTime } from "rxjs";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";
import { CustomFieldControlType } from "src/app/core/data/models/form/customFieldControlType";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { FormField } from "src/app/core/data/models/formField";
import { AuditState } from "src/app/pages/audit/auditState";
import { DataSourceService } from "./dataSourceService";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { TranslateService } from "@ngx-translate/core";
import { OtherSectionDataItem } from "./otherSectionDataItem";
import _ from 'lodash';
import { CustomFieldValueList } from 'src/app/core/data/models/database/customFieldValueList.database';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';
import { DynamicTabStructureItem } from 'src/app/core/data/models/database/dynamicTabStructureItem.database';
import { Folder } from 'src/app/core/data/models/form/folder';
import { DataSourceType } from 'src/app/core/data/models/dataSourceType';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { Control } from 'src/app/core/data/models/form/control';
import { DataType } from 'src/app/core/data/models/form/dataType';
import { AlertService } from 'src/app/core/utilities/alertService';

export class CalculatedField{
  otherSectionDataItems: any[] = [];

  private dataSource: any = {};

  private calculatedFieldSectionControls : Control[] = [];

  private allSectionControls: Control[] = [];

  private valueChangesSubscriptions: any[] = [];
  public flattedFormFields: FormField<any>[] = new Array<FormField<any>>();

  private formGroup: UntypedFormGroup;
  private auditState: AuditState;
  private baseRepository: BaseRepository;
  private translateService: TranslateService;

  private dynamicInstanceDataSource: any;
  private dynamicFolder: Folder;

  public sectionId: string;

  private valueChangeDependencyCounter: string[];

  // This allow to detect changes made by user in controls versus changes automatically made by calculated fields.
  // The goal is to detect circular dependencies.
  private isChangeMadeByCalculatedField: Boolean;

  // PictureBox and Signature are not loaded for a performance reason. Others are control types that don't hold data.
  private excludedControlTypes = [CustomFieldControlType.PictureBox, CustomFieldControlType.Signature, CustomFieldControlType.Label, CustomFieldControlType.GroupBox, CustomFieldControlType.Panel, CustomFieldControlType.Button];

  constructor(formGroup: UntypedFormGroup, auditState: AuditState, baseRepository: BaseRepository, translateService: TranslateService) {
    this.auditState = auditState;
    this.baseRepository = baseRepository;
    this.translateService = translateService;

    this.changeFormGroup(formGroup);
  }

  public changeFormGroup(formGroup: UntypedFormGroup){
    this.formGroup = formGroup;
    this.flattedFormFields = FormField.flatFormFields(this.auditState.formFields);
  }

  /**
  * This will detect all changes from controls in the current section and trigger the computing of calculated fields based
  * on those fields.
  * This function could be called multiple times so existing hooks must be detached before trying to hook them again to prevent
  * memory leak.
  */
  subscribeToValueChange() {
    // Calculated fields are deactivated for the moment in alert templates.
    if (this.auditState.form.alertTemplates.find(x => x.name == this.auditState.section.name))
      return;

    this.unsubscribeToValueChange();

    for (const value of Object.entries(this.formGroup.controls)) {
      const [key, control] = value;

      let valueChangeSubscription = { hook: null, key: key };

      let valueChanges = control.valueChanges;

      let calculatedFieldSectionControl = this.calculatedFieldSectionControls.find(x => x.sectionName == this.auditState.section.name && x.name == key);

      let formField = this.flattedFormFields.find(x => x.key == key);

      // Don't add a debounce time on calculated fields because there are not directly updated by the user.
      if (!calculatedFieldSectionControl){
        if (!formField)
          continue;

        if (formField.controlType == CustomFieldControlType.Label)
          continue;

        valueChanges = valueChanges.pipe(debounceTime(250));
      }

      // Debounce time to prevent too many value changes especially when multiple characters are typed in the same control.
      valueChangeSubscription.hook = valueChanges.subscribe(async (val) => {
          // Reset the dependency change counter only when the change is made by the user.
          // All other value changes are made by calculated fields and the counter must be kept alive to
          // track cycle dependencies.
          if (!this.isChangeMadeByCalculatedField){
            this.valueChangeDependencyCounter = [];
          }

          let currentControl: Control = this.allSectionControls.find(x => x.sectionName == this.auditState.section.name && x.name == key);

          // Update the in-memory data source to it continue to be synchronized with values in each control.
          if (this.auditState.section["templateId"]) {
            let dynamicSection: DynamicSection = this.auditState.section as DynamicSection;

            this.dynamicInstanceDataSource[key] = await this.getControlValue(currentControl, val);

            let mainSection = this.getMainSection(this.auditState.section["templateId"]);

            this.compute(`${mainSection.name}.${this.dynamicFolder.name}.${key}`);
          }
          else{
            this.dataSource[this.auditState.section.name][key] = await this.getControlValue(currentControl, val);

            this.compute(`${this.auditState.section.name}.${key}`);
          }
      });

      this.valueChangesSubscriptions.push(valueChangeSubscription);
    }
  }

  private async getControlValue(control: Control, value: any){
    let controlType = control.type as CustomFieldControlType;

    if (controlType == CustomFieldControlType.ComboBox || controlType == CustomFieldControlType.RadioButton){
      let dataSourceObject = {};

      // When the control is a data source related, the value always represents a primary key.
      let id = value;

      dataSourceObject["id"] = id;

      if (id != null && !!id){
        let source = control.extendedProperties.find(x => x.key == "Source").value as DataSourceType;

        switch (source) {
          case DataSourceType.Custom:
            let dataSourceItem = await this.baseRepository.get<CustomFieldValueItem>(CustomFieldValueItem.table, id);

            dataSourceObject["description"] = dataSourceItem.description;
            dataSourceObject["attribute1"] = dataSourceItem.attribute1;
            dataSourceObject["attribute2"] = dataSourceItem.attribute2;
            dataSourceObject["attribute3"] = dataSourceItem.attribute3;
            dataSourceObject["attribute4"] = dataSourceItem.attribute4;
            dataSourceObject["attribute5"] = dataSourceItem.attribute5;

            break;

          case DataSourceType.User:
            let userAccount = await this.baseRepository.get<UserAccount>(UserAccount.table, id);

            dataSourceObject["name"] = userAccount.name;

            break;
          default:
            break;
        }
      }

      return dataSourceObject;
    }
    else{
      return value;
    }
  }

  private getMainSection(templateId: string){
    for (const mainSection of this.auditState.form.sections) {
      if (mainSection.type == SectionType.DynamicTab){
        let dynamicSection = mainSection as DynamicSection;

        if (dynamicSection.templates.find(x => x.templateId == templateId))
          return mainSection;
      }
    }
  }

  /**
  * Retrieve all the calculated fields for the entire form to prevent having to retrieve them more than once.
  * This include all the templates related to dynamic section.
  * Controls are sorted ascending by the execution order.
  */
  load() {
    this.calculatedFieldSectionControls = [];
    this.allSectionControls = [];

    for (const section of this.auditState.form.sections) {
      if (!section.controls)
        continue;

        if (section.type === SectionType.DynamicTab) {
          let dynamicSection = (section as DynamicSection);

          for (let folder of dynamicSection.folders) {
            if (!folder.templateId)
              continue;

            let template = dynamicSection.templates.find(x => x.templateId == folder.templateId)

            if(!template)
              continue;

            for (const control of Section.getAllControls(template).sort(x => x.calculatedFieldExecutionOrder)) {
              control.sectionName = template.name;
              control.isDynamic = true;

              if (control.isCalculatedField){
                control.calculatedFieldExpression = control.calculatedFieldExpression.replaceAll("${folder}", folder.name);

                this.calculatedFieldSectionControls.push(control);
              }

              this.allSectionControls.push(control);
            }
          }
        } else {
          for (const control of Section.getAllControls(section).sort(x => x.calculatedFieldExecutionOrder)) {
            control.sectionName = section.name;

            if (control.isCalculatedField){
              this.calculatedFieldSectionControls.push(control);
            }

            this.allSectionControls.push(control);
          }
        }
    }

    this.calculatedFieldSectionControls = this.auditState.form.sections.reduce((prev, current: Section) => {
      // Some section don't have controls object like the information section.
      if (!current.controls) {
        return prev;
      }

      let calculatedControls = [];

      if (current.type === SectionType.DynamicTab) {
        let dynamicSection = (current as DynamicSection);

        for (let folder of dynamicSection.folders) {
          if (!folder.templateId)
            continue;

          let template = dynamicSection.templates.find(x => x.templateId == folder.templateId)

          if(!template)
            continue;

          calculatedControls = calculatedControls.concat(Section.getAllControls(template).filter((x) => {
            return x.isCalculatedField;
          }).sort(x => x.calculatedFieldExecutionOrder).map((control) => {

            control["sectionName"] = template.name;
            control["isDynamic"] = true;
            control.calculatedFieldExpression = control.calculatedFieldExpression.replaceAll("${folder}", folder.name);

            return control;
          }));
        }
      } else {
        calculatedControls = Section.getAllControls(current).filter((x) => {
          return x.isCalculatedField;
        }).sort(x => x.calculatedFieldExecutionOrder).map((control: any) => {
          control.sectionName = current.name;
          return control;
        });
      }

      return prev.concat(calculatedControls);
    }, []);
  }

  private async createDataSourceObject(section: Section, sectionData: AuditDataTable){
    let newObject = {};

    for (let control of Section.getAllControls(section)) {
      if (!this.excludedControlTypes.find(x => x == control.type)){
        const key = section.name + "." + control.name;

        let formField = this.flattedFormFields.find(x => x.sectionKey + "." + x.key == key);

        newObject[control.name] = await this.getControlValue(control, sectionData[control.dataColumnName]);
      }
    }

    return newObject;
  }

  /**
  * Create an in-memory representation of all the data contained in the current audit.
  * This will in the calculated field expressions to extract the required information.
  * The structure of the data source is:
  * Each standard section are accessible from dataSource[Tab.Name] and each controls from dataSource[Section.Name][Control.Name]
  * Each dynamic section from dataSource[Tab.Name][Folder.Name][Control.Name]
  */
  async initializeDataSource() {
    let auditDataTables = await AuditDataTable.table.where('auditId').equals(this.auditState.audit.id).toArray();
    let dynamicTabAuditTemplates = await DynamicTabAuditTemplate.table.where("auditId").equals(this.auditState.audit.id).toArray();

    this.dynamicFolder = null;

    for (let section of this.auditState.form.sections) {
      if (section.type === SectionType.CustomFields) {
        let sectionData = auditDataTables.filter(x => x.tableName === section.dataTableName)[0];

        if (sectionData) {
          this.dataSource[section.name] = await this.createDataSourceObject(section, sectionData);
        }
      } else if (section.type === SectionType.DynamicTab) {
        await this.initializeDynamicSection(section, dynamicTabAuditTemplates, auditDataTables);
      }
    }
  }

  private async initializeDynamicSection(section, dynamicTabAuditTemplates, auditDataTables){
    let dynamicSection = section as DynamicSection;
    let dynamicSectionObject = [];

    for (const folder of dynamicSection.folders) {
      if (!folder.templateId)
        continue;

      // Data structure must be loaded is the exact same position than the instances in each folder
      // because instances will be referenced in the future by than index position.
      let folderSectionData = _.sortBy(dynamicTabAuditTemplates.filter(x => x.dynamicTabStructureItem == folder.idKey), x => x.position);

      let folderInstances = [];

      if (folderSectionData && folderSectionData.length > 0) {
        for (const folderData of folderSectionData) {
          let template = dynamicSection.templates.find(x => x.templateId == folder.templateId);

          let instanceData = auditDataTables.find(x => x.id == folderData.customTableId);

          let result = await this.createDataSourceObject(template, instanceData);

          if (folder.id == this.auditState.folderId && folderData.position == this.auditState.instancePosition){
            this.dynamicFolder = folder;
            this.dynamicInstanceDataSource = result;
          }

          folderInstances.push(result);
        }
      }

      dynamicSectionObject[folder.name] = folderInstances;
    }

    this.dataSource[section.name] = dynamicSectionObject;
  }

  private async compute(controlKey: string) {
    if (this.valueChangeDependencyCounter.find(x => x == controlKey)){
      return;
    }
    else{
      this.valueChangeDependencyCounter.push(controlKey);
    }

    const AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;

    let utility = new CalculatedFieldUtility();

    let searchRegExp = new RegExp(controlKey);

    for (let calculatedFieldSectonControl of this.calculatedFieldSectionControls.filter(x => x.calculatedFieldExpression)) {
      let expression: string = calculatedFieldSectonControl.calculatedFieldExpression.toString();

      // Don't try to evaluate the expression of that field if the expression don't contains a reference to the control
      // that is triggering the value changes.
      if (!searchRegExp.test(expression))
        continue;

      let func = null;

      try {
        func = new AsyncFunction("dataSource", "instance", "utility", expression);
      } catch (error) {
        AlertService.show(this.translateService.instant("calculatedField.validation.invalidExpression", { controlKey: controlKey}) + ":\n\n" + error.message);

        continue;
      }

      let value;

      try {
        if (calculatedFieldSectonControl["isDynamic"]){
          value = await func(this.dataSource, this.dynamicInstanceDataSource, utility);
        }
        else{
          value = await func(this.dataSource, null, utility);
        }
      } catch (error) {
        AlertService.show(this.translateService.instant("calculatedField.validation.invalidExpression", { controlKey: controlKey}) + ":\n\n" + error.message);
      }

      if (this.auditState.section.name === calculatedFieldSectonControl.sectionName) {

        this.isChangeMadeByCalculatedField = true;

        this.setCurrentSectionControlValue(calculatedFieldSectonControl, value);

        this.isChangeMadeByCalculatedField = false;

      }
      else{
        await this.setOtherSectionControlValue(calculatedFieldSectonControl, value);
      }
    }
  }

  /**
  * Update the data directly in IndexedDB for that control. There is no other option at this point to update data not associated
  * with the current section.
  */
  private async setOtherSectionControlValue(control: any, value: any){
    let otherSectionDataItem = this.otherSectionDataItems.find(x => x.dataTableName == control.dataTableName && x.dataColumnName == control.dataColumnName);

    if (otherSectionDataItem == null){
      otherSectionDataItem = new OtherSectionDataItem();

      otherSectionDataItem.dataTableName = control.dataTableName;
      otherSectionDataItem.dataColumnName = control.dataColumnName;

      this.otherSectionDataItems.push(otherSectionDataItem);
    }

    otherSectionDataItem.value = this.getSafeValue(control, value);

    // Synchronize the data source with the new value. When updating a control that is in the same
    // section, the synchronization is automatically done in the ValueChanges event of the form group.
    this.dataSource[control.sectionName][control.name] = await this.getControlValue(control, value);

    // Changes from other section are not listened by the reactive form engine but they must
    // trigger value changes to evaluate their dependencies.
    this.compute(control.sectionName + "." + control.name);
  }

    private getSafeValue(control: any, value: any): any{
      if (value === undefined || value === null)
        return null;
      else if (control.dataType == DataType.String)
      {
        let maxLength: number;

        if (control.maxLength)
          maxLength = control.maxLength;
        else
          maxLength = Number.MAX_VALUE;

        let valueAsString;

        if (value === undefined || value === null)
          valueAsString = "";
        else if (value === true || value === false)
          valueAsString = value.toString();
        else
          valueAsString = value ? value.toString() : "";

        if (valueAsString.length > maxLength)
          valueAsString = valueAsString.substr(0, maxLength);

        return valueAsString;
      }
      else{
        return value;
      }
    }

    /**
    * Update the value of the control in the current section. This will update the control linked with the Reactive engine of Angular
    * and the process to save in the database will be done later when the user will leave the section, the tab or the browser.
    */
    private setCurrentSectionControlValue(control: any, value: any){
      this.formGroup.get(control.name).setValue(this.getSafeValue(control, value));

      this.formGroup.get(control.name).markAsDirty();

      let field = this.flattedFormFields.find(x => x.sectionKey + "." + x.key == control.sectionName + "." + control.name);

      field.value = value;
    }

    unsubscribeToValueChange(){
      for (const subscription of this.valueChangesSubscriptions) {
        subscription.hook.unsubscribe();
      }

      this.valueChangesSubscriptions = [];
    }
}

export class CalculatedFieldUtility{
  public isNumber(value: string | number): boolean
  {
    return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
  }
}
