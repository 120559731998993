import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY } from "rxjs";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Program } from "src/app/core/data/models/database/program.database";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { ProgramVersionRepository } from "src/app/core/data/repositories/programVersionRespository";

import { SynchronizationHttpClient } from "src/app/core/data/synchronization/synchronizationHttpClient";
import { SynchronizationService } from "src/app/core/data/synchronization/synchronizationService";
import { SectionConst } from "src/app/core/sections/sectionConst";
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditState } from "src/app/pages/audit/auditState";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { UserAuditRepository } from "src/app/core/data/repositories/userAuditRepository";

@Injectable()
export class AuditResolver {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private auditService: AuditService,
    private auditState: AuditState,
    private baseRepository: BaseRepository,
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private programVersionRepository: ProgramVersionRepository,
    private translateService: TranslateService,
    private router: Router,
    private userAuditRepository: UserAuditRepository
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    let auditNumber = route.paramMap.get('auditNumber');

    if (!this.synchronizationService.offline) {
      await this.synchronizationHttpClient.subscribeToAudit(auditNumber);

      // Check if the route is forms/:auditNumber/duplicate or forms/:auditNumber/delete
      // to hide synchronization popup
      let splitUrl = this.currentUrl.split("/");

      let lastUrlElement = splitUrl[splitUrl.length - 1];
      
      if (lastUrlElement === "duplicate" || lastUrlElement === "delete")
        await this.synchronizationService.getUpdates(false);
      else
        await this.synchronizationService.getUpdates(true);
    }

    let audit = await this.auditService.getAuditByNumber(auditNumber);

    if (!audit) {
      this.router.navigateByUrl(`forms/notfound/${auditNumber}`);

      return EMPTY.toPromise();
    }

    let program = await this.baseRepository.get(Program.table, audit.programId);

    // This is currently done to fix an issue where auditState seems to already exists
    // while it should be a new instance. In this case I reset the active section to null
    // to ensure it update with the default first section of the form opened.
    this.auditState.section = null;

    this.auditState.audit = audit;
    await this.auditState.updateWorkflowStep(audit.stepId);

    this.auditState.program = program;

    this.auditState.auditNumber = audit.number

    this.auditState.auditFormattedNumber = this.userAuditRepository.sanitarizeNumber(audit.number);

    let programVersion = await this.programVersionRepository.getLatestProgramVersion(this.auditState.audit.programId);

    let form = JSON.parse(programVersion.webModel);

    this.auditState.form = form;

    for (const section of this.auditState.form.sections) {
      // Could not check for the type DynamicSection at this point because
      // the form object is deserialize from JSON so each object couldn't use the
      // inheritance and DynamicSection is inherited from Section.
      if (section["templates"]) {
        let dynamicSection = section as DynamicSection;

        for (const sectionTemplate of dynamicSection.templates) {
          Section.mapExtendedProperties(sectionTemplate);
        }
      }
      else
        Section.mapExtendedProperties(section);
    }

    for (const section of this.auditState.form.alertTemplates) {
      Section.mapExtendedProperties(section);
    }

    this.loadSystemSections(form);

    await this.auditService.loadDynamicTabInstances(audit.id, form);

    await this.auditState.refreshState();

    this.auditState.dataSourceCache.clear();

    return;
  }

  private loadSystemSections(form: any) {
    let informationTitle = this.translateService.instant("audit.informationSectionTitle");

    form.sections.unshift(
      new Section({
        id: SectionConst.information,
        name: informationTitle,
        description: informationTitle,
        type: SectionType.Undefined,
      })
    );

    if (this.auditState.form.documents?.length > 0) {
      let reportTitle = this.translateService.instant("audit.reportSectionTitle");

      form.sections.push(new Section({
        id: SectionConst.reports,
        name: reportTitle,
        description: reportTitle,
        type: SectionType.Documents
      }));
    }
  }
}
