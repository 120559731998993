import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import _ from 'lodash';
import { throwIfEmpty } from 'rxjs';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportationResultViewModel } from '../data-source-importation-result/dataSourceImportationResultViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { RowClickEvent } from 'devextreme/ui/tree_list';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { DxTreeListComponent } from 'devextreme-angular';

@Component({
  selector: 'app-data-source-selector',
  templateUrl: './data-source-selector.component.html',
  styleUrls: ['./data-source-selector.component.scss']
})
export class DataSourceSelectorComponent implements OnInit {
  @Input() dataSourceImportationId = "";
  @Input() visible = false;
  @Input() canClose = true;
  @Input() boxFixed = false;
  @Input() minWidth = "";

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter;

  @ViewChild(PopupComponent) popup: PopupComponent;
  @ViewChild(ListComponent) list: ListComponent;
  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;

  private promiseResolver = null;
  public selectedItem: DataSourceImportationResultViewModel;

  public dataSourceItems: DataSourceImportationResult[];
  public idKey: string;
  public idParentKey: string;
  public positionKey: string;
  public fieldTitlesKey: string;
  public fieldSchema: FieldSchema[];
  public itemCount: number;

  async ngOnInit(): Promise<void> {
    if (!this.dataSourceImportationId)
      return;
  }

  public async display() {
    this.selectedItem = null;

    this.popup.width = this.minWidth;

    let dataSourceImportation = await DataSourceImportation.table
      .get(this.dataSourceImportationId);

    this.idKey = dataSourceImportation.fieldTitlesKey;
    this.idParentKey = dataSourceImportation.idParentKey;
    this.fieldSchema = JSON.parse(dataSourceImportation.fieldTitles);

    if (!this.fieldSchema.find(x => x.visible)) {
      let defaultKey = this.fieldSchema.find(x => x.key === this.idKey);
      defaultKey.visible = true;
    }

    this.dataSourceItems = await DataSourceImportationResult.table
      .where({ dataSourceImportationId: this.dataSourceImportationId })
      .toArray();

    this.popup.display();

    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  public close() {
    this.popup.close();
    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);
    this.dataSourceItems = null;
  }

  selectedItemsChange(item: RowClickEvent) {
    this.selectedItem = item.data;
  }

  okButtonClick() {
    this.promiseResolver(true);
    this.close();
  }

  cancelButtonClick() {
    this.promiseResolver(false);
    this.close();
  }

  toggleRow(cell) {
    if (cell.row.isExpanded) {
      this.treeList.instance.collapseRow(cell.row.key);
    } else {
      this.treeList.instance.expandRow(cell.row.key);
    }
  }
}

class FieldSchema {
  public key: string;
  public value: string;
  public allowSearch: boolean;
  public visible: boolean;
  public sortIndex: number;
  public sortOrder: string;
}