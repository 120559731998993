<div class="layout-vertical">
  <div class="layout-top">
    <div class="alert alert-danger" role="alert" *ngIf="validationMessage">{{ validationMessage }}</div>
    <div class="layout-horizontal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'task.list.title' | translate}} ({{ itemCount }})
      </div>
      <div class="layout-right">
        <button (click)="mapClick()" [hidden]="hideMap" [disabled]="selectedItemsCount === 0"><i class="fas fa-map"></i></button>
        <button (click)="searchClick()" [disabled]="!selectedItemId" [class.active]="filterCount">
          <i class="fas fa-search"></i>
          <div class="badgeHolder" *ngIf="filterCount">
            <div class="badge">{{filterCount}}</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="layout-center">
    <div class="padding-normal">
      <div>
        <label for="typeId" class="field">{{ 'taskType.list.filterDropDownList' | translate}}</label>
      </div>
      <div class="controlContainer">
        <dx-select-box id="typeId" [items]="taskTypes" valueExpr="key" displayExpr="text"
          (onValueChanged)="onValueChanged($event)" [(value)]="selectedItemId">
        </dx-select-box>
      </div>
    </div>

    <app-list #list [items]="tasks" 
      [(selectedItems)]="selectedTasks" 
      [numberOfSelectableItem]="-1" 
      [selectionEnabled]="true"
      [canToggleSelection]="true"
      (selectedItemsChange)="selectedItemsChange($event)">
      
      <ng-template #listGroupTemplate let-item>
        {{item.key}}
      </ng-template>

      <ng-template #listTemplate let-item>
        <div class="layout-horizontal item">
          <div class="layout-left fill padding-left" [innerHTML]="item[ListComponent.templatePropertyField]">
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>