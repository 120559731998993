import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { interceptorProviders } from './core/interceptor/interceptorProviders';
import { AppInfoService } from './core/services/app-info.service';
import { HeaderComponent } from "src/app/components/headers/header/header.component";
import { SynchronizationContextComponent } from './components/synchronization/synchronization-context/synchronization-context.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MsalModule, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { AuthenticationType } from './authentication/authenticationType';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SynchronizationContextComponent],
  exports: [],
  imports: [
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.authentication[AuthenticationType.AzureAd].clientId,
          authority: environment.authentication[AuthenticationType.AzureAd].authority,
          redirectUri: environment.authentication[AuthenticationType.AzureAd].redirectUri,
          postLogoutRedirectUri: environment.authentication[AuthenticationType.AzureAd].postLogoutRedirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: true,
        },
        system: {
          allowNativeBroker: false,
          loggerOptions: {
            loggerCallback: (logLevel, message) => console.log(message),
            logLevel: environment.authentication[AuthenticationType.AzureAd].logLevel,
            piiLoggingEnabled: false
          }
        }
      }), {
      interactionType: environment.authentication[AuthenticationType.AzureAd].interactionType,
    }, {
      interactionType: environment.authentication[AuthenticationType.AzureAd].interactionType,
      protectedResourceMap: new Map(environment.authentication[AuthenticationType.AzureAd].protectedResourceMap)
    }),
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    AppInfoService,
    interceptorProviders,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptor
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
})
export class AppModule {
  // Even if not used, by being required by the app module, it creates the instance of the object
  // therefore initializing the application with required information
  constructor() { }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


