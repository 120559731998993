import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { DataSourceType } from "src/app/core/data/models/dataSourceType";
import { UserAccountRepository } from "src/app/core/data/repositories/userAccountRepository";
import { Subscription, timer } from 'rxjs';
import { AuditState } from 'src/app/pages/audit/auditState';
import { SortOrder } from './sortOrder';
import { StringUtility } from 'src/app/core/stringUtility';
import { DataSourceChangeEventArgs } from 'src/app/pages/audit/dataSourceChangeEventArgs';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import { DropdownComponent } from 'src/app/components/controls/dropdown/dropdown.component';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-customfield-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [
    './dropdown.component.scss',
    '../../controls.scss'
  ]
})

export class CustomFieldDropdownComponent extends BaseControlComponent implements OnInit, OnDestroy {
  public dataSource: Function;
  public useDefaultSelect: boolean;

  private alertConditionChangedSubscription: Subscription;

  @ViewChild('dropdown') dropdown: DropdownComponent;

  readOnly: boolean = false;

  constructor(
    private readonly auditState: AuditState,
    private userAccountRepository: UserAccountRepository
  ) {
    super();
  }

  /**
   * Based on the source, the appropriate function will be set in the query
   * variable set in  the setUserDataSource(), setCustomerDataSource()
   * or setCustomDataSource(). From there, whenever updateDataSource()
   * is called, it refresh the data with the corresponding item paged accordingly.
   */
  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    const source = super.getExtendedProperty("Source") as DataSourceType;
    const dataSourceId = super.getExtendedProperty("DataSourceId") as string;

    this.readOnly = this.auditState.readonly;
    this.useDefaultSelect = super.getExtendedProperty("UseDefaultSelect");

    switch (source) {
      case DataSourceType.User:
        await this.setUserDataSource();
        break;
      case DataSourceType.Custom:
        await this.setCustomDataSource(dataSourceId);
        break;
    }

    if (this.input.alert) {
      this.alertConditionChangedSubscription = this.input.$onAlertConditionChanged.subscribe(args => {
        this.auditState.addAlertSummary(this.input, args.condition?.id, this.formControl.value, this.auditState.instanceId ? this.auditState.customTableId : "");
      });
    }
  }

  public selectedItemsChange(items) {
    this.input.referenceTableId = this.auditState.customTableId;

    this.setValue(items?.id);
    this.dropdown.popup.close();
    this.auditState.raiseDataSourceChange(new DataSourceChangeEventArgs({ name: this.input.dataColumnName, newValue: this.input.value }));
  }

  ngOnDestroy(): void {
    this.alertConditionChangedSubscription?.unsubscribe();
  }

  private async setUserDataSource(): Promise<void> {
    this.dataSource = async (dropdownContext): Promise<ListDataSourceFunctionResult> => {
      let users = await this.userAccountRepository.getUsers();

      // When control is loaded, the selected item will be null even if
      // the valus is set into the hidden control. This rebuild the selected item.
      if (this.form.controls[this.input.key].value !== _.first<DataSourceItem<string>>(dropdownContext.selectedItems)?.id) {
        let selectedUser = users.find((user) => user.id === this.input.value);

        if (selectedUser) {
          this.dropdown.selectedItems = [new DataSourceItem<string>(selectedUser.id, selectedUser.name)]
        } else {
          this.dropdown.selectedItems = [];
        }
      }

      let filteredUsers = users.filter((user) => {
        // Used includes() instead of indexOf() because it appears to be much faster even though
        // the documentation says it is not.
        return ComparatorService.stringMatch(user.name, dropdownContext.filter);
      });

      return new ListDataSourceFunctionResult({
        itemCount: filteredUsers.length,
        items: filteredUsers.map(user => new DataSourceItem<string>(user.id, user.name))
      });
    }
  }

  private async setCustomDataSource(dataSourceId: string): Promise<void> {
    let sortOrder = super.getExtendedProperty("SortOrder") as number;
    let displayMember = super.getExtendedProperty("DisplayMember");
    let secondDisplayMember = super.getExtendedProperty("SecondDisplayMember");
    let secondDisplayMemberSeparator = super.getExtendedProperty("SecondDisplayMemberSeparator");

    displayMember = StringUtility.turnToCamelCase(displayMember || "description");

    if (secondDisplayMember) {
      secondDisplayMember = StringUtility.turnToCamelCase(secondDisplayMember);

      secondDisplayMemberSeparator = secondDisplayMemberSeparator || " - ";
    }

    let sortFunction = (a: CustomFieldValueItem, b: CustomFieldValueItem) => a.description.localeCompare(b.description, environment.language)

    if (SortOrder.Position === sortOrder) {
      sortFunction = (a: CustomFieldValueItem, b: CustomFieldValueItem) => a.position - b.position;
    }

    this.dataSource = async (dropdownContext): Promise<ListDataSourceFunctionResult> => {
      let customFieldValueItems;

      let cacheItem = this.auditState.dataSourceCache.match(dataSourceId);

      if (cacheItem){
        customFieldValueItems = await cacheItem;
      }
      else{
        // IndexedDB allows for the single where to be executed. Everything else appear
        // to be faster when using javascript functions for this reason, the query has
        // been kept as simple as possible and additionnal filters are added below for paging.
        customFieldValueItems = await CustomFieldValueItem.table
          .where("valueListId").equals(dataSourceId).toArray();

        customFieldValueItems = customFieldValueItems.filter(x => !x.isDeleted || x.id == this.form.controls[this.input.key].value);

        this.auditState.dataSourceCache.add(dataSourceId, customFieldValueItems);
      }

      // When control is loaded, the selected item will be null even if
      // the valus is set into the hidden control. This rebuild the selected item.
      if (this.form.controls[this.input.key].value !== _.first<DataSourceItem<string>>(dropdownContext.selectedItems)?.id) {
        let selectedCustomFieldValueItem = customFieldValueItems.find((customFieldValueItem) => customFieldValueItem.id === this.input.value)
        if (selectedCustomFieldValueItem) {
          // Reused existing method to get the dropdown item but only to get one item.
          this.dropdown.selectedItems = [CustomFieldDropdownComponent.customFieldItemsToDatasource([selectedCustomFieldValueItem],
            displayMember, secondDisplayMember, secondDisplayMemberSeparator)[0]];
        } else {
          this.dropdown.selectedItems = [];
        }
      }

      let filteredCustomFieldValueItems = customFieldValueItems.filter((customFieldValueItem) => {
        return ComparatorService.stringMatch(customFieldValueItem.description, dropdownContext.filter);
      });

      let sortedCustomFieldValueItems = filteredCustomFieldValueItems.sort(sortFunction);

      let dropdownItems = CustomFieldDropdownComponent.customFieldItemsToDatasource(sortedCustomFieldValueItems,
        displayMember, secondDisplayMember, secondDisplayMemberSeparator);

      return new ListDataSourceFunctionResult({
        itemCount: filteredCustomFieldValueItems.length,
        items: dropdownItems
      });

    };
  }

  /**
   * TODO AC : Should this method be moved to a service ?
   *
   * @param items
   * @param displayMember
   * @param secondDisplayMember
   * @param secondDisplayMemberSeparator
   * @returns
   */
  private static customFieldItemsToDatasource(items: any, displayMember: any, secondDisplayMember: any, secondDisplayMemberSeparator: any) {
    let result: DataSourceItem<string>[] = [];
    let canAddItemToList: boolean;

    for (const item of items) {
      let dataSourceItem: DataSourceItem<string> = new DataSourceItem<string>();

      dataSourceItem.id = item.id;

      canAddItemToList = true;

      if (secondDisplayMember) {
        if (item[secondDisplayMember] && item[displayMember]) {
          dataSourceItem.text = item[displayMember] + secondDisplayMemberSeparator + item[secondDisplayMember];
        }
        else if (item[displayMember]) {
          dataSourceItem.text = item[displayMember];
        }
        else if (item[secondDisplayMember]) {
          dataSourceItem.text = item[secondDisplayMember];
        }
        else {
          canAddItemToList = false;
        }
      }
      else {
        if (item[displayMember]) {
          dataSourceItem.text = item[displayMember];
        }
        else {
          canAddItemToList = false;
        }
      }

      if (canAddItemToList) {
        result.push(dataSourceItem);
      }
    }

    return result;
  }
}
