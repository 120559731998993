import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import SignaturePad, { SignaturePadOptions } from 'signature_pad';

/*
  This component highly depend on SignaturePad. An implementation can be found in the following git repository. It could not be used
  because at the time of writing this, did not support version 16 of angular so I copied source into our project to avoid a dependency mistmatch.
  https://github.com/almothafar/angular-signature-pad/blob/master/projects/angular-signature-pad/src/lib/angular-signature-pad.component.ts
*/
export interface NgSignaturePadOptions extends SignaturePadOptions {
  canvasHeight: number;
  canvasWidth: number;
}

@Component({
  template: '<canvas class="signature-pad-canvas"></canvas>',
  selector: 'app-signature-pad',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadAngularComponent implements AfterContentInit, OnDestroy {
  private signaturePad: SignaturePad;
  
  @Input() public options: NgSignaturePadOptions;
  @Output() public drawStart: EventEmitter<MouseEvent | Touch>;
  @Output() public drawBeforeUpdate: EventEmitter<MouseEvent | Touch>;
  @Output() public drawAfterUpdate: EventEmitter<MouseEvent | Touch>;
  @Output() public drawEnd: EventEmitter<MouseEvent | Touch>;


  constructor(private elementRef: ElementRef) {
    this.options = this.options || {} as NgSignaturePadOptions;
    this.drawStart = new EventEmitter<MouseEvent | Touch>();
    this.drawBeforeUpdate = new EventEmitter<MouseEvent | Touch>();
    this.drawAfterUpdate = new EventEmitter<MouseEvent | Touch>();
    this.drawEnd = new EventEmitter<MouseEvent | Touch>();
  }

  public ngAfterContentInit(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();

    if (this.options.canvasHeight) {
      canvas.height = this.options.canvasHeight;
    }

    if (this.options.canvasWidth) {
      canvas.width = this.options.canvasWidth;
    }

    this.signaturePad = new SignaturePad(canvas, this.options);
    this.signaturePad.onBegin = (event: MouseEvent) => this.beginStroke(event);
    this.signaturePad.onEnd = (event: MouseEvent) => this.endStroke(event);
  }

  public ngOnDestroy(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    canvas.width = 0;
    canvas.height = 0;
  }

  /**
   * Redraw or Resize canvas, note this will clear data.
   */
  public redrawCanvas(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    // when zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1, and only part of the canvas is cleared then.
    const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  /**
   * Returns signature image as an array of point groups
   */
  public toData(): SignaturePad.Point[][] {
    if (this.signaturePad) {
      return this.signaturePad.toData();
    } else {
      return [];
    }
  }

  /**
   * Draws signature image from an array of point groups
   */
  public fromData(points: any): void {
    this.signaturePad.fromData(points);
  }

  /**
   * Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
   */
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  /**
   * Draws signature image from data URL
   */
  public fromDataURL(dataURL: string, options: { ratio?: number; width?: number; height?: number } = {}): void {
    this.signaturePad.fromDataURL(dataURL, options);
  }

  /**
   * Clears the canvas
   */
  public clear(): void {
    this.signaturePad.clear();
  }

  /**
   * Returns true if canvas is empty, otherwise returns false
   */
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  /**
   * Unbinds all event handlers
   */
  public off(): void {
    this.signaturePad.off();
  }

  /**
   * Rebinds all event handlers
   */
  public on(): void {
    this.signaturePad.on();
  }

  /**
   * set an option on the signaturePad - e.g. set('minWidth', 50);
   * @param option one of SignaturePad to set with value, properties of NgSignaturePadOptions
   * @param value the value of option
   */
  public set(option: string, value: any): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    switch (option) {
      case 'canvasHeight':
        canvas.height = value;
        break;
      case 'canvasWidth':
        canvas.width = value;
        break;
      default:
        this.signaturePad[option] = value;
    }
  }

  public beginStroke(event: MouseEvent): void {
    this.drawStart.emit(event);
  }

  public endStroke(event: MouseEvent): void {
    this.drawEnd.emit(event);
  }

  public getSignaturePad(): SignaturePad {
    return this.signaturePad;
  }

  private getCanvas(): HTMLCanvasElement {
    return this.elementRef.nativeElement.querySelector('canvas');
  }
}