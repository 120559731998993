// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Program extends ChangeTrackingEntity {
  public static table: Dexie.Table<Program, string>;
  public static tableName: string = 'Program';

  public static ALLOW_DUPLICATE_EXTERNAL_NUMBER: string = 'allowDuplicateExternalNumber';  public static CREATED_DATE: string = 'createdDate';  public static DEFAULT_CHECKBOX_APPEARANCE_ID: string = 'defaultCheckboxAppearanceId';  public static DEFAULT_CHECKBOX_STYLE_ID: string = 'defaultCheckboxStyleId';  public static DEFAULT_IMAGE_SIZE_ID: string = 'defaultImageSizeId';  public static DESCRIPTION: string = 'description';  public static HAS_CHANGES_TO_APPLY: string = 'hasChangesToApply';  public static HAS_VERSIONS: string = 'hasVersions';  public static ID: string = 'id';  public static IS_ACTIVE: string = 'isActive';  public static IS_DELETED: string = 'isDeleted';  public static KEY_IDENTIFIER: string = 'keyIdentifier';  public static NAME: string = 'name';  public static NOTE: string = 'note';  public static NUMBER_PADDING: string = 'numberPadding';  public static NUMBER_PREFIX: string = 'numberPrefix';  public static OTHER_PROGRAM_TYPE: string = 'otherProgramType';  public static TIME_STAMP: string = 'timeStamp';  public static TYPE_ID: string = 'typeId';  public static USE_CUSTOM_FIELD_KEYS: string = 'useCustomFieldKeys';  public static USE_NOT_APPLICABLE: string = 'useNotApplicable';

  public allowDuplicateExternalNumber: boolean;  public createdDate: Date;  public defaultCheckboxAppearanceId: string;  public defaultCheckboxStyleId: string;  public defaultImageSizeId: string;  public description: string;  public hasChangesToApply: boolean;  public hasVersions: boolean;  public id: string;  public isActive: boolean;  public isDeleted: boolean;  public keyIdentifier: string;  public name: string;  public note: string;  public numberPadding: number;  public numberPrefix: string;  public otherProgramType: string;  public timeStamp: Date;  public typeId: number;  public useCustomFieldKeys: boolean;  public useNotApplicable: boolean;

  public constructor(args?: Partial<Program>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Program.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Program.tableName);
      this.table.mapToClass(Program);
  }
}
