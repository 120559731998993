import { Component, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { ListDataSourceResult } from 'src/app/components/list/listDatasourceResult';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { ProgramDataTemplate } from 'src/app/core/data/models/database/programDataTemplate.database';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { DataImportationSupervisor, DataSourceItem } from './dataImportationSupervisor';
import { FormInjector } from 'src/app/core/services/formInjector';

@Component({
  selector: 'app-data-importation',
  templateUrl: './data-importation.component.html',
  styleUrls: ['./data-importation.component.scss']
})
export class DataImportationComponent implements OnInit {
  selectedProgramDataTemplateId: string;
  selectedDataSearchItem: string;

  programId: string;
  auditId: string;

  templateDataSource: any;
  searchDataSource: any = [];

  canImportDataSource: boolean = false;

  importationInProgress: boolean = false;

  @ViewChild(PopupComponent) popup: PopupComponent;

  @ViewChild('dataTemplateList') dataTemplateList: ListComponent;
  @ViewChild('dataSearchList') dataSearchList: ListComponent;

  constructor(
    private supervisor: DataImportationSupervisor,
    private formInjector: FormInjector
  ) { }

  ngOnInit(): void {
    this.templateDataSource = async (args) => {
      let dataSource = await ProgramDataTemplate.table.where("programId").equals(this.programId).toArray();

      if (args.filter) {
        dataSource = dataSource.filter(x => ComparatorService.stringMatch(x.name, args.filter));
      }

      return new ListDataSourceResult({ groupedItems: _.sortBy(dataSource, x => x.name), itemCount: dataSource.length })
    };
  }

  async display(programId: string, auditId: string) {
    this.popup.width = "96%";
    this.popup.height = "50%";

    this.programId = programId;
    this.auditId = auditId;

    this.dataTemplateList.updateData();

    return await this.popup.display();
  }

  async dataSearchClick(item: any) {
    this.selectedDataSearchItem = item[item[DataSourceItem.SearchableColumnName]];

    this.canImportDataSource = true;
  }

  async programDataTemplateClick(item: ProgramDataTemplate) {
    this.selectedProgramDataTemplateId = item.id;

    let dataSource = this.supervisor.getDataSource(item);

    this.dataSearchList.items = dataSource;

    await this.dataSearchList.updateData();

    this.canImportDataSource = false;
  }

  async okButtonClick() {
    this.popup.close();

    this.importationInProgress = true;

    try {
      let values = await this.formInjector.getDataSourceValues(this.selectedProgramDataTemplateId, this.selectedDataSearchItem);

      await this.formInjector.inject(this.auditId, values)

      this.importationInProgress = false;

      await this.resetUI();

      this.popup.resolve(true);
    } catch (exception) {
      this.importationInProgress = false;
      this.popup.reject(exception);
    }

  }

  async cancelButtonClick() {
    await this.resetUI();

    this.popup.resolve(false);
    this.popup.close();
  }

  private async resetUI() {
    this.dataSearchList.items = [];

    await this.dataSearchList.updateData();

    this.selectedDataSearchItem = null;
    this.selectedProgramDataTemplateId = null;
  }
}
