import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { Program } from 'src/app/core/data/models/database/program.database';
import { UserAudit } from 'src/app/core/data/models/databaseLocal/userAudit.database';
import { SynchronizationHttpClient } from 'src/app/core/data/synchronization/synchronizationHttpClient';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AuditService } from '../audit/auditService';

@Component({
  selector: 'app-formtemplate-fill-redirect',
  templateUrl: './formtemplate-fill-redirect.component.html',
  styleUrls: ['./formtemplate-fill-redirect.component.scss']
})
export class FormTemplateFillRedirectComponent implements OnInit {
  private EXTERNAL_NUMBER: string = 'externalNumber';
  public failed = false;
  public failedMessage: string;
  public message: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auditService: AuditService,
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private translateService: TranslateService
  ) {
    this.message = this.translateService.instant("formtemplate.fill-redirect.defaultMessage");
    this.failedMessage = this.translateService.instant("formtemplate.fill-redirect.failedMessage");
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async (queryParams) => {
      const systemParameters = ["id", "prefix", this.EXTERNAL_NUMBER];

      let programId = queryParams.get('id').toUpperCase();
      let programPrefix = queryParams.get('prefix');
      let externalNumber = queryParams.get(this.EXTERNAL_NUMBER);

      let additionalDataInfos: any[] = [];

      for (const parameterName of queryParams.keys) {
        if (!systemParameters.find(x => x === parameterName)) {
          additionalDataInfos.push({ name: parameterName, value: queryParams.get(parameterName) });
        }
      }

      let program: Program = await this.getProgram(programId, programPrefix);

      // Currrently does not support subscription to a program with a prefix 
      // and is intentionnally not supported at this time.
      if (!program && programId) {
        await this.synchronizationHttpClient.subscribeToProgram(programId, new Date());
      }

      // Synchronize to ensure the form template is available and up to date
      // as well as the audit itself if it was created by an other mobile with a specific external number.
      await this.synchronizationService.getUpdates(false);

      if (!program) {
        program = await this.getProgram(programId, programPrefix);
      }

      if (externalNumber) {
        if (externalNumber.toString().length > 15) {
          this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.maximumExternalNumberLength"));
          return;
        }

        let existingUserAudit = await UserAudit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();
        if (existingUserAudit) {
          program = await Program.table.get(existingUserAudit.programId);

          if (program) {
            if (program.allowDuplicateExternalNumber) {
              this.message = this.translateService.instant("formtemplate.fill-redirect.existingFormDuplicate");
            } else {
              this.message = this.translateService.instant("formtemplate.fill-redirect.existingForm");
            }

            let existingAudit = await Audit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();

            if (!existingAudit) {
              await this.synchronizationHttpClient.subscribeToAudit(existingUserAudit.number);
              await this.synchronizationService.getUpdates(false);
              existingAudit = await Audit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();
            }

            if (existingAudit.isDeleted) {
              this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.auditDeleted"))
              return;
            }

            if (!program.allowDuplicateExternalNumber) {
              this.navigateToAudit(existingAudit);

              return;
            }
          }
        }
      }

      this.message = this.translateService.instant("formtemplate.fill-redirect.newForm");

      if (!programId && !programPrefix) {
        this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.noProgramIdOrPrefixNumber"));
        return;
      }


      this.validateProgram(program)

      let creationResult = await this.auditService.createAudit(program.id, externalNumber, additionalDataInfos);

      if (!creationResult.success) {
        this.failed = true;
        return;
      }

      this.navigateToAudit(creationResult.audit);
    });
  }

  private async getProgram(programId: string, programPrefix: string) {
    let program: Program = null;

    if (programId) {
      program = await Program.table.get(programId);
    } else if (programPrefix) {
      program = await this.getProgramByPrefix(programPrefix);
    }

    return program;
  }

  private async getProgramByPrefix(prefix: string) {
    // There can be more than one program with the same prefix but only one not deleted.
    // However, there can also be more than one delete program with the same prefix.
    // First, try to find the one not deleted.
    // If nothing found, then try to take the first one deleted.
    var programs = await Program.table.where("numberPrefix").equals(prefix).toArray();

    let program = null;

    if (programs.length > 1) {
      program = programs.find(x => !x.isDeleted);

      if (!program) {
        program = programs[0];
      }
    }
    else {
      program = programs[0];
    }

    return program;
  }


  private validateProgram(program: Program) {
    if (!program) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programNotExist"));
      return false;
    }

    if (program.isDeleted) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programDeleted"));
      return false;
    }

    if (!program.isActive) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programNotActive"));
      return false;
    }

    return true;
  }

  private markAsFailed(message: string) {
    this.failed = true;
    this.failedMessage = message;
  }

  private navigateToAudit(audit: Audit) {
    let auditRoute = `/forms/${audit.number}`;
    this.router.navigateByUrl(auditRoute);
  }

  public goToList() {
    this.router.navigateByUrl("/forms");
  }
}
