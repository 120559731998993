import { Inject, Injectable } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { Subject, filter, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthenticationType } from "./authenticationType";
import { IAuthenticationService } from "./iAuthenticationService.interface";
import { ConnectionService } from "../core/services/connectionService";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationAzureAdService implements IAuthenticationService {
  public canLogOff = true;

  private loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private _isAuthenticated: boolean;

  constructor(
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private connectionService: ConnectionService
  ) { }

  public async initialize() {
    if (!await this.connectionService.isOnline()) {
      return;
    }

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe(async (result: EventMessage) => {
        if (!this.authService.instance.getActiveAccount()) {
          await this.login();
        } else {
          this.setLoginDisplay();
        }
      });


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  public async isAuthenticated(): Promise<boolean> {
    return true;
  }

  public async login(): Promise<boolean> {
    if (!await this.connectionService.isOnline()) {
      return;
    }

    if (environment.authentication[AuthenticationType.AzureAd].interactionType === InteractionType.Redirect) {
      await this.loginRedirect();
    } else {
      await this.loginPopup();
    }

    return true;
  }

  public async logout() {
    if (!await this.connectionService.isOnline()) {
      return;
    }

    if (environment.authentication[AuthenticationType.AzureAd].interactionType === InteractionType.Redirect) {
      await this.authService.logoutRedirect();
    } else {
      await this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    }
  }

  public async destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private async loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      await this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      await this.authService.loginRedirect();
    }
  }

  private async loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      await this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      await this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }
}
