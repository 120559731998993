import { Injectable } from '@angular/core';
import _ from 'lodash';
import { UserAccountGroup } from '../data/models/database/userAccountGroup.database';
import { UserGroup } from '../data/models/database/userGroup.database';
import { WFStep } from '../data/models/database/wFStep.database';
import { WFStepAttribution } from '../data/models/database/wFStepAttribution.database';
import { WFStepExecutor } from '../data/models/database/wFStepExecutor.database';
import { Form } from '../data/models/form/form';

@Injectable()
export class WorkflowStepService {
  constructor(
  ) { }

  /**
  * Returns the last step of the form.
  * Generally used to indicate that the form is completed.
  */
  public getLastStep(form: Form){
    return form.steps[form.steps.length - 1];
  }

  public async getNextWorkflowSteps(workflowStepId: string, userId: string): Promise<WFStep[]> {
    let workflowStepAttributions = await WFStepAttribution.table
      .filter(x => x.currentStepId === workflowStepId
        && x.attributedStepId !== workflowStepId
        && x.canAccessStep)
      .toArray();

    let nextWorkflowSteps = await WFStep.table
      .where("id")
      .anyOf(workflowStepAttributions.map(x => x.attributedStepId))
      .toArray();

    nextWorkflowSteps = nextWorkflowSteps.filter(x => !x.isDeleted);

    let nextWorkflowStepsWithoutPermissions = await nextWorkflowSteps
      .filter(workflowStep => workflowStep.wFPermissionModeId === WFPermissionModeEnum.All);

    let nextWorkflowStepsWithPermissions = await nextWorkflowSteps
      .filter(workflowStep => workflowStep.wFPermissionModeId === WFPermissionModeEnum.Permissions);

    let allowedNextWorkflowStepsWithPermissions = [];

    if (nextWorkflowStepsWithPermissions.length > 0) {
      let workflowStepExecutorsWithCurrentGroups = await this.getExecutorsForUser(nextWorkflowStepsWithPermissions, userId);

      allowedNextWorkflowStepsWithPermissions = nextWorkflowStepsWithPermissions
        .filter(workflowStep => workflowStepExecutorsWithCurrentGroups
          .map(x => x.wFStepId)
          .includes(workflowStep.id)
        );
    }

    let result = _.concat(nextWorkflowStepsWithoutPermissions, allowedNextWorkflowStepsWithPermissions);

    result = _.sortBy(result, "number");

    return result;
  }

  public async getWorkflowStepModifyPermission(workflowStepId: string, userId: string): Promise<boolean> {
    let workflowStep = await WFStep.table.get(workflowStepId);

    if (workflowStep.wFPermissionModeId === WFPermissionModeEnum.All) {
      return true;
    } else if (workflowStep.wFPermissionModeId === WFPermissionModeEnum.Permissions) {
      let executors = await this.getExecutorsForUser([workflowStep], userId);

      for (let executor of executors) {
        if (executor.canModify)
          return true;
      }

      return false;
    }
  }

  private async getExecutorsForUser(workflowSteps: WFStep[], userId: string) {
    let workflowStepExecutors = await WFStepExecutor.table
      .where("wFStepId")
      .anyOf(workflowSteps.map(x => x.id))
      .toArray();

    let userAccountGroups = await UserAccountGroup.table
      .where("userId")
      .equals(userId)
      .toArray();

    let userGroups = await UserGroup.table
      .where("id")
      .anyOf(userAccountGroups.map(x => x.groupId))
      .toArray();

    let workflowStepExecutorsWithCurrentGroups = workflowStepExecutors
      .filter(workflowStepExecutor => _.includes(userGroups.map(userGroup => userGroup.id), workflowStepExecutor.userGroupId)
        && workflowStepExecutor.canAttributeStep);

    return workflowStepExecutorsWithCurrentGroups;
  }
}

enum WFPermissionModeEnum {
  All = 1,
  Permissions = 2,
}
