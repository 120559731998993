import { Component, OnInit, Input, ViewChild, ViewEncapsulation, OnDestroy, AfterViewInit, AfterContentInit, ElementRef } from "@angular/core";
import { BaseControlComponent } from "../basecontrol/basecontrol.component";
import { Subscription } from "rxjs";
import { AuditState } from "src/app/pages/audit/auditState";

@Component({
  selector: "app-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SignatureComponent extends BaseControlComponent implements OnInit, AfterViewInit, OnDestroy {
  public signatureImg: string;
  public signaturePadOptions: any;
  public readOnly: boolean = false;

  private valueChangesSubscription: Subscription;
  private skipValueChanges = false;

  @ViewChild('signaturePad') signaturePad: any;

  constructor(private auditState: AuditState) {
    super();

    this.signaturePadOptions = {
      minWidth: 0.5,
      canvasWidth: 300,
      canvasHeight: 70,
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.signaturePadOptions.canvasWidth = super.getExtendedProperty("Width") as number;
    this.signaturePadOptions.canvasHeight = super.getExtendedProperty("Height") as number;

    if (this.auditState.readonly) {
      this.readOnly = true;
    }
  }

  ngAfterViewInit() {
    this.loadImage(this.form.controls[this.input.key].value)

    // This will detect changes from the datasource to reset to the original image when user cancel changes.
    this.valueChangesSubscription = this.form.valueChanges.subscribe(x => {
      if (this.skipValueChanges)
        return;

      if (Object.keys(x)[0] == this.input.key) {
        this.loadImage(Object.values(x)[0]);
      }
    })

    if (this.readOnly) {
      this.signaturePad.off();
    }
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  public drawComplete() {
    this.skipValueChanges = true;
    this.form.controls[this.input.key].setValue(this.signaturePad.toDataURL());
    this.form.controls[this.input.key].markAsDirty();
    this.skipValueChanges = false;
  }

  public clearSignature() {
    this.signaturePad.clear();
    this.drawComplete();
  }

  private loadImage(image) {
    this.signaturePad.clear();

    if (image)
      this.signaturePad.fromDataURL(image);
  }
}
