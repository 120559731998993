export class NumberFormatter{
  public static format(value: any, format: string) : string {
    if (format[0] == "c") {
      return this.formatMoney(value, this.tryGetMinimumFractionDigits(format));
    } else if (format[0] == "p") {
      return this.formatPercent(value, this.tryGetMinimumFractionDigits(format));
    } else if (format[0] == "f") {
      return this.formatNumber(value, this.tryGetMinimumFractionDigits(format));
    } else {
      return this.formatCustom(value, format);
    }
  }

  /**
  * Will try to get the numeric part of a format like c2, f2 or p2.
  */
  private static tryGetMinimumFractionDigits(format: string): number{
    if (!format || format.length <= 1)
      return 0;

    let minimumFractionDigits = parseFloat(format.substring(1, format.length));

    if (this.isNumber(minimumFractionDigits))
      return minimumFractionDigits;
    else
      return 0;
  }

  public static formatCustom(value: any, format: string): string{
    let fraction: string = "";
    let integer: string = "";
    let hasFractionMinimum: boolean = false;
    let hasIntegerMinimum: boolean = false;
    let beforeSymbol: string = "";
    let afterSymbol: string = "";
    let hasGrouping: boolean = false;
    let beforeSymbolEnded: boolean = false;
    let hasDigit: boolean = false;

    if (!this.isNumber(value))
      return "";

    let numericValue = parseFloat(value);

    if (!format)
      return !!value ? numericValue.toString() : "";

    for (const formatCharacter of format) {
      if (formatCharacter == "0" || formatCharacter == "#"){
        beforeSymbolEnded = true;

        if (hasDigit){
          fraction += formatCharacter;
        }
        else{
          integer += formatCharacter;
        }

        if (formatCharacter == "0"){
            if (hasDigit){
                hasFractionMinimum = true;
            }
            else{
                hasIntegerMinimum = true;
            }
        }
      }
      else if (formatCharacter == ","){
        hasGrouping = true;
      }
      else if (formatCharacter == "."){
        hasDigit = true;
      }
      else if (hasDigit){
        afterSymbol += formatCharacter;
      }
      else{
        if (beforeSymbolEnded)
            throw new Error("Invalid symbol character after the begin of constraint integer characters.");

        beforeSymbol += formatCharacter;
      }
    }

    let result: string = "";

    if (hasFractionMinimum){
      result = new Intl.NumberFormat("en", {
        minimumIntegerDigits: hasIntegerMinimum ? integer.length : 1,
        minimumFractionDigits: fraction.length,
        useGrouping: hasGrouping
      }).format(numericValue)
    }
    else{
      result = new Intl.NumberFormat("en", {
        minimumIntegerDigits: hasIntegerMinimum ? integer.length : 1,
        maximumFractionDigits: fraction.length,
        useGrouping: hasGrouping
      }).format(numericValue)
    }

    return beforeSymbol + result + afterSymbol;
  }

  public static formatPercent(value: string, minimumFractionDigits?: number): string{
    if (!minimumFractionDigits)
    minimumFractionDigits = 0;

    return new Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: minimumFractionDigits, signDisplay: "never" }).format(parseFloat(value));
  }

  public static formatMoney(value: string, minimumFractionDigits?: number) : string{
    if (!minimumFractionDigits)
      minimumFractionDigits = 0;

    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: minimumFractionDigits }).format(parseFloat(value));
  }

  public static formatNumber(value: string, minimumFractionDigits?: number): string{
    if (!minimumFractionDigits)
      minimumFractionDigits = 0;

    return new Intl.NumberFormat('en', { style: 'number', minimumFractionDigits: minimumFractionDigits }).format(parseFloat(value));
  }

  private static isNumber(value?: string | number): boolean
  {
    return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
  }
}
