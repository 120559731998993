<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <dx-radio-group [formControlName]="input.key"
                  [items]="items"
                  valueExpr="id"
                  displayExpr="text"
                  layout="horizontal"
                  [readOnly]="readOnly"
                  (onValueChanged)="handleValueChange($event)"
                  [id]="input.key">
  </dx-radio-group>
</div>
