<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <div class="signature layout-horizontal">
    <div class="layout-left align-left">
      <button type="button" class="signIcon">
        <i class="fas fa-signature"></i>
      </button>
    </div>
    <div class="layout-left align-left">
      <app-signature-pad #signaturePad [options]="signaturePadOptions" (drawEnd)="this.drawComplete()" [id]="input.key">
      </app-signature-pad>
    </div>
    <div class="layout-left align-left">
      <button type="button" (click)="this.clearSignature()" class="clear-button" [id]="'ClearSignatureButton-' + input.key"
        *ngIf="!readOnly">
        <i class="fas fa-redo"></i>
      </button>
    </div>
  </div>
</div>
