import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AbstractFormGroupDirective, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, RequiredValidator, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DynamicTabAuditTemplate } from 'src/app/core/data/models/database/dynamicTabAuditTemplate.database';
import { DynamicTabFolderViewModel } from '../audit-sections/dynamicTabFolderViewModel';
import { AuditService } from '../auditService';
import { AuditState } from '../auditState';
import { CreateDynamicTabInstanceResult } from '../createDynamicTabInstanceResult';
import { Observable, timer } from 'rxjs';
import { DynamicSection } from 'src/app/core/data/models/form/dynamicSection';
import { Form } from 'src/app/core/data/models/form/form';

@Component({
  selector: 'app-new-dynamic-tab-instance',
  templateUrl: './new-dynamic-tab-instance.component.html',
  styleUrls: ['./new-dynamic-tab-instance.component.scss']
})
export class NewDynamicTabInstanceComponent {
  @ViewChild('popup') popup: PopupComponent

  private id: string;

  private _visible: boolean;

  public get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;

    if (!value && this.folder)
      this.folder.inEdition = false;
  }

  public folder: DynamicTabFolderViewModel;

  public title: string;
  public titleIcon: string;
  public saveButtonText: string;

  public alreadyExistingInstance = false;
  public templateIsEmpty = true;
  public task: Promise<void | CreateDynamicTabInstanceResult>;

  modalDialog = new SimplePopupOptions();

  form = new UntypedFormGroup({
    position: new UntypedFormControl('', [Validators.required, Validators.maxLength(10), this.alreadyExistingInstanceValidator()]),
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
  });

  constructor(
    public readonly auditState: AuditState,
    private auditService: AuditService,
    private router: Router,
    private baseRepository: BaseRepository
  ) { 
  }

  public async show(id: string, folder: DynamicTabFolderViewModel) {
    folder.inEdition = true;

    let templateSection;

    // This function is called both in edition and in add mode and the way to get the template section is different.
    // In edition, the focus in is the instance itself. In add mode, the focus is the folder so we need to get the template section from the folder 
    // and find the related template.
    if (this.auditState.parentSectionId)
      templateSection = Form.findSection(this.auditState.form, this.auditState.parentSectionId);
    else{
      let parentSection = this.auditState.form.sections.find(x => x.id === folder.sectionId) as DynamicSection;

      templateSection = parentSection.templates.find(x => x.templateId === folder.templateId);
    }

    this.templateIsEmpty = !templateSection

    this.popup.display();

    this.id = id;
    this.folder = folder;

    if (id) {
      let instance = await this.baseRepository.get(DynamicTabAuditTemplate.table, id);

      this.position.setValue(instance.number);
      this.name.setValue(instance.description);

      this.titleIcon = 'fas fa-edit';
      this.title = "newDynamicTabInstance.updateTitle";
      this.saveButtonText = "newDynamicTabInstance.actions.update";
    }
    else {
      let number = this.auditService.getNextDynamicTabInstanceNumber(folder).toString();
      let description = folder.description;

      this.position.setValue(number);
      this.name.setValue(description);

      this.titleIcon = 'fas fa-plus';
      this.title = "newDynamicTabInstance.addTitle";
      this.saveButtonText = "newDynamicTabInstance.actions.add";
    }
  }

  close() {
    this.folder.inEdition = false;

    this.popup.close();
  }

  get position() {
    return this.form.controls["position"];
  }

  get name() {
    return this.form.controls["name"];
  }

  async save(): Promise<void> {
    if (!this.form.valid || this.task) {
      return;
    }

    if (this.id) {
      this.task = this.auditService.updateDynamicTabInstance(
        this.id,
        this.position.value,
        this.name.value);

      await this.task;

      this.auditState.section.description = this.position.value + " - " + this.name.value;
    }
    else {
      let result: CreateDynamicTabInstanceResult;

      this.task = this.auditService.createDynamicTabInstance(
        this.folder, this.auditState.audit.id, this.auditState.form,
        this.position.value,
        this.name.value,
        this.folder.instances.length + 1);

      result = await this.task as CreateDynamicTabInstanceResult;

      this.id = result.dynamicTabAuditTemplate.id;
    }

    await this.auditService.loadDynamicTabInstances(this.auditState.audit.id, this.auditState.form);

    await this.auditState.refreshState();

    await this.router.navigate([`/forms/${this.auditState.auditNumber}/instances/${this.id}`]);
    this.close();
    this.task = null;
  }

  alreadyExistingInstanceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = this.folder?.instances?.find(x => x.number === control.value && x.id != this.id) != null;
      return forbidden ? { alreadyExisting: { value: control.value } } : null;
    };
  }
}
