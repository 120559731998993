import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { BaseListDetailsState } from "src/app/components/custom-field-value-lists/baseListDetailsState";
import { CustomFieldValueListService } from "src/app/components/custom-field-value-lists/customFieldValueListService";
import { CustomFieldValueItemRepository } from "src/app/core/data/repositories/customFieldValueItemRepository";


@Injectable()
export class CustomFieldValueListResolver  {
  constructor(
    private customFieldValueListsService: CustomFieldValueListService,
    private customFieldValueItemRepository: CustomFieldValueItemRepository,
    private baseListDetailsState: BaseListDetailsState) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    let customFieldValueLists = await this.customFieldValueListsService.getAll();
    
    for(let customFieldValueList of customFieldValueLists){
      customFieldValueList.customFieldValueItems = await this.customFieldValueItemRepository.getItemsByListId(customFieldValueList.id, "position");
    }
  
    this.baseListDetailsState.init(customFieldValueLists);
  }
}
