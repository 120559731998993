<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <dx-text-area [ngClass]="{'borderless' : hideBorder}" #controlMultiline *ngIf="dataType === dataTypes.String && multiLine"
    [formControlName]="input.key" [maxLength]="maxLength" [height]="controlHeight" [readOnly]="readOnly" [id]="input.key">
  </dx-text-area>

  <dx-text-box [ngClass]="{'borderless' : hideBorder}" #control *ngIf="dataType === dataTypes.String && !multiLine"
    [formControlName]="input.key" [maxLength]="maxLength" [format]="displayFormat" valueChangeEvent="input"
    [readOnly]="readOnly" [id]="input.key" [showClearButton]="true">
  </dx-text-box>

  <dx-text-box #controlMaskEdit *ngIf="input.controlType === controlTypes.MaskedEditTextBox"
    [formControlName]="input.key" [mask]="inputMask" [readOnly]="readOnly" [id]="input.key" 
    valueChangeEvent="input" [showClearButton]="true" maskInvalidMessage="">
  </dx-text-box>

  <div class="controlContainer">
    <dx-number-box [ngClass]="{'borderless' : hideBorder}" #controlNumeric *ngIf="dataType === dataTypes.Decimal" [formControlName]="input.key"
      [readOnly]="readOnly" [format]="displayFormat" (onValueChanged)="onValueChanged($event)" valueChangeEvent="keyup"
      class="control" (onFocusOut)="focusOut()" showClearButton="true" [id]="input.key">
    </dx-number-box>
  </div>
</div>

