import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { AuditDuplicateService } from './auditDuplicateService';
import { DuplicateStep as DuplicateStep } from './duplicateStep';

@Component({
  selector: 'app-duplicate',
  templateUrl: './audit-duplicate.component.html',
  styleUrls: ['./audit-duplicate.component.scss']
})
export class AuditDuplicateComponent implements OnInit {

  public inError: boolean;

  public duplicatingStep: DuplicateStep = DuplicateStep.validating;

  public get DuplicateStep() { return DuplicateStep }

  constructor(
    private headerState: HeaderState,
    private auditduplicateService: AuditDuplicateService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.headerState.title = "";
    this.headerState.useBackButton = false;
    this.inError = false;

    // url format : /forms/:auditNumber/duplicate
    let auditNumber = this.router.url.split("/")[2];

    await this.validateAuditNumber(auditNumber);
    await this.duplicate(auditNumber);
  }

  public navigateToHome(): void {
    this.router.navigate([`/home`]);
  }

  private async validateAuditNumber(auditNumber: string): Promise<void> {
    if (await this.auditduplicateService.validateAuditNumber(auditNumber)) {
      this.duplicatingStep = DuplicateStep.duplicating;
    } else {
      this.router.navigate([`/forms/notfound/${auditNumber}`]);
    }
  }

  private async duplicate(auditNumber: string): Promise<void> {
    let newAuditNumber = await this.auditduplicateService.duplicate(auditNumber);
    if (newAuditNumber) {
      this.router.navigate([`/forms/${newAuditNumber}`]);
    }
    else {
      this.inError = true;
    }
  }
}
