import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HealthHttpClient } from 'src/app/core/security/healthHttpClient';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {

  public healthChecks: any[] = [];
  public loading = false;

  constructor(
    private healthHttpClient: HealthHttpClient, private translateService: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    let result;
    try {
      result = await this.healthHttpClient.getHealth();
    } catch (error) {
      result = error.error;
    }

    for (let key in result.entries) {
      this.healthChecks.push({
        name: this.translateService.instant(`healthPage.${key}Title`),
        description: this.translateService.instant(`healthPage.${key}Description`),
        status: this.getHealthStatus(result.entries[key].status)
      });
    }

    this.loading = false;
  }

  private getHealthStatus(status: string){
    if (status == "Healthy")
      return this.translateService.instant("healthPage.validState");
    else
      return this.translateService.instant("healthPage.invalidState");
  }
}