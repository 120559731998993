<app-modal #sectionPopup></app-modal>

<form [formGroup]="auditForm">
  <div class="layout-horizontal padding-left height-normal color-set-lightblue">
    <div class="layout-left">
      <h1>{{ 'form.information.form' | translate }}</h1>
    </div>
    <div class="layout-right">
      <button class="color-set-transparent" type="button" (click)="onSaveChangesClick()"
        [disabled]="!auditForm.dirty || auditForm.invalid">
        <i class="fas fa-save"></i>
      </button>
      <button type="button" (click)="onRevertChangesClick()" [disabled]="!auditForm.dirty">
        <i class="fas fa-rotate-left"></i>
      </button>
    </div>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.number"></label><br>
    <label class="auditNumber">{{ auditState.auditNumber }}</label>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="form.information.externalNumber"></label><br>
    <dx-text-box formControlName="externalNumber" maxLength="15"></dx-text-box>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.responsible" [formGroup]="auditForm" fieldControlName="responsibleId"
      appMarkAsterisk></label>

    <dx-select-box [dataSource]="responsibles" valueExpr="id" displayExpr="text" [searchEnabled]="true"
      formControlName="responsibleId">
    </dx-select-box>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.step" [formGroup]="auditForm" fieldControlName="stepId"
      appMarkAsterisk></label>
    <div class="layout-horizontal color-set-white">
      <div class="layout-left">
        <label>{{auditState?.workflowStep?.name || ''}}</label>
      </div>
      <div class="layout-right">
        <button
          *ngIf="workflowStepPopup && workflowStepPopup.workflowSteps && workflowStepPopup.workflowSteps.length > 1"
          type="button" class="color-set-green" (click)="showWorkflowStepPopup()" id="NextStepButton">
          <div><span>{{ 'workflowStepPopup.changeStepButton' | translate }}</span></div>
        </button>
        <button
          *ngIf="workflowStepPopup && workflowStepPopup.workflowSteps && workflowStepPopup.workflowSteps.length === 1"
          type="button" class="color-set-green" (click)="showWorkflowStepPopup()" id="NextStepButton">
          <div><span>{{ 'workflowStepPopup.changeStepTo' | translate }}
              {{workflowStepPopup.workflowSteps[0].name}}</span></div>
        </button>
      </div>
    </div>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.effectiveDate" [formGroup]="auditForm" fieldControlName="effectiveDate"
      appMarkAsterisk></label>

    <dx-date-box id="effectiveDate" formControlName="effectiveDate" type="date">
    </dx-date-box>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.createdBy"></label>

    <dx-select-box [dataSource]="creators" valueExpr="id" displayExpr="text" [searchEnabled]="true"
      formControlName="createdById" [readOnly]="true">
    </dx-select-box>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.createdDate"></label>

    <dx-date-box id="createdDate" formControlName="createdDate" type="date" required [readOnly]="true">
    </dx-date-box>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="audit.updatedDate"></label>

    <dx-date-box id="updatedDate" formControlName="updatedDate" type="date" required [readOnly]="true">
    </dx-date-box>
  </div>
</form>

<div>
  <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
    <div class="layout-left">
      <h1>{{ 'form.information.formTemplate' | translate}}</h1>
    </div>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="form.information.formTemplatePrefix"></label>
    <div>{{ auditState.program.numberPrefix }}</div>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="form.information.formTemplateDescription"></label>
    <div>{{ auditState.program.description }}</div>
  </div>

  <div class="zone field-zone">
    <label class="field" translate="form.information.formTemplateUpdatedDate"></label>
    <div>{{ auditState.program.timeStamp }}</div>
  </div>
</div>


<app-popup [visible]="updateStepVisible" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal padding-normal height-normal color-set-blue">
        <div class="layout-left">
          <h1>{{ 'form.information.nextStep' | translate }}</h1>
        </div>
      </div>
    </div>

    <div class="layout-center">
      <app-list [items]="steps" [enableFilter]="true" (selectedItemsChange)="workflowStepSelected($event)">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal height-normal padding-horizontal-normal">
            <div class="layout-left">
              {{item.text}}
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom padding-compact">
      <div class="layout-horizontal">
        <div class="layout-right">
          <button type="submit" class="color-set-green" [disabled]="!stepForm.dirty">
            <div><span>{{ 'form.information.changeStep' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>

<app-workflow-step-popup #workflowStepPopup [userId]="authenticatedUser.id"
  (workflowStepSelected)="workflowStepSelected($event)">
</app-workflow-step-popup>
