import { AfterViewInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { Logger } from 'src/app/core/log/logger';
import { AlertService } from 'src/app/core/utilities/alertService';
import { environment } from 'src/environments/environment';
import platform from 'platform';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements AfterViewInit, OnDestroy {
  public visible: boolean;
  public supportNativeCamera: boolean;
  public deviceCount = 0;
  public imageUrl: any;

  // Properties for native camera support
  public cameraInputElement: HTMLInputElement;

  // Properties custom camera support
  public imageElement: HTMLImageElement;
  public imageWidth: any;
  public imageHeight: any;

  public camera: MediaStream;
  public flashlightEnabled: boolean;
  public flashEnabled: boolean;
  public flashlightActivated: boolean;
  public videoElement: HTMLVideoElement;

  private canvas: HTMLCanvasElement = document.createElement("canvas");
  private cameraIndex = null;

  private NSPEK_CAMERA_DEVICEID = "nspek-camera-deviceId";

  constructor(
    private translateService: TranslateService,
    private logger: Logger
  ) { }

  ngAfterViewInit(): void {
    this.supportNativeCamera = this.supportCapture() && !environment.disableNativeCamera;
  }

  @Output() photoChange: EventEmitter<boolean> = new EventEmitter();

  // Set and dispose of the video element while also making sure the
  // video element is not kept alive. The mediaStream must be called
  // idealy only once while open to avoid not being able to fully
  // dispose the camera. For this reason I kept the job of opening
  // and closing the camera to this component / html element while
  // considering the html element can be removed though ngIf conditions.
  @ViewChild('video', { read: ElementRef, static: false }) set videoElementSet(content: ElementRef<HTMLVideoElement>) {
    if (content) {
      this.videoElement = content.nativeElement;

      (async () => {
        await this.initializeCamera();
      })();

    } else {
      this.closeCamera();
      delete this.videoElement
    }
  }

  // Set local context element considering ngIf conditions on the html element.
  @ViewChild('image', { read: ElementRef, static: false }) set imageElementSet(content: ElementRef<HTMLImageElement>) {
    if (content) {
      this.imageElement = content.nativeElement;
    }
  }

  // Set local context element considering ngIf conditions on the html element.
  @ViewChild('cameraInput', { read: ElementRef, static: false }) set cameraInputSet(content: ElementRef<HTMLInputElement>) {
    if (content) {
      this.cameraInputElement = content.nativeElement;
    }
  }

  @ViewChild(PopupComponent) popup: PopupComponent;

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.closeCamera();
    delete this.videoElement
  }

  /* 
   * Setting the visibility of the camera component serves more that
   * simply displaying the component. The component has 2 main behaviors
   * which are Windows and Android/iOS.
   *
   * On Windows, the camera is opened though the custom camera, it works by
   * displaying the video element, camera component listen to that event
   * through the @ViewChild('video', ...) and use the mediaStream library 
   * to find and display the camera.
   *
   * On Android/iOS, the camera is opened through the native camera which
   * is a simple HTML5 input. However, because we do not want to display
   * the input itself and want the camera icon to be the action trigger
   * used by the users, we need to trigger that input click event manually.
   * This is currently done after setting the visibility to true if 
   * supportNativeCamera is true which should have been evaluated in the 
   * afterViewInit() method.
   *
   * In other words, the Android/iOS path is very clear in the function, but
   * the Windows path is completly hidden behind the visible property, which
   * trigger @ViewChild('video', ...)  which trigger the camera initialization
   * and so on.
   * */
  async open() {
    this.visible = true;

    if (this.supportNativeCamera) {
      setTimeout(() => {
        // Delay click to allow dom tree to be updated considering
        // the change of camera support. When not delayed two click
        // are required to open the camera which is not desired.
        this.cameraInputElement.click();
      });
    }
  }

  async capturePhoto() {
    let delayMs = 0;

    // Flashlight can be enabled if the browser doesn't supports native
    // camera and the flashlight is supported which currently only
    // in Chrome mobile and desktop. Actual camera flash needs to be
    // simulated for this reason we open the flashlight and set it to
    // take the photo half a second later and close the flashlight
    // an other 0.3 seconds later.
    // Note: Flash is currently in beta an could not be tested in
    // Chrome on Windows 10 due to the lack of access to a camera
    // with a flash. More information need to be gathered from
    // client devices to know if it fully work.
    if (this.flashEnabled) {
      delayMs = 500;
      await this.setFlashlight(true);

      setTimeout(() => {
        this.setImageUrl();

        if (this.flashEnabled) {
          setTimeout(async () => {
            await this.setFlashlight(false);
          }, 300);
        }
      }, delayMs);
    } else {
      this.setImageUrl();
    }
  }

  private setImageUrl() {
    this.canvas.width = this.videoElement.videoWidth;
    this.canvas.height = this.videoElement.videoHeight;

    this.canvas.getContext("2d").drawImage(this.videoElement, 0, 0);
    this.imageUrl = this.canvas.toDataURL();
  }

  async confirmPhoto(cameraEvent) {
    let file;

    if (this.supportNativeCamera)
      file = cameraEvent.target.files[0];
    else
      file = this.imageUrl;

    // When native camera is supported, the photo taken is saved into the
    // input triggered by the change event after the confirmation has been
    // made by the user. But the conversion of the image to base64 not yet
    // done so we complete the conversion so it can be transmitted with this
    // component photoChange event.
    if (this.supportNativeCamera) {
      this.imageUrl = await this.toBase64(cameraEvent.target.files[0]);
    }

    this.photoChange.emit(this.imageUrl);

    this.clearPhoto();

    await this.close();
  }

  async clearPhoto() {
    // Makes sure the full canvas is cleared before the next photo is taken
    // so that the previous photo cropped into the current photo.
    this.canvas.getContext("2d").clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.imageUrl = null;

    if (this.imageElement) {
      this.imageElement.src = null;
    }
  }

  async close() {
    this.clearPhoto();
    this.visible = false;
  }

  public async initializeCamera(cameraIndex = null) {
    let cameras = await this.getCameras();

    if (!cameras || cameras.length === 0) {
      this.popup.display();
      this.visible = false;
      return;
    }

    this.deviceCount = cameras.length;

    if (!Number.isInteger(cameraIndex) || cameraIndex >= cameras.length || cameraIndex < 0) {
      let deviceId = localStorage.getItem(this.NSPEK_CAMERA_DEVICEID);
      cameraIndex = cameras?.findIndex((x => x?.deviceId === deviceId)) || 0;

      if (cameraIndex === -1) {
        cameraIndex = 0;
      }
    }

    let deviceId = cameras[cameraIndex].deviceId;
    localStorage.setItem(this.NSPEK_CAMERA_DEVICEID, deviceId)

    // Set the camera constraint to filter only the camera with the specified
    // deviceId found the the device list through enumerateDevices();
    // The height and width are set to make sure the image is taken with
    // maximum resolution up to 4K.
    // Note: This could be configured in the future to be displayed to the user
    const contraints: MediaStreamConstraints = {
      video: {
        deviceId: deviceId,
        width: { ideal: 4096 },
        height: { ideal: 2160 }
      }
    }

    let camera = null;

    try {
      camera = await navigator.mediaDevices.getUserMedia(contraints);
    } catch (error) {
      if (error.message === "Device in use") {
        AlertService.show(this.translateService.instant("components.customFields.pictureBox.cameraAlreadInUse"))
      } else {
        switch (error.name) {
          case "NotAllowedError":
            AlertService.show(this.translateService.instant("components.customFields.pictureBox.cameraNotAllowed"));
            break;
          case "NotReadableError":
          case "NotFoundError":
            AlertService.show(this.translateService.instant("components.customFields.pictureBox.cameraNotFound"));
            break;
          default:
            AlertService.show(this.translateService.instant("components.customFields.pictureBox.cameraUnhandledException"));

            // This error is logged to allow in the future add use cases to handle more exceptions.
            await this.logger.logInformation(this.translateService.instant("components.customFields.pictureBox.cameraUnhandledException"), error, "CameraComponent.initializeCamera");

            break;
        }
      }
    }

    if (camera) {
      if (environment.cameraFlash) {
        const track = await this.camera.getVideoTracks()[0];

        // @ts-ignore Ignore because ImageCapture is not in the typings
        // and is supported only in Chrome
        const imageCapture = new ImageCapture(track);
        const photoCapabilities = await imageCapture.getPhotoCapabilities();

        this.flashlightEnabled = photoCapabilities.fillLightMode && photoCapabilities.fillLightMode.length > 0;
      }

      this.camera = camera;

      if (!camera) {
        this.popup.display();
        this.visible = false;
      }
    }
  }

  public async switchCamera() {
    this.closeCamera();

    let cameras = await this.getCameras();

    this.cameraIndex++;

    if (this.cameraIndex >= cameras.length) {
      this.cameraIndex = 0;
    }

    await this.initializeCamera(this.cameraIndex);
  }

  public async setFlashlight(status) {
    const track = await this.camera.getVideoTracks()[0];

    await track.applyConstraints({
      // @ts-ignore this does not compile but is supported
      // in chrome;
      advanced: [{ torch: status }]
    });
  }

  public toggleFlash() {
    this.flashEnabled = !this.flashEnabled;
  }

  public async toggleFlashlight() {
    this.flashlightActivated = !this.flashlightActivated;
    await this.setFlashlight(this.flashlightActivated);
  }

  private closeCamera() {
    if (this.camera) {
      // This close all tracks for the camera. However it does not mean
      // the camera is closed. It can happen that the camera is still used
      // via an other component.
      this.camera.getTracks().forEach((track) => {
        track.stop();
      });

      this.camera = null;
    }
  }

  // Feature check to know if the browser supports the camera
  // natively. Should be true in Android and iOS.
  private supportCapture() {
    let supportCapturePlatform = environment.nativeCameraSupportedPlatforms;
    let currentPlatform: string = platform.description;
    let supported = false;

    for (const platform of supportCapturePlatform) {
      if (currentPlatform.toLowerCase().indexOf(platform) > -1) {
        supported = true;
        break;
      }
    }

    return supported;
  }

  private async toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private async getCameras() {
    // Get device list without actually opening the camera.
    let devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(x => x.kind === 'videoinput');
  }
}
