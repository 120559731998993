import { Dexie } from "dexie";
import { DatabaseContext } from "../../databaseContext";

export class FormTemplateNextNumber {
  public static table: Dexie.Table<FormTemplateNextNumber, number>;
  public static tableName: string = 'FormTemplateNextNumber';

  public static PROGRAM_ID: string = 'programId';
  public static NEXT: string = 'next';

  public programId: string;
  public next: number;

  public constructor(args?: Partial<FormTemplateNextNumber>) {
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return FormTemplateNextNumber.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(FormTemplateNextNumber.tableName);
      this.table.mapToClass(FormTemplateNextNumber);
  }
}
