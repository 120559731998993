import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ListComponent } from '../../list/list.component';
import { PopupComponent } from '../../popup/popup.component';
import { ListDataSourceFunctionResult } from '../../list/listDatasourceFunctionResult';
import { DataSourceItem } from 'src/app/core/data/viewModels/dataSourceItem';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent implements OnInit {
  @Input() public title: string;

  // Lists inputs and outputs
  @Input() public items: any[];
  @Input() public dataSource: Function;

  @Input() public numberOfRequiredItems: number = 0;
  @Input() public numberOfSelectableItem: number = 1;
  @Input() public selectedItems: any | any[];
  @Input() public readOnly: boolean = false;
  @Input() public useDefaultSelect: boolean;

  @Output() selectedItemsChange = new EventEmitter<any>();
  @Output() activatedItem = new EventEmitter<any>();

  @ViewChild('list') list: ListComponent;
  @ViewChild('popup') popup: PopupComponent;

  @ContentChild('dropdownListTemplate') dropdownListTemplate: TemplateRef<any>;
  @ContentChild('dropdownSelectedTemplate') dropdownSelectedTemplate: TemplateRef<any>;

  /**
   * Public for HTML
   * These properties needs to be known by the drowdown implementation as they will
   * sent to the implementer query function for appropriate filtering.
   */
  public Array = Array;
  public itemList: any[];

  constructor() { }

  public async ngOnInit(): Promise<void> {
    if (this.useDefaultSelect && this.numberOfSelectableItem > 0) {
      if (this.dataSource) {
        let listDatasourceFunctionResult: ListDataSourceFunctionResult = await this.dataSource(this);
        this.itemList = listDatasourceFunctionResult.items;
      }
      else if (this.items) {
        this.itemList = this.items;
      }
    }
  }

  public showDropdown() {
    if (this.readOnly)
      return;

    this.popup.display();

    // Require a very slight delay for the focus to occur correctly. Delaying
    // the focus on the next instruction set seems to work correctly.
    setTimeout(() => {
      this.list.inputFilterElement.nativeElement.focus();
    });
  }

  public updateData(){
    this.list.updateData();
  }

  public async onSelectedItemsChange(item: any) {
    // This code is required to manage the case where a classic dropdown is used and only
    // an id is passed in parameters.
    if (typeof item === "string") {
      let customFieldValueItem = await CustomFieldValueItem.table.get(item);
      item = new DataSourceItem(customFieldValueItem.id, customFieldValueItem.description)
    }

    this.selectedItemsChange.emit(item);

    if (this.numberOfSelectableItem === 1) {
      this.popup.close();
    }
  }
}
