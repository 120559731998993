import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PopupUtility } from "src/app/components/popup/popup.utility";
import { SimplePopupComponent } from "src/app/components/popup/simplePopup/simplePopup.component";
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { WorkflowStepPopupComponent } from "src/app/components/workflowstep/workflow-step-popup/workflow-step-popup.component";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { AuditState } from "src/app/pages/audit/auditState";
import { NavigationService } from "src/app/core/navigationService";

@Component({
  selector: "app-audit-navigation",
  templateUrl: "./audit-navigation.component.html",
  styleUrls: ["./audit-navigation.component.scss"],
})
export class AuditNavigationComponent implements OnInit {
  public firstSectionId: string;
  public lastSectionId: string;

  private accessibleSections: Section[];

  @ViewChild('workflowStepPopup') workflowStepPopup: WorkflowStepPopupComponent;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;
  @Output() stepChanging = new EventEmitter();

  constructor(
    public readonly auditState: AuditState,
    public authenticatedUser: AuthenticatedUser,
    public baseRepository: BaseRepository,
    private translateService: TranslateService,
    private router: Router,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.accessibleSections = this.auditState.form.sections.filter(x => x.type !== SectionType.DynamicTab);

    this.firstSectionId = this.accessibleSections[0].id;
    this.lastSectionId = this.accessibleSections[this.accessibleSections.length - 1].id;
  }

  onPreviousSectionClick() {
    let currentSectionIndex = this.accessibleSections.map((x) => x.id).indexOf(this.auditState.section.id);

    if (currentSectionIndex === 0) {
      return;
    }

    let previousSectionId = this.accessibleSections[currentSectionIndex - 1].id;

    this.auditState.nativateToSection(previousSectionId)
  }

  onNextSectionClick() {
    let currentSectionIndex = this.accessibleSections.map((x) => x.id).indexOf(this.auditState.section.id);

    if (currentSectionIndex === this.accessibleSections.length - 1) {
      return;
    }

    let nextSectionId = this.accessibleSections[currentSectionIndex + 1].id;

    this.auditState.nativateToSection(nextSectionId)
  }

  public async showWorkflowStepPopup() {
    await this.stepChanging.emit(null);

    this.workflowStepPopup.display();
  }

  public async saveWorkflowStep(workflowStep) {
    await this.baseRepository.update(Audit.table, new Audit({ id: this.auditState.audit.id, stepId: workflowStep.id }), "AuditNavigationComponent.saveWorkflowStep");
    await this.auditState.updateWorkflowStep(workflowStep.id);
    this.workflowStepPopup.updateData();

    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService, this.translateService.instant("audit.returnToMainMenuConfirmationTitle"), this.translateService.instant("audit.returnToMainMenuConfirmationMessage"), x => {
      this.navigationService.navigateToParentUrl();
    });
  }
}
