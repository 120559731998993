import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { AuditAlertSummary } from 'src/app/core/data/models/database/auditAlertSummary.database';
import { AuditAlertSummarySatelliteDataItem } from 'src/app/core/data/models/database/auditAlertSummarySatelliteDataItem.database';
import { AuditDataTable } from 'src/app/core/data/models/database/auditDataTable.database';
import { AuditSubscription } from 'src/app/core/data/models/database/auditSubscription.database';
import { AutomaticDocumentExportationTelemetry } from 'src/app/core/data/models/database/automaticDocumentExportationTelemetry.database';
import { DynamicTabAuditTemplate } from 'src/app/core/data/models/database/dynamicTabAuditTemplate.database';
import { MobileEntity } from 'src/app/core/data/models/database/mobileEntity.database';
import { Program } from 'src/app/core/data/models/database/program.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { environment } from 'src/environments/environment';
import { AuditService } from '../auditService';
import { AuditImage } from 'src/app/core/data/models/database/auditImage.database';
import { Image } from 'src/app/core/data/models/database/image.database';
import _ from 'lodash';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';

@Injectable({
  providedIn: 'root',
})
export class AuditDuplicateService {
  private auditToDuplicate: Audit;
  private newAudit: Audit;

  constructor(
    private auditService: AuditService,
    private baseRepository: BaseRepository,
    private synchronizationService: SynchronizationService,
    private authenticatedUser: AuthenticatedUser
  ) {
  }

  private tablesMapping: RecordMapping[];
  private imagesMapping: RecordMapping[];

  public async validateAuditNumber(auditNumber: string): Promise<boolean> {
    return await this.auditService.validateAuditNumberExist(auditNumber);
  }

  public async duplicate(auditNumber: string): Promise<string> {
    await this.synchronizationService.getUpdates(false);

    await this.createNewAudit(auditNumber);

    await this.auditService.createAuditHeader(this.newAudit);

    await this.duplicateAuditDataTables();

    await this.createChangeTrackingEntity(AuditAlertSummary, await this.filterTableFromAudit(AuditAlertSummary));

    await this.createChangeTrackingEntity(AuditAlertSummarySatelliteDataItem, await this.filterTableFromAudit(AuditAlertSummarySatelliteDataItem));

    await this.createChangeTrackingEntity(MobileEntity, await this.filterTableFromAudit(MobileEntity));

    await this.createAuditSubscriptionChangeTracking();

    return this.newAudit.number;
  }

  private async filterTableFromAudit(table) {
    return (await table.table.toArray()).filter(row => row.auditId === this.auditToDuplicate.id);
  }

  private async createNewAudit(auditNumber: string) {
    this.auditToDuplicate = await Audit.table.where("number").equals(auditNumber).first();

    this.newAudit = new Audit();

    this.newAudit = await Audit.table.where("number").equals(auditNumber).first();

    let now = new Date();

    this.newAudit.createdDate = now;
    this.newAudit.updatedDate = now;
    this.newAudit.effectiveDate = now;
    this.newAudit.id = "";

    let program = await Program.table.get(this.newAudit.programId);

    this.newAudit.number = await this.auditService.getNextNumber(program);

    await this.setNewAuditStep();

    await this.baseRepository.insert(Audit.table, this.newAudit);
  }

  private async setNewAuditStep() {
    let steps: Array<WFStep> = await WFStep.table.toArray();
    let auditStep: WFStep = steps.find(step => step.id === this.newAudit.stepId);

    if (auditStep.number !== 1) {
      this.newAudit.stepId = steps.filter(step => step.programId === auditStep.programId)
        .find(step => step.number === 1).id;
    }
  }

  private async createChangeTrackingEntity(table, entries) {
    for (var entry of entries) {
      let newEntry = entry;

      let now = new Date();

      newEntry.timeStamp = now;
      newEntry.auditId = this.newAudit.id;
      newEntry.id = await Dexie.Observable.createUUID();

      switch (table) {
        case AuditAlertSummary:
          newEntry.createdDate = now;
        case AutomaticDocumentExportationTelemetry:
          newEntry.startTime = now;
      }

      await this.baseRepository.insert(table.table, newEntry);
    }
  }

  private async duplicateAuditDataTables() {
    let auditDataTables = await AuditDataTable.table.where("auditId").equals(this.auditToDuplicate.id).toArray();

    this.tablesMapping = [];
    this.imagesMapping = [];

    for (const auditDataTable of auditDataTables) {
      let newAuditDataTable = new AuditDataTable();

      newAuditDataTable.id = Dexie.Observable.createUUID();
      newAuditDataTable.auditId = this.newAudit.id;
      newAuditDataTable.tableId = newAuditDataTable.id;
      newAuditDataTable.tableName = auditDataTable.tableName;

      for (const key in auditDataTable) {
        switch (key) {
          case "id":
          case "auditId":
          case "tableId":
            break;
          default:
            newAuditDataTable[key] = auditDataTable[key]
        }
      }

      await this.baseRepository.insert(AuditDataTable.table, newAuditDataTable);

      this.tablesMapping.push({ oldId: auditDataTable.id, newId: newAuditDataTable.id });
    }

    let dynamicTabAuditTemplates = await DynamicTabAuditTemplate.table.where("auditId").equals(this.auditToDuplicate.id).toArray();

    await this.duplicateDynamicTabAuditTemplates(dynamicTabAuditTemplates);

    let auditImages: AuditImage[] = await AuditImage.table.where("auditId").equals(this.auditToDuplicate.id).toArray();
    let images: Image[] = await Image.table.where("auditId").equals(this.auditToDuplicate.id).toArray();

    await this.duplicateAuditImages(auditImages, images);
  }

  private async duplicateDynamicTabAuditTemplates(dynamicTabAuditTemplates: DynamicTabAuditTemplate[]) {
    for (const dynamicTabAuditTemplate of dynamicTabAuditTemplates) {
      let newDynamicTabAuditTemplate = new DynamicTabAuditTemplate();

      newDynamicTabAuditTemplate.id = Dexie.Observable.createUUID();
      newDynamicTabAuditTemplate.auditId = this.newAudit.id;

      const newId = this.tablesMapping.find(x => x.oldId === dynamicTabAuditTemplate.customTableId).newId;

      newDynamicTabAuditTemplate.customTableId = newId;

      for (const key in dynamicTabAuditTemplate) {
        switch (key) {
          case "id":
          case "auditId":
            break;
          case "customTableId":
            newDynamicTabAuditTemplate.customTableId = newId;

            break;
          case "referenceTableId":
            const newReferenceTableId = this.tablesMapping.find(x => x.oldId === dynamicTabAuditTemplate.referenceTableId).newId;

            newDynamicTabAuditTemplate.referenceTableId = newReferenceTableId;

            break;
          default:
            newDynamicTabAuditTemplate[key] = dynamicTabAuditTemplate[key]
        }
      }

      await this.baseRepository.insert(DynamicTabAuditTemplate.table, newDynamicTabAuditTemplate);
    }
  }

  /**
  * Images have to be duplicated grouped by audit data table records because each of them has a different customTableId
  * which is very important for alert and dynamic tab instances.
  */
  private async duplicateAuditImages(auditImages: AuditImage[], images: Image[]) {
    const auditImageToDupplicate = auditImages.filter(auditImage => auditImage.toDuplicate);

    for (const auditImage of auditImageToDupplicate) {
      await this.duplicateAuditImage(auditImage, images, this.newAudit.id);
    }
  }

  private async duplicateAuditImage(auditImage: AuditImage, images: Image[], auditId: string) {
    let newAuditImage = new AuditImage();

    newAuditImage.id = Dexie.Observable.createUUID();
    newAuditImage.auditId = auditId;

    const linkedImage = _.find(this.imagesMapping, { oldId: auditImage.imageId });

    if (linkedImage)
      newAuditImage.imageId = linkedImage.newId;
    else
      newAuditImage.imageId = await this.duplicateImage(images.find(image => image.id === auditImage.imageId));

    if (!!auditImage.originalImageId) {
      newAuditImage.originalImageId = await this.duplicateImage(images.find(image => image.id === auditImage.originalImageId));
    }

    newAuditImage.annotation = auditImage.annotation;
    newAuditImage.customFieldName = auditImage.customFieldName;
    newAuditImage.description = auditImage.description;
    newAuditImage.isLink = !!linkedImage;
    newAuditImage.toPrint = auditImage.toPrint;
    newAuditImage.toDuplicate = auditImage.toDuplicate;

    if (auditImage.customTableId)
      newAuditImage.customTableId = this.tablesMapping.find(x => x.oldId === auditImage.customTableId).newId;

    await this.baseRepository.insert(AuditImage.table, newAuditImage);
  }

  private async duplicateImage(image: Image): Promise<string> {
    let newImage = new Image();

    newImage.id = Dexie.Observable.createUUID();
    newImage.auditId = this.newAudit.id;
    newImage.value = image.value;

    await this.baseRepository.insert(Image.table, newImage);

    this.imagesMapping.push({ oldId: image.id, newId: newImage.id });

    return newImage.id;
  }

  private async createAuditSubscriptionChangeTracking() {
    let newAuditSubscription = new AuditSubscription();

    let now = new Date();

    newAuditSubscription.startDate = now;

    let endDate = new Date();
    endDate.setDate(endDate.getDate() + environment.auditSubscriptionDays);

    newAuditSubscription.endDate = endDate;

    newAuditSubscription.auditId = this.newAudit.id;
    newAuditSubscription.userAccountId = this.authenticatedUser.id;

    await this.baseRepository.insert(AuditSubscription.table, newAuditSubscription);
  }
}

class RecordMapping {
  oldId: string;
  newId: string;
}
