import { Injectable } from "@angular/core";
import { CustomFieldValueItem } from "../models/database/customFieldValueItem.database";

@Injectable({
  providedIn: 'root',
})
export class CustomFieldValueItemRepository {
  public async getItem(id: string): Promise<CustomFieldValueItem> {
    return await CustomFieldValueItem.table.get(id);
  }

  public async getItemsByListId(listId: string, sortProperty: string, excludedItemId?: string): Promise<CustomFieldValueItem[]> {
    let customFieldValueItems = await CustomFieldValueItem.table.where(CustomFieldValueItem.VALUE_LIST_ID).equals(listId);

    let result;

    if (sortProperty == "position")
      result = await (customFieldValueItems.sortBy(sortProperty));
    else {
      result = (await customFieldValueItems.toArray()).sort((a, b) => a.description.localeCompare(b.description, 'fr'));
    }

    result = result.filter(x => !x.isDeleted || x.id == excludedItemId)

    return result;
  }
}
