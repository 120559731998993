import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class NavigationService{
  constructor(private router: Router) {
    
  }

  navigateToParentUrl(){
    let currentRouteUrl = this.router.url;
    let formBaseRouteUrl = currentRouteUrl.substring(0, currentRouteUrl.lastIndexOf("/"));

    this.router.navigate([ formBaseRouteUrl ]);
  }
}