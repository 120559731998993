import { Injectable } from "@angular/core";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { TransactionType } from "../dexiejs/transactionType";
import { ProgramVersion } from "../models/database/programVersion.database";
import { BaseRepositoryConstructor } from "../baseRepositoryConstructor";

@Injectable({
  providedIn: 'root',
})
export class ProgramVersionRepository extends BaseRepository {

  constructor(
    public baseRepositoryConstructor: BaseRepositoryConstructor
  ) {
    super(baseRepositoryConstructor);
  }

  public async getLatestProgramVersion(programId: string): Promise<ProgramVersion> {
    return await this.baseTransaction<ProgramVersion>(TransactionType.Read,
      [ProgramVersion.table],
      async (transaction) => {
        // TODO AC: Review filtering to avoid looping across all versions to get the last version.
        // Possibly filter
        let programVersions = await ProgramVersion.table.where("programId").equals(programId).sortBy('number');

        if (programVersions.length === 0) {
          return null;
        }

        return programVersions[programVersions.length - 1];
      },
      "ProgramVersionRepository.getLatestProgramVersion");
  }
}
