import { Component, OnInit } from '@angular/core';
import { IAuthenticationService } from '../iAuthenticationService.interface';
import { AuthenticationServiceFactory } from '../iAuthenticationService.factory';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginFailed = false;
  public authenticationService: IAuthenticationService;

  constructor(
    public authenticationServiceFactory: AuthenticationServiceFactory,
    public synchronizationService: SynchronizationService
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  public async login() {
    if (!this.synchronizationService.synchronizationInProgressPromise) {
      // TODO AC: currenly always return to window location origin
      // Login might need to add a custom redirectUrl that would
      // return to the correct location. At this time no usecase have been
      // tested to require this and has been keep to it simplest from for now.
      this.loginFailed = !await this.authenticationService.login();
    }
  }
}
