import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationWindowsHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Login`;

  constructor(private http: HttpClient) { }

  async checkAuthentication(): Promise<boolean> {
    return await firstValueFrom(this.http.get<boolean>(this.configUrl));
  }
}