<app-list [items]="documents">
  <ng-template #listTemplate let-item>
    <div class="layout-horizontal height-normal padding-horizontal-normal padding-vertical-compact">
      <div class="layout-center align-left">{{item.name}}</div>
      <div class="layout-right ">
        <button id="send-email-document" (click)="sendEmail(item.id)">
          <i class="fas fa-envelope"></i>
        </button>
        <button id="download-document" (click)="download(item.id)">
          <i class="fas fa-download"></i>
        </button>
      </div>
    </div>
  </ng-template>
</app-list>

<app-document-format-type #documentFormatTypePopup></app-document-format-type>

<app-modal></app-modal>
