import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuditViewModel } from './userAuditViewModel';
import { ListDataSourceResult } from 'src/app/components/list/listDatasourceResult';
import { ListComponent } from 'src/app/components/list/list.component';
import { AuditListSearchComponent } from './audit-list-search/audit-list-search.component';
import { NewAuditComponent } from './new-audit/new-audit.component';
import { AuditListSearchOptions } from './auditListSearchOptions';
import { FieldMetadata } from 'src/app/components/list/list-option/field-metadata';
import moment from 'moment';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { AdditionalOptionsComponent } from '../audit/additional-options/additional-options.component';
import { PermissionService } from 'src/app/core/services/permissionService';
import { PermissionType } from 'src/app/core/data/enums/permissionType';
import _ from 'lodash';
import { FormFilterOptionService } from 'src/app/core/services/formFilterOptionService';
import { UserAuditRepository } from 'src/app/core/data/repositories/userAuditRepository';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit, AfterViewInit {

  public moment = moment

  public auditListDataSource: Function;
  public searchOptions: AuditListSearchOptions = new AuditListSearchOptions();
  public filterCount: number = 0;
  public canAddForm: boolean = false;
  public canEditOthersForm: boolean = false;
  public canDeleteForm: boolean = false;

  public navigator = navigator;

  private queryParamMap;

  /**
  * Where the application is in offline mode and there is at least one custom field search, the system
  * will not be able to search in custom fields without risking to returns an invalid result because
  * forms not downloaded will not have additionnal infos.
  */
  public localSearchOnly: boolean = false;

  public dataSourceResult: ListDataSourceResult = new ListDataSourceResult();

  @ViewChild('list') list: ListComponent;
  @ViewChild('addAudit') addAuditPopup: NewAuditComponent;
  @ViewChild('searchAudit') searchAuditPopup: AuditListSearchComponent;
  @ViewChild('additionalOptions') additionalOptionsPopup: AdditionalOptionsComponent;

  public metadata = [
    new FieldMetadata({ key: "number", label: "Number" }),
    new FieldMetadata({ key: "programId", label: "Form Template" }),
  ]

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private userAuditRepository: UserAuditRepository,
    private permissionService: PermissionService,
    private formFilterOptionService: FormFilterOptionService,
    private synchronizationService: SynchronizationService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.setDataSource();

    this.canAddForm = await this.permissionService.hasAccess(PermissionType.FormAdd);
    this.canEditOthersForm = await this.permissionService.hasAccess(PermissionType.FormEditOthers);
    this.canDeleteForm = await this.permissionService.hasAccess(PermissionType.FormDelete);
    this.searchAuditPopup.setFilterCount();
  }

  public ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe(async (params) => {
      this.queryParamMap = params;

      this.list.updateData();
    });
  }

  public showAdd(): void {
    this.addAuditPopup.show();
  }

  public async showSearch(): Promise<void> {
    await this.searchAuditPopup.open();

    // Because the popup save the search into database, we need to update the search options
    // by reloading the datasource function.
    this.setDataSource();

    // Once the datasource is updated, we update the actual data
    // in case it changed.
    this.list.updateData();
  }

  public async enter(item: UserAuditViewModel): Promise<void> {
    await this.navigateToAudit(item);
  }

  public onListResultChange(result): void {
    this.dataSourceResult = result;
  }

  public async dataSourceResultChange(result) {
    const groupedItems = result["groupedItems"];

    for (const key in groupedItems) {
      await this.userAuditRepository.feedCustomFieldsSearch(groupedItems[key]);
    }
  }

  public openAditionnalOptionsPopup(): void {
    this.additionalOptionsPopup.openAdditionalOptionsPopup();
  }

  private async navigateToAudit(item: UserAuditViewModel): Promise<void> {
    this.router.navigate([`/forms/${item.number}`]);
  }

  private setDataSource() {
    this.auditListDataSource = async (context: ListComponent) => {
      this.searchOptions = await this.formFilterOptionService.get();

      if (!this.synchronizationService.offline && this.searchOptions.customFieldSearchOperators.length > 0)
        this.localSearchOnly = true;

      if (this.localSearchOnly) {
        return await this.userAuditRepository.getSortedUserAuditListWithSearchOptions(this.searchOptions, context.filter);
      } else {
        return await this.userAuditRepository.getSortedUserAuditListWithSearchOptions(this.searchOptions, context.filter);
      }
    };
  }
}
