import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import { ListComponent } from "src/app/components/list/list.component";
import { ListOptions } from "src/app/components/list/listOptions";
import { ListSortGroup } from "src/app/components/list/listSortGroup";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { EntityState } from "src/app/core/data/changeTracking/entityState";
import { DataSourceImportation } from "src/app/core/data/models/database/dataSourceImportation.database";
import { DataSourceImportationResult } from "src/app/core/data/models/database/dataSourceImportationResult.database";
import { OptionList } from "src/app/core/data/models/database/optionList.database";
import { Task } from "src/app/core/data/models/database/task.database";
import { TaskType } from "src/app/core/data/models/database/taskType.database";
import { TaskFilter } from "src/app/core/data/models/databaseLocal/taskFilter.database";
import { GeolocationService } from "src/app/core/geolocationService";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { Validation } from "src/app/core/validation";
import { EntitySecurityGroupPermissionRepository } from "../entity-security-group/entitySecurityGroupPermissionRepository";
import { EntitySecurityGroupPermissionService } from "../entity-security-group/entitySecurityGroupPermissionService";
import { TaskMapComponent } from "../task-map/task-map.component";
import { HtmlTemplateRenderer } from "../option-list-edit/htmlTemplateRenderer";
import { TemplateType } from "../templateType";
import { FilterExpression } from "./filterExpression";
import { FilterExpressionFormatterFactory } from "./filterExpressionFormatterFactory";
import { TaskListItemViewModel } from "./taskListItemViewModel";
import { TryGetResult } from "./tryGetResult";
import { UserFilterExpressionFormatter } from "./userFilterExpressionFormatter";

/**
  * Save the user preferences based on the current selected task type + option list combination.
  *
  * @param selectedItemId - Represents a combinaison of a task type id + an option list id with a pipe character between them.
  */
@Injectable({
  providedIn: 'root',
})
export class TaskListService {
  currentLocation: google.maps.LatLngLiteral;

  constructor(
    private authenticatedUser: AuthenticatedUser,
    private entitySecurityGroupPermissionService: EntitySecurityGroupPermissionService,
    private baseRepository: BaseRepository,
    private filterExpressionFormatterFactory: FilterExpressionFormatterFactory,
    private translateService: TranslateService) {

    navigator.geolocation.getCurrentPosition((position) => {
      this.currentLocation = GeolocationService.getCurrentLocation(position);
    });
  }

  /**
  * Returns a list of all tasks combined with options lists visible by the current user.
  */
  public async getTaskTypeOptions(): Promise<any[]> {
    let taskTypes = _.sortBy(await (await TaskType.table.toArray()).filter(x => !x.isDeleted), x => x.name);

    let optionLists = await OptionList.table.toArray();

    optionLists = await this.entitySecurityGroupPermissionService.getAllowedEntities(
      optionLists,
      EntitySecurityGroupPermissionRepository.OptionListVisibleBy,
      this.authenticatedUser.id);

    let result = [];

    for (const taskType of taskTypes) {
      let taskTypeOptionLists = optionLists.filter(x => x.entityId === taskType.id);

      for (const taskTypeOptionList of taskTypeOptionLists) {
        result.push({ key: taskType.id + "|" + taskTypeOptionList.id, text: taskType.name + " - " + taskTypeOptionList.name });
      }
    }

    return result;
  }

  /**
  * Save the user preferences based on the current selected task type + option list combination.
  *
  * @param selectedItemId - Represents a combinaison of a task type id + an option list id with a pipe character between them.
  */
  public async updateTaskFilterState(taskFilter: TaskFilter, selectedItemId: string) {
    // Update in real time the user preference based on new selection.
    if (taskFilter.getTaskTypeOptionListId() != selectedItemId) {
      taskFilter.setTaskTypeOptionListId(selectedItemId);

      taskFilter.filterFields = "";

      await this.baseRepository.update(TaskFilter.table, taskFilter);
    }
  }

  public async tryGetTasks(taskFilter: TaskFilter, listOptions: ListOptions): Promise<TryGetResult<TaskListItemViewModel[]>> {
    let tasks: DataSourceImportationResult[] = [];
    let taskType = await TaskType.table.get(taskFilter.taskTypeId);
    let dataSource = await DataSourceImportation.table.get(taskType.dataSourceImportationId);

    let result = new TryGetResult<TaskListItemViewModel[]>();

    if (dataSource.isDeleted)
      return result;

    let optionList = await OptionList.table.get(taskFilter.optionListId);

    tasks = await DataSourceImportationResult.table.where("dataSourceImportationId").equals(taskType.dataSourceImportationId).toArray();

    if (taskType.isActivated){
      // In case the map is activated, all data source items that don't have a numeric value are excluded of the list
      // to prevent exception by the Google map Api process.
      tasks = tasks.filter(x => {
         return !Number.isNaN(x[taskType.latitudeField]) && !Number.isNaN(x[taskType.longitudeField]) && x[taskType.latitudeField] && x[taskType.longitudeField];
      });
    }

    let filterExpressionFormatters = this.filterExpressionFormatterFactory.create();

    if (taskType.globalJavascriptFilter) {
      try {
        let filterExpression = new FilterExpression(taskType.globalJavascriptFilter, filterExpressionFormatters);

        tasks = filterExpression.execute(tasks);
      } catch (error) {
        result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.taskTypeFilter", { message: error.message })}));

        return result;
      }
    }

    if (optionList.filterScript) {
      try {
        let filterExpression = new FilterExpression(optionList.filterScript, filterExpressionFormatters);

        tasks = filterExpression.execute(tasks);
      } catch (error) {
        result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.optionListFilter", { message: error.message })}));

        return result;
      }
    }

    if (optionList.textFilter) {
      let filterFields = JSON.parse(optionList.textFilter);

      for (const filter of filterFields) {
        if (!filter.value)
          continue;

        try {
          let filterExpression = new FilterExpression("item." + filter.key + ".match(/" + filter.value + "/i)", filterExpressionFormatters);

          tasks = filterExpression.execute(tasks);
        } catch (error) {
          result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.taskTypeTextFilter", { message: error.message })}))

          return result;
        }
      }
    }

    if (taskFilter.filterFields) {
      let filterFields = JSON.parse(taskFilter.filterFields);

      for (const filter of filterFields) {
        if (!filter.value)
          continue;

        try {
          let filterExpression = new FilterExpression("item." + filter.key + ".match(/" + filter.value + "/i)", filterExpressionFormatters);

          tasks = filterExpression.execute(tasks);
        } catch (error) {
          result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.optionListTextFilter", { message: error.message })}))

          return result;
        }
      }
    }



    if (taskFilter.distanceFromFirstTask && this.currentLocation) {
      tasks = tasks.filter(x => {
        const currentPointPosition: google.maps.LatLngLiteral = { lat: Number(x[taskType.latitudeField]), lng: Number(x[taskType.longitudeField]) };

        return GeolocationService.getDistanceInKm(
          this.currentLocation.lat,
          this.currentLocation.lng,
          currentPointPosition.lat,
          currentPointPosition.lng) <= taskFilter.distanceFromFirstTask;
      });
    }

    let htmlTemplateRenderer;

    // The validation of the html template here is done in two parts. First the declaration of the function body
    // and then the execution a couple of lines down. The only reason is to increase the performance preventing
    // declaring the body function for each item.
    try {
      htmlTemplateRenderer = new HtmlTemplateRenderer(listOptions);
    } catch (error) {
      result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.invalidOptionListHtmlTemplate", {message: error.message})}));
    }

    result.value = _.map(tasks, x => {
      let taskListItemViewModel = new TaskListItemViewModel();

      Object.keys(x).forEach((key) => {
        taskListItemViewModel[key] = x[key]
      })

      try {
        htmlTemplateRenderer.execute(taskListItemViewModel);
      } catch (error) {
        result.validationDictionary.add(new Validation({message: this.translateService.instant("taskList.validations.invalidOptionListHtmlTemplate", {message: error.message})}));
      }

      return taskListItemViewModel;
    })

    return result;
  }

  /**
  * Returns a list of all tasks associated with the corresponding option list and the authenticated user id.
  */
  async getSelectedTasks(optionListId: string, tasks: TaskListItemViewModel[]): Promise<TaskListItemViewModel[]>{
    const userAccountId = this.authenticatedUser.id;

    let selectedTasks = await Task.table.where("optionListId").equals(optionListId).and(x => x.userAccountId == userAccountId).toArray();

    selectedTasks = _.orderBy(selectedTasks, x => x.position);

    let result = [];

    for (const task of selectedTasks) {
      let selectedTask = tasks.find(x => x.id == task.dataSourceImportationResultId);

      if (selectedTask){
        selectedTask.position = task.position;

        result.push(selectedTask);
      }
    }

    return result;
  }

  /**
  * Returns the component used by the ListComponent to display the information about tasks based
  * on the task type + option list selected.
  */
  public async getDisplayOptions(taskFilter: TaskFilter) {
    let result = new ListOptions();

    let optionList = await OptionList.table.get(taskFilter.optionListId);

    result.sortBy = [];

    if (optionList.sortBy) {
      var sortByProperties = optionList.sortBy.split(",");

      let position = 1;

      for (const sortProperty of sortByProperties) {
        result.sortBy.push(new ListSortGroup({ property: sortProperty, order: "asc", position: position }));

        position += 1;
      }
    }

    result.groupBy = [];

    if (optionList.groupBy) {
      var groupByProperties = optionList.groupBy.split(",");

      for (const groupProperty of groupByProperties) {
        result.groupBy.push(new ListSortGroup({ property: groupProperty }))
      }
    }

    if (optionList.templateType == TemplateType.Text) {
      let lineFields1 = _.map(optionList.lineTemplate1 ? optionList.lineTemplate1.split(",").filter(x => x) : [], x => "item." + x);
      let lineFields2 = _.map(optionList.lineTemplate2 ? optionList.lineTemplate2.split(",").filter(x => x) : [], x => "item." + x);
      let lineFields3 = _.map(optionList.lineTemplate3 ? optionList.lineTemplate3.split(",").filter(x => x) : [], x => "item." + x);;

      let fieldsTemplate = this.formatTemplateFieldsList(lineFields1, false);

      if (lineFields2.length > 0) {
        fieldsTemplate += this.formatTemplateFieldsList(lineFields2, true);
      }

      if (lineFields3.length > 0) {
        fieldsTemplate += this.formatTemplateFieldsList(lineFields3, true);
      }

      result.mapItemFunction = `
          item[properties.template]=${fieldsTemplate}

          return item;
        `;
    }
    else {
      result.mapItemFunction = optionList.htmlTemplate;
    }

    if (optionList.groupBy) {
      if (optionList.groupByHtmlTemplate){
        result.mapGroupFunction = optionList.groupByHtmlTemplate;
      }
      else{
        let groupByProperty = this.formatTemplateFieldsList(_.map(optionList.groupBy.split(","), x => "group.properties." + x), false);

        result.mapGroupFunction = `
            group[properties.template] = ${groupByProperty};

            return group;
          `
      }
    }

    return result;
  }

  private formatTemplateFieldsList(items: string[], addOnAnotherLine: boolean) {
    let result: string = " ";

    if (addOnAnotherLine)
      result = " + '<br>' + ";

    result += items.join("+ ', ' + ");

    return result;
  }

  /**
  * Clear the existing tasks associated with the task type + option list + user and create a new list
  * base on selected tasks.
  */
  async saveTasks(tasks: TaskListItemViewModel[], optionListId: string, taskTypeId: string) {
    const userAccountId = this.authenticatedUser.id;

    let existingTasks = await Task.table.where("optionListId").equals(optionListId).and(x => x.userAccountId == userAccountId).toArray();

    for (const existingTask of existingTasks) {
      await this.baseRepository.deleteById(Task.table, existingTask.id);
    }

    for (const task of tasks) {
      let newTask = new Task({ entityState: EntityState.New });

      newTask.dataSourceImportationResultId = task.id;
      newTask.userAccountId = userAccountId;
      newTask.optionListId = optionListId;
      newTask.taskTypeId = taskTypeId;
      newTask.position = task.position;

      await this.baseRepository.insert(Task.table, newTask);
    }
  }
}
