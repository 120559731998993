import { Injectable } from "@angular/core";
import { UserAccount } from "../data/models/database/userAccount.database";

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUser {
  public code: string;
  public name: string;
  public typeId: number;
  public title: string;
  public email: string;
  public phone: string;
  public extension: string;
  public inactive: boolean;
  public windowsAuthentication: boolean;
  public windowsUsername: string;
  public signature: string;
  public subscriptionPeriod: number;
  public subscriptionPeriodAfterLastStep: number;
  public image: string;
  public avatar: string;
  public oldId: number;
  public id: string;
  public isDeleted: boolean;
  public imagePath: string;
  public avatarPath: string;
  public listAuditsWithSubscriptionOnly: boolean;
  public timeStamp: Date;
  public password: string;

  public initials = '';
}
