import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _, { get } from 'lodash';
import { Subject, interval, timer } from 'rxjs';
import { AuthenticationServiceFactory } from 'src/app/authentication/iAuthenticationService.factory';
import { IAuthenticationService } from 'src/app/authentication/iAuthenticationService.interface';
import { SynchronizationPopupState } from 'src/app/components/synchronization/synchronizationPopupState';
import { environment } from 'src/environments/environment';
import { Logger } from '../../log/logger';
import { PermissionService } from '../../services/permissionService';
import { VersionService } from '../../services/versionService';
import { ChangeOperation } from '../changeTracking/changeOperation';
import { DatabaseContext } from '../databaseContext';
import { DatabaseTableAll } from '../databaseTableAll';
import { TransactionType } from '../dexiejs/transactionType';
import { ApplicationLog } from '../models/database/applicationLog.database';
import { Audit } from '../models/database/audit.database';
import { AuditDataTable } from '../models/database/auditDataTable.database';
import { DataSourceImportationResult } from '../models/database/dataSourceImportationResult.database';
import { Mobile } from '../models/database/mobile.database';
import { SynchronizationRange } from '../models/database/synchronizationRange.database';
import { ChangeTracking } from '../models/databaseLocal/changeTracking.database';
import { ChangeTrackingRange } from '../models/databaseLocal/changeTrackingRange.database';
import { FormTemplateNextNumber } from '../models/databaseLocal/formTemplateNextNumber.database';
import { UserAudit } from '../models/databaseLocal/userAudit.database';
import { MobileHttpClient } from './mobileHttpClient';
import { SynchronizationHttpClient } from './synchronizationHttpClient';
import { TableColumnNameReference } from './tableColumnNameReference';
import { UserAuditHttpClient } from './userAuditHttpClient';
import { ConnectionService } from '../../services/connectionService';
import { LocalParameter } from '../models/databaseLocal/localParameter.database';
import { UserHttpClient } from '../../security/userHttpClient';
import { UserAccount } from '../models/database/userAccount.database';
import { AuthenticatedUser } from '../../security/authenticatedUser';
import moment from 'moment';
import { UserService } from '../../services/userService';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationService {

  /* General synchronization state properties */
  public mobileId: string;

  public serverConnectionSubject = new Subject();

  private _offline: boolean = true;

  get offline(): boolean {
    return this._offline;
  }
  set offline(value: boolean) {
    this._offline = value;
    this.serverConnectionSubject.next(value);
  }

  get online(): boolean {
    return !this.offline;
  }

  public requireSynchronization: boolean = false;
  public isForcedOffline: boolean;
  /**
   * Indicate whether the synchronization system is accessible based on the current location.
   * This help to desactivate the synchronization system for some pages.
  */
  public isAccessibleByLocation: boolean = true;
  public USER_ID_PARAMETER = "UserId";
  public hasError = false;

  /* Synchronization lock related properties */
  public synchronizationInProgressPromise: Promise<any> = null;
  public synchronizationInProgressInAnotherTab: boolean = false;
  private synchronizationDebounceSubscription = null;
  private synchronizationDebouncePromise: Promise<boolean> = null;
  private synchronizationDebouncePromiseResolve: Function = null;
  private authenticationService: IAuthenticationService;
  private SYNCHRONIZATION_LOCK = "synchronization-lock";

  /* Popup related properties */
  public couldNotReachServer = false;
  public isNotAuthenticated = false;
  public mobileIdNotConfigured = false;
  public hasOtherError = false;
  public hasOtherErrorMessage = null;
  public popupConfirmationPromise: Promise<boolean> = null;
  private popupConfirmationPromiseResolve: Function = null;

  constructor(
    private synchronizationPopupState: SynchronizationPopupState,
    private authenticationServiceFactory: AuthenticationServiceFactory,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private mobileHttpClient: MobileHttpClient,
    private userAuditHttpClient: UserAuditHttpClient,
    private databaseContext: DatabaseContext,
    private databaseTable: DatabaseTableAll,
    private versionService: VersionService,
    private logger: Logger,
    private permissionService: PermissionService,
    private connectionService: ConnectionService,
    private userService: UserService,
    private userHttpClient: UserHttpClient,
    private authenticatedUser: AuthenticatedUser,
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();

    LocalParameter.table.get(LocalParameter.FORCED_OFFLINE_MODE).then(lp => this.isForcedOffline = lp.value);

    interval(environment.checkServerInterval).subscribe((x => {
      this.updateOfflineStatus();
    }));
  }

  public async getUpdates(display: boolean): Promise<boolean> {
    this.hasError = false;
    this.couldNotReachServer = false;
    this.isNotAuthenticated = false;
    this.mobileIdNotConfigured = false;
    this.hasOtherError = false;
    this.synchronizationPopupState.display = display;

    return await this.debounceSynchronization(async () => {
      return await this.lockSynchronization(async () => {
        let synchronization = null;
        let mobile: Mobile = null;

        if (await this.validateRequiredState()) {

          let userId = await this.userHttpClient.getUserId();

          await LocalParameter.table.put(new LocalParameter({
            name: LocalParameter.USER_ID_PARAMETER_NAME,
            value: userId.toUpperCase()
          }));

          try {
            // Local changes saved as ChangeTracking are sent to server before any synchronization to secure
            // the client against any error that could occur. By succesfully sending all changes, we can assume
            // the local indexedDB database can be fully destroyed and recreated if needed be. Of course we don't
            // for performance reasons.
            await this.applyChangeTrackings();
          } catch (exception) {
            this.setError(true);

            await this.logger.logError(exception);
          }

          if (!this.hasError) {
            // If we still have any ChangeTracking after they have been applied, it means at least one of them is
            // failling and need to prohibit further progress of the synchronization.
            let remainingChangeTrackingCount = await ChangeTracking.table.where("tableName").notEqual(ApplicationLog.tableName).count()
            if (remainingChangeTrackingCount > 0) {
              this.setError(false);
              await this.logger.logError(`ChangeTracking could not all be synchronized to server. ${remainingChangeTrackingCount} remaining, first one is likely causing the problem.`)
            }
          }

          if (!this.hasError) {
            if (!await this.checkForUpdate()) {
              this.setError(false);
            }
          }

          if (!this.hasError) {
            try {
              let databaseTables = this.databaseTable.getTableNames();

              let dateTableColumnNameReference = await this.synchronizationHttpClient.getDateTableColumnNameReference();

              let start = performance.now();

              mobile = await this.mobileHttpClient.getMobile();

              if (mobile.isDeleted) {
                await this.logger.logDebug(`Mobile is inactive, restoring to active state`);
                await this.logger.logDebug(`Deleting mobile database and restoring an empty database`);
                await this.databaseContext.delete();
                await this.databaseContext.open();
                await this.logger.logDebug(`Empty database created successfully`);
                await this.logger.logDebug(`Activating mobile on server`);
                await this.mobileHttpClient.createMobile(mobile);
                await this.logger.logDebug(`Succesfully restored mobile to active state`);

                await this.restoreMobileState(mobile.id.toUpperCase());
              }

              synchronization = await this.synchronizationHttpClient.createSynchronization();

              await this.logger.logDebug(`Preparing data`);
              let synchronizationRanges: SynchronizationRange[] = await this.synchronizationHttpClient.prepareSynchronizationData();
              await this.logger.logDebug(`Completed preparation`);

              if (synchronizationRanges && synchronizationRanges.length > 0) {
                let index = 1;
                for (let range of synchronizationRanges) {
                  await this.logger.logDebug(`Fetching data for ${range.tableName}`);
                  let synchronizationRangeItems = await this.synchronizationHttpClient.getSynchronizationRangeData(range.id);
                  await this.logger.logDebug(`Received ${synchronizationRangeItems.length} for ${range.tableName}`);
                  await this.feedDatabase(databaseTables, range.tableName, synchronizationRangeItems, range.changeOperation, dateTableColumnNameReference);
                  await this.synchronizationHttpClient.completeSynchronizationRange(range.id);
                  await this.logger.logDebug(`Completed range '${range.id}' (${index++}/${synchronizationRanges.length}) for ${range.tableName}`);
                  this.synchronizationPopupState.initializeState(synchronizationRanges.length, true, index - 1);
                }
              } else {
                await this.logger.logDebug(`No data to sync skipping to next table.`);
              }

              await this.synchronizationHttpClient.completeSynchronization(synchronization);

              // UserAudit is a special case in the synchronization which assume that the data will always
              // be cleared because it will always receive a new copy of the updated data. We do this to avoid
              // having to consider the changeTracking for this table because they are very volatile and would
              // need to be filtered by user.
              await this.logger.logDebug(`Updating UserAudits`);
              await UserAudit.table.clear();
              let newUserAudits = await this.userAuditHttpClient.getAll();
              UserAudit.table.bulkPut(newUserAudits);
              await this.logger.logDebug(`Completed update for UserAudits`);


              await this.logger.logDebug(`Completed synchonisation in ${(performance.now() - start) / 1000} seconds for ranges`, synchronizationRanges);

              let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);
              let userAccount = await UserAccount.table.get(userIdParameter.value);

              for (let key in userAccount) {
                this.authenticatedUser[key] = userAccount[key];
              }
              this.authenticatedUser.initials = this.userService.getInitials(userAccount.name);

              if (this.authenticatedUser["language"]) {
                moment.locale(this.authenticatedUser["language"]);
              } else {
                // this.translate.setDefaultLang(environment.language);
                moment.locale(environment.language);
              }

              // An update can change the permissions of the current user
              // the permission map of the permission service need to be refresh
              await this.permissionService.createUserPermissionMap();

              // Hide button when completed successfully
              this.requireSynchronization = false;
            } catch (exception) {
              if (exception instanceof HttpErrorResponse)
                this.setError(true, exception);
              else {
                this.setError(true, exception.message);
              }
            }
          }
        }

        if (this.popupConfirmationPromise) {
          await this.popupConfirmationPromise;
        }

        return !this.hasError;
      });
    });
  }

  /**
   * @remark Debounce the synchronization to avoid sending a request too early when others actions are being performed
   * which also result in synchronizations requests. Waiting for the last one to complete will ensure that
   * the synchronization is not performed too often on a very short period of time and include all the lastest
   * changes.
   */
  private async debounceSynchronization(callback) {
    if (!this.synchronizationDebouncePromise) {
      this.synchronizationDebouncePromise = new Promise<boolean>((resolve) => {
        this.synchronizationDebouncePromiseResolve = resolve;
      });
    }

    if (this.synchronizationDebounceSubscription) {
      this.synchronizationDebounceSubscription.unsubscribe();
    }

    this.synchronizationDebounceSubscription = timer(environment.synchronizationDebounceMilliseconds).subscribe(async () => {
      let result = await callback();
      this.synchronizationDebouncePromiseResolve(result);
      this.synchronizationDebouncePromise = null;
    });

    return await this.synchronizationDebouncePromise;
  }

  /**
   * @remark To start a synchronization, we need to ensure that the synchronization is not already in progress
   * and if it is, we need to wait for it to complete without requesting a new one. To do so, we use a brower
   * wide lock to lock the synchronization contect to also consider the fact that a synchronization can be
   * requested in different browser tabs which would result in multiple synchronization to be requested at
   * the same time. We use the ifAvailable flag options to ensure it fails if the lock is already taken.
   * 
   * If it does fail, we request the lock again without the ifAvailable flag to wait for the lock to be released
   * and without calling the callback which would launch a new synchronization. The lock will be queued, awaited
   * and executed when the current synchronization is completed so the only thing it needs to execute is to indicate
   * the completion on the current browser tab.
   */
  private async lockSynchronization(callback) {
    if (!this.synchronizationInProgressPromise) {
      let lockOptions = {
        ifAvailable: true
      };

      const COULD_NOT_GET_LOCK_EXCEPTION = "COULD_NOT_GET_LOCK_EXCEPTION";

      this.synchronizationInProgressPromise = navigator.locks.request(this.SYNCHRONIZATION_LOCK, lockOptions, async (lock) => {
        if (lock) {
          let result = await callback();
          this.synchronizationInProgressPromise = null;
          this.hidePopup();
          return result;
        } else {
          return COULD_NOT_GET_LOCK_EXCEPTION;
        }
      });

      let result = await this.synchronizationInProgressPromise;

      if (result === COULD_NOT_GET_LOCK_EXCEPTION) {
        this.synchronizationInProgressInAnotherTab = true;
        await this.logger.logDebug("Synchronization has been triggered while its already in progress in an OTHER tab, request is ignored and will wait for the current synchronization to complete.");
        this.synchronizationInProgressPromise = navigator.locks.request(this.SYNCHRONIZATION_LOCK, async () => {
          await this.logger.logDebug("Synchronization completed in another tab, this tab will now continue.");
          this.synchronizationInProgressPromise = null;
          this.synchronizationInProgressInAnotherTab = false;
          this.hidePopup();

          return true;
        });
      }

    } else {
      await this.logger.logDebug("Synchronization has been triggered while its already in progress, request is ignored and will wait for the current synchronization to complete.");
    }

    return await this.synchronizationInProgressPromise;
  }

  /**
   * @remark Restore the mobile state after a mobile has been reset on the server
   * which include setting autoIncrement of changeTracking table, initializing form 
   * template number for form creation and initializing the user.
   */
  public async restoreMobileState(originalMobileId: string) {
    await this.setAutoIncrementOfChangeTrackingTable();
    await this.insertFormTemplateNextNumber();

    // Ensure initializeMobileCommand in app.middleware.ts is not running again 
    // following mobile being reset so that next synchronization is not considered
    // like a new mobile.
    await LocalParameter.table.put(new LocalParameter({
      name: LocalParameter.MOBILE_ID_PARAMETER_NAME,
      value: originalMobileId
    }));
  }

  /**
   * @remark Restore form template numbers for form creation after a database has been created
   * to ensure next forms will continue the existing sequence of numbers for that mobile.
   */
  private async insertFormTemplateNextNumber() {
    let formTemplateNextNumbers = await this.mobileHttpClient.getFormTemplateNextNumber();

    await FormTemplateNextNumber.table.bulkPut(formTemplateNextNumbers);
  }

  private async feedDatabase(databaseTables: string[], tableName: any, synchronizationRangeItems: any[], changeOperation: any, dateTableColumnNameReference: TableColumnNameReference[]) {
    await this.databaseContext.transaction(TransactionType.ReadWrite, databaseTables, async (transaction) => {
      // While the changeTracking should already have been filtered by the server to not to return any
      // items for a deleted table. We double checked here for safety in case the data would have been prepared
      // manually, likely to fix an error on a mobile.
      if (databaseTables.indexOf(tableName) === -1) {
        // TODO AC: Add logging
        await this.logger.logWarning(`Ignored ${synchronizationRangeItems.length} items for ${tableName}`);
        return;
      }

      let table = this.databaseContext.table(tableName);

      // Because synchronization range are split by size and table and changeOperation we can be sure all items
      // to be for the same table as the first one. A refactor might come in place to make this
      // clearer, mostly to provide more details to the users / completion progress, but at
      // this time, it was good enough. 
      if (changeOperation === ChangeOperation.Delete) {
        await this.logger.logDebug(`Deleting ${synchronizationRangeItems.length} items for ${tableName}`);
        await table.bulkDelete(synchronizationRangeItems.map((synchronizationRangeItem) => {
          if (synchronizationRangeItem.tableId) {
            return synchronizationRangeItem.tableId.toUpperCase()
          }

          return synchronizationRangeItem.tableIdInt;
        }));
        await this.logger.logDebug(`Completed ${synchronizationRangeItems.length} deletions for ${tableName}`);
      } else {
        await this.logger.logDebug(`Converting ${synchronizationRangeItems.length} web entities for ${tableName}`);

        let camelCaseEntities = this.getWebEntities(tableName, synchronizationRangeItems, dateTableColumnNameReference);

        if (camelCaseEntities.length > 0) {
          switch (tableName) {
            case DataSourceImportationResult.tableName:
              // This transformations let convert the JSON data column in this table to 
              // a list of columns.
              camelCaseEntities = this.transformDataSourceImportationResultEntities(camelCaseEntities);

              break;

            case AuditDataTable.tableName:
              // This transformations let convert the JSON data column in this table to 
              // a list of columns.
              camelCaseEntities = this.mergeAuditDataTableDataValues(camelCaseEntities);

              break;
          }
        }

        await this.logger.logDebug(`Completed ${synchronizationRangeItems.length} convertion for ${tableName}`);

        if (changeOperation === ChangeOperation.Insert) {
          // Iteration 3: Using Bulk add again with a catch to improve performance and timeout errors while ignoring
          // dupplicates.
          // Iteration 2: Used to be Bulk put instead of insert to avoid error in cases of duplicate keys. It assume that all entities
          // with the change operation type insert contains all its data and if there actually is a duplicate 
          // key and the data would be different, the last one will win.
          // This should not occur, but it is a fail safe.
          // Iteration 1: Used bulkAdd, but caused problems dues to sometime having dupplicate id.
          //// Possible performance improvement to cache data but it is not really needed at this time.
          //// Kept for reference purposes.
          // await this.logger.logInformation(`Inserting ${synchronizationRangeItems.length} items for ${tableName}`);
          // await TableCache.table.add(new TableCache({ tableName: tableName, data: camelCaseEntities }));
          // await this.logger.logInformation(`Completed cache insert for ${tableName}`);
          await this.logger.logDebug(`Inserting ${synchronizationRangeItems.length} ${tableName}`);
          // await this.databaseContext.transaction(TransactionType.ReadWriteReuseTransactionIfCompatible, tableName, async (transaction) => {
          //   let query = table.bulkAdd(camelCaseEntities)
          //   query.catch((e) => {
          //     console.error(e);
          //     console.error(`${e.failures.length} ${tableName} did not succeed. However, ${camelCaseEntities.length - e.failures.length} ${tableName} was added successfully`);
          //   });
          //   await query;
          // });
          // Bulk put instead of insert to avoid error in cases of duplicate keys. It assume that all entities
          // with the change operation type insert contains all its data and if there actually is a duplicate 
          // key and the data would be different, the last one will win.
          // This should not occur, but it is a fail safe.
          await table.bulkPut(camelCaseEntities);

          await this.logger.logDebug(`Completed ${synchronizationRangeItems.length} insertion for ${tableName}`);
        } else if (changeOperation === ChangeOperation.Update) {
          await this.logger.logDebug(`Updating ${synchronizationRangeItems.length} ${tableName}`);

          let groupedEntities = _.groupBy(camelCaseEntities, 'id')
          let mappedEntities = Object.keys(groupedEntities)
            .map((entityId) => {
              return {
                key: entityId,
                changes: groupedEntities[entityId]
                  .reduce((next, value) => Object.assign(next, value), {})
              }
            });

          let result = await table.bulkUpdate(mappedEntities);

          await this.logger.logDebug(`Completed ${synchronizationRangeItems.length} updates for ${tableName}`);
        }
      }

      this.synchronizationDebounceSubscription.unsubscribe();
    });
  }

  private async checkForUpdate() {

    try {
      if (await this.versionService.requireUpdate()) {
        document.location.reload();
      }

      return true;
    } catch (exception) {
      await this.logger.logError(exception);
    }

    return false;
  }

  public async sendUpdates(): Promise<boolean> {
    return await this.debounceSynchronization(async () => {
      return await this.lockSynchronization(async () => {
        let changeTrackings = await ChangeTracking.table.toArray();
        if (changeTrackings.length === 0) {
          return true;
        }

        if (await this.validateRequiredState()) {
          try {
            await this.applyChangeTrackings();
            return true;
          } catch (exception) {
            return false;
            // In this case when we sent data and it fails, do not prohibit user to continue.
            // Even it might result in data loss, the full synchronization will redirect to an error
          }
        }
      });
    });
  }

  /**
   * @remark Reset auto increment key to match the server, if the auto increment doesn't
   * match the number on the server, future changes made by the users will be
   * ignored by the server because he will consider to already have received
   * those changes due to the number being lower than what he currently has.
   */
  private async setAutoIncrementOfChangeTrackingTable() {
    let localChangeTracking = await this.mobileHttpClient.getLocalChangeTracking();
    ChangeTracking.table.add(new ChangeTracking({ id: localChangeTracking }));
    ChangeTracking.table.delete(localChangeTracking);
  }

  private getWebEntities(tableName: string, entities: any[], dateTableColumnNameReference: TableColumnNameReference[]): any[] {
    return entities.map((synchronizationRangeItem) => {
      let entityData = JSON.parse(synchronizationRangeItem.dataValues);

      if (synchronizationRangeItem.tableId) {
        synchronizationRangeItem.tableId = synchronizationRangeItem.tableId.toUpperCase();
      }

      entityData.id = synchronizationRangeItem.tableId || synchronizationRangeItem.tableIdInt;

      this.convertStrignifiedDateToJavascriptDate(tableName, entityData, dateTableColumnNameReference);

      entityData = this.getCamelCaseEntity(entityData, tableName);

      return entityData;
    });
  }

  private transformDataSourceImportationResultEntities(entities: any[]): any[] {
    return entities.map((entity) => {

      let entityData = entity.data ? this.getCamelCaseEntity(JSON.parse(entity.data)) : {};

      // Remove to avoid any conflict with original key as currently
      // it would not be allowed to have an id in the imported data.
      delete entityData.id;

      let result = _.merge(entity, entityData);

      delete result.data;

      return result;
    });
  }

  private mergeAuditDataTableDataValues(auditDataTables: AuditDataTable[]): any[] {
    return auditDataTables.map((entity) => {

      let entityData = entity.dataValues ? JSON.parse(entity.dataValues) : {};

      // Remove to avoid any conflict with original key as currently
      // it would not be allowed to have an id in the imported data.
      delete entityData.Id;
      delete entityData.AuditId;

      let result = _.merge(entity, entityData);

      delete result.dataValues;
      delete result.dataValuesSize;

      return result;
    });
  }

  // Date converted to JSON are now string that need to be cast to date again
  // before being inserted in the database, to do so, we get the list table and
  // related column names that are dates and only for those case to date.
  // Some exception need to be taken into account for AuditDataTable table name
  // must be infered from the original tables and for UserAudit which does not exist
  // on the server but for which all dates related to the Audit table.
  private convertStrignifiedDateToJavascriptDate(tableName: string, entity: any, dateTableColumnNameReference: TableColumnNameReference[]) {
    let tableNameForDateConversion = null;
    switch (tableName) {
      case AuditDataTable.tableName:
        tableNameForDateConversion = entity.TableName;
        break;

      case UserAudit.tableName:
        tableNameForDateConversion = Audit.tableName;
        break;

      default:
        tableNameForDateConversion = tableName;
    }

    for (let reference of dateTableColumnNameReference.filter(x => x.tableName === tableNameForDateConversion)) {
      if (entity[reference.columnName]) {
        entity[reference.columnName] = new Date(entity[reference.columnName]);
      }
    }
  }

  private getCamelCaseEntity(entity: any, tableName?: string): any {
    // Dynamic custom table columns must be skipped
    if (tableName === AuditDataTable.tableName) {
      return this.toCamelCase(entity, [
        'Id',
        'TableName',
        'TableId',
        'AuditId',
        'DataValues',
        'DataValuesSize',
        'TimeStamp'
      ]);
    } else if (tableName === DataSourceImportationResult.tableName) {
      let mergedEntity = entity;

      if (entity?.Data) {
        mergedEntity = _.merge(entity, JSON.parse(entity.Data))

        delete mergedEntity.Data;
      }
      else
        mergedEntity = entity;

      return this.toCamelCase(mergedEntity);
    } else {
      return this.toCamelCase(entity);
    }
  }

  private toCamelCase(object, onlyTheseProperties: string[] = []): any {
    let camelCaseEntity = {};

    for (let property in object) {
      if (onlyTheseProperties.length > 0 && !onlyTheseProperties.includes(property)) {
        camelCaseEntity[property] = object[property];
      } else {
        camelCaseEntity[property[0].toLowerCase() + property.substring(1, property.length)] = object[property];
      }
    }

    return camelCaseEntity;
  }

  private async applyChangeTrackings() {
    let changeTrackingRanges = await ChangeTrackingRange.table.toArray();

    let changeTrackingTotalCount = await ChangeTracking.table.count();

    await this.logger.logDebug(`Synchronizing ${changeTrackingTotalCount} changes...`);

    var index = 1;
    for (let changeTrackingRange of changeTrackingRanges) {
      let changeTrackings = await ChangeTracking.table.where('id').belowOrEqual(changeTrackingRange.toChangeTrackingId).toArray();

      if (!changeTrackings || changeTrackings.length === 0) {
        await this.logger.logWarning(`Synchronization skipped due to no data, this should not happen.`);
        continue;
      }

      await this.logger.logDebug(`Synchronizing ${changeTrackings.length} changeTracking to server`);

      await this.synchronizationHttpClient.applyChangeTracking(changeTrackings);

      await this.logger.logDebug(`Synchronized ${changeTrackings.length} changeTracking to server`);
      this.synchronizationPopupState.initializeState(changeTrackingRanges.length, false, index);

      await ChangeTracking.table.where(ChangeTracking.ID).belowOrEqual(changeTrackingRange.toChangeTrackingId).delete();

      await this.logger.logDebug(`Deleted changeTracking below or equals #${changeTrackingRange.toChangeTrackingId}`);

      await ChangeTrackingRange.table.delete(changeTrackingRange.id)

      await this.logger.logDebug(`Synchronized ${changeTrackings.length} changes, removing changeTrackingRange #${changeTrackingRange.id}`);
      index++;
    }

    await this.logger.logDebug(`Synchronized ${changeTrackingTotalCount} changes.`);
  }

  /**
   * This method is used to validate the current state of the synchronization service
   * to ensure that it is ready to start a synchronization. The intention is to avoid 
   * sending queries to the the server if we know that the synchronization is not possible.
   * 
   * This is not a replacement to handle errors that could occur during the synchronization as
   * HTTP request can still fail and need to be handled. Example of this would be a network
   * error, authentication token expired.
   */
  private async validateRequiredState(): Promise<boolean> {
    if (!await this.connectionService.isOnline() || !await this.pingSynchronizationServer()) {
      this.couldNotReachServer = true;
      this.setError(true);
      return false;
    }

    if (!await this.authenticationService.isAuthenticated()) {
      this.isNotAuthenticated = true;
      this.setError(true);
      return false;
    }

    if (!this.mobileId) {
      this.mobileIdNotConfigured = true;
      this.setError(true);
      return false;
    }

    return true;
  }

  /**
   * Ping the server, but also catch any error that could occur while doing so.
   * 
   * @remark This concept should likely be moved to a more generic service or utility class or
   * integrated with better HTTP validation that are not currently handled by default.
   * 
   * @returns true if the server is not available, false otherwise.
   */
  private async pingSynchronizationServer(): Promise<boolean> {
    try {
      let result = await this.synchronizationHttpClient.pingSynchronizationServer();
      return result;
    }
    catch (e) {
      return false;
    }
  }

  public async updateOfflineStatus() {
    // Cannot use pingSynchronizationServer as it require authentication
    // and doesn't work when authentication token expired as it will redirect
    // to unautorized page.
    this.offline = !await this.connectionService.isOnline();

    if (this.offline) {
      //console.info("Server is not available, offline mode ON.");
    } else {
      //console.info("Server is available, offline mode OFF.")
      if (!await this.authenticationService.isAuthenticated()) {
        await this.authenticationService.initialize();
      }
    }
  }

  public async hidePopup() {
    this.synchronizationPopupState.display = false;
    if (this.popupConfirmationPromiseResolve) {
      this.popupConfirmationPromiseResolve();
      this.popupConfirmationPromiseResolve = null;
      this.popupConfirmationPromise = null;
    }
  }

  private requirePopupManualConfirmation() {
    this.popupConfirmationPromise = new Promise<boolean>((resolve) => {
      this.popupConfirmationPromiseResolve = resolve;
    });
  }

  private setError(requirePopupManualConfirmation, hasOtherErrorMessage = null) {
    this.hasError = true;
    this.hasOtherError = this.hasError && !this.couldNotReachServer && !this.isNotAuthenticated && !this.mobileIdNotConfigured;

    if (requirePopupManualConfirmation && this.synchronizationPopupState.display) {
      this.requirePopupManualConfirmation();
    }

    this.hasOtherErrorMessage = hasOtherErrorMessage;
  }

  async toggleForcedOffline() {
    this.isForcedOffline = !this.isForcedOffline;
    await LocalParameter.table.update(LocalParameter.FORCED_OFFLINE_MODE, { value: this.isForcedOffline });
    if (!this.isForcedOffline)
      this.getUpdates(true);

    this.updateOfflineStatus();
  }
}
