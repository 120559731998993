<nav class="layout-horizontal height-large color-set-blue">
  <div class="layout-left">
    <button (click)="onBackButtonClick()">
      <i class="fa fa-chevron-left"></i>
    </button>
  </div>
  <div class="layout-center">
    {{ title }}
  </div>
  <div class="layout-right">
      <button (click)="openAdditionalOptionsPopup()" [hidden]="!additionalOptions.hasAccessToAtLeastOneOption">
        <i class="fa fa-ellipsis-h"></i>
      </button>
  </div>
</nav>

<app-additional-options #additionalOptions [auditNumber]="auditNumber"></app-additional-options>
