import { Form } from "src/app/core/data/models/form/form";
import { Injectable } from "@angular/core";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { FormField } from "src/app/core/data/models/formField";
import { Section } from "src/app/core/data/models/form/section";
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { EntityState } from "src/app/core/data/changeTracking/entityState";
import { AlertSummaryBuilder } from "src/app/components/customFields/controls/alert/alertSummaryBuilder";
import { AuditSectionState } from "src/app/core/data/viewModels/auditSectionState";
import { Router } from "@angular/router";
import { Program } from "src/app/core/data/models/database/program.database";
import { DynamicFormCustomAction } from "src/app/components/dynamic-form/dynamicFormCustomAction";
import { SectionStateService } from "src/app/core/services/sectionStateService";
import { DataSourceChangeEventArgs } from "./dataSourceChangeEventArgs";
import { AuditSearchCriteriaState } from "./auditSearchCriteriaState";
import { WFStep } from "src/app/core/data/models/database/wFStep.database";
import { WorkflowStepService } from "src/app/core/services/workflowStepService";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { ButtonDataSourceSelectedEventArgs } from "./buttonDataSourceSelectedEventArgs";
import { DataSourceCache } from "./dataSourceCache";

@Injectable()
export class AuditState {
  public form: Form;
  public audit: Audit;
  public program: Program;
  public workflowStep: WFStep;
  public auditNumber: string;
  public auditFormattedNumber: string;
  public formFields: FormField<any>[];
  public section: Section;
  public alertSummary: AuditAlertSummary[];

  public searchCriteria: AuditSearchCriteriaState;
  public readonly: boolean;
  public instanceId: string;
  public instancePosition: number;
  public folderId: string;
  public parentSectionId: string;
  public parentInstanceId: string;

  // These are related to the dynamic tab features.
  // Contains the id of the section from which the dynamic tab originate.
  public reference: string;

  // Contains the id of the record in the custom field table used to locate the dynamic tab instance.
  // This is store inside the AuditState to prevent having to reload related tables from dynamic tab structure again when saving
  // the data.
  public customTableId: string;

  public sectionStates: AuditSectionState[];

  public refreshSectionStates = new Subject();

  private dataSourceChange = new Subject<DataSourceChangeEventArgs>();

  public $dataSourceChange = this.dataSourceChange.asObservable();

  private buttonDataSourceSelected = new Subject<ButtonDataSourceSelectedEventArgs>();

  public onNavigationChange;

  public dataSourceCache: DataSourceCache = new DataSourceCache();

  /**
  * Allow to trigger an event when a template button is clicked to notify potential controls to react.
  */
  public $buttonDataSourceSelected = this.buttonDataSourceSelected.asObservable();

  constructor(
    private readonly router: Router,
    private readonly alertSummaryBuilder: AlertSummaryBuilder,
    private sectionStateService: SectionStateService,
    private workflowStepService: WorkflowStepService,
    private authenticatedUser: AuthenticatedUser
  ) { }

  public raiseDataSourceChange(args: DataSourceChangeEventArgs) {
    this.dataSourceChange.next(args);
  }

  public raiseButtonDataSourceSelected(args: ButtonDataSourceSelectedEventArgs) {
    this.buttonDataSourceSelected.next(args);
  }

  getCustomActions(): DynamicFormCustomAction[] {
    let actions = new Array<DynamicFormCustomAction>();

    if (this.instanceId && !this.readonly) {
      actions.push(new DynamicFormCustomAction({ icon: "fa-trash", name: "deleteDynamicTabInstance" }))
      actions.push(new DynamicFormCustomAction({ icon: "fa-edit", name: "editDynamicTabInstance" }))
    }

    return actions;
  }

  /**
  * Returns a collection of original values from form fields.
  */
  public getFormFieldsOriginalValues(): Record<string, any>[] {
    let result: Record<string, any>[] = [];

    let flattedFormFields = FormField.flatFormFields(this.formFields);

    flattedFormFields = flattedFormFields.filter(x => x.isInputControl())

    for (const formField of flattedFormFields) {
      result.push({ key: formField.key, value: formField.value });
    }

    return result;
  }

  public nativateToSection(sectionId: string) {
    if (this.parentInstanceId) {
      this.router.navigate([`forms/${this.auditNumber}/instances/${this.parentInstanceId}`]);

      this.parentInstanceId = null;
      this.reference = null;
    }
    else {
      this.router.navigate([`forms/${this.auditNumber}/sections/${sectionId}`]);
    }

    if (this.onNavigationChange) {
      this.onNavigationChange();
    }
  }

  public raiseRefreshSectionStates() {
    this.refreshSectionStates.next(null);
  }

  auditResolver: Promise<void>;

  public validateRequiredFields() {
    return this.form.steps.find(x => x.id == this.audit.stepId).validateRequiredFields
  }

  public addAlertSummary(formField: FormField<any>, conditionId: string, value: string, customTableId: string) {
    let controlKey = formField.dataTableName + "." + formField.dataColumnName;

    if (customTableId) {
      controlKey += "." + customTableId;
    }

    let alertSummary = this.alertSummary.find(x => x.controlKey === controlKey)

    if (!conditionId) {
      if (alertSummary) {
        if (alertSummary.entityState === EntityState.New)
          this.alertSummary.splice(this.alertSummary.findIndex(x => x.controlKey === controlKey));
        else if (alertSummary.entityState === EntityState.Default || alertSummary.entityState === EntityState.Modified)
          alertSummary.entityState = EntityState.Deleted;
      }
    }
    else {
      if (!alertSummary) {
        let controlDescription = formField.sectionKey + "." + (formField.description || formField.key);

        const newAlert = this.alertSummaryBuilder.create(this.audit.id, value, "CustomField", conditionId, controlDescription, controlKey);

        this.alertSummary.push(newAlert);
      }
      else {
        if (alertSummary.entityState === EntityState.Deleted || alertSummary.entityState === EntityState.Default)
          alertSummary.entityState = EntityState.Modified;

        alertSummary.alertConditionDetailId = conditionId;
        alertSummary.value = value;
      }
    }
  }

  public async refreshState() {
    if (this.sectionStates) {
      this.sectionStates.length = 0;
    }
    else {
      this.sectionStates = [];
    }

    await this.sectionStateService.load(this.audit.id, this.form, this.sectionStates);
  }

  findFormFieldByName(name: string) {
    return this.findFormFieldByNameInternal(name, this.formFields);
  }

  private findFormFieldByNameInternal(name: string, formFields: FormField<any>[]) {
    for (const formField of formFields) {
      if (formField.dataColumnName == name) {
        return formField;
      }
      else {
        if (formField.children) {
          let result = this.findFormFieldByNameInternal(name, formField.children);

          if (result)
            return result;
        }
      }
    }
  }

  private allControls: any[];

  private loadAllControls(){
    this.allControls = [];

    let sections = Form.getAllSections(this.form);

    for (const section of sections) {
      let controls = Section.getAllControls(section);

      if (!controls)
        continue;

      for (const control of controls) {
        this.allControls.push({section, control});
      }
    }
  }

  getControlByName(name: string){
    if (!this.allControls)
      this.loadAllControls();

    return this.allControls.find(x => x.control.dataColumnName == name);
  }

  public async updateWorkflowStep(workdflowStepId: string) {
    this.audit.stepId = workdflowStepId;
    this.workflowStep = await WFStep.table.get(workdflowStepId);
    this.readonly = !await this.workflowStepService.getWorkflowStepModifyPermission(this.audit.stepId, this.authenticatedUser.id);
  }
}
