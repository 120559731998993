import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Dexie from 'dexie';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { BaseFormComponent } from 'src/app/components/forms/BaseForm.component';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListOptions } from 'src/app/components/list/listOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { OptionList } from 'src/app/core/data/models/database/optionList.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { EntitySecurityGroupListComponent } from '../entity-security-group-list/entity-security-group-list.component';
import { EntitySecurityGroupPermissionRepository } from '../entity-security-group/entitySecurityGroupPermissionRepository';
import { FilterMode } from '../filterMode';
import { DataSourceService } from '../task-list/dataSourceService';
import { DataSourceFilterValidator } from '../task-type-edit/dataSourceFilterValidator';
import { TemplateType } from '../templateType';
import { HtmlTemplateRenderer } from './htmlTemplateRenderer';

@Component({
  selector: 'app-option-list',
  templateUrl: './option-list-edit.component.html',
  styleUrls: ['./option-list-edit.component.scss']
})
export class OptionListEditComponent extends BaseFormComponent<OptionList> implements OnDestroy {
  visible: boolean;
  private onResolve: Function;
  private hasChanges: Boolean;
  private result: Promise<Boolean>;

  @ViewChild("visibleByList") visibleByList: EntitySecurityGroupListComponent;
  @ViewChild("allowedEditorsList") allowedEditorsList: EntitySecurityGroupListComponent;

  // TagBox could not be bound with Angular Reactive Form.
  filterModes: any;
  fields: any;
  templateTypes: any;

  groupByFields: any = [];
  sortByFields: any = [];
  lineTemplate1Fields: any = [];
  lineTemplate2Fields: any = [];
  lineTemplate3Fields: any = [];

  displayGroupByHtmlTemplateControl: boolean = false;

  isHtmlTemplate: Boolean;
  isJavascriptFilter: Boolean; 

  private isFilterFieldsInitialized: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    baseRepository: BaseRepository,
    private headerState: HeaderState,
    readonly router: Router,
    readonly translateService: TranslateService,
    private entitySecurityGroupPermissionRepository: EntitySecurityGroupPermissionRepository,
    private authenticatedUser: AuthenticatedUser,
    private dataSourceFilterValidator: DataSourceFilterValidator,
    private dataSourceService: DataSourceService) {

    super(translateService, router, baseRepository);

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl("id"),
      name: new UntypedFormControl("name", [Validators.required]),
      source: new UntypedFormControl("source", [Validators.required]),
      entityId: new UntypedFormControl("entityId", [Validators.required]),
      filterMode: new UntypedFormControl("filterMode", [Validators.required]),
      textFilter: new UntypedFormControl("textFilter"),
      sortBy: new UntypedFormControl("sortBy"),
      groupBy: new UntypedFormControl("groupBy"),
      templateType: new UntypedFormControl("templateType", [Validators.required]),
      lineTemplate1: new UntypedFormControl("lineTemplate1"),
      lineTemplate2: new UntypedFormControl("lineTemplate2"),
      lineTemplate3: new UntypedFormControl("lineTemplate3"),
      htmlTemplate: new UntypedFormControl("htmlTemplate"),
      filterScript: new UntypedFormControl("filterScript"),
      groupByHtmlTemplate: new UntypedFormControl("groupByHtmlTemplate")
    });

    this.form.reset();

    this.filterModes = [{ key: FilterMode.Text, text: "Text" }, { key: FilterMode.Javascript, text: "Javascript" }];
    this.templateTypes = [{ key: TemplateType.Text, text: "Text" }, { key: TemplateType.Html, text: "Html" }];

    this.table = OptionList.table;
    this.redirectAfterAction = false;
  }

  private setupFilterFields(){
    if (this.isFilterFieldsInitialized)
      return;

    this.isFilterFieldsInitialized = true;

    this.excludedColumns = [];

    for (const field of this.fields) {
      let fieldKey = this.convertToDunderStyle(field.key);

      this.excludedColumns.push(fieldKey);

      this.form.addControl(fieldKey, new UntypedFormControl(fieldKey));

      this.form.controls[fieldKey].setValue("");
    }
  }

  ngOnDestroy(): void {
    this.filterModelSubscription?.unsubscribe();
    this.templateTypeSubscription?.unsubscribe();
  }

  async edit(optionListId: string, fields: any[]){
    this.model = await OptionList.table.get(optionListId);

    return await this.show(fields);
  }

  async add(entityId: string, source: string, fields: any[]): Promise<Boolean> {
    let model = new OptionList();

    model.id = Dexie.Observable.createUUID();
    model.entityState = EntityState.New;
    model.source = source;
    model.entityId = entityId;
    model.filterMode = 1;
    model.templateType = 1;

    this.model = model;

    return this.show(fields);
  }

  private filterModelSubscription: Subscription;
  private templateTypeSubscription: Subscription;

  private excludedColumns: string[];

  private async show(fields: any[]): Promise<Boolean>{
    this.dataLoaded = false;

    this.id = this.model.id;

    this.formSynchronizer.setValuesToControls(this.model);

    this.fields = fields;

    this.setupFilterFields();

    this.load();

    this.result = new Promise<Boolean>((resolve) => { this.onResolve = resolve });

    this.visible = true;

    this.filterModelSubscription = this.form.controls.filterMode.valueChanges.subscribe(() => { this.setModeStates() });
    this.templateTypeSubscription = this.form.controls.templateType.valueChanges.subscribe(() => { this.onTemplateTypeChanged() });

    this.onTemplateTypeChanged();

    this.setModeStates();

    this.visibleByList.entityId = this.model.id.toString();
    this.allowedEditorsList.entityId = this.model.id.toString();

    this.visibleByList.refresh();
    this.allowedEditorsList.refresh();

    this.dataLoaded = true;

    return this.result;
  }

  private onTemplateTypeChanged(){
    this.setModeStates();

    let isTextBasedTemplate = this.form.controls.templateType.value == TemplateType.Text;

    // When it's a template text based, at least one field of first line must be selected 
    // to prevent having an empty task list.
    super.setRequiredField("lineTemplate1", isTextBasedTemplate);
  }

  private setModeStates(){
    this.isJavascriptFilter = this.form.controls.filterMode.value == FilterMode.Javascript;
    this.isHtmlTemplate = this.form.controls.templateType.value == TemplateType.Html;
  }

  public close() {
    this.visible = false;
  }

  lineTemplateFieldValueChange(item) {
    // tag boxes could not be bound with Angular reactor so the value must be set explicitely to the 
    // control but this raise the event once the view is displayed and in this case, the form must not be marked as dirty.
    // When the event is triggered while rendering the view, the event property is null. This is the trick to prevent
    // marking the view as dirty only when user change the value.
    if (item.event){
      this.form.markAsDirty();

      let fieldsObject = this[item.element.id + "Fields"]

      this.synchronizeFieldsControl(fieldsObject, item.element.id);
    }
  }

  groupByValueChange(item) {
    // See comment in lineTemplateFieldValueChange fonction.
    if (item.event) {
      this.form.markAsDirty();
      this.synchronizeFieldsControl(this.groupByFields, "groupBy");
      this.setDisplayGroupByHtmlTemplateState();
    }
  }

  sortByValueChange(item) {
    // See comment in lineTemplateFieldValueChange fonction.
    if (item.event) {
      this.form.markAsDirty();
      this.synchronizeFieldsControl(this.sortByFields, "sortBy");
    }
  }

  async load(){
    this.lineTemplate1Fields = [];
		this.lineTemplate2Fields = [];
		this.lineTemplate3Fields = [];
		this.sortByFields = [];
    this.groupByFields = [];

		this.synchronizeControlWithFields(this.form.controls.lineTemplate1.value, this.lineTemplate1Fields);
		this.synchronizeControlWithFields(this.form.controls.lineTemplate2.value, this.lineTemplate2Fields);
		this.synchronizeControlWithFields(this.form.controls.lineTemplate3.value, this.lineTemplate3Fields);
		this.synchronizeControlWithFields(this.form.controls.sortBy.value, this.sortByFields);
		this.synchronizeControlWithFields(this.form.controls.groupBy.value, this.groupByFields);

    this.setDisplayGroupByHtmlTemplateState();

    this.loadTextFilters();
  }

  private setDisplayGroupByHtmlTemplateState(){
    this.displayGroupByHtmlTemplateControl = !!this.form.controls.groupBy.value;
  }

  private loadTextFilters(){
    if (!!this.form.controls.textFilter.value){
      let textFilters = JSON.parse(this.form.controls.textFilter.value);

      for (const textFilter of textFilters) {
        let field = this.fields.find(x => x.key == textFilter.key);

        if (field){
          this.form.controls[this.convertToDunderStyle(field.key)].setValue(textFilter.value);
        }
      }
    }
  }

  /**
  * This is to avoid name clash with field name from the related data source.
  */
  private convertToDunderStyle(value: string){
    return "__" + value + "__";
  }

  private synchronizeControlWithFields(value, fields) {
		if (value) {
			var tokens = _.split(value, ",");

			for (const token of tokens) {
				fields.push(token);
			}
		}
	}

  public synchronizeModel(){
    this.saveTextFilters();
  }

  private saveTextFilters(){
    let textFilters = [];

    for (const field of this.fields) {
      let value = this.form.controls[this.convertToDunderStyle(field.key)].value;

      textFilters.push({key: field.key, value: value});
    }

    let serializedTextFilters = JSON.stringify(textFilters);

    this.model["textFilter"] = serializedTextFilters;
  }

  private synchronizeFieldsControl(fields: any[], key: string){
		if (fields){
      let uniqueKeys = _.uniq(fields);

      let availableProperties = _.intersectionBy(uniqueKeys, this.fields.map(x => x.key));

			this.form.controls[key].setValue(_.join(availableProperties, ","));
		}
	}

  async saveButtonClick(){
    if (await super.saveAndRedirect(this.excludedColumns)){
      this.onResolve(true);
      this.visible = false;
    }
  }

  async cancelButtonClick(){
    if (await super.cancel()){
      this.onResolve(false);
      this.visible = false;
    }
  }

  public async validate(model: OptionList, validationDictionary: string[]): Promise<string[]>{
    if (validationDictionary.length == 0 && this.form.controls.filterScript.value && this.form.controls.filterMode.value == FilterMode.Javascript){
      let taskType = await TaskType.table.get(this.form.controls.entityId.value);

      let filterExpressionValidation = await this.dataSourceFilterValidator.validate(
        taskType.dataSourceImportationId, this.form.controls.filterScript.value)

      if (!filterExpressionValidation.isValid()){
        validationDictionary.push(filterExpressionValidation.getValidations()[0].message);
      }
    }

    let isHtmlTemplateActivated: boolean = this.form.controls.htmlTemplate.value && this.form.controls.templateType.value == TemplateType.Html;
    let isGroupByHtmlTemplateActivated: boolean = this.form.controls.groupBy.value && this.form.controls.groupByHtmlTemplate.value ;

    // Validate rendering the list whether an html group template or an item template is configured because
    // in both case, there is a potential error from the user entry.
    if (validationDictionary.length == 0 && 
      isGroupByHtmlTemplateActivated || isHtmlTemplateActivated){

      let listOptions = new ListOptions(
        {
          mapItemFunction: this.form.controls.htmlTemplate.value,
          mapGroupFunction: this.form.controls.groupByHtmlTemplate.value
        });

      let taskType = await TaskType.table.get(this.form.controls.entityId.value);

      try {
        let htmlTemplateRenderer = new HtmlTemplateRenderer(listOptions);
        let dummyItem = isHtmlTemplateActivated ? await this.dataSourceService.createDummyItem(taskType.dataSourceImportationId) : null;
        let dummyGroup = isGroupByHtmlTemplateActivated ? { key: dummyItem[0], properties: {}, visible: true} : null;

        htmlTemplateRenderer.execute(dummyItem, dummyGroup);
      } catch (error) {
        validationDictionary.push(this.translateService.instant("optionList.edit.validations.invalidHtmlTemplate", { message: error.message }));
      }
    }

		return validationDictionary;
	}
}
