import { Injectable } from "@angular/core";
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditDataTable } from "../data/models/database/auditDataTable.database";
import { DynamicSection } from "../data/models/form/dynamicSection";
import { Form } from "../data/models/form/form";
import { Section } from "../data/models/form/section";
import { SectionType } from "../data/models/form/sectionType";
import { AuditSectionState } from "../data/viewModels/auditSectionState";

@Injectable()
export class SectionStateService {
  public async load(auditId: string, form: Form, sectionState: AuditSectionState[]) {
    sectionState.length = 0;

    sectionState.push(...await this.loadStates(auditId, form));
  }

  private async loadStates(auditId: string, form: Form): Promise<AuditSectionState[]> {
    let result = new Array<AuditSectionState>();

    let dataTables = await AuditDataTable.table.where("auditId").equals(auditId).toArray();

    for (const section of form.sections) {
      result.push(... this.loadSectionStates(section, dataTables));
    }

    for (const section of form.alertTemplates) {
      result.push(... this.loadSectionStates(section, dataTables));
    }

    return result;
  }

	private loadSectionStates(section: Section, dataTables: AuditDataTable[]): AuditSectionState[]{
    let result = new Array<AuditSectionState>();

    if (section.type === SectionType.DynamicTab) {
      let dynamicSection = section as DynamicSection;

      for (const folder of dynamicSection.folders) {
        for (const instance of folder.instances) {
          let dataTable = dataTables.find(x => x.tableName === folder.tableName && x.tableId === instance.customTableId);

          if (dataTable)
            result.push(AuditService.createAuditSectionState(dataTable));
        }
      }
    }

    if (section.dataTableName) {
      let filteredTables = dataTables.filter(x => x.tableName === section.dataTableName);

      for (const filteredTable of filteredTables) {
        result.push(AuditService.createAuditSectionState(filteredTable));
      }
    }

    return result;
  }
}