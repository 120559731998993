// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Task extends ChangeTrackingEntity {
  public static table: Dexie.Table<Task, string>;
  public static tableName: string = 'Task';

  public static DATA_SOURCE_IMPORTATION_RESULT_ID: string = 'dataSourceImportationResultId';  public static ID: string = 'id';  public static OPTION_LIST_ID: string = 'optionListId';  public static POSITION: string = 'position';  public static TASK_TYPE_ID: string = 'taskTypeId';  public static TIME_STAMP: string = 'timeStamp';  public static USER_ACCOUNT_ID: string = 'userAccountId';

  public dataSourceImportationResultId: string;  public id: string;  public optionListId: string;  public position: number;  public taskTypeId: string;  public timeStamp: Date;  public userAccountId: string;

  public constructor(args?: Partial<Task>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Task.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Task.tableName);
      this.table.mapToClass(Task);
  }
}
