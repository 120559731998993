import { Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Dexie from "dexie";
import { Subscription } from "rxjs";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { ChangeTrackingEntity } from "src/app/core/data/changeTracking/changeTrackingEntity";
import { EntityState } from "src/app/core/data/changeTracking/entityState";
import { CanLeaveEntityAutoSaveResult } from "src/app/core/sections/canLeaveEntityAutoSaveResult";
import { IEntityAutoSaveGuard } from "src/app/core/sections/IEntityAutoSaveGuard";
import { FormSynchronizer } from "src/app/core/formSynchronizer";
import { PopupUtility } from "../popup/popup.utility";
import { SimplePopupComponent } from "../popup/simplePopup/simplePopup.component";
import { SimplePopupOptions } from "../popup/simplePopup/simplePopupOptions";

@Component({
  selector: 'app-base-form-component',
  template: ""
})
export abstract class BaseFormComponent<T extends ChangeTrackingEntity> implements IEntityAutoSaveGuard, OnDestroy {
  constructor(public translateService: TranslateService, public router: Router, public baseRepository: BaseRepository) {
    this.setDefaultActionButtonsState();
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  setDefaultActionButtonsState() {
    this.cancelButtonText = this.translateService.instant("general.close");
  }

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  private _form: UntypedFormGroup;
  private valueChangesSubscription: Subscription;

  public dataLoaded: boolean = false;

  public get form() {
    return this._form;
  }

  public set form(value: UntypedFormGroup) {
    this._form = value;

    this.formSynchronizer = new FormSynchronizer({ form: value });

    this.valueChangesSubscription = this._form.valueChanges.subscribe(x => {
      if (this.dataLoaded)
        this.cancelButtonText = this.translateService.instant("general.cancel");
    });
  }

  public model: T;
  public id: string | number;
  public isLoading: boolean;
  public entityUrl: string;
  public redirectUrl: string;
  public formSynchronizer: FormSynchronizer;
  public table: Dexie.Table<any, string>;
  public redirectAfterAction: Boolean = true;

  public cancelButtonText: string;

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHander(event: any) {
    return (await this.canLeave()).success;
  }

  async saveChanges(): Promise<boolean> {
    if (this.form.dirty)
      return this.save();
    else
      return true;
  }

  async canLeave(): Promise<CanLeaveEntityAutoSaveResult> {
    let result = new CanLeaveEntityAutoSaveResult();

    if (!this.form.dirty)
      result.success = true;
    else {
      let validationDictionary = [];

      this.synchronizeModelInternal();

      await this.validateInternal(this.model, validationDictionary);

      result.message = validationDictionary[0];
      result.success = validationDictionary.length === 0;
    }

    return result;
  }

  public abstract load()

  public synchronizeModel() {
  }

  private synchronizeModelInternal(excludedColumns?: string[]) {
    this.formSynchronizer.setValuesToModel(this.model, excludedColumns);

    this.synchronizeModel();
  }

  public async validate(model: ChangeTrackingEntity, validationDictionary: string[]): Promise<string[]> {
    return [];
  }

  private async validateInternal(model: ChangeTrackingEntity, validationDictionary: string[]): Promise<string[]> {
    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.get(key).errors) {
          for (const error of Object.keys(this.form.get(key).errors)) {
            if (error == "required")
              validationDictionary.push(this.translateService.instant("validations.oneOrMoreFieldsRequired"))

            return validationDictionary;
          }
        }
      }
    }

    await this.validate(model, validationDictionary);

    return validationDictionary;
  }

  async save(excludedColumns?: string[]): Promise<boolean> {
    let validationDictionary = [];

    this.synchronizeModelInternal(excludedColumns);

    await this.validateInternal(this.model, validationDictionary);

    if (validationDictionary.length > 0) {
      await this.modalPopup.display(new SimplePopupOptions({
        titleIcon: 'fas fa-exclamation-triangle',
        title: this.translateService.instant("validations.title"),
        content: validationDictionary[0]
      }));

      return false;
    }
    else {
      // Force the state of the item to save to be dirty because at this point,
      // there is no mechanism to set the state automatically when updating properties
      // of model compared to Entity Framework.
      if (this.model.entityState == undefined || this.model.entityState == EntityState.Default) {
        this.model.entityState = EntityState.Modified;
      }

      this.baseRepository.save(this.table, this.model);

      this.id = this.model.id;

      this.setDefaultActionButtonsState();

      this.resetActionButtons();

      this.afterSave();

      return true;
    }
  }

  public afterSave(){

  }

  private resetActionButtons() {
    // 'reset' method should not be use because read only drop down controls from
    // devexpress will be emptied even if they have values.

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  async saveAndRedirect(excludedColumns?: string[]) {
    if (!this.table) {
      throw new Error("A value must be specified to the property 'table' of the BaseForm.component.");
    }

    if (await this.save(excludedColumns)) {
      if (this.isAddMode()) {
        if (this.redirectAfterAction)
          this.navigateToEdit();

        return true;
      }
      else
        return true;
    };

    return false;
  }

  navigateToEdit() {
    this.router.navigate([`/${this.entityUrl}/` + this.model.id])
  }

  isAddMode() {
    return this.router.url.split('/').pop() === "new";
  }

  isEditMode() {
    return !this.isAddMode();
  }

  async cancel(): Promise<Boolean> {
    const self = this;

    let resolver: Function;

    let result = new Promise<Boolean>((resolve) => { resolver = resolve });

    if (this._form.dirty) {
      await PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService,
        this.translateService.instant("popup.question.confirmCancelTitle"),
        this.translateService.instant("popup.question.confirmCancelMessage"),
        async () => {
          this.resetActionButtons();

          if (this.id || !this.redirectAfterAction) {
            self.load();
          }
          else
            this.navigateToList();

          this.setDefaultActionButtonsState();

          resolver(true);
        });
    }
    else {
      resolver(true);
    }

    return result;
  }

  public navigateToList(queryParams?: any) {
    if (this.redirectUrl) {
      this.router.navigate([`/${this.redirectUrl}`], { queryParams: queryParams });
    } else {
      this.router.navigate([`/${this.entityUrl}`], { queryParams: queryParams });
    }
  }

  public setControlState(control: AbstractControl, enable: boolean) {
    if (enable)
      control.enable();
    else
      control.disable();
  }

  /**
  * Set whether a field is required.
  */
  public setRequiredField(field: string, isRequired: boolean) {
    if (isRequired) {
      this.form.get(field).setValidators(Validators.required);
    }
    else {
      this.form.get(field).clearValidators();
    }

    this.form.controls[field].updateValueAndValidity();
  }
}