<app-popup [boxFixed]="true" #customFieldForm [canClose]="false">

  <div class="layout-vertical" [formGroup]="form">

    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i class="fas fa-search"></i>
            <div><span>{{ 'form.search.customfields.title' | translate}}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="close()"></i></button>
        </div>
      </div>
    </div>


    <div class="layout-center">
      <div class="field-zone">
        <label>{{ 'form.search.customfields.control' | translate}}</label>

        <dx-select-box [items]="controlDataSource" formControlName="control" displayExpr="description" valueExpr="key">
        </dx-select-box>

      </div>

      <div class="field-zone">

        <div [ngSwitch]="control?.type">
          <div *ngSwitchCase="customFieldControlTypes.TextBox">
            <div *ngIf="control.dataType === dataTypes.Decimal">

              <div class="field-zone">
                <label for="numericComparisonType">{{ 'form.search.customfields.comparison' | translate}}</label>
                <dx-select-box name="numericComparisonType" formControlName="numericComparisonType" [items]="numericComparisonTypeDataSource" displayExpr="description" valueExpr="key"></dx-select-box>
              </div>

              <div class="field-zone">
                <label for="numericValue">{{ 'form.search.customfields.value' | translate}}</label>
                <dx-number-box name="numericValue" formControlName="numericValue"></dx-number-box>
              </div>
            </div>

            <div *ngIf="control.dataType === dataTypes.String">
              <div class="field-zone">
                <label for="stringComparisonType">{{ 'form.search.customfields.comparison' | translate}}</label>
                <dx-select-box name="stringComparisonType" formControlName="stringComparisonType" [items]="stringComparisonTypeDataSource" displayExpr="description" valueExpr="key"></dx-select-box>
              </div>

              <div class="field-zone">
                <label for="stringValue">{{ 'form.search.customfields.value' | translate}}</label>
                <dx-text-box name="stringValue" formControlName="stringValue"></dx-text-box>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="customFieldControlTypes.DateTimePicker">
            <div class="field-zone">
              <label for="dateFromValue">{{ 'form.search.customfields.dateFrom' | translate}}</label>

              <app-date-time-picker
                name="dateFromValue"
                formControlName="dateFromValue"
                [inputMask]="inputMask"
                [form]="form"
              ></app-date-time-picker>

            </div>
            <div class="field-zone">
              <label for="dateToValue">{{ 'form.search.customfields.dateTo' | translate}}</label>

              <app-date-time-picker
                name="dateToValue"
                formControlName="dateToValue"
                [inputMask]="inputMask"
                [form]="form"
              ></app-date-time-picker>

            </div>
          </div>

          <div *ngSwitchCase="customFieldControlTypes.CheckBox">
            <div class="field-zone">
              <label for="booleanValue">{{ 'form.search.customfields.value' | translate}}</label>
              <dx-check-box name="booleanValue" formControlName="booleanValue"></dx-check-box>
            </div>
          </div>

          <div *ngSwitchCase="customFieldControlTypes.ComboBox">
            <field-zone>
              <label for="dataSourceValue">{{ 'form.search.customfields.value' | translate}}</label>
              <dx-select-box name="dataSourceValue" formControlName="dataSourceValue" [items]="customFieldDataSource" displayExpr="description" valueExpr="key"></dx-select-box>
            </field-zone>
          </div>

          <div *ngSwitchCase="customFieldControlTypes.RadioButton">
            <div class="field-zone">
              <label for="dataSourceValue">{{ 'form.search.customfields.value' | translate}}</label>
              <dx-select-box name="dataSourceValue" formControlName="dataSourceValue" [items]="customFieldDataSource" displayExpr="description" valueExpr="key"></dx-select-box>
            </div>
          </div>

          <div *ngSwitchCase="customFieldControlTypes.MaskedEditTextBox">
            <div class="field-zone">
              <label for="stringComparisonType">{{ 'form.search.customfields.comparison' | translate}}</label>
              <dx-select-box name="stringComparisonType" formControlName="stringComparisonType" [items]="stringComparisonTypeDataSource" displayExpr="description" valueExpr="key"></dx-select-box>
            </div>

            <div class="field-zone">
              <label for="stringValue">{{ 'form.search.customfields.value' | translate}}</label>
              <dx-text-box name="stringValue" formControlName="stringValue" [mask]="inputMask"></dx-text-box>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="layout-bottom">
      <div class="layout-horizontal padding-vertical-compact padding-horizontal-normal color-set-white">
        <div class="layout-right space">
          <button class="color-set-green compact" (click)="apply()" [disabled]="form.invalid">
            <div><span>{{ 'form.search.customfields.apply' | translate}}</span></div>
          </button>
          <button class="color-set-green compact" (click)="cancel()">
            <div><span>{{ 'form.search.customfields.cancel' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>

  </div>
</app-popup>
