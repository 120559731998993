import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserAccountMobileInterceptor } from '../../authentication/authenticationMobileId.interceptor';
import { environment } from 'src/environments/environment';
import { AuthenticationType } from 'src/app/authentication/authenticationType';
import { AuthenticationWindowsInterceptor } from 'src/app/authentication/authenticationWindows.interceptor';
import { MsalInterceptor } from '@azure/msal-angular';
import { AuthenticationNoneInterceptor } from 'src/app/authentication/authenticationNone.interceptor';
import { AzureAdInterceptor } from 'src/app/authentication/authenticationAzureAd.interceptor';

let providers: any = [
  { provide: HTTP_INTERCEPTORS, useClass: UserAccountMobileInterceptor, multi: true }
];

if (environment.authentication.authenticationType === AuthenticationType.Windows) {
  providers.push({ provide: HTTP_INTERCEPTORS, useClass: AuthenticationWindowsInterceptor, multi: true })
} else if (environment.authentication.authenticationType === AuthenticationType.AzureAd) {
  providers.push({ provide: HTTP_INTERCEPTORS, useClass: AzureAdInterceptor, multi: true })
} else if (environment.authentication.authenticationType === AuthenticationType.None) {
  providers.push({ provide: HTTP_INTERCEPTORS, useClass: AuthenticationNoneInterceptor, multi: true })
}

export const interceptorProviders = providers
