import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';
import { ControlFont } from 'src/app/core/data/models/form/controlFont';
import { ExtendedProperty } from 'src/app/core/data/models/form/extendedProperty';
import { FormField } from "src/app/core/data/models/formField";
import { CustomFieldValueItemRepository } from 'src/app/core/data/repositories/customFieldValueItemRepository';
import { StringUtility } from 'src/app/core/stringUtility';
import { AuditState } from 'src/app/pages/audit/auditState';
import { BaseControlComponent } from '../basecontrol/basecontrol.component';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent extends BaseControlComponent implements OnInit, OnDestroy {

  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});

	private dataSourceChangeSubscription: Subscription;
	private linkMemberField: FormField<any>;

  description: string;
  includeInWebInterface: Boolean;
  treatLikeUrl: Boolean;
  foreColor: string;
  backColor: string;
  font: ControlFont;

  constructor(
    private readonly customFieldValueItemRepository: CustomFieldValueItemRepository,
    private readonly auditState: AuditState) {
    super();
  }

	ngOnDestroy(): void {
		this.dataSourceChangeSubscription?.unsubscribe();
	}

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.includeInWebInterface = super.getExtendedProperty("IncludeInWebInterface", false) as boolean;
    this.treatLikeUrl = super.getExtendedProperty("TreatLikeUrl", false) as boolean;

    if (super.hasExtendedProperty("ForeColor"))
      this.foreColor = super.getExtendedProperty("ForeColor") as string;

    if (super.hasExtendedProperty("BackColor"))
      this.backColor = super.getExtendedProperty("BackColor") as string;

    this.font = this.input.font;

		let linkMemberName = super.getExtendedProperty("LinkMemberName") as string;

    if (linkMemberName) {
			this.linkMemberField = this.auditState.findFormFieldByName(linkMemberName);

			if (this.linkMemberField){
				await this.refreshLinkMemberDisplayValue(this.linkMemberField.value);

				this.dataSourceChangeSubscription = this.auditState.$dataSourceChange.subscribe(async x => {
					if (x.name == this.linkMemberField.dataColumnName){
						await this.refreshLinkMemberDisplayValue(x.newValue);
					}
				});
			}
    }
		else
			this.description = this.input.description;
  }
	
	private async refreshLinkMemberDisplayValue(value: string) : Promise<void>{
		if (value){
			const item = await CustomFieldValueItem.table.get(value);
			let displayMember = StringUtility.turnToCamelCase(super.getExtendedProperty("DisplayMember") as string);

			let itemDescription = item[displayMember];

			if (super.hasExtendedProperty("SecondDisplayMember")){
				let secondDisplayMember = StringUtility.turnToCamelCase(super.getExtendedProperty("SecondDisplayMember") as string);

				if (secondDisplayMember){
					let secondDisplayMemberSeparator: string = " - ";

					if (super.hasExtendedProperty("SecondDisplayMemberSeparator")){
						secondDisplayMemberSeparator = super.getExtendedProperty("SecondDisplayMemberSeparator") as string;
					}

					itemDescription += secondDisplayMemberSeparator + item[secondDisplayMember];
				}
			}

			this.description = itemDescription;
		}
		else{
			this.description = "";
		}
	}
}
