import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from './services/permissionService';
import { PermissionType } from './data/enums/permissionType';


@Injectable()
export class NavigatorGuard  {
  locks: any = {};
  private routeAccessType: Map<string, PermissionType>;

  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) {
    window.addEventListener('beforeunload', (event) => {
      if (this.hasRoutingLocks()) {
        event.preventDefault();
        event.returnValue = '';
      }
    });

    this.routeAccessType = new Map<string, PermissionType>();
    this.routeAccessType.set('/groups', PermissionType.GroupView);
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    for (let [key, value] of this.routeAccessType) {
      if (state.url.includes(key) && !this.permissionService.hasAccess(value)) {
        this.router.navigate(['/home']);
      }
    }

    return true;
  }

  canActivateChild() {
    if (this.hasRoutingLocks()) {
      if (confirm('Leave site?')) {
        this.removeAllRoutingLocks();
        return true;
      }
      return false;
    }
    return true;
  }

  setRoutingLock(key: string) {
    this.locks[key] = true;
  }

  removeRoutingLock(key: string) {
    delete this.locks[key];
  }

  removeAllRoutingLocks() {
    this.locks = {};
  }

  hasRoutingLocks(): boolean {
    return !!Object.keys(this.locks).length;
  }
}