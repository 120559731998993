export class FormatUtility{
  public static getPredefinedFormatSymbol(value: string) {
    let firstCharacter = value.substr(0, 1).toLowerCase();

    switch (firstCharacter) {
      case "c":
        return "$ ";
      case "p":
        return "% ";
      case "f":
        return "";
    }
  }

  public static isPredefinedFormat(value: string) {
    let firstCharacter = value.substr(0, 1).toLowerCase();

    switch (firstCharacter) {
      case "c":
      case "p":
      case "f":
        return true;
      default:
        return false;
    }
  }

  /**
  * Actual output format for dates was only used in the Windows parts to print reports but they will
  * now be used in the Web like in the form search. This function will try to handle the maximum number of cases
  * where moment.js library is used to format date as string.
  */
  public static convertInfragisticsToMomentDateFormat(value: string){
    let result: string;

    if (!value){
      return "DD-MM-yyyy HH:mm:ss";
    }

    switch (value) {
      case "d":
        result = "DD-MM-yyyy";
        break;
      case "D":
        result = "DD MMM yyyy";
        break;
      case "t":
        result = "hh:mm a"
        break;
      case "T":
        result = "hh:mm:ss a"
        break;
      case "f":
        result = "DD MMM yyyy hh:mm a"
        break;
      case "F":
        result = "DD MMM yyyy hh:mm:ss a"
        break;
      case "g":
        result = "DD-MM-yyyy hh:mm a"
        break;
      case "G":
        result = "DD-MM-yyyy hh:mm:ss a"
        break;
      default:
        // With moment.js, only two consecutive d in in upper case represents a date as number.
        // So ddd or dddd represents the day of the week but dd means nothing.
        // In Infragistics, dd means the date as number. So this is the only pattern to be replaced.
        // let consecutiveDs = this.findConsecutivePairs(value, "d");

        let customFormat: string = value;

        // Considering there is no more dddd in all the patterns available and only two consecutives characters
        // have to be replaces in upper case, the following algorithm is way simpler than one that would support
        // an infinite number of consecutive characters where two and only two would have to be find.
        // ▼ is an alt+31 ascii character not impossible to enter by the user but really not likely to happen.
        customFormat = customFormat.replaceAll("dddd", "▼▼▼▼");
        customFormat = customFormat.replaceAll("ddd", "▼▼▼");
        customFormat = customFormat.replaceAll("dd", "DD");
        customFormat = customFormat.replaceAll("▼", "d");

        result = customFormat;

        break;
    }

    return result;
  }

  static findConsecutivePairs(input: string, character: string): number[] {
    const consecutivePairs: number[] = [];

    for (let i = 0; i < input.length - 1; i++) {
        if (input[i] !== character)
          continue;

        const pair = input.substring(i, i + 2);

        if (pair.length === 2 && pair[0] === pair[1]) {
            consecutivePairs.push(i);
            consecutivePairs.push(i + 1);
        }
    }

    return consecutivePairs;
}
}
