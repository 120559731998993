import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SynchronizationService } from "../data/synchronization/synchronizationService";

@Injectable()
export class ConnectedGuardService {
  constructor(
    private router: Router,
    private synchronizationService: SynchronizationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.synchronizationService.offline) {
      this.router.navigate(['home']);
    }
    else {
      return true;
    }
  }
}
