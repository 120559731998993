<div class="layout-vertical">
  <div class="layout-center">
    <app-list [(items)]="menuItems" [paging]="false" (activatedItem)="goToLink($event)">
      <ng-template #listTemplate let-item>
        <div class="layout-horizontal height-normal">
          <div class="layout-center align-left">
            <button class="width-100 color-set-transparent" [disabled]="item.requireOnline && synchronizationService.offline" [id]="'MainMenuTool-' + item.title">
              <i [class]="item.icon"></i>
              <div><span>{{item.title | translate}}</span> </div>
            </button>
          </div>
        </div>
      </ng-template>
    </app-list>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-gray">
      <div class="layout-left">Administration</div>
    </div>

    <app-list #menuList [(items)]="adminMenuItems" [paging]="false"
      (activatedItem)="goToLink($event)">
      <ng-template #listTemplate let-item>
        <div class="layout-horizontal height-normal">
          <div class="layout-center align-left">
            <button class="width-100 color-set-transparent" [disabled]="item.requireOnline && synchronizationService.offline" [id]="'MainMenuTool-' + item.title">
              <i [class]="item.icon"></i>
              <div><span>{{item.title | translate}} {{item.disabled}}</span> </div>
            </button>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
  <div class="layout-bottom" *ngIf="this.authenticationService.canLogOff">
    <div class="layout-horizontal height-normal">
      <div class="layout-center align-left">
        <button *ngIf="authenticationService.isAuthenticated" (click)="this.authenticationService.logout()" id="LogoutButton" class=" color-set-orange width-100" [disabled]="synchronizationService.offline">
          <div><span>Logout</span> </div>
        </button>
      </div>
    </div>
  </div>
</div>
