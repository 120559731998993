import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from "devextreme/data/array_store";
import { AuditState } from '../auditState';
import { AlertSummaryMapper } from './alertSummaryMapper';
import { UserService } from 'src/app/core/services/userService';
import { AuditAlertSummary } from 'src/app/core/data/models/database/auditAlertSummary.database';
import { AlertConditionDetail } from 'src/app/core/data/models/database/alertConditionDetail.database';
import { Router } from '@angular/router';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import _ from 'lodash';
import { DynamicTabAuditTemplate } from 'src/app/core/data/models/database/dynamicTabAuditTemplate.database';
import { Form } from 'src/app/core/data/models/form/form';


@Component({
  selector: 'app-alert-summary',
  templateUrl: './alert-summary.component.html',
  styleUrls: ['./alert-summary.component.scss']
})
export class AlertSummaryComponent implements OnInit {
  public dataSource: DataSource;

  constructor(
    public readonly auditState: AuditState,
    public userService: UserService,
    private router: Router,
    private readonly mapper: AlertSummaryMapper
  ) { }

  public async ngOnInit(): Promise<void> {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: await this.mapper.map(this.auditState.alertSummary),
        key: "id"
      }),
      group: "category",
      searchExpr: ["controlDescription", "value", "createdBy"]
    });
  }

  public async navigateToSection(item: { id: string; auditId: string; }) {
    let urlSectionType: string;

    let controlKey = (await AuditAlertSummary.table.get(item.id)).controlKey;
    let customTableName = _.first(controlKey.split('.'));

    let section = Form.findSectionByTableName(this.auditState.form, customTableName);

    if (section == null)
      throw new Error(`The section with the name ${customTableName} doesn't exists.`);

    let id = section.id;

    let audit = await Audit.table.get(item.auditId);

    if (section["isTemplate"] === true) {
      urlSectionType = "instances";

      let customTableId = _.last(controlKey.split('.'));

      id = (await DynamicTabAuditTemplate.table.where('customTableId').equals(customTableId).first()).id;
    }
    else
      urlSectionType = "sections";

    this.router.navigate([`/forms/${audit.number}/${urlSectionType}/${id}`]);

  }
}