// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class MobileEntity extends ChangeTrackingEntity {
  public static table: Dexie.Table<MobileEntity, string>;
  public static tableName: string = 'MobileEntity';

  public static ENTITY_ID: string = 'entityId';  public static ENTITY_ID_INT: string = 'entityIdInt';  public static ID: string = 'id';  public static MOBILE_ID: string = 'mobileId';  public static TABLE_NAME: string = 'tableName';  public static TIME_STAMP: string = 'timeStamp';

  public entityId: string;  public entityIdInt: number;  public id: string;  public mobileId: string;  public tableName: string;  public timeStamp: Date;

  public constructor(args?: Partial<MobileEntity>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return MobileEntity.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(MobileEntity.tableName);
      this.table.mapToClass(MobileEntity);
  }
}
