import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Mobile } from '../models/database/mobile.database';
import { FormTemplateNextNumber } from '../models/databaseLocal/formTemplateNextNumber.database';

@Injectable({
  providedIn: 'root',
})
export class MobileHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Mobile`;

  constructor(private http: HttpClient) { }

  async createMobile(mobile: Mobile): Promise<Mobile> {
    return await firstValueFrom(this.http.post<Mobile>(this.configUrl, mobile));
  }

  async getMobile(): Promise<Mobile> {
    return await firstValueFrom(this.http.get<Mobile>(this.configUrl));
  }

  async activateMobile(): Promise<void> {
    await firstValueFrom(this.http.get(`${this.configUrl}/Activate`));
  }

  async getLocalChangeTracking(): Promise<number> {
    return await firstValueFrom(this.http.get<number>(`${this.configUrl}/LocalChangeTracking`));
  }

  async getFormTemplateNextNumber(): Promise<FormTemplateNextNumber[]> {
    return await firstValueFrom(this.http.get<FormTemplateNextNumber[]>(`${this.configUrl}/FormTemplateNextNumber`));
  }
}