import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router"
import { environment } from 'src/environments/environment';
import { AuthenticationAuthorizationRouting } from './authenticationAuthorization.routing';
import { AuthenticationType } from './authenticationType';

@Injectable({
  providedIn: 'root'
})

// Intercept errors comming back from the webapi and check if it contains an error, which is a json object
// containing the api exception returned. In this case we check it is specifically an ApiException 
// with exceptionCode 403. This exception was specially create to identify that the user has no 
// access to this request but also all web api request, which is why we redirect to an unauthorized
// page instead displaying a message or toast.
// Note: This is a first implementation of an interceptor and might need revision to work correctly with
// future interceptors.
export class AuthenticationWindowsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.authentication.authenticationType === AuthenticationType.Windows) {
      request = request.clone({
        withCredentials: true
      });

      return this.handleError(request, next);
    }
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // Error can be of at least 2 types which explain why we need to have multiple cases for the same
      // error code. At this time here are the following use cases that I know of
      // - 401 / 403 when a request is handled correctly by the server return error.error.
      // - 401 when a request is handled correctly by the server but when a user cancel the windows authentication
      //   login dialog, it return error from 
      catchError((error: any): ObservableInput<any> => {
        if (error?.status === 401
          || error?.error?.applicationExceptionCode === 401) {

          this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);

          return throwError(() => error);
        } else if (error?.status === 403
          || error?.error?.applicationExceptionCode === 403) {

          this.router.navigateByUrl(`${AuthenticationAuthorizationRouting.unauthorizedRoute}/${error?.status || error?.error?.applicationExceptionCode}`);

          return throwError(() => error);
        }

        return throwError(() => error);
      })
    );
  }
}