import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MsalInterceptor, MsalService } from '@azure/msal-angular';
import { ConnectionService } from '../core/services/connectionService';

@Injectable({
  providedIn: 'root'
})
export class AzureAdInterceptor implements HttpInterceptor {
  constructor(private msalInterceptor: MsalInterceptor,
    private connectionService: ConnectionService
  ) { }

  /** @remark To handle authentification correctly considering offline mode
   * we need to check if online before calling msalintereptor.intercept(). If offline
   * we just call next.handle(req) to avoid redirecting to login page.
   * 
   * Because the online check it also a request, it must be escaped to avoid infinite loop.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url == environment.apiBaseUrl + '/ping') {
      return next.handle(req);
    }

    if (req.url.startsWith(environment.apiBaseUrl)) {
      return from(this.handle(req, next));
    } else {
      return next.handle(req);
    }
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let isOnline = await this.connectionService.isOnline();
    
    //console.log('isOnline', isOnline);

    if (isOnline) {
      return await lastValueFrom(this.msalInterceptor.intercept(req, next));
    } else {
      return await lastValueFrom(next.handle(req));
    }
  }
}