import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

// Add UserId to requests when no authentication is configured to dernermine which
// user is to be considered by the server. User is set from localStorage so it can
// be changed on later request by the qa team to test various scenarios with different
// user. 
// THIS SHOULD NOT BE USED IN PRODUCTION.
export class AuthenticationNoneInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addUserIdOverride(request);

    return next.handle(request);
  }

  private addUserIdOverride(request) {
    let userId = localStorage.getItem(environment.userIdHeaderKey);
    if (userId) {
      return request.clone({
        headers: request.headers.set(environment.userIdHeaderKey, userId || ''),
      });
    }

    return request;
  }
}