<div class="layout-vertical" [class.compact]="!paging">
  <div class="layout-top">
    <div id="list-menu" class="layout-horizontal height-normal color-set-white"
      *ngIf="(numberOfSelectableItem !== 1 || enableFilter) && showHeader">
      <div class="layout-left">
        <button type="button" *ngIf="canEnableSelection && !selectionEnabled" (click)="enableSelection()"
          id="ListToggleSelectionButton">
          <i class="fa-solid fa-list-check"></i>
        </button>

        <button type="button" *ngIf="canToggleSelection && selectionEnabled" (click)="toggleSelection()"
          id="ListToggleSelectionButton">
          <i class="fa-regular fa-circle" [class.fa-circle-check]="hasAllPagedItemsSelected"></i>
        </button>

        <button type="button" *ngIf="enableFilter" id="ListEnableFilterButton"><i class="fas fa-filter"></i></button>
      </div>
      <div class="layout-center fill padding-right-normal" *ngIf="enableFilter">
        <input #inputFilter class="basic textFilter" type="search" (ngModelChange)="filterChanged()"
          [(ngModel)]="filter" id="ListFilterTextBox">
      </div>
      <div class="layout-right">
        <ng-container [ngTemplateOutlet]="rightOptionalButton">
        </ng-container>
      </div>
    </div>
  </div>
  <div class="layout-center">
    <div class="overlay-container" [class.hide-overflow]="showLoading">
      <div class="overlay-display" *ngIf="showLoading">
        <div class="layout-vertical">
          <div class="layout-top align-center">
            <div class="layout-horizontal rightPadding">
              <div class="layout-center align-center">
                <div class="overlay-box">
                  <button type="button"><i class="fas fa-spin fa-sync"></i> </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div *ngIf="!dataSourceResult?.itemCount && filter" class="layout-horizontal rightPadding">
          <div class="layout-left">
            <button id="ListEmptyFilterButton">
              <i class="fas fa-exclamation-triangle"></i>
              <div><span> {{ 'controls.dropdown.emptyFilter' | translate}}</span></div>
            </button>
          </div>
        </div>

        <div *ngIf="showInstructions && !dataSourceResult?.itemCount && !filter" class="layout-horizontal rightPadding">
          <div class="layout-left">
            <button id="ListEmptyButton"><i class="fas fa-exclamation-triangle"></i></button>
            {{ 'controls.dropdown.empty' | translate }}
          </div>
        </div>

        <div class="list" [class.list-grid]="displayGrid" [class.selectable]="numberOfSelectableItem !== 0"
          [class.hoverable]="hoverable">
          <div *ngFor="let group of dataSourceResult?.groups">
            <div *ngIf="group.key !== noGroupType">
              <div *ngIf="listOptions.mapGroupFunction && group[templateProperty]"
                [innerHtml]="group[templateProperty]">
              </div>
            </div>
            <div class="list-content">
              <div class="item" [class.selected]="isSelectedItem(item) && highLightSelectedItems"
                *ngFor="let item of dataSourceResult?.groupedItems[group.key]; let i = index"
                (click)="activateItem(item)" [id]="'ListItem-' + i">
                <div class="layout-horizontal">
                  <div class="layout-left" *ngIf="showCheckBoxes && (canToggleSelection || selectionEnabled)">
                    <button class="color-set-transparent-blue" (click)="toggleItemSelection($event, item)"
                      [id]="'ListToggleItemButton-' + i">
                      <i class="fa-regular" [class.fa-circle-check]="isSelectedItem(item)"
                        [class.fa-circle]="!isSelectedItem(item)"></i>
                    </button>
                  </div>
                  <div class="layout-center align-left">
                    <div *ngIf="listOptions.mapItemFunction && item[templateProperty]"
                      [innerHtml]="item[templateProperty]" class="width-100">
                    </div>
                    <div *ngIf="!listOptions.mapItemFunction" class="width-100">
                      <ng-container class="item" *ngTemplateOutlet="listTemplate; context: { $implicit: item }">
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="layout-bottom" *ngIf="paging">

    <div class="layout-horizontal"
      *ngIf="selectedItems?.length > 0 && numberOfSelectableItem !== 1 && numberOfSelectableItem !== 0 && showInstructions">
      <div class="layout-center">
        {{ 'list.selectedItems' | translate: { selected: selectedItems?.length, count: dataSourceResult?.itemCount} }}
      </div>
    </div>

    <app-paging [(page)]="page" (pageChange)="updateData()" [itemCount]="dataSourceResult?.itemCount || 0">
    </app-paging>

    <div class="layout-horizontal" *ngIf="showInstructions && selectionEnabled && numberOfRequiredItems === 1"
      [class.color-set-orange]="!selectedItems" [class.color-set-green]="selectedItems">

      <div class="layout-left">
        <button type="button">
          <i class="fas" [class.fa-check]="selectedItems" [class.fa-warning]="!selectedItems"></i>
          <div><span> {{ 'controls.dropdown.requiredItem' | translate }} </span></div>
        </button>
      </div>

    </div>

    <div class="layout-horizontal" *ngIf="showInstructions && selectionEnabled && numberOfRequiredItems > 1"
      [class.color-set-orange]="selectedItems.length < numberOfRequiredItems"
      [class.color-set-green]="selectedItems.length >= numberOfRequiredItems">

      <div class="layout-left">
        <button type="button">
          <i class="fas" [class.fa-check]="selectedItems.length >= numberOfRequiredItems"
            [class.fa-warning]="selectedItems.length < numberOfRequiredItems"></i>
          <div>
            <span>{{ 'list.requiredItems' | translate: { selected: selectedItems.length, count: numberOfRequiredItems} }}</span>
          </div>
        </button>
      </div>

    </div>

  </div>

</div>

<ng-container *ngIf="advancedMetadata">
  <app-list-option #listOption [fieldsMetadata]="advancedMetadata"></app-list-option>
</ng-container>
