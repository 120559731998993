// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class WFStep extends ChangeTrackingEntity {
  public static table: Dexie.Table<WFStep, string>;
  public static tableName: string = 'WFStep';

  public static ID: string = 'id';  public static IS_DELETED: string = 'isDeleted';  public static NAME: string = 'name';  public static NUMBER: string = 'number';  public static OLD_STEP_ID: string = 'oldStepId';  public static PERCENTAGE: string = 'percentage';  public static PROGRAM_ID: string = 'programId';  public static TIME_STAMP: string = 'timeStamp';  public static UPDATE_SUBSCRIPTION: string = 'updateSubscription';  public static VALIDATE_REQUIRED_FIELDS: string = 'validateRequiredFields';  public static W_F_PERMISSION_MODE_ID: string = 'wFPermissionModeId';

  public id: string;  public isDeleted: boolean;  public name: string;  public number: number;  public oldStepId: string;  public percentage: number;  public programId: string;  public timeStamp: Date;  public updateSubscription: boolean;  public validateRequiredFields: boolean;  public wFPermissionModeId: number;

  public constructor(args?: Partial<WFStep>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return WFStep.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(WFStep.tableName);
      this.table.mapToClass(WFStep);
  }
}
