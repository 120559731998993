import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GoogleMap, MapDirectionsService, MapInfoWindow } from '@angular/google-maps';
import _ from 'lodash';
import { TaskFilter } from 'src/app/core/data/models/databaseLocal/taskFilter.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { Subscription } from 'rxjs';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { Router } from '@angular/router';
import { HeaderStateLeftButtonType } from 'src/app/components/headers/header/headerStateLeftButtonType';
import { GeolocationService } from 'src/app/core/geolocationService';
import { ListComponent } from 'src/app/components/list/list.component';
import { CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { TaskListService } from '../task-list/taskListService';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { TranslateService } from '@ngx-translate/core';
import { MapWaypoint } from './mapWaypoint';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { TaskListItemViewModel } from '../task-list/taskListItemViewModel';

// Reference: https://timdeschryver.dev/blog/google-maps-as-an-angular-component#angular

@Component({
  selector: 'app-task-map',
  templateUrl: './task-map.component.html',
  styleUrls: ['./task-map.component.scss']
})
export class TaskMapComponent implements OnInit, OnDestroy {
  private longitudeField: string;
  private latitudeField: string;
  private backButtonSubscription: Subscription;

  public static notSelectedMapMarkerPropertyName: string = "nspek-38957f6a57204018a32c5c2fab3f4dfa";
  public static selectedMapMarkerPropertyName: string = "nspek-2ba2429d34d8487e97169473c3eea682";

  apiLoaded: Observable<boolean>;

  currentLocation: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;

  directionEnabled: boolean = false;
  directionResult: google.maps.DirectionsResult;
  directionDetailEnabled: boolean = false;

  waypointOptimizationInformation: string;

  directionActionEnabled: boolean = true;

  warningMessage: string = "";

  templatePropertyResult: string = ListComponent.templatePropertyField;

  tasks: TaskListItemViewModel[] = [];

  public googleMap: google.maps.Map;

  public activeTask = null;
  public waypoints: MapWaypoint[] = [];

  private selectedTasks: any[] = [];

  private taskFilter: TaskFilter;

  public regionOptions = {
    strokeColor: '#000000',
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: '#CACACA',
    fillOpacity: 0.7,
    paths: [
      [
        { lat: 44.980973, lng: -74.626375 },
        { lat: 46.134841, lng: -74.626375 },
        { lat: 46.134841, lng: -72.83423 },
        { lat: 44.980973, lng: -72.83423 },
        { lat: 44.980973, lng: -74.626375 },
      ],
      [
        { lat: 45.306227, lng: -74.138857 },
        { lat: 45.29222, lng: -73.79931 },
        { lat: 45.316971, lng: -73.643013 },
        { lat: 45.33978, lng: -73.527914 },
        { lat: 45.34328, lng: -73.487659 },
        { lat: 45.407551, lng: -73.340288 },
        { lat: 45.631987, lng: -73.309045 },
        { lat: 45.710796, lng: -73.309217 },
        { lat: 45.732967, lng: -73.332735 },
        { lat: 45.737415, lng: -73.394662 },
        { lat: 45.736112, lng: -73.462082 },
        { lat: 45.714092, lng: -73.54817 },
        { lat: 45.712234, lng: -73.657175 },
        { lat: 45.65602, lng: -73.784891 },
        { lat: 45.580528, lng: -73.885141 },
        { lat: 45.428276, lng: -74.187265 },
        { lat: 45.306227, lng: -74.138857 },
      ]
    ]
  }

  public markers = [];

  public mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    fullscreenControl: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    // Hide default icons on the maps like restaurants, stores, etc.
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      }
    ]
    // ,
    // restriction: {
    //   latLngBounds: {
    //     north: 45.749983,
    //     south: 45.28,
    //     west: -74.1,
    //     east: -73.34,
    //   },
    //   strictBounds: false,
    // }
  }

  constructor(
    public mapDirectionsService: MapDirectionsService,
    private headerState: HeaderState,
    private router: Router,
    private taskListService: TaskListService,
    private baseRepository: BaseRepository,
    private translateService: TranslateService
  ) {

  }

  ngOnDestroy(): void {
    this.backButtonSubscription.unsubscribe();

    this.headerState.setDefaultButtonState();

    this.headerState.title = "";
  }

  async ngOnInit(): Promise<void> {
    this.taskFilter = await TaskFilter.table.toCollection().first();

    let taskType = await TaskType.table.get(this.taskFilter.taskTypeId);

    let displayOptions = await this.taskListService.getDisplayOptions(this.taskFilter);

    let taskResult = await this.taskListService.tryGetTasks(this.taskFilter, displayOptions);

    if (taskResult.success){
      this.tasks = taskResult.value;

      this.selectedTasks = await this.taskListService.getSelectedTasks(this.taskFilter.optionListId, this.tasks);
    }
    else{
      this.warningMessage = taskResult.validationDictionary.getFirstValidationMessage();
    }

    this.longitudeField = taskType.longitudeField;
    this.latitudeField = taskType.latitudeField;

    navigator.geolocation.getCurrentPosition((position) => {
      this.currentLocation = GeolocationService.getCurrentLocation(position);

      this.setMarkers();
    });

    this.backButtonSubscription = this.headerState.backButtonClick.subscribe(() => {
      this.navigateToList();
    });

    this.headerState.leftButtonType = HeaderStateLeftButtonType.backButton;

    this.headerState.changeLeftButton("tasks")
  }

  private navigateToList() {
    this.router.navigate([`/tasks`]);
  }


  public toggleDirectionDetails() {
    this.directionDetailEnabled = !this.directionDetailEnabled;
  }

  public async toggleDirections() {
    if (this.selectedTasks.length <= 0) {
      return;
    }

    let canToggle: boolean;

    if (!this.directionEnabled) {
      this.clearMarkers();
      canToggle = await this.setDirection();

      if (!canToggle){
        this.clearDirection();
        this.setMarkers();
      }
    } else {
      this.clearDirection();
      this.setMarkers();

      canToggle = true;
    }

    if (canToggle){
      this.directionEnabled = !this.directionEnabled;

      await this.saveTaskList();
    }
  }

  private async setDirection() : Promise<boolean> {
    let result: boolean = false;

    this.warningMessage = "";

    if (this.taskFilter.optimizeWaypoints)
      this.waypointOptimizationInformation = this.translateService.instant("map.directions.optimizedMessage");
    else
      this.waypointOptimizationInformation = this.translateService.instant("map.directions.NotOptimizedMessage");

    let request: google.maps.DirectionsRequest = {
      origin: this.currentLocation,
      waypoints: this.selectedTasks.map((task) => {
        return { location: this.getLatLng(task, this.latitudeField, this.longitudeField), stopover: true } as google.maps.DirectionsWaypoint;
      }),
      destination: this.currentLocation,
      optimizeWaypoints: !!this.taskFilter.optimizeWaypoints,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    let mapDirectionResponse = await this.mapDirectionsService.route(request).toPromise() as any;

    this.directionResult = mapDirectionResponse.result;

    let waypoints = [];
    let waypointsOrder = mapDirectionResponse?.result.routes[0]?.waypoint_order;

    if (mapDirectionResponse.status == "ZERO_RESULTS"){
      this.warningMessage = this.translateService.instant("map.directions.zeroResults");
    }
    else{
      let legs = mapDirectionResponse.result.routes[0].legs;

      let nextLetter = "B";

      for (let index = 0; index < waypointsOrder.length; index++) {
        const waypoint = waypointsOrder[index];

        let newWayPoint = new MapWaypoint({ index: waypoint });

        newWayPoint.distance = legs[index].distance.text;
        newWayPoint.duration = legs[index].duration.text;
        newWayPoint.letter = nextLetter;

        nextLetter = String.fromCharCode(nextLetter.charCodeAt(nextLetter.length - 1) + 1);

        newWayPoint.text =
          this.selectedTasks[newWayPoint.index][ListComponent.templatePropertyField];

        waypoints.push(newWayPoint);
      }

      result = true;
    }

    this.waypoints = waypoints;

    return result;
  }

  private clearDirection() {
    this.directionResult = null;
  }

  mapClick() {
    this.activeTask = null;
  }

  markerClick(marker) {
    marker.toggle(marker);
  }

  private setMarkers() {
    for (let task of this.tasks) {
      this.markers.push({
        position: this.getLatLng(task, this.latitudeField, this.longitudeField),
        icon: this.getIcon(task),
        toggle: (marker) => {
          this.activeTask = task;

          let index = _.findIndex(this.selectedTasks, task);
          if (index >= 0) {
            this.selectedTasks.splice(index, 1);
            this.activeTask = null;
          } else {
            this.selectedTasks.push(task);
            this.activeTask = task;
          }
          marker.icon = this.getIcon(task);
        }
      });
    }

    if (this.tasks.length > 0) {
      this.center = this.getLatLng(this.tasks[0], this.latitudeField, this.longitudeField);
    } else {
      this.center = this.currentLocation;
    }
  }

  private clearMarkers() {
    this.markers = [];
  }

  private getLatLng(task, latitudeKey, longitudeKey) {
    return { lat: Number.parseFloat(task[latitudeKey]), lng: Number.parseFloat(task[longitudeKey]) };
  }

  private getIcon(task: TaskListItemViewModel) {
    let url = "";

    if (_.findIndex(this.selectedTasks, task) >= 0) {
      url = task[TaskMapComponent.selectedMapMarkerPropertyName]

      if (!url)
        url = this.getCarIcon("selected-map-marker");
    }
    else {
      url = task[TaskMapComponent.notSelectedMapMarkerPropertyName]

      if (!url)
        url = this.getCarIcon("unselected-map-marker");
    }

    return url;
  }

  private getCarIcon(name: string) {
    return `./../../../assets/icons/${name}.png`;
  }

  public openMap(task) {
    try {
      // https://developers.google.com/maps/documentation/urls/get-started
      let currentWindow: any = window as any;
      currentWindow.open(`https://www.google.com/maps/dir/?api=1&origin=${this.currentLocation.lat},${this.currentLocation.lng}&destination=${task[this.latitudeField]},${task[this.longitudeField]}`);
    } catch (e) {
      console.log(e);
    }
  }

  async refreshDirectionsClick() {
    await this.saveTaskList();

    this.clearMarkers();
    await this.setDirection();
  }

  async saveTaskList() {
    await this.taskListService.saveTasks(this.selectedTasks, this.taskFilter.optionListId, this.taskFilter.taskTypeId);
  }

  async toggleWaypointsOptimizationClick() {
    await this.changeWaypointsOptimization(!this.taskFilter.optimizeWaypoints);

    if (!this.taskFilter.optimizeWaypoints) {
      let position: number = 1;

      for (const waypoint of this.waypoints) {
        this.selectedTasks[waypoint.index].position = position;

        position += 1;
      }

      this.selectedTasks = _.orderBy(this.selectedTasks, x => x.position);
    }

    this.clearMarkers();
    await this.setDirection();
  }

  async changeWaypointsOptimization(value: boolean) {
    this.taskFilter.optimizeWaypoints = value;

    await this.baseRepository.update(TaskFilter.table, this.taskFilter);
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedTasks, event.previousIndex, event.currentIndex);
    moveItemInArray(this.waypoints, event.previousIndex, event.currentIndex);

    let position = 1;

    for (const task of this.selectedTasks) {
      task.position = position;
      position += 1;
    }
  }

  directionActionEnabledClick() {
    this.directionActionEnabled = !this.directionActionEnabled;
  }
}
