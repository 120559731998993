import { Injectable } from '@angular/core';

import { AppMiddleware } from './app.middleware';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppResolver   {

  constructor(
    private appMiddleware: AppMiddleware,
    private router: Router
  ) { }

  async resolve(): Promise<boolean> {
    let validationDictionary = await this.appMiddleware.run();

    if (!validationDictionary.isValid()){
      this.router.navigate(["unauthorized"]);

      return false;
    }
  }
}
