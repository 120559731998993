<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'dataSource.result.title' | translate}} ({{ itemCount }})
      </div>

      <div class="layout-right">
        <button class="color-set-transparent" [disabled]="!selectedDataSource || !allowEdit" (click)="deleteClick()">
          <i class="fas fa-solid fa-trash"></i>
        </button>

        <button class="color-set-transparent" (click)="addClick()" [disabled]="!allowEdit">
          <i class="fas fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  </div>

  <app-list #list [dataSource]="dataSource" 
    (activatedItem)="navigateToDatasource($event)"
    (selectedItemsChange)="selectedItemsChange($event)" 
    [numberOfRequiredItems]="0" 
    [selectionEnabled]="true" 
    [showCheckBoxes]="false"
    [enableFilter]="true"
    [displayLoading]="false"
    [showInstructions]="false">
  <ng-template #listTemplate let-item>
    <div class="layout-center">
      <div class="layout-horizontal height-normal padding-vertical-normal padding-left">
        <div class="layout-left">
          <div [innerHtml]="item.description">
          </div>
        </div>

        <div class="layout-right">
          <button class="color-set-transparent" (click)="resultItemClick(item)" [disabled]="!allowEdit">
            <i class="fas fa-solid fa-pen"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</app-list>

  <app-data-source-importation-result-item #resultItemPopup minWidth="90%" [dataSourceImportationId]="id"></app-data-source-importation-result-item>
</div>

<app-modal #modalPopup></app-modal>