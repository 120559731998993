import { IndexableType, Table } from "dexie"
import { ChangeTrackingEntity } from "./changeTracking/changeTrackingEntity"

export class TableEntity {
  entity: ChangeTrackingEntity | ChangeTrackingEntity[]
  table: Table<ChangeTrackingEntity, IndexableType>

  constructor(table: Table<ChangeTrackingEntity, IndexableType>, entity: ChangeTrackingEntity | ChangeTrackingEntity[]) {
    this.entity = entity
    this.table = table
  }
}