import { Mobile } from "../models/database/mobile.database";
import platform from 'platform';
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { Injectable } from "@angular/core";
import { MobileHttpClient } from "./mobileHttpClient";
import { SynchronizationHttpClient } from "./synchronizationHttpClient";
import { ValidationDictionary } from "../../validationDictionary";
import { Validation } from "../../validation";
import { SynchronizationService } from "./synchronizationService";

@Injectable({
  providedIn: 'root',
})
export class InitializeMobileCommand {
  constructor(
    private mobileHttpClient: MobileHttpClient,
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient
  ) { }

  private newMobile = new Mobile({ description: platform.description });

  private async canSkip(): Promise<boolean> {
    let mobileIdParamater = await LocalParameter.table.get(LocalParameter.MOBILE_ID_PARAMETER_NAME);

    return mobileIdParamater && mobileIdParamater.value;
  }

  public async run(newMobile = this.newMobile): Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        if (this.synchronizationService.offline) {
          console.log("Browser need to be online to initialize this device.")
        }

        // Retrive mobileId from local storage to detect if the mobile
        // used to exist but was lost due to database supression. If found,
        // on mobile creation, server will ensure reset of the mobile and resuse
        // the same id
        let localStorageMobileId = localStorage.getItem(LocalParameter.MOBILE_ID_PARAMETER_NAME)

        if (localStorageMobileId) {
          this.newMobile.id = localStorageMobileId;
        }

        let mobile = await this.mobileHttpClient.createMobile(this.newMobile)

        localStorage.setItem(LocalParameter.MOBILE_ID_PARAMETER_NAME, mobile.id);

        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.MOBILE_ID_PARAMETER_NAME,
          value: mobile.id.toUpperCase()
        }));

        this.synchronizationService.mobileId = mobile.id.toUpperCase();
        await this.synchronizationService.restoreMobileState(mobile.id.toUpperCase());
      }

      let mobileIdParameter = await LocalParameter.table.get(LocalParameter.MOBILE_ID_PARAMETER_NAME);

      if (this.synchronizationService.mobileId != mobileIdParameter.value) {
        this.synchronizationService.mobileId = mobileIdParameter.value;
      }

      if (!this.synchronizationService.offline) {
        await this.updateRequireSynchronizationFlag();
      }
    } catch (error) {
      validationDictionary.add(new Validation({ key: "InitializeMobileCommand", message: error.message }));
    }

    return validationDictionary;
  }

  private async updateRequireSynchronizationFlag() {
    let synchronization = await this.synchronizationHttpClient.getLastSynchronization();
    let latestChangeTrackingNumber = await this.synchronizationHttpClient.getLastestChangeTrackingToApplyForMobile();

    this.synchronizationService.requireSynchronization = latestChangeTrackingNumber === 0 || latestChangeTrackingNumber > synchronization.toChangeTrackingId || !synchronization.completed;
  }
}
