// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DataSourceImportationType extends ChangeTrackingEntity {
  public static table: Dexie.Table<DataSourceImportationType, number>;
  public static tableName: string = 'DataSourceImportationType';

  public static DESCRIPTION: string = 'description';  public static ID: string = 'id';  public static TIME_STAMP: string = 'timeStamp';

  public description: string;  public id: number;  public timeStamp: Date;

  public constructor(args?: Partial<DataSourceImportationType>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DataSourceImportationType.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DataSourceImportationType.tableName);
      this.table.mapToClass(DataSourceImportationType);
  }
}
