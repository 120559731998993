import Dexie from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class FormSearchOption extends ChangeTrackingEntity {
  public static table: Dexie.Table<FormSearchOption, string>;
  public static tableName: string = 'FormSearchOption';

  public constructor(args?: Partial<FormSearchOption>) {
    super();

    Object.assign(this, args);
  }

  public id: string;
  public templateIds: string; // JSON Array
  public assignedToIds: string; // JSON Array
  public number: string;
  public externalNumber: string;
  public workflowStepNames: string; // JSON Array
  public fromCreatedDate: Date;
  public toCreatedDate: Date;
  public fromUpdatedDate: Date;
  public toUpdatedDate: Date;
  public synchronizedOnly: boolean = false;
  public customFieldSearchOperators: string; // JSON Array

  public getDatabaseTableName() {
    return FormSearchOption.tableName;
  }

  public getDatabasePrimaryKeyType() {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(FormSearchOption.tableName);
    this.table.mapToClass(FormSearchOption);
  }
}
