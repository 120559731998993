import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/data/enums/userType'
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/core/services/permissionService';
import { PermissionType } from 'src/app/core/data/enums/permissionType';
import { ListComponent } from 'src/app/components/list/list.component';
import { IAuthenticationService } from 'src/app/authentication/iAuthenticationService.interface';
import { AuthenticationServiceFactory } from 'src/app/authentication/iAuthenticationService.factory';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';

@Component({
  templateUrl: './mainMenu.component.html',
  styleUrls: ['./mainMenu.component.scss']
})

export class MainMenuComponent implements OnInit {
  public menuItems = [];
  public adminMenuItems = [];
  public userTypes = UserType;
  public navigator = navigator;

  @ViewChild('menuList') menuList: ListComponent;

  public authenticationService: IAuthenticationService;

  constructor(
    private router: Router,
    public authenticatedUser: AuthenticatedUser,
    public authenticationServiceFactory: AuthenticationServiceFactory,
    public synchronizationService: SynchronizationService,
    private location: Location,
    private permissionService: PermissionService
  ) {
    this.authenticationService = this.authenticationServiceFactory.get();
  }

  public async ngOnInit(): Promise<void> {
    this.location.replaceState('/home');

    await this.createMenu();

    this.menuList.updateData();
  }

  private async createMenu(): Promise<void> {
    this.menuItems.push({
      title: 'home.menuItems.forms',
      href: '/forms',
      icon: 'fas fa-list'
    });

    this.menuItems.push({
      title: 'home.menuItems.programSubscriptionManagement',
      href: '/program-subscription-management',
      icon: 'fa-solid fa-cloud-arrow-down',
      requireOnline: true
    });

    if (environment.enableFeatures.tasks) {
      this.menuItems.push({
        title: 'home.menuItems.taskListTitle',
        href: '/tasks',
        icon: 'fas fa-list-check',
        requireOnline: true
      });
    }

    this.menuItems.push({
      title: 'home.menuItems.profile',
      href: '/profile',
      icon: 'far fa-user'
    });

    if (environment.enableFeatures.dataSourceImportations && await this.permissionService.hasAccess(PermissionType.DataSources)) {
      this.adminMenuItems.push({
        title: 'home.menuItems.dataSourceImportations',
        href: '/administration/data-source-importations',
        requireOnline: true,
        icon: 'bi-card-list'
      });
    }

    if (environment.enableFeatures.tasks && await this.permissionService.hasAccess(PermissionType.TaskTypes)) {
      this.adminMenuItems.push({
        title: 'home.menuItems.taskTypeListTitle',
        href: '/administration/task-types',
        icon: 'fas fa-list-check',
        requireOnline: true,
      });
    }

    this.adminMenuItems.push({
      title: 'home.menuItems.systemHealth',
      href: '/administration/health',
      icon: 'fas fa-heartbeat',
      requireOnline: true
    });

    this.adminMenuItems.push({
      title: 'home.menuItems.systemInformation',
      href: '/system',
      icon: 'fas fa-info'
    });
  }

  public goToLink(item): void {
    if (item.requireOnline && this.synchronizationService.offline) {
      return;
    }

    this.router.navigate([item.href]);
  }
}
