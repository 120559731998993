import { environment } from "src/environments/environment";

export class AlertService {
  public static show(message: string) {
    if (environment.disableAlerts) {
      console.warn(message);
    } else {
      alert(message);
    }
  }
}