import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SynchronizationHttpClient } from "../data/synchronization/synchronizationHttpClient";
import { SynchronizationService } from "../data/synchronization/synchronizationService";
import { PopupUtility } from "src/app/components/popup/popup.utility";
import { SimplePopupComponent } from "src/app/components/popup/simplePopup/simplePopup.component";

@Injectable({
   providedIn: 'root'
})
export abstract class AdministrativeEntitySynchronizationService {
   constructor(
      private route: ActivatedRoute,
      private synchronizationService: SynchronizationService
   ) { }

   public async synchronize() {
      this.route.queryParams.subscribe(async params => {
         if (!params.skipSynchronization) {
            await this.synchronizationService.getUpdates(true);
         }
      });
   }

}