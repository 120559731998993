import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { CustomFieldValueItemRepository } from "src/app/core/data/repositories/customFieldValueItemRepository";
import { AuditState } from 'src/app/pages/audit/auditState';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadioButtonComponent extends BaseControlComponent implements OnInit, OnDestroy {
  constructor(
    private customFieldValueItemRepository: CustomFieldValueItemRepository,
    private auditState: AuditState,
    private translateService: TranslateService) {
    super();
  }

  dataSourceId: string;

  readOnly: boolean = false;

  items: DataSourceItem<string>[];

  private alertConditionChangedSubscription: Subscription;

  ngOnInit(): void {
    super.ngOnInit();

    this.dataSourceId = super.getExtendedProperty("DataSourceId") as string;

    let sortOrder = super.getExtendedProperty("SortOrder") as number;
    let sortProperty: string = "description";

    switch (sortOrder) {
      case 2:
        sortProperty = "position";
        break;
    }

    this.readOnly = this.auditState.readonly;

    this.customFieldValueItemRepository.getItemsByListId(this.dataSourceId, sortProperty, this.form.controls[this.input.key].value).then(x => {
      this.items = x.map(x => new DataSourceItem<string>(x.id, x.description));
    });

    if (this.input.alert) {
      this.alertConditionChangedSubscription = this.input.$onAlertConditionChanged.subscribe(async args => {
        let formattedValue = this.formControl.value;

        if (formattedValue === undefined || formattedValue === null)
          formattedValue = "";
        else
          formattedValue = formattedValue ? this.translateService.instant("alert.yes") : this.translateService.instant("alerts.no");

        this.auditState.addAlertSummary(this.input, args.condition?.id, formattedValue, this.auditState.instanceId ? this.auditState.customTableId : "");
      })
    }
  }

  handleValueChange(e) {
    if (e.component.skipOnValueChanged) {
      e.component.skipOnValueChanged = false;

      return;
    }

    this.checkForAlert();
  }

  private checkForAlert() {
    const controlValue = this.formControl.value;

    this.input.referenceTableId = this.auditState.customTableId;

    this.input.value = controlValue == undefined ? null : controlValue;
  }

  ngOnDestroy(): void {
    this.alertConditionChangedSubscription?.unsubscribe();
  }

}
