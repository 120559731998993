<app-popup #popup [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal padding-left height-normal color-set-blue">
        <div class="layout-left">
          <h1>{{ 'dataSourceSelector.title' | translate}}</h1>
        </div>
      </div>
    </div>

    <div class="layout-center">
      <div *ngIf="!idParentKey" class="layout-center" class="padding-normal">
        <dx-tree-list [dataSource]="dataSourceItems" [keyExpr]="idKey" [columnAutoWidth]="true" [wordWrapEnabled]="true"
          [showBorders]="true" (onRowClick)="selectedItemsChange($event)" [showColumnHeaders]="false"
          [showBorders]="false" searchMode="contains" dataStructure="plain">
          <dxo-search-panel [visible]="true" width="auto"></dxo-search-panel>
          <dxo-selection mode="single"></dxo-selection>

          <dxi-column *ngFor="let field of fieldSchema" [dataField]="field.key" [allowSearch]="field.allowSearch"
            [sortIndex]="field.sortIndex" [sortOrder]="field.sortOrder || 'asc'" [visible]="field.visible || false ">
          </dxi-column>
        </dx-tree-list>
      </div>

      <div *ngIf="idParentKey" class="layout-center" class="padding-normal">
        <dx-tree-list [dataSource]="dataSourceItems" [keyExpr]="idKey" [parentIdExpr]="idParentKey"
          [columnAutoWidth]="true" [wordWrapEnabled]="true" [showBorders]="true"
          (onRowClick)="selectedItemsChange($event)" [showColumnHeaders]="false" [showBorders]="false"
          searchMode="contains">
          <dxo-search-panel [visible]="true" width="auto"></dxo-search-panel>
          <dxo-selection mode="single"></dxo-selection>

          <div *dxTemplate="let cell of 'folderIconCellTemplate'">
            <div *ngIf="cell.row.node?.children?.length" (click)="toggleRow(cell)">
              <i *ngIf="!cell.row.isExpanded" class="fas fa-folder"></i>
              <i *ngIf="cell.row.isExpanded" class="fas fa-folder-open"></i>
            </div>
          </div>
          <dxi-column cellTemplate="folderIconCellTemplate" [minWidth]="70"></dxi-column>
          <dxi-column *ngFor="let field of fieldSchema" [dataField]="field.key" [allowSearch]="field.allowSearch"
            [sortIndex]="field.sortIndex" [sortOrder]="field.sortOrder || 'asc'" [visible]="field.visible"></dxi-column>
        </dx-tree-list>
      </div>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-right space">
          <button type="submit" [disabled]="!selectedItem" class="color-set-green compact" (click)="okButtonClick()">
            <div><span>{{ 'dataSourceSelector.apply' | translate}}</span></div>
          </button>

          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ 'dataSourceSelector.cancel' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>
