import { AlertControlCondition } from "src/app/components/customFields/controls/alert/alertControlCondition";

export class AlertSummary {
  id: string;
  auditId: string;
  controlKey: string;
  controlDescription: string;
  createdDate: string;
  createdBy: string;
  value: string;
  source: string;
  description: string;
  condition: AlertControlCondition;
  category: string;
  statusClass: string;
  instruction: string;

  public constructor(args?: Partial<AlertSummary>) {
    Object.assign(this, args);
  }
}
