export enum PermissionType {
    GroupAdd = 'permissions.userGroup.add',
    GroupView = 'permissions.userGroup.visualize',
    GroupDelete = 'permissions.userGroup.delete',
    FormAdd = 'permissions.audit.add',
    FormEdit = 'permissions.audit.edit',
    FormDelete = 'permissions.audit.delete',
    FormEditOthers = 'permissions.audit.editOthers',
    AlertManagement = 'permissions.warningInstruction.conditions',
    InstructionManagement = 'permissions.warningInstruction.instructions',
    Mobiles = 'permissions.general.mobiles',
    DataSources = 'permissions.general.dataSources',
    ImageSize = 'permissions.general.imageSize',
    TaskTypes = 'permissions.general.taskTypes'
}
